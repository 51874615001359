import { takeEvery, put, call } from "redux-saga/effects";

import { RANK } from "./actionTypes";
import { rankSuccess, rankError } from "./actions";

import { getRank as apiGetRank } from "src/api";
function* getRank() {
    try {
        const response = yield call(apiGetRank);
        const data = response.data;
        yield put(rankSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(rankError(message));
    }
}

function* authSaga() {
    yield takeEvery(RANK, getRank);
}

export default authSaga;
