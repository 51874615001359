import {
    COIN,
    COIN_SUCCESS,
    COIN_ERROR,
    COIN_UPDATE,
  } from "./actionTypes"
  
  export const getCoin = (coinAllowed) => {
    return {
      type: COIN,
      payload:coinAllowed
    }
  }
  export const updateCoin = (payload) => {
    return {
      type: COIN_UPDATE,
      payload:payload
    }
  }
  export const coinSuccess = message => {
    return {
      type: COIN_SUCCESS,
      payload: message,
    }
  }
  
  export const coinError = message => {
    return {
      type: COIN_ERROR,
      payload: message,
    }
  }
  