import React from "react";
import { useHistory, Link } from "react-router-dom";
const PreRegisteredUser = (props) => {
    const history = useHistory();
    const propsState = props.location.state;

    const backToSignup = () => {
        try {
            history.push(propsState.prevPath);
        } catch (error) {
            history.push("/auth/signin");
        }
    };
    return (
        <div className="sc-dbhs">
            <header className="or-header">
                <button className="back-button me-auto" onClick={backToSignup}>
                    <i className="fa-solid fa-angle-left back-icon"></i>
                </button>
            </header>
            <div className="dbhs-content" style={{ padding: 20 }}>
                Dear BIDBOX Users,
                <br />
                <br />
                BIDBOX would like to congratulate all users who have activated
                our AI Service during the pre-registration period. You are all a
                part of how BIDBOX is born.
                <br />
                <br />
                We are also proudly announcing that the registration period for
                new users will be open on 8 October 2022 at 15.00 PM (GMT
                +00.00). BIDBOX is now open to international market as well, and
                is ready to compete as a crypto trading tool that can be used by
                anyone and from any country.
                <br />
                <br />
                However, to prepare the system, we apologize that our AI System
                will not be producing any signals until the registration for new
                users is open.
                <br />
                <br />
                We have a great vision to become the best crypto trading tool in
                the world and complete the ecosystem of the crypto industry, and
                it all starts with you.
                <br />
                <br />
                Thank you.
                <br />
                <br />
                <br />
                Regards,
                <br />
                BIDBOX
            </div>
        </div>
    );
};

export default PreRegisteredUser;
