import {
    TICKET_HISTORY,
    TICKET_HISTORY_SUCCESS,
    TICKET_HISTORY_ERROR,
    TICKET_CREATE,
    TICKET_CREATE_SUCCESS,
    TICKET_CREATE_ERROR,
    TICKET_REPLY,
    TICKET_REPLY_SUCCESS,
    TICKET_REPLY_ERROR,
    TICKET_INIT,
} from "./actionTypes";

export const ticketInit = () => {
    return {
        type: TICKET_INIT,
    };
};

export const ticketHistory = (payload) => {
    return {
        type: TICKET_HISTORY,
        payload: payload,
    };
};

export const ticketHistorySuccess = (payload) => {
    return {
        type: TICKET_HISTORY_SUCCESS,
        payload: payload,
    };
};

export const ticketHistoryError = (payload) => {
    return {
        type: TICKET_HISTORY_ERROR,
        payload: payload,
    };
};

export const ticketCreate = (payload) => {
    return {
        type: TICKET_CREATE,
        payload: payload,
    };
};

export const ticketCreateSuccess = (payload) => {
    return {
        type: TICKET_CREATE_SUCCESS,
        payload: payload,
    };
};

export const ticketCreateError = (payload) => {
    return {
        type: TICKET_CREATE_ERROR,
        payload: payload,
    };
};

export const ticketReply = (payload) => {
    return {
        type: TICKET_REPLY,
        payload: payload,
    };
};

export const ticketReplySuccess = (payload) => {
    return {
        type: TICKET_REPLY_SUCCESS,
        payload: payload,
    };
};

export const ticketReplyError = (payload) => {
    return {
        type: TICKET_REPLY_ERROR,
        payload: payload,
    };
};
