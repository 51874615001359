import React, { useState } from "react";

const WAM = () => {
    const [tabNewAddress, setTabNewAddress] = useState(false);

    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Withdrawal address
                </span>
                <button
                    className="back-button"
                    onClick={() => setTabNewAddress(!tabNewAddress)}
                >
                    <i className="fa-solid fa-plus back-icon"></i>
                </button>
            </header>
            {/* <div className="card mb-2 card-wam">
                <div className="card-body">
                    <div className="on-header d-flex align-items-center">
                        <p className="m-0 fz:15 text-uppercase">USDT</p>
                        <div className="badge badge-indigo text-uppercase">
                            TRC20
                        </div>
                    </div>
                    <p className="my-1 fz:13 text-gray">
                        TRC4fysB2Rm5WnZ73FGZ2tGHrhUwnm4zMn
                    </p>
                </div>
                <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 fz:14 text-dark">TokoCrypto jihan</p>
                        <div
                            className="d-flex align-items-center"
                            style={{ columnGap: "5px" }}
                        >
                            <button className="btn-oncard">
                                <i className="fa-regular fa-star"></i>
                            </button>
                            <button className="btn-oncard">
                                <i className="fa-regular fa-pen-to-square"></i>
                            </button>
                            <button className="btn-oncard">
                                <i className="fa-regular fa-trash-can"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-2 card-wam">
                <div className="card-body">
                    <div className="on-header d-flex align-items-center">
                        <p className="m-0 fz:15 text-uppercase">USDT</p>
                        <div className="badge badge-indigo text-uppercase">
                            TRC20
                        </div>
                    </div>
                    <p className="my-1 fz:13 text-gray">
                        TRC4fysB2Rm5WnZ73FGZ2tGHrhUwnm4zMn
                    </p>
                </div>
                <div className="card-footer">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 fz:14 text-dark">TokoCrypto jihan</p>
                        <div
                            className="d-flex align-items-center"
                            style={{ columnGap: "5px" }}
                        >
                            <button className="btn-oncard">
                                <i className="fa-regular fa-star"></i>
                            </button>
                            <button className="btn-oncard">
                                <i className="fa-regular fa-pen-to-square"></i>
                            </button>
                            <button className="btn-oncard">
                                <i className="fa-regular fa-trash-can"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className={tabNewAddress ? "form-add show" : "form-add"}>
                <div
                    className={
                        tabNewAddress
                            ? "form-add-validation show"
                            : "form-add-validation"
                    }
                >
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h1 className="m-0 fz:16 text-dark fw:500">
                            Add new address
                        </h1>
                        <button
                            className="back-button"
                            onClick={() => setTabNewAddress(false)}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <form>
                        <ul className="list-group">
                            <li className="list-btn py-1 px-2 fz:14">
                                <span className="fw:500 text-dark">
                                    Currency
                                </span>
                                <span className="fw:500 text-dark text-uppercase">
                                    USDT
                                </span>
                            </li>
                            <li className="list-btn py-1 px-2 fz:14">
                                <span className="fw:500 text-dark">Chain</span>
                                <span className="fw:500 text-dark text-uppercase">
                                    TRC-20
                                </span>
                            </li>
                            <li className="list-btn py-1 px-2 fz:14">
                                <span className="fw:500 text-dark">
                                    Address
                                </span>
                                <input
                                    type="text"
                                    placeholder="Enter address here"
                                    className="form-add-input"
                                />
                            </li>
                            <li className="list-btn py-1 px-2 fz:14">
                                <span className="fw:500 text-dark">
                                    Wallet Label
                                </span>
                                <input
                                    type="text"
                                    placeholder="Enter label here"
                                    className="form-add-input"
                                />
                            </li>
                        </ul>
                        <button className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white text-uppercase">
                            Confirm
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default WAM;
