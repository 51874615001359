import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Binance from "../../assets/icons/coin/binance.png";
import Tokocrypto from "../../assets/icons/coin/tko.png";
import Banner from "../../components/banner/Banner";
import Modal from "../../components/modal/Modal";
import "src/assets/css/horizontal-menu.css";
import { useSelector, useDispatch } from "react-redux";
import HelpIcon from "src/assets/icons/account/help.png";
import IconAutoSLGrey from "src/assets/icons/icon_auto_sl_grey.png";
import IconAveragingGrey from "src/assets/icons/icon_averaging_grey.png";
import IconSLGrey from "src/assets/icons/icon_sl_grey.png";
import IconConvertToBNBBlue from "src/assets/icons/icon_convert_to_bnb_blue.png";

import {
    exchangeInit,
    getBotSetting,
    getBotStatus,
    getBotPlugin,
    initIPBinding,
    botSetting,
    botUpgrade,
    exchangeActivation,
    setRequestTrade,
} from "src/store/actions";
import toast from "react-hot-toast";
const Botsetting = (props) => {
    const dispatch = useDispatch();
    const bot = useSelector((state) => state.Bot);
    const [botSignal, setBotSignal] = useState(0);
    const trades = useSelector((state) => state.Trades);
    const ip_binding = useSelector((state) => state.IpBinding);
    const [amountPosition, setAmountPosition] = useState("");
    const [signalQty, setSignalQty] = useState("");

    const uri = [
        { id: 1, name: "binding-binance" },
        { id: 2, name: "binding-tokocrypto" },
    ];
    const [modeModal, setModeModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });

    const [generalModal, setGeneralModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });
    const [exchangeModal, setExchangeModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });
    const [requestTradeModal, setRequestTradeModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });

    // useEffect(() => {
    //     if (amountPosition < 20) {
    //         if (timeoutAmountPosition) {
    //             clearTimeout(timeoutAmountPosition);
    //             setTimeoutAmountPosition(null);
    //         }
    //         setTimeoutAmountPosition(
    //             setTimeout(() => {
    //                 toast("Minimum first buy-in amount is 20 USDT");
    //                 setAmountPosition(20);
    //             }, 700)
    //         );
    //     } else {
    //         if (timeoutAmountPosition) {
    //             clearTimeout(timeoutAmountPosition);
    //             setTimeoutAmountPosition(null);
    //         }
    //     }
    // }, [amountPosition]);
    useEffect(() => {
        if (ip_binding.successMsg !== null) {
            toast(ip_binding.successMsg);
            dispatch(getBotSetting());
            dispatch(getBotStatus());
        }
        if (ip_binding.errorMsg !== null) toast(ip_binding.errorMsg);
        dispatch(initIPBinding());
    }, [ip_binding.successMsg, ip_binding.errorMsg]);
    useEffect(() => {
        if (bot.successMsg !== null) {
            toast(bot.successMsg);
            dispatch(getBotSetting());
            dispatch(getBotStatus());
        }
        if (bot.errorMsg !== null) toast(bot.errorMsg);
        setAmountPosition("");
        setSignalQty("");
    }, [bot.successMsg, bot.errorMsg]);
    const updateBotSetting = (tipe) => {
        // if (popupRequiredBinding() === false) return;
        if (bot.plugin_name === null) {
            toast(
                "To configure Bot Settings you need to activate your account first. Please go to Account menu and click Activate"
            );
            return;
        }
        let raw_body = {
            exchange_id: 1,
            max_pending_order: bot.binance.bot_setting.max_pending_order,
            open_position_amount: bot.binance.bot_setting.open_position_amount,
            signal_mode: bot.binance.bot_setting.signal_mode,
        };
        switch (tipe) {
            case "mode":
                if (bot.binance.bot_setting.signal_mode === 0) {
                    raw_body.signal_mode = 1;
                } else {
                    raw_body.signal_mode = 0;
                }
                break;

            case "amount_position":
            case "signal_qty":
                if (amountPosition !== "") {
                    raw_body.open_position_amount = parseInt(amountPosition);
                }
                if (signalQty !== "") {
                    raw_body.max_pending_order = parseInt(signalQty);
                }

                break;
            default:
                break;
        }
        dispatch(botSetting(raw_body));
    };
    const showModalSignalQty = () => {
        // if (popupRequiredBinding() === false) return;
        setGeneralModal({
            isVisible: true,
            buttons: [
                {
                    label: "Confirm",
                    className: "btn-modal",
                    action: () => {
                        updateBotSetting("signal_qty");
                        setGeneralModal({
                            isVisible: false,
                        });
                    },
                },
                {
                    label: "Cancel",
                    className: "sch-modal-cancel",
                    action: () => {
                        setGeneralModal({
                            isVisible: false,
                        });
                    },
                },
            ],
            title: "",
            message:
                "You are changing the amount of signal and signal quantity you want to receive.",
        });
    };
    const showModalAmountPosition = () => {
        // if (popupRequiredBinding() === false) return;
        setGeneralModal({
            isVisible: true,
            buttons: [
                {
                    label: "Confirm",
                    className: "btn-modal",
                    action: () => {
                        updateBotSetting("amount_position");
                        setGeneralModal({
                            isVisible: false,
                        });
                    },
                },
                {
                    label: "Cancel",
                    className: "btn-modal bg-transparent text-app",
                    action: () => {
                        setGeneralModal({
                            isVisible: false,
                        });
                    },
                },
            ],
            title: "",
            message: "You are changing the First Buy in Amount",
        });
    };
    const GO_API_BINDING = (id) => {
        if (bot.plugin_name === null) {
            toast(
                "To configure Bot Settings you need to activate your account first. Please go to Account menu and click Activate"
            );
            return;
        }
        if (id === "binding-tokocrypto" && bot.tokocrypto.exchange_active) {
            props.history.push(`/user/api-binding/${id}`);
        }
        if (id === "binding-binance" && bot.binance.exchange_active) {
            props.history.push(`/user/api-binding/${id}`);
        }
    };

    const REQUEST_TRADE = (tradeName) => {
        if (tradeName === "stop-loss") {
            // setRequestTradeModal({
            //     isVisible: true,
            //     message: "Are you sure you want to set stop loss",
            //     buttons: [
            //         {
            //             label: "No",
            //             className: "btn-modal bg-transparent text-app",
            //             action: () => {
            //                 setRequestTradeModal({
            //                     isVisible: false,
            //                 });
            //             },
            //         },
            //         {
            //             label: "Yes",
            //             className: "btn-modal",
            //             action: () => {
            //                 dispatch(
            //                     setRequestTrade({
            //                         stopLoss: true,
            //                     })
            //                 );
            //                 setRequestTradeModal({
            //                     isVisible: false,
            //                 });
            //             },
            //         },
            //     ],
            // });
        } else if (tradeName === "auto-stop-loss") {
            // setRequestTradeModal({
            //     isVisible: true,
            //     message: "Are you sure you want to set auto stop loss",
            //     buttons: [
            //         {
            //             label: "No",
            //             className: "btn-modal bg-transparent text-app",
            //             action: () => {
            //                 setRequestTradeModal({
            //                     isVisible: false,
            //                 });
            //             },
            //         },
            //         {
            //             label: "Yes",
            //             className: "btn-modal",
            //             action: () => {
            //                 dispatch(
            //                     setRequestTrade({
            //                         autoStopLoss: true,
            //                     })
            //                 );
            //                 setRequestTradeModal({
            //                     isVisible: false,
            //                 });
            //             },
            //         },
            //     ],
            // });
        } else if (tradeName === "auto-averaging") {
            let message = "Are you sure you want to set auto averaging";

            let raw_body = {
                exchange_id: 1,
                max_pending_order: bot.binance.bot_setting.max_pending_order,
                open_position_amount:
                    bot.binance.bot_setting.open_position_amount,
                signal_mode: bot.binance.bot_setting.signal_mode,
            };
            if (bot.binance.bot_setting.allow_auto_averaging === false) {
                message = "Are you sure you want to set auto averaging";
                raw_body = {
                    ...raw_body,
                    allow_auto_averaging: true,
                };
            } else {
                message = "Are you sure you want to unset auto averaging";
                raw_body = {
                    ...raw_body,
                    allow_auto_averaging: false,
                };
            }
            setRequestTradeModal({
                isVisible: true,
                message: message,
                buttons: [
                    {
                        label: "No",
                        className: "btn-modal bg-transparent text-app",
                        action: () => {
                            setRequestTradeModal({
                                isVisible: false,
                            });
                        },
                    },
                    {
                        label: "Yes",
                        className: "btn-modal",
                        action: () => {
                            dispatch(
                                setRequestTrade("auto-averaging", raw_body)
                            );
                            setRequestTradeModal({
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        }
    };

    const CONFIRM_CHANGE_MODE = (modeName) => {
        if (
            modeName === "standart" &&
            bot.binance.bot_setting.signal_mode_name !== "STANDARD"
        ) {
            setModeModal({
                isVisible: true,
                message:
                    "Users who opt for the Standard Mode will only receive open position signals for major coins.",
                buttons: [
                    {
                        label: "Confirm",
                        className: "btn-modal",
                        action: () => {
                            updateBotSetting("mode");
                            setModeModal({
                                isVisible: false,
                            });
                        },
                    },
                    {
                        label: "Cancel",
                        className: "btn-modal bg-transparent text-app",
                        action: () => {
                            setModeModal({
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        } else if (
            bot.binance.bot_setting.signal_mode_name !== "PROFESSIONAL" &&
            modeName === "professional"
        ) {
            setModeModal({
                isVisible: true,
                message:
                    'Users who opt for the Professional Mode, will receive open position signals for major and also coins with high volatility (alt and meme). <br/><br/><b style="color:red">This mode is high risk and may causes high floating, please proceed with caution.</b>',
                buttons: [
                    {
                        label: "Confirm",
                        className: "btn-modal",
                        action: () => {
                            updateBotSetting("mode");
                            setModeModal({
                                isVisible: false,
                            });
                        },
                    },
                    {
                        label: "Cancel",
                        className: "btn-modal bg-transparent text-app",
                        action: () => {
                            setModeModal({
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        }
    };

    const CONFIRM_CHANGE_EXCHANGE = (exchangeName) => {
        let message = "";
        if (exchangeName === "binance") {
            if (exchangeName === "binance") {
                if (bot.binance.api_binding.active) {
                    message =
                        "You are deactivating and will stop receiving signal for Binance exchange.";
                } else {
                    message =
                        "You are activating and will start receiving signal for Binance exchange.";
                }
            }
            setExchangeModal({
                isVisible: true,
                message: message,
                buttons: [
                    {
                        label: "Confirm",
                        className: "btn-modal",
                        action: () => {
                            changeExchange(exchangeName);
                            setExchangeModal({
                                isVisible: false,
                            });
                        },
                    },
                    {
                        label: "Cancel",
                        className: "btn-modal bg-transparent text-app",
                        action: () => {
                            setExchangeModal({
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        } else {
            if (bot.tokocrypto.api_binding.active) {
                message =
                    "You are deactivating and will stop receiving signal for Tokocrypto exchange.";
            } else {
                message =
                    "You are activating and will start receiving signal for Tokocrypto exchange.";
            }
            setExchangeModal({
                isVisible: true,
                message: message,
                buttons: [
                    {
                        label: "Confirm",
                        className: "btn-modal",
                        action: () => {
                            changeExchange(exchangeName);
                            setExchangeModal({
                                isVisible: false,
                            });
                        },
                    },
                    {
                        label: "Cancel",
                        className: "btn-modal bg-transparent text-app",
                        action: () => {
                            setExchangeModal({
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        }
    };
    const changeExchange = (exchangeName) => {
        if (popupRequiredBinding() === false) return;
        if (bot.plugin_name === null) {
            toast(
                "To configure Bot Settings you need to activate your account first. Please go to Account menu and click Activate"
            );
            return;
        }
        let exchange_id = 1;
        let command = 1;
        if (exchangeName === "binance") {
            exchange_id = 1;
            command = !bot.binance.api_binding.active;
        } else {
            exchange_id = 2;
            command = !bot.tokocrypto.api_binding.active;
        }
        if (command === false) {
            command = 0;
        } else {
            command = 1;
        }
        dispatch(
            exchangeActivation({
                command: command,
                exchange_id: exchange_id,
            })
        );
    };
    useEffect(() => {
        dispatch(getBotSetting());
        dispatch(getBotStatus());
        dispatch(getBotPlugin());
        setAmountPosition("");
    }, []);
    useEffect(() => {
        if (trades.successMsg !== null) {
            toast(trades.successMsg);
            dispatch(getBotSetting());
        }
        if (trades.errorMsg !== null) toast(trades.errorMsg);
        dispatch(exchangeInit());
    }, [trades.successMsg, trades.errorMsg]);
    const handleUpgradePlugin = () => {
        // popupRequiredBinding();
        if (bot.plugin_name === null) {
            toast(
                "To configure Bot Settings you need to activate your account first. Please go to Account menu and click Activate"
            );
            return;
        }
        let advPrice = 0;
        let advQty = 0;
        let expPrice = 0;
        let expQty = 0;
        bot.itemPlugins.forEach((e) => {
            if (e.name === "Advanced") {
                advPrice = e.price;
                advQty = e.signal_qty;
            }
            if (e.name === "Expert") {
                expPrice = e.price;
                expQty = e.signal_qty;
            }
        });
        const options = [
            {
                title: `To upgrade to Advance Signal Option, your Asset balance will be deducted ${advPrice} USDT`,
                message: `Advance Signal Option allows you to request buy-in amount up to 500 USDT per positions`,
            },
            {
                title: `To upgrade to Expert Signal Option, your Asset balance will be deducted ${expPrice} USDT`,
                message: `Expert Signal Option allows you to request buy-in amount up to 10,000 USDT per positions`,
            },
        ];
        if (botSignal < 1) {
            toast("Please select Bot Signal");
        } else if (botSignal <= 1) {
            dispatch(
                botUpgrade({
                    plugin_id: parseInt(botSignal),
                })
            );
        } else {
            setGeneralModal({
                isVisible: true,
                title: options[botSignal - 2].title,
                message: options[botSignal - 2].message,
                buttons: [
                    {
                        label: "Confirm",
                        className: "btn-modal",
                        action: () => {
                            setGeneralModal({
                                isVisible: false,
                            });
                            // if (popupRequiredBinding() === false) return;
                            dispatch(
                                botUpgrade({
                                    plugin_id: parseInt(botSignal),
                                })
                            );
                        },
                    },
                    {
                        label: "Cancel",
                        className: "btn-modal bg-transparent text-app",
                        action: () => {
                            setGeneralModal({
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        }
    };
    const popupRequiredBinding = () => {
        if (
            bot.tokocrypto.api_binding.api_key === "" &&
            bot.binance.api_binding.api_key === ""
        ) {
            toast(
                "You have not finished API Binding for the selected exchange yet."
            );
            return false;
        }
        return true;
    };
    useEffect(() => {
        if (bot.plugin_name !== null) {
            if (bot.plugin_name.toLowerCase() === "basic") {
                if (amountPosition > 50) setAmountPosition(50);
            }
            if (bot.plugin_name.toLowerCase() === "advanced") {
                if (amountPosition > 500) setAmountPosition(500);
            }
            if (bot.plugin_name.toLowerCase() === "expert") {
                if (amountPosition > 10000) setAmountPosition(10000);
            }
        }
    }, [amountPosition]);
    return (
        <div className="botsetting-content content-on">
            <Banner />

            <h1 className="mb-1 fz:13 fw:600 text-dark text-uppercase">
                API Binding
            </h1>
            <div className="card mb-2 on-bot-setting">
                <div className="card-body">
                    <div className="d-grid grid-column:2">
                        <button
                            className={`btn-permission coin-group ${
                                bot.binance.api_binding.api_key === ""
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() => GO_API_BINDING(uri[0].name)}
                        >
                            <div className="icon-coin lg">
                                <img src={Binance} alt={Binance} />
                            </div>
                            <div className="d-block text-left">
                                <h1 className="m-0 fz:12 fw:600 text-dark text-uppercase">
                                    Binance
                                </h1>
                                <p
                                    className={`m-0 fz:12 fw:500 ${
                                        bot.binance.api_binding.api_key === ""
                                            ? "text-green"
                                            : "text-red"
                                    } text-uppercase`}
                                >
                                    {bot.binance.api_binding.api_key !== ""
                                        ? "Unbound"
                                        : "Bound"}
                                </p>
                            </div>
                        </button>
                        <button
                            className={`btn-permission coin-group ${
                                bot.tokocrypto.api_binding.api_key === ""
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() => GO_API_BINDING(uri[1].name)}
                        >
                            <div className="icon-coin lg">
                                <img src={Tokocrypto} alt={Tokocrypto} />
                            </div>
                            <div className="d-block text-left">
                                <h1 className="m-0 fz:12 fw:600 text-dark text-uppercase">
                                    Tokocrypto
                                </h1>
                                <p
                                    className={`m-0 fz:12 fw:500 ${
                                        bot.tokocrypto.api_binding.api_key ===
                                        ""
                                            ? "text-green"
                                            : "text-red"
                                    } text-uppercase`}
                                >
                                    {bot.tokocrypto.api_binding.api_key !== ""
                                        ? "Unbound"
                                        : "Bound"}
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <h1 className="mb-2 fz:13 fw:600 text-dark text-uppercase">
                Signal Request
            </h1>
            <div className="card mb-2 on-bot-setting">
                <div className="card-body">
                    <div className="d-grid grid-column:2a">
                        <div
                            className="d-flex align-items-center justify-content-between mb-1"
                            style={{ columnGap: "10px" }}
                        >
                            <div className="req-trade-flex-1">
                                <div>
                                    <input
                                        type="number"
                                        className="input-control sm"
                                        style={{ marginBottom: 6 }}
                                        value={signalQty}
                                        placeholder="Signal Quantity"
                                        onChange={(e) =>
                                            setSignalQty(e.target.value)
                                        }
                                        required
                                    />
                                    <input
                                        type="number"
                                        className="input-control sm"
                                        placeholder="Buy-in Amount"
                                        value={amountPosition}
                                        onChange={(e) =>
                                            setAmountPosition(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <button
                                    onClick={showModalSignalQty}
                                    className="btn-bot-setting sm"
                                >
                                    <i className="fas fa-save"></i>
                                </button>
                            </div>
                        </div>
                        <div style={{ margin: "0 auto" }}>
                            <ul className="nav-horizontal m-0 p-0">
                                <li>
                                    <button
                                        onClick={() =>
                                            REQUEST_TRADE("stop-loss")
                                        }
                                    >
                                        {bot.stopLoss ? (
                                            <img
                                                width="55"
                                                src={require("../../assets/icons/ICON-SL.png")}
                                                alt="SL"
                                            />
                                        ) : (
                                            <img
                                                width="25"
                                                src={require("../../assets/icons/icon_sl_grey.png")}
                                                alt="SL"
                                            />
                                        )}

                                        <br />
                                        <span className="fz:9">Stop Loss</span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            REQUEST_TRADE("auto-stop-loss")
                                        }
                                    >
                                        {bot.autoStopLoss ? (
                                            <img
                                                width="25"
                                                src={require("../../assets/icons/ICON-AUTOSL-BIRU.png")}
                                                alt="AutoSL"
                                            />
                                        ) : (
                                            <img
                                                width="25"
                                                src={require("../../assets/icons/icon_auto_sl_grey.png")}
                                                alt="AutoSL"
                                            />
                                        )}

                                        <br />
                                        <span className="fz:9">
                                            Auto Stop Loss
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        onClick={() =>
                                            REQUEST_TRADE("auto-averaging")
                                        }
                                    >
                                        {bot.binance.bot_setting
                                            .allow_auto_averaging ? (
                                            <img
                                                width="25"
                                                src={require("../../assets/icons/ICON-AVERAGING-BIRU.png")}
                                                alt="AutoSL"
                                            />
                                        ) : (
                                            <img
                                                width="25"
                                                src={require("../../assets/icons/icon_averaging_grey.png")}
                                                alt="AutoSL"
                                            />
                                        )}
                                        <br />
                                        <span className="fz:9">
                                            Auto Averaging
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div
                        className="d-flex align-items-center justify-content-between mb-1"
                        style={{ columnGap: "10px" }}
                    >
                        <input
                            type="number"
                            className="input-control sm"
                            placeholder="First Buy-in Amount"
                            value={amountPosition}
                            onChange={(e) => setAmountPosition(e.target.value)}
                            required
                        />
                        <button
                            onClick={showModalAmountPosition}
                            className="btn-bot-setting sm"
                        >
                            Save
                        </button>
                    </div> */}
                </div>
            </div>
            <h1 className="mb-2 fz:13 fw:600 text-dark text-uppercase">
                SIGNAL MODE
            </h1>
            <div className="card mb-2 on-bot-setting">
                <div className="card-body">
                    <div className="d-grid grid-column:1">
                        <button
                            className={
                                bot.binance.bot_setting.signal_mode_name ===
                                "STANDARD"
                                    ? "btn bg-app-color text-white text-uppercase fz:13 fw:600"
                                    : "btn bg-white-color text-dark hover:bg-app-color hover:text-white-color text-uppercase fz:13 fw:600"
                            }
                            style={
                                bot.binance.bot_setting.signal_mode_name ===
                                "STANDARD"
                                    ? {}
                                    : {
                                          border: "1px solid #008aae",
                                          transition:
                                              "background 0.4s, color 0.2s",
                                      }
                            }
                            onClick={() => CONFIRM_CHANGE_MODE("standart")}
                        >
                            Standard
                        </button>
                        <button
                            className={
                                bot.binance.bot_setting.signal_mode_name !==
                                "STANDARD"
                                    ? "btn bg-app-color text-white text-uppercase fz:13 fw:600"
                                    : "btn bg-white-color text-dark hover:bg-app-color hover:text-white-color text-uppercase fz:13 fw:600"
                            }
                            style={
                                bot.binance.bot_setting.signal_mode_name !==
                                "STANDARD"
                                    ? {}
                                    : {
                                          border: "1px solid #008aae",
                                          transition:
                                              "background 0.4s, color 0.2s",
                                      }
                            }
                            onClick={() => CONFIRM_CHANGE_MODE("professional")}
                        >
                            Professional
                        </button>
                    </div>
                </div>
            </div>
            <h1 className="mb-2 fz:13 fw:600 text-dark text-uppercase">
                Signal Option
            </h1>
            <div className="card mb-2 on-bot-setting">
                <div className="card-body">
                    <div
                        className="d-flex align-items-center justify-content-between mb-1"
                        style={{ columnGap: "10px" }}
                    >
                        <select
                            id="select"
                            onChange={(e) => setBotSignal(e.target.value)}
                            className="form-select form-select-sm"
                        >
                            <option
                                key={0}
                                value={0}
                                className="text-dark"
                                id="option-ubs"
                            >
                                Select Signal Option
                            </option>
                            {bot.itemPlugins.map((key, index) => (
                                <option
                                    key={index}
                                    value={key.id}
                                    className="text-dark"
                                    id="option-ubs"
                                >
                                    {key.name}
                                </option>
                            ))}
                        </select>
                        <button
                            onClick={handleUpgradePlugin}
                            className="btn-bot-setting px-4 py-1 fz:12"
                        >
                            Upgrade
                        </button>
                    </div>
                </div>
            </div>
            <h1 className="mb-2 fz:13 fw:600 text-dark text-uppercase">
                SIGNAL SETTING SUMMARY
            </h1>
            <div className="card mb-2 on-bot-setting">
                <div className="card-body">
                    <div
                        className="justify-content-between mb-1"
                        style={{ width: "100%" }}
                    >
                        <div
                            className="card bg-app-color text-dark "
                            style={{ width: "100%" }}
                        >
                            <div className="card-body" style={{ padding: 10 }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td className="fz:11 text-dark text-uppercase fw-500 whitespace-nowrap">
                                                Signal Quantity
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-500">
                                                :
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-600 text-end">
                                                {
                                                    bot.binance.bot_setting
                                                        .max_pending_order
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fz:11 text-dark text-uppercase fw-500 whitespace-nowrap">
                                                BUY-IN AMOUNT
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-500">
                                                :
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-600 text-end">
                                                {
                                                    bot.binance.bot_setting
                                                        .open_position_amount
                                                }{" "}
                                                USDT
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td className="fz:11 text-dark text-uppercase fw-500 whitespace-nowrap">
                                                STOP LOSS
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-500">
                                                :
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-600 text-end">
                                                -%
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td className="fz:11 text-dark text-uppercase fw-500 whitespace-nowrap">
                                                AUTO AVERAGING
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-500">
                                                :
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-600 text-end">
                                                {bot.binance.bot_setting
                                                    .allow_auto_averaging ===
                                                true
                                                    ? "Active"
                                                    : "Not Active"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fz:11 text-dark text-uppercase fw-500 whitespace-nowrap">
                                                SIGNAL MODE
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-500">
                                                :
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-600 text-end">
                                                {
                                                    bot.binance.bot_setting
                                                        .signal_mode_name
                                                }
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="fz:11 text-dark text-uppercase fw-500 whitespace-nowrap">
                                                SIGNAL OPTION
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-500">
                                                :
                                            </td>
                                            <td className="fz:11 text-dark text-uppercase fw-600 text-end">
                                                {bot.plugin_name}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="mb-2 fz:13 fw:600 text-dark text-uppercase">
                Connected Exchange
            </h1>
            <div className="card mb-2 on-bot-setting">
                <div className="card-body">
                    <div className="d-grid grid-column:2">
                        <div className="d-grid mb-2">
                            <button
                                style={
                                    bot.binance.api_binding.active
                                        ? {}
                                        : {
                                              border: "1px solid #008aae",
                                              transition:
                                                  "background 0.4s, color 0.2s",
                                          }
                                }
                                className={
                                    bot.binance.api_binding.active
                                        ? "btn fz:13 fw:500 d-flex align-items-center justify-content-between bg-app-color text-white text-uppercase px-2"
                                        : "btn fz:13 fw:500 d-flex align-items-center justify-content-between bg-app-white-color hover:bg-app-color text-dark text-uppercase px-2"
                                }
                                onClick={() =>
                                    CONFIRM_CHANGE_EXCHANGE("binance")
                                }
                            >
                                <span
                                    className={
                                        bot.binance.api_binding.active
                                            ? "text-white"
                                            : "text-dark"
                                    }
                                >
                                    Binance
                                </span>
                                <span
                                    className={
                                        bot.binance.api_binding.active
                                            ? "text-green"
                                            : "text-red"
                                    }
                                >
                                    {bot.binance.api_binding.active
                                        ? "On"
                                        : "Off"}
                                </span>
                            </button>
                            <span className="fz:12" style={{ padding: 5 }}>
                                Total Balance : - USDT
                            </span>
                        </div>

                        <div className="d-grid mb-2">
                            <button
                                style={
                                    bot.tokocrypto.api_binding.active
                                        ? {}
                                        : {
                                              border: "1px solid #008aae",
                                              transition:
                                                  "background 0.4s, color 0.2s",
                                          }
                                }
                                className={
                                    bot.tokocrypto.api_binding.active
                                        ? "btn fz:13 fw:500 d-flex align-items-center justify-content-between bg-app-color text-white text-uppercase px-2"
                                        : "btn fz:13 fw:500 d-flex align-items-center justify-content-between bg-app-white-color hover:bg-app-color text-dark text-uppercase px-2"
                                }
                                onClick={() =>
                                    CONFIRM_CHANGE_EXCHANGE("tokocrypto")
                                }
                            >
                                <span className="text-dark">Tokocrypto</span>
                                <span
                                    className={
                                        bot.tokocrypto.api_binding.active
                                            ? "text-green"
                                            : "text-red"
                                    }
                                >
                                    {bot.tokocrypto.api_binding.active
                                        ? "On"
                                        : "Off"}
                                </span>
                            </button>
                            <span className="fz:12" style={{ padding: 5 }}>
                                Total Balance : - USDT
                            </span>
                        </div>
                    </div>

                    <Link
                        to="/user/convert-to-bnb"
                        className="fz:12 text-app text-decoration-none hover:text-app-color-90 active:text-app-color-70"
                        style={{ padding: 5 }}
                    >
                        <img
                            src={IconConvertToBNBBlue}
                            alt="iconSmall"
                            width={13}
                        />
                        <span style={{ padding: 3 }}>
                            Convert Small Balance to BNB
                        </span>
                    </Link>
                    {/* <h1 className="mb-0 fz:12 fw:600 text-gray">
            Available Balance : 0 USDT
          </h1> */}
                </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center">
        <button
          className="btn text-center fz:14 fw:600 bg-white-color-10 hover:bg-app-color hover:text-white-color px-5 text-uppercase"
          style={{
            border: "1px solid #008aae",
            transition: "background 0.3s, border 0.3s, color 0.3s",
          }}
        >
          Start
        </button>
      </div> */}
            {exchangeModal.isVisible ? (
                <Modal
                    message={exchangeModal.message}
                    title={exchangeModal.title}
                    buttons={exchangeModal.buttons}
                />
            ) : modeModal.isVisible ? (
                <Modal
                    message={modeModal.message}
                    title={modeModal.title}
                    buttons={modeModal.buttons}
                />
            ) : null}
            {generalModal.isVisible ? (
                <Modal
                    message={generalModal.message}
                    title={generalModal.title}
                    buttons={generalModal.buttons}
                />
            ) : (
                ""
            )}
            {requestTradeModal.isVisible && (
                <Modal
                    message={requestTradeModal.message}
                    buttons={requestTradeModal.buttons}
                />
            )}
        </div>
    );
};

export default Botsetting;
