import React, { useState, useEffect } from "react";
import { userRequestOtp, changePin, initAccount } from "src/store/actions";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
const TransactionPin = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.Account);
    const [showPin1, setShowPin1] = useState(false);
    const [showPin2, setShowPin2] = useState(false);
    const [oldPin, setOldPin] = useState(null);
    const [pin, setPin] = useState(null);
    const [pinRepeat, setPinRepeat] = useState(null);
    const [otp, setOtp] = useState(null);
    const handleGetOtp = () => {
        dispatch(
            userRequestOtp({
                otp_context: 4,
                target: 0,
            })
        );
    };
    const handleChangePin = () => {
        dispatch(
            changePin({
                new_security_pin: pin,
                new_security_pin_repeat: pinRepeat,
                otp: otp,
                security_pin: "000000",
            })
        );
    };
    useEffect(() => {
        return () => {
            dispatch(initAccount());
        };
    }, []);
    useEffect(() => {
        if (account.reqOtpSuccessMsg !== null) toast(account.reqOtpSuccessMsg);
        if (account.reqOtpErrorMsg !== null) toast(account.reqOtpErrorMsg);
    }, [account.reqOtpSuccessMsg, account.reqOtpErrorMsg]);

    useEffect(() => {
        if (account.successMsg !== null) {
            toast(account.successMsg);
            window.history.back();
        }
        if (account.errorMsg !== null) toast(account.errorMsg);
    }, [account.successMsg, account.errorMsg]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Transaction Pin
                </span>
                <div className="n"></div>
            </header>
            <form id="form-validation">
                {/* {account.security_pin_active && (
                    <div className="field">
                        <input
                            type="password"
                            id="old-pin"
                            onChange={(e) => setOldPin(e.target.value)}
                            className="input-control"
                            placeholder="Please enter a 6 - digit Old PIN"
                            required
                        />
                    </div>
                )} */}

                <div className="field">
                    <input
                        type={showPin1 ? "text" : "password"}
                        id="pin"
                        onChange={(e) => setPin(e.target.value)}
                        className="input-control"
                        placeholder="Please enter a 6 - digit PIN"
                        required
                    />
                    <button
                        style={{ marginTop: -5 }}
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPin1(!showPin1)}
                    >
                        <i
                            className={
                                showPin1
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <div className="field">
                    <input
                        type={showPin2 ? "text" : "password"}
                        id="re-enter-pin"
                        onChange={(e) => setPinRepeat(e.target.value)}
                        className="input-control"
                        placeholder="Re-enter a 6 - digit PIN"
                        required
                    />
                    <button
                        style={{ marginTop: -5 }}
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPin2(!showPin2)}
                    >
                        <i
                            className={
                                showPin2
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <div className="field">
                    <input
                        type="text"
                        id="otp-code"
                        onChange={(e) => setOtp(e.target.value)}
                        className="input-control"
                        placeholder="Input OTP Code"
                        required
                    />
                    <button
                        onClick={handleGetOtp}
                        type="button"
                        className="input-btn-absolute bg-slate-color-30 text-dark fw:600 px-3"
                    >
                        Get Otp
                    </button>
                </div>
                <button
                    onClick={handleChangePin}
                    type="button"
                    className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white text-uppercase"
                >
                    Confirm
                </button>
            </form>
        </div>
    );
};

export default TransactionPin;
