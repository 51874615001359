import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import BidboxLogo from "../../assets/image/logo/bidbox-logo-vertical_optimized.png";
import { LoadingBidbox } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "src/store/actions";
import toast from "react-hot-toast";

const Signin = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    // const dispatch = useDispatch();
    const loginState = useSelector((state) => state.Login);
    // const history = useHistory();
    const dispatch = useDispatch();

    const LOGINSUBMIT = async (e) => {
        e.preventDefault();
        dispatch(
            loginUser(
                {
                    email: email,
                    password: password,
                },
                props.history
            )
        );
    };
    useEffect(() => {
        if (loginState.errorMessage !== null) toast(loginState.errorMessage);
    }, [loginState.errorMessage]);
    return (
        <div className="auth-box">
            {loginState.loading && <LoadingBidbox />}
            <div className="auth-logo">
                <img src={BidboxLogo} alt="_Bidbox-logo.png" />
            </div>
            <div className="auth-body">
                <h1 className="fz:22 fw:500">Sign In</h1>

                <form
                    onSubmit={LOGINSUBMIT}
                    id="form-validation"
                    className="auth-form"
                >
                    <div className="group">
                        <div className="input-field">
                            <input
                                type="text"
                                id="email"
                                placeholder="Email"
                                onChange={(evt) => setEmail(evt.target.value)}
                                required
                            />
                            <i className="fa-solid fa-user icon-absolute-right"></i>
                        </div>
                        <div className="input-field">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                placeholder="Your Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                className="icon-absolute-right"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i
                                    className={
                                        showPassword
                                            ? "fa-regular fa-eye"
                                            : "fa-regular fa-eye-slash"
                                    }
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end my-2">
                        <Link
                            to="/auth/forgot-password"
                            className="fz:14 fw:500 text-app hover:text-app-color-90 active:text-app-color-70 text-decoration-none"
                        >
                            Forgot Password?
                        </Link>
                    </div>
                    <button
                        type="submit"
                        className="btn-md btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white fz:18"
                        style={{ borderRadius: "13px" }}
                    >
                        Sign In
                    </button>
                </form>
                <p className="my-2 text-center text-gray fz:14 fw:500">
                    No Account yet?{" "}
                    <Link
                        to="/auth/signup"
                        className="text-app text-decoration-none hover:text-app-color-90 active:text-app-color-70"
                    >
                        Register Now
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Signin;
