import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    REGISTER_REQ_OTP,
    REGISTER_REQ_OTP_SUCCESS,
    REGISTER_REQ_OTP_ERROR,
    INIT_REGISTER_STORE,
} from "./actionTypes";

export const registerUser = (user, history) => {
    return {
        type: REGISTER,
        payload: { user, history },
    };
};

export const registerSuccess = (payload) => {
    return {
        type: REGISTER_SUCCESS,
        payload: payload,
    };
};
export const registerError = (message) => {
    return {
        type: REGISTER_ERROR,
        payload: message,
    };
};

export const requestOtpRegister = (payload) => {
    return {
        type: REGISTER_REQ_OTP,
        payload: payload,
    };
};

export const requestOtpRegisterSuccess = (payload) => {
    return {
        type: REGISTER_REQ_OTP_SUCCESS,
        payload: payload,
    };
};
export const requestOtpRegisterError = (message) => {
    return {
        type: REGISTER_REQ_OTP_ERROR,
        payload: message,
    };
};

export const initRegisterStore = () => {
    return {
        type: INIT_REGISTER_STORE,
    };
};
