import { BALANCE, BALANCE_ERROR, BALANCE_SUCCESS } from "./actionTypes";

const initialState = {
    active_balance: 0,
    currency_id: "USDT",
    locked_balance: 0,
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BALANCE:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case BALANCE_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
            };
            break;
        case BALANCE_ERROR:
            state = { ...state, loading: false, errorMsg: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
