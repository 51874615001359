import { takeEvery, put, call } from "redux-saga/effects";

import { WITHDRAW_HISTORY } from "./actionTypes";
import { withdrawHistoryError, withdrawHistorySuccess } from "./actions";

import { getHistoryWithdraw as apiGetHistoryWithdraw } from "src/api";
function* getHistoryWithdraw({ payload }) {
    try {
        const response = yield call(apiGetHistoryWithdraw, payload.from_id);
        const data = response.data;
        yield put(withdrawHistorySuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(withdrawHistoryError(message));
    }
}
function* authSaga() {
    yield takeEvery(WITHDRAW_HISTORY, getHistoryWithdraw);
}

export default authSaga;
