export const EXCHANGE_ACTIVATION = "EXCHANGE_ACTIVATION";
export const EXCHANGE_ACTIVATION_SUCCESS = "EXCHANGE_ACTIVATION_SUCCESS";
export const EXCHANGE_ACTIVATION_ERROR = "EXCHANGE_ACTIVATION_ERROR";
export const EXCHANGE_ACTIVATION_INIT = "EXCHANGE_ACTIVATION_INIT";
export const TRADES = "TRADES";
export const TRADES_SUCCESS = "TRADES_SUCCESS";
export const TRADES_ERROR = "TRADES_ERROR";
export const TRADES_INIT = "TRADES_INIT";
export const TRADES_UPDATE = "TRADES_UPDATE";
export const TRADES_REMOVE = "TRADES_REMOVE";
export const TRADES_INSERT = "TRADES_INSERT";
export const TRADES_CANCEL = "TRADES_CANCEL";
export const TRADES_CANCEL_SUCCESS = "TRADES_CANCEL_SUCCESS";
export const TRADES_CANCEL_ERROR = "TRADES_CANCEL_ERROR";
export const TRADES_SELL = "TRADES_SELL";
export const TRADES_SELL_SUCCESS = "TRADES_SELL_SUCCESS";
export const TRADES_SELL_ERROR = "TRADES_SELL_ERROR";
export const TRADES_CLEAR_MESSAGE = "TRADES_CLEAR_MESSAGE";
export const TRADES_PROFIT = "TRADES_PROFIT";
export const TRADES_PROFIT_SUCCESS = "TRADES_PROFIT_SUCCESS";
export const TRADES_PROFIT_ERROR = "TRADES_PROFIT_ERROR";
export const TRADES_HISTORY = "TRADES_HISTORY";
export const TRADES_HISTORY_SUCCESS = "TRADES_HISTORY_SUCCESS";
export const TRADES_HISTORY_ERROR = "TRADES_HISTORY_ERROR";
export const TRADES_SYNC = "TRADES_SYNC";
export const TRADES_SYNC_SUCCESS = "TRADES_SYNC_SUCCESS";
export const TRADES_SYNC_ERROR = "TRADES_SYNC_ERROR";
export const TRADES_AVG = "TRADES_AVG";
export const TRADES_AVG_SUCCESS = "TRADES_AVG_SUCCESS";
export const TRADES_AVG_ERROR = "TRADES_AVG_ERROR";
export const TICK_SIZE = "TICK_SIZE";
export const TICK_SIZE_SUCCESS = "TICK_SIZE_SUCCESS";
export const TICK_SIZE_ERROR = "TICK_SIZE_ERROR";
export const TRADES_CURRENT_PRICE = "TRADES_CURRENT_PRICE";
export const TRADES_CURRENT_PRICE_SUCCESS = "TRADES_CURRENT_PRICE_SUCCESS";
export const TRADES_CURRENT_PRICE_ERROR = "TRADES_CURRENT_PRICE_ERROR";
