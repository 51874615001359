import { takeEvery, put, call } from "redux-saga/effects";

import {OPENFIRE_LOGIN} from "./actionTypes";
import {openfireLoginError, openfireLoginSuccess} from "./actions";

import {loginOpenfire as apiOpenfireLogin} from "src/api";
function* openfireLogin() {
    try {
        const response = yield call(apiOpenfireLogin);
        const data = response.data;
        yield put(openfireLoginSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data;
        } catch (q) {}
        yield put(openfireLoginError(message));
    }
}

function* openfireSaga() {
    yield takeEvery(OPENFIRE_LOGIN, openfireLogin);
}

export default openfireSaga;
