import React from "react";
import styled from "styled-components";
import htmlParse from "html-react-parser";
import { toFixedIfNecessary } from "src/utils/helpers";
const FModal = styled.div`
    position: fixed;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: fade 0.4s forwards;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }
`;
const ModalDialog = styled.div`
    position: relative;
    width: "50%";
    margin: 0.5rem;
    pointer-events: none;
`;
const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    border-radius: 0.3rem;
    outline: 0;
    min-width: 100px;

    @media screen and (max-width: 400px) {
        min-width: auto;
    }
`;
const ModalHeader = styled.div`
    position: relative;
`;
const ModalBody = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
`;
const ModalFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem 0.75rem 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    column-gap: 10px;
`;

const ModalButton = styled.button`
    position: relative;
    display: inline-block;
    padding: 0.3rem 1rem;
    background: ${(props) => (props.primary ? "#ffffff" : "#008aae")};
    color: ${(props) => (props.primary ? "#008aae" : "#ffffff")};
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 15px;
    border-radius: 0.25rem;
    transition: ${(props) =>
        props.primary ? "background 0.3s, color 0.2s" : "none"};
    &:hover {
        background: ${(props) => (props.primary ? "#008aae" : "#007e9e")};
        color: ${(props) => (props.primary ? "#ffffff" : "#ffffff")};
    }
    &:active {
        background: ${(props) => (props.primary ? "#0897bb" : "#068cad")};
    }
`;
const Modal = ({
    buttons,
    title,
    data,
    priceChange,
    buyOnChange,
    tpOnChange,
    buyPrice,
    targetProfit,
    decimalLength,
    sellOnChange,
}) => {
    try {
        const qty = data?.position_amount / data?.buy_price;
        const modal_total =
            parseFloat(data.position_amount) + parseFloat(buyPrice);
        const avg_qty = buyPrice / priceChange.price;

        const profit_and_lose =
            ((priceChange.price - data.buy_price) / data.buy_price) * 100;

        const total_qty = avg_qty + qty;
        const avg_profit_and_lose =
            ((total_qty * priceChange.price - modal_total) / modal_total) * 100;
        let avg_sell_price =
            ((targetProfit / 100) * modal_total + modal_total) / total_qty;
        if (decimalLength !== 0) {
            avg_sell_price = toFixedIfNecessary(avg_sell_price, decimalLength);
        }
        sellOnChange(avg_sell_price);
        return (
            <FModal>
                <ModalDialog>
                    <ModalContent>
                        <ModalBody>
                            {title ? (
                                <p className="fz:15 fw:600 text-center m-0">
                                    {title}
                                </p>
                            ) : null}
                            <table className="fz:12" style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td>Pair</td>
                                        <td>:</td>
                                        <td>{data.pair}</td>
                                    </tr>
                                    <tr>
                                        <td>Buy Price</td>
                                        <td>:</td>
                                        <td>{data.buy_price}</td>
                                    </tr>
                                    <tr>
                                        <td>Sell Price</td>
                                        <td>:</td>
                                        <td>{data.sell_price}</td>
                                    </tr>
                                    <tr>
                                        <td>Position Amount</td>
                                        <td>:</td>
                                        <td>{data.position_amount}</td>
                                    </tr>
                                    {/* <tr>
                                    <td>Quantity</td>
                                    <td>:</td>
                                    <td>{data.pair}</td>
                                </tr> */}
                                    <tr>
                                        <td>Current Price</td>
                                        <td>:</td>
                                        <td>
                                            <span
                                                className={
                                                    priceChange.price_change &&
                                                    priceChange.price_change > 0
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                {priceChange.price &&
                                                    !isNaN(priceChange.price) &&
                                                    toFixedIfNecessary(
                                                        priceChange.price,
                                                        8
                                                    )}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Position Profit and Lose</td>
                                        <td>:</td>
                                        <td>
                                            <span
                                                className={
                                                    profit_and_lose > 0
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                {toFixedIfNecessary(
                                                    profit_and_lose,
                                                    8
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className="fw:600">
                                            &nbsp;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Quantity</td>
                                        <td>:</td>
                                        <td>
                                            {!isNaN(avg_qty) &&
                                                toFixedIfNecessary(avg_qty, 8)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>AVG Sell Price</td>
                                        <td>:</td>
                                        <td>
                                            {!isNaN(avg_sell_price) &&
                                                toFixedIfNecessary(
                                                    avg_sell_price,
                                                    8
                                                )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Quantity</td>
                                        <td>:</td>
                                        <td>
                                            {!isNaN(total_qty) &&
                                                toFixedIfNecessary(
                                                    total_qty,
                                                    8
                                                )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Profit and lose</td>
                                        <td>:</td>
                                        <td>
                                            <span
                                                className={
                                                    avg_profit_and_lose > 0
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                {!isNaN(avg_profit_and_lose) &&
                                                    toFixedIfNecessary(
                                                        avg_profit_and_lose,
                                                        8
                                                    )}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Buy Amount</td>
                                        <td>:</td>
                                        <td>
                                            <input
                                                style={{ width: "80px" }}
                                                onBlur={() => (data.focus = "")}
                                                onFocus={() =>
                                                    (data.focus = "buy")
                                                }
                                                type="number"
                                                value={buyPrice}
                                                onChange={buyOnChange}
                                            />
                                            &nbsp; USDT
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Target Profit</td>
                                        <td>:</td>
                                        <td>
                                            <input
                                                style={{ width: "80px" }}
                                                onBlur={() => (data.focus = "")}
                                                onFocus={() =>
                                                    (data.focus = "tp")
                                                }
                                                type="number"
                                                value={targetProfit}
                                                onChange={tpOnChange}
                                            />
                                            &nbsp; %
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalBody>
                        {buttons && (
                            <ModalFooter>
                                {buttons.map((key, index) => {
                                    return (
                                        <ModalButton
                                            key={index}
                                            type="button"
                                            className={key.className}
                                            onClick={() =>
                                                key.action(
                                                    avg_sell_price,
                                                    buyPrice
                                                )
                                            }
                                        >
                                            {key.label}
                                        </ModalButton>
                                    );
                                })}
                            </ModalFooter>
                        )}
                    </ModalContent>
                </ModalDialog>
            </FModal>
        );
    } catch (error) {
        // console.log(error.message);
    }
};

export default Modal;
