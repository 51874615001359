import {get_decimal_length, searchIcon} from "src/utils/helpers";
import { takeEvery, put, call, delay } from "redux-saga/effects";
import {
    EXCHANGE_ACTIVATION,
    TRADES,
    TRADES_PROFIT,
    TRADES_CANCEL,
    TRADES_SELL,
    TRADES_HISTORY,
    TRADES_SYNC,
    TRADES_AVG,
    TICK_SIZE,
    TRADES_CURRENT_PRICE,
} from "./actionTypes";
import {
    exchangeActivationSuccess,
    exchangeActivationError,
    tradesSuccess,
    tradesError,
    tradesCancelSuccess,
    tradesCancelError,
    tradesSellError,
    tradesSellSuccess,
    tradesProfitError,
    tradesProfitSuccess,
    tradesHistoryError,
    tradesHistorySuccess,
    tradesSyncError,
    tradesSyncSuccess,
    removeTrades,
    tradesAvgSuccess,
    tradesAvgError,
    tickSizeSuccess,
    tickSizeError,
    tradesGetCurrentPrice,
    tradesGetCurrentPriceSuccess,
    tradesGetCurrentPriceError,
} from "./actions";

import {
    exchangeActivation as apiExchangeActivation,
    getActiveTrades as apiGetTrades,
    cancelPendingBuy as apiCancelTrades,
    cancelPendingSell as apiSellTrades,
    getTradesProfit as apiGetTradesProfit,
    getTradesHistory as apiGetTradesHistory,
    syncTrades as apiSyncTrades,
    avgTrades as apiAvgTrades,
    getTickSize as apiGetTickSize,
    getCurrentPriceBinance as apiGetCurrentPrice,
} from "src/api";
function* exchangeActivation({ payload }) {
    try {
        yield call(apiExchangeActivation, payload);
        yield put(
            exchangeActivationSuccess("Exchange change status successfully")
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(exchangeActivationError(message));
    }
}
function* getTrades({ payload }) {
    try {
        const response = yield call(apiGetTrades, payload);
        let data = response.data;
        if (data.trades === null) data.trades = [];
        if (data.trades !== null) {
            let symbol = [];
            data.trades.forEach(async (el, idx) => {
                const pair = `${el.base_asset}${el.quote_asset}`;
                if (symbol.indexOf(pair) < 0) {
                    symbol.push(pair);
                }
                if (el.status_name === "PENDING_BUY") {
                    data.trades[idx].sort_priority = 1;
                } else {
                    data.trades[idx].sort_priority = 2;
                }
                data.trades[idx].symbol = `${el.base_asset}${el.quote_asset}`;
                data.trades[idx].image = await searchIcon(el.base_asset);
            });
            if (symbol.length > 0) yield put(tradesGetCurrentPrice(symbol));
        }
        yield put(tradesSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesError(message));
    }
}
function* cancelTrades({ payload }) {
    try {
        yield call(apiCancelTrades, payload);
        yield put(tradesCancelSuccess("Cancel Buy successfully"));
        yield put(
            removeTrades({
                id: payload.trade_id,
                exchange_id: payload.exchange_id,
            })
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesCancelError(message));
    }
}

function* sellTrades({ payload }) {
    try {
        yield call(apiSellTrades, payload);
        yield put(tradesSellSuccess("Sell successfully"));
        yield put(
            removeTrades({
                id: payload.trade_id,
                exchange_id: payload.exchange_id,
            })
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesSellError(message));
    }
}

function* getTradesProfit({ payload }) {
    try {
        const response = yield call(apiGetTradesProfit, payload);
        let data = response.data;
        let now = new Date().toLocaleDateString("en-CA");
        let profitToday = 0;
        let lossToday = 0;
        if (data.items === null) data.items = [];
        data.items.forEach((el) => {
            el.date_epoch = new Date(el.date).getTime();
            let profit_amount = 0;
            if (el.items !== null && el.items.length > 0) {
                el.items.forEach(async (el2) => {
                    if (el2.status_str === "AVERAGING") return;

                    if (el2.avg_for !== "") {
                        data.items.forEach((dt) => {
                            dt.items.forEach((it) => {
                                if (it.order_id === el2.avg_for) {
                                    const qty_total = it.qty + el2.qty;
                                    const modal =
                                        it.qty * it.buy_price +
                                        el2.qty * el2.buy_price;
                                    const jual = qty_total * el2.sell_price;
                                    el2.profit_amount = jual - modal;
                                    el2.profit_pc = el2.profit_amount / modal;
                                    el2.ori = it;
                                }
                            });
                        });
                    }
                    if(el2.profit_amount >= 0) {
                        profit_amount += el2.profit_amount;
                    }
                    el2.image = await searchIcon(el2.base_asset);
                });
            }
            el.profit_amount = profit_amount;
            console.log(el.profit_amount)
            if (el.date === now) {
                profitToday += el.profit_amount ? el.profit_amount : 0;
                lossToday += el.loss_amount ? el.loss_amount : 0;
            }
        });
        data.lossToday = lossToday;
        data.profitToday = profitToday;

        yield put(tradesProfitSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesProfitError(message));
    }
}

function* getTradesHistory({ payload }) {
    try {
        const response = yield call(apiGetTradesHistory, payload);
        let data = response.data;
        if (data.items === null) data.items = [];
        if (data.items !== null) {
            data.items.forEach(async (el) => {
                el.date_epoch = new Date(el.date).getTime();
                if (el.items !== null) {
                    el.items.forEach(async (el_items) => {
                        el_items.image = await searchIcon(el_items.base_asset);
                    });
                }
            });
        }
        yield put(tradesHistorySuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesHistoryError(message));
    }
}

function* syncTrades({ payload }) {
    try {
        yield delay(2000);
        yield call(apiSyncTrades, payload);
        yield put(tradesSyncSuccess("Synchronize successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesSyncError(message));
    }
}
function* avgTrades({ payload }) {
    try {
        yield call(apiAvgTrades, payload);
        yield put(tradesAvgSuccess("Averaging successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesAvgError(message));
    }
}

function* tickSize({ payload }) {
    try {
        const response = yield call(apiGetTickSize, payload);
        const data = {
            symbol:`${response.data.base_asset}${response.data.quote_asset}`,
            tickSize:response.data.tick_size,
            decimalLength:get_decimal_length(response.data.tick_size),
            message:'SUCCESS'
        };
        const symbol = data.symbol;
        const ticksize_data = localStorage.getItem(
            `bidbox_user_ticksize_${symbol}`
        );
        if (ticksize_data === null || ticksize_data === undefined) {
            localStorage.setItem(
                `bidbox_user_ticksize_${symbol}`,
                JSON.stringify(data)
            );
        }
        yield put(tickSizeSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tickSizeError(message));
    }
}

function* getCurrentPrice({ payload }) {
    try {
        const response = yield call(apiGetCurrentPrice, payload);
        const data = response.data;
        yield put(tradesGetCurrentPriceSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(tradesGetCurrentPriceError(message));
    }
}
function* authSaga() {
    yield takeEvery(TRADES_SYNC, syncTrades);
    yield takeEvery(EXCHANGE_ACTIVATION, exchangeActivation);
    yield takeEvery(TRADES, getTrades);
    yield takeEvery(TRADES_CANCEL, cancelTrades);
    yield takeEvery(TRADES_SELL, sellTrades);
    yield takeEvery(TRADES_PROFIT, getTradesProfit);
    yield takeEvery(TRADES_HISTORY, getTradesHistory);
    yield takeEvery(TRADES_AVG, avgTrades);
    yield takeEvery(TICK_SIZE, tickSize);
    yield takeEvery(TRADES_CURRENT_PRICE, getCurrentPrice);
}

export default authSaga;
