import React from "react";
import { useHistory } from "react-router-dom";
const TNC = (props) => {
    const history = useHistory();
    const propsState = props.location.state;

    const backToSignup = () => {
        try {
            history.push(propsState.prevPath);
        } catch (error) {
            history.push("/auth/signup");
        }
    };
    return (
        <div className="sc-dbhs">
            <header className="or-header">
                <button className="back-button me-auto" onClick={backToSignup}>
                    <i className="fa-solid fa-angle-left back-icon"></i>
                </button>
                <span className="text-uppercase fw-500 me-auto">
                    Terms of Use
                </span>
            </header>
            <div className="dbhs-content" style={{ padding: 20 }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>TERMS OF USE</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>BIDBOX</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    Terms of Use of BIDBOX application
                                    (hereinafter referred to as &ldquo;
                                    <strong>Terms of Use</strong>&rdquo;) is an
                                    agreement between You (hereinafter referred
                                    to as &ldquo;<strong>You</strong>&rdquo; or
                                    &ldquo;<strong>User</strong>&rdquo;) and
                                    (hereinafter referred to as &ldquo;
                                    <strong>BIDBOX</strong>&rdquo;).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    BIDBOX provides a subscription-based
                                    application to help its user perform
                                    cryptocurrency trading in the spot market.
                                    This Terms of Use regulates how You use
                                    products, services, and technologies
                                    provided by BIDBOX, including but not
                                    limited to when You access BIDBOX official
                                    website, and download, register, and/or use
                                    BIDBOX application both Android and iOS
                                    based.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    These Terms of Use govern Your use of Our
                                    Application or Our Service. As used in these
                                    Terms of Use, &ldquo;Application&rdquo;,
                                    &ldquo;BIDBOX&rdquo;, "Our Service" or "The
                                    Service" means the personalized service
                                    provided by BIDBOX as a tool to help You do
                                    cryptocurrencies trading activity on a
                                    cryptocurrencies exchanger platform,
                                    including all features and functionalities,
                                    recommendations and reviews, our websites,
                                    and user interfaces, as well as all content
                                    and software associated with BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    By signing up as User, You hereby declare
                                    that You have READ, UNDERSTOOD, ACCEPTED,
                                    AND AGREED to the entire terms and
                                    conditions that are included in this Terms
                                    of Use
                                </p>
                                <p>as follows:</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>A.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>DEFINITIONS</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    As long as the context of the sentence does
                                    not specify otherwise, the terms or
                                    definitions in this Terms of Use have the
                                    following meanings:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Account means a
                                    BIDBOX account used to access the Service
                                    and represents one's membership as a BIDBOX
                                    User. This definition includes Account that
                                    has activated BIDBOX Robot and Account that
                                    has not activated BIDBOX Robot yet.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; Active User means
                                    a User who has activated the BIDBOX Robot on
                                    the BIDBOX Application. Active User also
                                    automatically become members of the BIDBOX
                                    Multi-Level Marketing team.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; Code of Ethics is
                                    a set of documents containing a code of
                                    ethics and regulations that must be complied
                                    with by all Users in order to create a good
                                    direct selling business environment.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; Crypto Asset
                                    Trading Activity or Crypto Trading means the
                                    activity of buying and selling Crypto Assets
                                    on the basis of achieving the points of
                                    agreement between demand price and offer
                                    price that happens in a market or exchanger
                                    platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; Crypto Assets
                                    mean intangible commodities in digital form,
                                    using cryptography that use principles of
                                    peer-to-peer (interface) network-based
                                    decentralized technology or known as
                                    Blockchain Network, that is traded in a
                                    platform. Reference to Crypto Assets in this
                                    document has the same meaning as
                                    cryptocurrency.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp;&nbsp;&nbsp; Marketing Plan is
                                    a set of documents containing the BIDBOX
                                    business plan and regulations regarding all
                                    matters related to multi-level direct
                                    selling in relation with BIDBOX Applications
                                    and Robots as the products.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    7.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX means an
                                    application with the aim of selling a Crypto
                                    Asset trading tool called the BIDBOX Robot.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    8.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX Robot
                                    means a tool for trading Crypto Assets in
                                    the form of an automator to read crypto
                                    market movements, place buy or sell
                                    positions on the Crypto Asset
                                    Exchange/Trading Platform, as well as
                                    executing the sale or purchase of Crypto
                                    Assets automatically based on the algorithm
                                    provided by the User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    9.&nbsp;&nbsp;&nbsp;&nbsp; Privacy Policy
                                    means a provision that regulates how BIDBOX
                                    collects, uses, maintains and discloses
                                    information collected from Users through the
                                    BIDBOX application.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    10.&nbsp;{" "}
                                    <strong>Registration&nbsp;</strong>means the
                                    registration process to become a Member of
                                    BIDBOX through BIDBOX platform, which
                                    consists of registering private information,
                                    creating a BIDBOX account, and approval to
                                    the Terms of Use and Privacy Policy of
                                    BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    11.&nbsp; Services mean services provided by
                                    the BIDBOX application including but not
                                    limited to the sale of the BIDBOX Robot
                                    software.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    12.&nbsp; The Exchange/Trading Platform
                                    means a platform that provides Crypto Asset
                                    exchange (buying and selling) services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    13.&nbsp; User or You means the person
                                    (individual) owner of the BIDBOX Account.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    14.&nbsp; User&rsquo;s BIDBOX Address means
                                    a combination of numbers and letters
                                    operating on the Tron network (TRC20) with a
                                    parent (core) controlled by BIDBOX,
                                    functioning to store, send and receive
                                    Crypto Assets in USDT.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    15.&nbsp; Username means the unique name
                                    entered by User during Registration.
                                    Username also acts as a referral code when a
                                    User invites a prospective user.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>B.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>BIDBOX ACCOUNT REGISTRATION</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp; To be able to use Our
                                    Services, You must be registered as User
                                    with the following requirements:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Is of 18 (eighteen) years old or older;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Physically and mentally healthy, has a
                                    common sense;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Registering through BIDBOX Application; and
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Agreed to be subject to this Terms of Use
                                    and the Privacy Policy.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp; Upon registering a BIDBOX
                                    Account, You must provide Your information,
                                    including but not limited to:
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>Name;&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>E-mail;&nbsp;and</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>Citizenship.</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp; During Registration process,
                                    User will be required to make a Username.
                                    Username that has been used cannot be used
                                    by other Users. A Username consists of a
                                    minimum 5 and a maximum of 10 characters of
                                    alphabet and/or number without space or
                                    symbol. Username will be used as a
                                    credential along with Password to login into
                                    User&rsquo;s Account and as a referral code
                                    when inviting a Prospective User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp; BIDBOX has the right to
                                    request for information or other documents
                                    as mentioned in this Terms of Use that is
                                    required in relation to the User&rsquo;s
                                    information. User Candidate hereby declares
                                    and guarantees that every
                                    data/explanation/document/information/statement
                                    that is provided in relation to him/herself
                                    including but not limited to regarding their
                                    registration process as User is
                                    comprehensive, original, true, and in
                                    accordance with the actual circumstances and
                                    that every
                                    data/explanation/document/information/statement
                                    is the latest that has not been changed and
                                    is still valid/not expired.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp; User hereby agrees that
                                    access to the BIDBOX Account is valid
                                    effectively after all requirements are
                                    fulfilled by User and the registration
                                    process has went through the verification
                                    process to be approved by BIDBOX. Any risks
                                    that may arise related to the
                                    closing/blocking/freezing of Accounts caused
                                    by errors and/or negligence of&nbsp;the
                                    Verified User, will be the responsibility of
                                    the said&nbsp;Verified User. BIDBOX is not
                                    responsible and will not
                                    compensate&nbsp;Verified User or Party in
                                    any form for any claim/suit and compensation
                                    from&nbsp;Verified User or any Party in
                                    connection with the
                                    closing/blocking/freezing of Verified
                                    Member.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp; All data, explanation,
                                    information, statement, document obtained by
                                    BIDBOX regarding User, will be the property
                                    of BIDBOX and BIDBOX have the right to
                                    verify, match, assess, keep confidential or
                                    use them for the benefit of BIDBOX in
                                    accordance with the applicable legal
                                    provisions without obligation to notify or
                                    request approval, provide guarantees or
                                    compensation and for any reason to User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    7.&nbsp;&nbsp; BIDBOX will regulate, manage
                                    and carry out supervision according to
                                    procedures specified by BIDBOX for all data,
                                    explanation, information, statement,
                                    document or anything related to User or the
                                    business activities or
                                    User&rsquo;s&nbsp;transactions&nbsp;related
                                    to User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    8.&nbsp;&nbsp; User approves and authorizes
                                    BIDBOX to use all data, explanation and
                                    information obtained by BIDBOX regarding
                                    User including but not limited to using
                                    User&rsquo;s personal communication
                                    facilities for all other purposes as long as
                                    possible and permitted by Legislation that
                                    applies, including those aimed at marketing
                                    for BIDBOX or for other parties in
                                    collaboration with BIDBOX. For data usage
                                    that requires the approval of other parties,
                                    User declares that they have given written
                                    approval to any third party for use such
                                    data, explanation and information, and
                                    therefore BIDBOX will not provide
                                    compensation and/or liability in any form to
                                    User and any party for any risk, claim, suit
                                    and/or compensation that may arise in the
                                    future related to use of data, explanation
                                    and information that has obtained such
                                    written approval by BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>C.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>BIDBOX ACCOUNT</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Every Account
                                    opened will be administered by BIDBOX based
                                    on the special identification according to
                                    the User&rsquo;s name which will also apply
                                    as the name/identity of Account as stated in
                                    the User&rsquo;s identity document. User is
                                    prohibited from using a User Account other
                                    than User&rsquo;s own Account, or accessing
                                    other User&nbsp;Account, or helping other
                                    people in order to get unauthorized access
                                    to the Account.&nbsp;All usage of User
                                    Account is the sole responsibility of the
                                    Account owner recorded in the BIDBOX
                                    database system.
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User must use and
                                    include User&rsquo;s email/username and
                                    password as verified by BIDBOX at
                                    registration.&nbsp;The BIDBOX system will
                                    automatically reject any access to an
                                    Account, if it is not accompanied by the
                                    correct combination of email/username and
                                    password.&nbsp;User is responsible for
                                    maintaining the confidentiality of password,
                                    Account, transaction PIN, access to
                                    User&rsquo;s BIDBOX Address, email login
                                    access and all types of activities that
                                    include transactions in User&rsquo;s
                                    Account. User is fully responsible for the
                                    use of passwords and BIDBOX
                                    Accounts.&nbsp;If there is password and/or
                                    Account usage without permission from the
                                    User and other alleged violations occur,
                                    User&nbsp;must contact BIDBOX by sending
                                    an&nbsp;e-mail<em>&nbsp;</em>to&nbsp;
                                    <a href="mailto:support@bidbox.community">
                                        support@bidbox.community
                                    </a>{" "}
                                    accompanied by supporting information.
                                    BIDBOX is not responsible for losses
                                    incurred due to misuse of Accounts and/or
                                    passwords, with or without
                                    User&rsquo;s&nbsp;knowledge.
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; User agrees not
                                    to use the Service provided by BIDBOX to
                                    commit criminal acts of any kind, including
                                    but not limited to, money laundering,
                                    gambling, the purchase of illegal goods,
                                    terrorist activities or hacking activities.
                                    Each User who violates these rules can be
                                    dismissed and must be responsible for the
                                    losses suffered by BIDBOX or other users of
                                    BIDBOX. BIDBOX has the right to close or
                                    freeze the Account, disqualify User&rsquo;s
                                    rank, freeze received Bonus and upcoming
                                    Bonus or disable Account withdrawal feature,
                                    and freeze on-going strategies connected to
                                    the Exchanger(s) therein, if it finds
                                    suspicious activities or any prohibited
                                    activities as mentioned in this Terms of
                                    Use, in the Account up to an indefinite
                                    time.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>D.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>ACCOUNT DATA CHANGES</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; User cannot
                                    change the Username and email which has been
                                    registered during Registration process, with
                                    any reason whatsoever.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; Changes in
                                    Passwords are possible as a reason for
                                    security and the mechanism is available in
                                    the Service. For the security reasons after
                                    changing password, User&rsquo;s Account will
                                    not be able to do withdrawal for 12 (twelve)
                                    hours. On-going strategies will not be
                                    affected when User change the password.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>E.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>SCOPE OF SERVICE</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX ROBOT</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.1.&nbsp; BIDBOX provides Services in the
                                    form of mobile-based applications (Android
                                    and iOS), which are intended as tools for
                                    User to execute transactions on the
                                    Exchange/Trading Platform, which are
                                    referred to as BIDBOX Robot (Robot).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.2.&nbsp; User will be asked to pay a
                                    subscription fee for one year in advance
                                    when using the BIDBOX Robot.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.3.&nbsp; After completing the payment of
                                    the Robot subscription fee, the User can use
                                    the Robot with its features and updates as
                                    follows:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp; Connect Robot to
                                    Exchanger/Market Platform through API
                                    Binding.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp; Make trade settings of
                                    automated buy and sell on Robot to execute
                                    Crypto Asset transaction in the
                                    Exchanger/Market Platform. The settings are:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (a)&nbsp;&nbsp; First Buy In Amount: the
                                    amount of initial buy-ins in USDT (minimum
                                    buy-in is 20 USDT, it is highly recommended
                                    to only use 3-5% out of your capital as a
                                    first buy-in amount).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (b)&nbsp;&nbsp; Signal Quantity: the amount
                                    of signals you want to receive as a maximum
                                    pending sell before you stop receiving
                                    another signal.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (c)&nbsp;&nbsp;&nbsp; Modes: there are two
                                    modes available. Standard mode allows you to
                                    receive signals on major coins, while
                                    Professional mode allows you to receive
                                    signals on major and minor coins (alt coin,
                                    meme coin).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (d)&nbsp;&nbsp; Bot Signal: there are three
                                    different services. The Basic Bot allows you
                                    to request up to 3 Signals Quantity per day;
                                    The Advance Bot allows you to request up to
                                    10 Signals Quantity per day; The Expert Bot
                                    allows you to request up to 25 Signals
                                    Quantity per day.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp; Receive report and calculation of
                                    profits from trading in Exchanger/Market
                                    Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp; Receive report and calculation of
                                    referral bonus from Robot activation.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    v.&nbsp;&nbsp; Access price, floating
                                    profit/loss, quantity, and on-going strategy
                                    of a Crypto Asset based on real-time data
                                    obtained from Exchanger/Market Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; MULTI-LEVEL
                                    MARKETING
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.1. BIDBOX opens opportunities for
                                    interested Users to market the BIDBOX
                                    Application on a commission based sales
                                    basis as set out in the Marketing Plan.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.2. Every User who has completed
                                    Registration will have a Username. When User
                                    has activated the Robot, the Username will
                                    also function as a referral code to invite
                                    other Prospective Users and as a User ID in
                                    the BIDBOX's marketing network.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.3. The user is entitled to receive a
                                    commission every time his Username is used
                                    by another person to Register. The
                                    commission value received by the User
                                    depends on the User's rank when his Username
                                    is used for Registration. User rank are
                                    determined based on their achievements as
                                    set out in the Marketing Plan.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.4. Any commissions received by the User
                                    will be distributed directly through the
                                    BIDBOX Application system to the User's USDT
                                    TRC20 address located on the BIDBOX
                                    Application. (&ldquo;
                                    <strong>User&rsquo;s BIDBOX Address</strong>
                                    &rdquo;)
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>3.&nbsp;&nbsp;&nbsp;&nbsp; PROFIT SHARING</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.1. In providing Services, particularly
                                    related to trading transactions carried out
                                    with the help of Robots, BIDBOX applies a
                                    profit sharing system of 83% 17% (83% profit
                                    for User and 17% profit for BIDBOX) for each
                                    time User received profit or will be
                                    referred to as &ldquo;
                                    <strong>Gas Fee</strong>&rdquo;.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.2. User will receive profits from Crypto
                                    Assets trading directly on their own
                                    Exchange/Trading Platform, not through
                                    BIDBOX or other third parties. Therefore,
                                    every time the User receives a profit, the
                                    Robot will charge the Gas Fee to the
                                    User&rsquo;s BIDBOX Address that is on the
                                    User's BIDBOX Account.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.3. If the User does not have enough funds
                                    to pay the Gas Fee, the Robot's function
                                    will be temporarily suspended and will
                                    resume normally when BIDBOX has successfully
                                    debited the Gas Fee to the User&rsquo;s
                                    BIDBOX Address.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.4. BIDBOX does not require User to deposit
                                    USDT for Gas Fee to the User&rsquo;s BIDBOX
                                    Address in a certain amount. BIDBOX will
                                    only notify the User to immediately deposit
                                    USDT when the USDT balance at the
                                    User&rsquo;s BIDBOX Address is less than 10
                                    USDT for the purpose of the smooth operation
                                    of the Robot. BIDBOX advises the User to
                                    wisely calculate the percentage of profit
                                    that may be received based on the conditions
                                    of the Crypto Asset trading strategy
                                    executed by the Robot in connection with the
                                    deduction of Gas Fee by BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; USER&rsquo;S
                                    BIDBOX ADDRESS AND WITHDRAWAL
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.1. Obtain an USDT TRC20 address generated
                                    once User completing Registration
                                    (User&rsquo;s BIDBOX Address). The
                                    User&rsquo;s BIDBOX Address will be used to
                                    receive bonus distribution. User may deposit
                                    and/or withdraw the USDT in User&rsquo;s
                                    BIDBOX Address. BIDBOX can credit funds
                                    available in such address for Robot
                                    activation and Gass Fee.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.2. Withdrawal of the commissions received
                                    by User can be done anytime anywhere as long
                                    as it is connected to the internet.
                                    Withdrawal can be made by transferring or
                                    withdrawing USDT in the User&rsquo;s BIDBOX
                                    Address to the User's address or wallet on
                                    the platform that provides Crypto Asset
                                    exchange services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.3. Withdrawals on User&rsquo;s BIDBOX
                                    Address are limited per day for each User
                                    according to his/her rating. Following are
                                    the terms for the maximum Withdrawals based
                                    on rank as defined in the Marketing Program:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp; VIP1 maximum withdrawal
                                    500 USDT
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp; VIP2 maximum withdrawal
                                    1,000 USDT
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp; VIP3 maximum withdrawal 2,000
                                    USDT
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp; VIP4 maximum withdrawal 5,000 USDT
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    v.&nbsp;&nbsp; VIP5 maximum withdrawal
                                    10,000 USDT
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vi.&nbsp; VIP6 maximum withdrawal 50,000
                                    USDT
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.4. Withdrawals are processed in real-time,
                                    unless the withdrawal amount exceeds 5,000
                                    USDT which will be verified by an
                                    administrator in advance within a maximum
                                    period of 1 hour.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>5.&nbsp;&nbsp;&nbsp;&nbsp; OTHER SERVICES</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.1. Receive report and calculation of
                                    profits from trading in Exchanger/Market
                                    Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.2. Receive report and calculation of
                                    referral bonus from Robot activation.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.3. Access price, floating profit/loss,
                                    quantity, and on-going strategy of a Crypto
                                    Asset based on real-time data obtained from
                                    Exchanger/Market Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.4. Access customer support centre via
                                    chat.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>F.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        SUSPENSION, TERMINATION, AND
                                        CANCELLATION
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>1.&nbsp;&nbsp;&nbsp;&nbsp; We can:</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>(a)&nbsp;&nbsp; cancel membership;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (b)&nbsp;&nbsp; disable Account temporarily
                                    or permanently;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (c)&nbsp;&nbsp;&nbsp; degrade User&rsquo;s
                                    rank;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (d)&nbsp;&nbsp; stop bonus distribution
                                    and/or withdrawal;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (e)&nbsp;&nbsp; disable User&rsquo;s
                                    Referral Code; and/or
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (f)&nbsp;&nbsp;&nbsp; suspend, limit, or
                                    stop User&rsquo;s access to several or all
                                    of the Services, including but not limited
                                    to disable Robot and all of its on-going
                                    strategies in Exchanger/Market Platform
                                    connected to the Robot.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    In the event of the following case,
                                    including but not more, as follows:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (a)&nbsp;&nbsp; We, based on our reasonable
                                    opinion, required by the prevailing law or
                                    the court or any other authorities in any
                                    jurisdiction;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (b)&nbsp;&nbsp; We reasonably suspect that
                                    User violates this Terms of Use;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (c)&nbsp;&nbsp;&nbsp; We reasonably suspect
                                    that User violates the Privacy Policy;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (d)&nbsp;&nbsp; We reasonably suspect that
                                    User violates the Code of Ethics;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (e)&nbsp;&nbsp; We are concerned that there
                                    are suspicious activities such as but not
                                    limited to continuous credentials error in a
                                    login attempt, intervention to the
                                    Robot&rsquo;s on-going strategy, is wrong or
                                    breaches the User&rsquo;s Account security;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (f)&nbsp;&nbsp;&nbsp; We suspect that the
                                    Services used in a fraudulent or
                                    unauthorized manner;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (g)&nbsp;&nbsp; We suspect there is a money
                                    laundering, terrorism funding, scam, or
                                    other financial crime;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (h)&nbsp;&nbsp; The use of Your Account is
                                    under a litigation process, investigation,
                                    or delayed legal process, and/or we notice
                                    high legal risk related to the use of Your
                                    Account; and/or
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (i)&nbsp;&nbsp;&nbsp;&nbsp; User took any
                                    kinds of action that may prevent Our
                                    control, such as opening several accounts or
                                    misuse the promotion that we may offer from
                                    tim to time.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; We may also
                                    refuse to renew User&rsquo;s membership when
                                    it is due, if there is not enough balance to
                                    pay for the Robot&rsquo;s subscription fee.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; We may also
                                    suspend the Robot function if User does not
                                    have enough balance to pay for gass fee.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; If we close or
                                    suspend Your Account or terminate Your use
                                    of the Service under the terms set out in
                                    this section, We will (unless not permitted
                                    by law) provide notice of Our actions and
                                    the reasons for closing or suspending and
                                    where necessary, with procedures to correct
                                    any factual errors leading to closure or
                                    suspension. We will lift the closure or
                                    suspension as soon as possible after the
                                    reasons for the closure or suspension have
                                    been resolved, however, we do not guarantee
                                    such revocation.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; We may suspend,
                                    limit or terminate your access to any or all
                                    of the Services and/or disable or cancel
                                    Your Account, without reason by giving you 1
                                    (one) week notice before the effective date
                                    of closure or cancellation. You acknowledge
                                    that our decision to take certain actions,
                                    including restricting access, suspending or
                                    closing your Account, may be based on
                                    confidential criteria that are important to
                                    Our risk management and security protocols.
                                    You agree that we are under no obligation to
                                    disclose details of its risk management and
                                    security procedures to You.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp;&nbsp;&nbsp; In the event of
                                    termination of the Account, the User agrees
                                    and states that he is willing to remain
                                    bound by the Terms and Conditions and stop
                                    using the Service, gives BIDBOX the right to
                                    delete all information and data on the
                                    BIDBOX server, and declares that BIDBOX is
                                    not responsible to the User or third parties
                                    for the termination of access and deletion
                                    of information and data in the Account.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>G.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        FEEDBACKS, QUESTIONS, COMPLAINTS, AND
                                        TROUBLESHOOTING
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; If you have
                                    feedback, questions, complaints or
                                    troubleshoots to be submitted, contact us
                                    via our customer support website page at
                                    bidbox.community When you contact us, please
                                    provide your name, registered user id/email
                                    address, and other information we need to
                                    identify you, your BIDBOX Account, and the
                                    transaction from which you received
                                    feedback, questions or complaints.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; If you wish to
                                    make a complaint, it is expected that you
                                    state the cause of the complaint, how you
                                    would like us to resolve the complaint and
                                    any other information you believe is
                                    relevant. We will acknowledge receipt of
                                    your complaint if you contact us via our
                                    customer support web page. The Customer
                                    Complaints Officer ("
                                    <strong>Officer</strong>") will consider
                                    your complaint. The officer will consider
                                    your complaint without prejudice based on
                                    the information you provide and any
                                    information provided by BIDBOX. Within 15
                                    (fifteen) working days of receipt of your
                                    complaint, the Officer will address all
                                    points raised in your complaint by sending
                                    an e-mail ("
                                    <strong>Resolution Notice</strong>") in
                                    which the Officer will: (i) offer to resolve
                                    your complaint by what you ask; (ii) make a
                                    determination to reject your complaint and
                                    explain the reasons for refusal; or (iii)
                                    offer to resolve your complaint with an
                                    alternative solution. In exceptional
                                    circumstances, if the Officer is unable to
                                    respond to your complaint within 15
                                    (fifteen) working days for reasons beyond
                                    BIDBOX control, the Officer will send a
                                    reply indicating the reason for the delay in
                                    responding to your complaint and specifying
                                    the time limit used by the Officer. will
                                    respond to your complaint (which is no later
                                    than 35 (thirty five) working days since we
                                    received your complaint.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>H.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>STATEMENT AND GUARANTEE</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; All services
                                    inside the Website does not provide any
                                    warranty or guarantee and the BIDBOX does
                                    not guarantee that Website will always be
                                    accessible at any time due to error or
                                    system maintenance and periodic network
                                    maintenance.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User states and
                                    guarantees that they will use BIDBOX
                                    services properly and responsibly and not
                                    take actions that are contrary to the laws,
                                    rules and regulations that may apply.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; User hereby
                                    states and guarantees not to use Services in
                                    the sale of goods and/or services related
                                    to:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (a)&nbsp;&nbsp; Narcotics, materials and/or
                                    chemicals for research;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (b)&nbsp;&nbsp; money and anything similar
                                    to money, including&nbsp;derivatives;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (c)&nbsp;&nbsp;&nbsp; goods and/or services
                                    that violate Intellectual Property Rights;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (d)&nbsp;&nbsp; ammunition, firearms,
                                    explosives, sharp weapons in accordance with
                                    applicable legal provisions;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (e)&nbsp;&nbsp; goods and/or services that
                                    discloses personal information from Third
                                    Party that Is against the law;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (f)&nbsp;&nbsp;&nbsp; Support for Ponzi
                                    schemes and matrix programs;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (a)&nbsp;&nbsp; Goods and/or services
                                    related to lottery purchases,&nbsp;lay-away
                                    systems;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>(g)&nbsp;&nbsp; services related to bank;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>(h)&nbsp;&nbsp; Credit card payments;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (i)&nbsp;&nbsp;&nbsp;&nbsp; Settlement of
                                    credit;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (j)&nbsp;&nbsp;&nbsp;&nbsp; Support towards
                                    prohibited organization or prohibited by the
                                    government.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; User declares and
                                    guarantees not to use Services for the
                                    actions involved in practice of gambling
                                    and/or other activities that charge entry
                                    and promising prizes, including but not
                                    limited to casino games, gambling in sports,
                                    businesses that facilitate gambling and
                                    lottery methods.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; User declares and
                                    guarantees that all the data, information
                                    and documents provided by User
                                    <em>&nbsp;</em>to BIDBOX are true, legal,
                                    honest, transparent, complete and accurate
                                    data, information and documents. User
                                    <em>&nbsp;</em>states that they are willing
                                    to be prosecuted in the criminal manner and
                                    if the BIDBOX knows or obtains information
                                    from any party that the data, information
                                    and documents provided by User
                                    <em>&nbsp;</em>turns out to be untrue/not
                                    true/false. Member are willing to update
                                    data/information (profile update) if at any
                                    time requested by BIDBOX and all documents
                                    have been given become fully owned by
                                    BIDBOX.
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX has
                                    provided sufficient information and
                                    explanation regarding BIDBOX services that
                                    will be used by User in&nbsp;accordance with
                                    provisions in Terms of Use and User have
                                    understood and understood and willing to
                                    bear all consequences that may arise in
                                    connection with the use of BIDBOX services
                                    including benefits, risks and costs inherent
                                    in services and services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    7.&nbsp;&nbsp;&nbsp;&nbsp; User hereby
                                    agrees and authorizes BIDBOX to use all the
                                    data, explanation and information obtained
                                    by the BIDBOX regarding User including but
                                    not limited to the use of User&rsquo;s
                                    personal communication facilities for all
                                    other purposes as long as possible and
                                    permitted by applicable legislation,
                                    including those aimed at marketing the
                                    BIDBOX products or other parties, in
                                    collaboration with BIDBOX. For data usage
                                    that requires approval of other parties,
                                    with this User stating that there has been
                                    written approval from any third parties for
                                    the data usage, explanation and information,
                                    and therefore User guarantees and agrees
                                    that BIDBOX will not provide compensation
                                    and/or liability in any form to the User or
                                    any party of risks, losses, demands and/or
                                    responsibilities that may arise in the
                                    future in connection with data usage,
                                    explanation and information that has
                                    obtained such written approval by BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    8.&nbsp;&nbsp;&nbsp;&nbsp; User declares and
                                    guarantees that transactions carried out
                                    using BIDBOX services are transactions that
                                    do not violate provisions of prevailing laws
                                    and regulations and provisions regarding
                                    receipt of the transaction implementation as
                                    stipulated in Terms of Use. In the event
                                    that BIDBOX finds indications of
                                    transactions that are not in accordance with
                                    the provisions of the applicable legislation
                                    and/or provisions regarding acceptance of
                                    transactions arranged in Terms of Use, then
                                    BIDBOX has full rights to close the User
                                    Accounts by disabling BIDBOX services on the
                                    User and the User hereby agrees that BIDBOX
                                    hereby will not provide compensation and or
                                    liability in any form to the User or any
                                    party for claim and/or demands arising in
                                    connection with the deactivation of Service
                                    on the User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    9.&nbsp;&nbsp;&nbsp;&nbsp; User states and
                                    guarantees that risks to use of services,
                                    products and promotions of Third Parties
                                    with User(if any), are borne by User, and
                                    User states that BIDBOX is not responsible
                                    for the service and the performance of Third
                                    Party services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    10.&nbsp; User hereby are fully responsible
                                    and agree that BIDBOX will not provide
                                    compensation and or liability in any form to
                                    User or any party for any losses and or
                                    claims and or demands that arise or may be
                                    experienced by BIDBOX as a result of
                                    negligence or failure of User in operating
                                    BIDBOX Robot.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    11.&nbsp; The User hereby is fully
                                    responsible and agrees that BIDBOX will not
                                    provide compensation and or liability in any
                                    form to the User or any party for any losses
                                    and or claims and or demands that arise or
                                    may be experienced by BIDBOX as a result of
                                    the User's negligence or failure to the
                                    security of passwords or credentials of the
                                    User's BIDBOX Account.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    12.&nbsp; User hereby guarantees the BIDBOX
                                    that the User along with all employees
                                    and/or other parties work with the User will
                                    not reproduce and/or make, give, rent, sell,
                                    move, transfer, and/or switch BIDBOX
                                    services either partially or wholly to other
                                    parties for any reason, including but not
                                    limited to misuse of BIDBOX websites,
                                    application, and services to make
                                    transactions other than those specified in
                                    Terms of Use for any purpose, including but
                                    not limited to crime/fraud /deception. If
                                    User violates these provisions, then User
                                    must be responsible for any losses, claims
                                    and/or suits arising from these violations
                                    and hereby agree that BIDBOX will not
                                    provide compensation and or liability in any
                                    form to the Useror any party for any claims
                                    or demands and/or claims arising from the
                                    violation.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>
                                        I.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </strong>
                                    <strong>RESPONSIBILITY</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <ol>
                                    <li>
                                        User agrees to bear any risks, losses or
                                        consequences suffered by the User caused
                                        by, among others:
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    damage, delay, loss or error in sending
                                    order and communication, electronically
                                    caused by User;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    BIDBOX Account report or notification of the
                                    use of BIDBOX services sent to the User is
                                    received or read or misused by an
                                    unauthorized party on the BIDBOX Account;
                                    and/or
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    &nbsp;&nbsp;&nbsp;
                                    iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Account&nbsp;password known to other
                                    people/parties for User errors.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <ol>
                                    <li>
                                        User understands and agrees that User
                                        will use BIDBOX Accounts and services
                                        for transactions that do not conflict
                                        with applicable laws and/or BIDBOX
                                        internal policies and/or other
                                        regulations that apply nationally and
                                        internationally related to the execution
                                        of the transaction, either directly or
                                        indirectly, and BIDBOX will not provide
                                        compensation and/or liability in any
                                        form to the User or any party for any
                                        claims and/or claims and/or losses
                                        arising in connection with use of BIDBOX
                                        services by User for transactions that
                                        are categorized as suspicious
                                        transactions and/or transactions that
                                        are prohibited by provisions of laws
                                        and/or BIDBOX internal policies that
                                        apply and/or other regulations that
                                        apply both nationally and
                                        internationally that are related to
                                        transaction activities carried out by
                                        User directly or indirectly.
                                    </li>
                                </ol>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <ol>
                                    <li>
                                        In operating strategy on Robot to
                                        conduct transactions in Exchanger/Market
                                        Platform, User understands and agrees
                                        that there are certain sanctions imposed
                                        by government, including other State
                                        governments and/or the other authorized
                                        agencies on several countries, bodies
                                        and individuals. Refer to this, BIDBOX
                                        has the right not to carry out/process
                                        strategy and/or disable Robot that is
                                        considered as a violation of provisions
                                        of sanctions, and competent authorities
                                        can require disclosure of related
                                        information. BIDBOX is not responsible
                                        if BIDBOX website and/or application or
                                        other parties fail or delay the strategy
                                        that affect in execution of the
                                        transaction, or disclosure of
                                        information as a result of direct or
                                        indirect violations of the sanction
                                        provisions.
                                    </li>
                                </ol>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>
                                        J.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    </strong>
                                    <strong>RISKS</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Crypto Asset
                                    Trading is the high-risk activity. Crypto
                                    Asset Price are volatile, where prices can
                                    change significantly over time. In
                                    connection with price fluctuations, value of
                                    Crypto Asset can increase or decrease
                                    significantly at any time. All Crypto Asset
                                    or not, have the potential to experience a
                                    change in the value drastically or even
                                    become meaningless. There is high risk of
                                    loss as a result of buying, selling or
                                    trading anything on the Exchanger/Market
                                    Platform and BIDBOX as a party that provides
                                    device to help execute transaction is not
                                    responsible for changes in fluctuations in
                                    Crypto Asset exchange rate.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; Crypto Asset
                                    Trading also has additional risks not
                                    experienced by Crypto Asset or other
                                    commodities on the market. Unlike most
                                    currencies guaranteed by government or other
                                    legal institutions or by gold and silver,
                                    Crypto asset are a unique Crypto Asset and
                                    are guaranteed by the technology and trust.
                                    There is no central bank that can control,
                                    protect the value of Crypto Asset in a
                                    crisis, or print the currency.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; User is
                                    encouraged to be careful in measuring
                                    financial situation and ensure that User is
                                    willing to face risks involved in selling,
                                    buying or trading Crypto Asset. User is
                                    strongly advised to carry out personal
                                    research before making a decision to trade
                                    Crypto Asset. All decisions on trading
                                    Crypto Asset are independent decision by
                                    user consciously without coercion and
                                    release BIDBOX for trading activities in
                                    Crypto Asset.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX does not
                                    guarantee the long-term sustainability of
                                    Crypto Asset traded or exchanged in
                                    Exchanger/Market Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; With regard to
                                    mining activities Crypto Asset need to be
                                    rectified and confirmed that BIDBOX does not
                                    carry out mining, producing or printing
                                    Crypto Asset activities. Crypto asset was
                                    created and mined using the software
                                    specifically by miners that are scattered
                                    randomly throughout the world and was
                                    interconnected with the peer-to-peer
                                    technology on the blockchain.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>K.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>RESTRICTION</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Without reducing
                                    the intent of provisions regarding
                                    restrictions contained in this Terms of Use,
                                    then the User is prohibited from doing the
                                    following:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User is not
                                    permitted to take actions that can cause
                                    harm to BIDBOX and/or contrary to the Terms
                                    of Use and applicable laws and regulations.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; If User violates
                                    one or several conditions in this Terms of
                                    Use, then User will be fully responsible and
                                    hereby agree that for such violations BIDBOX
                                    has right to limit use BIDBOX services and
                                    User knows and agrees that BIDBOX will not
                                    provide compensation and/or liability in any
                                    form to that User or any party for any risks
                                    or consequences arising from restrictions on
                                    the use of these services. User hereinafter
                                    obliged to pay compensation to BIDBOX in the
                                    amount of the value of the loss that BIDBOX
                                    might experience for the violation. User
                                    hereby authorizes BIDBOX to debit
                                    User&rsquo;s Accounts for such compensation
                                    payments. In the event that funds in
                                    User&rsquo;s Account are not available
                                    and/or insufficient, then User must return
                                    all of the funds in cash to BIDBOX within no
                                    later than 7 (seven) working days from the
                                    time BIDBOX submits that claim.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>L.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>CONFIDENTIALITY</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Any information
                                    and/or data provided by BIDBOX to User and
                                    or data obtained by User as implementation
                                    of Terms of Use either given or delivered
                                    verbally, written, graphically or delivered
                                    through electronic media or information
                                    and/or data in other forms during the course
                                    of the conversation or during other work are
                                    confidential (hereinafter referred to as
                                    &ldquo;<strong>Confidential</strong>{" "}
                                    <strong>Information</strong>&ldquo;).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User agrees and
                                    coincidences at any time to keep
                                    confidential any Confidential Information
                                    obtained as an implementation of cooperation
                                    with anyone or will not use it for the
                                    interests of User or the interests of other
                                    parties, without first obtaining written
                                    approval from the authorized official from
                                    BIDBOX or other authorized parties in
                                    accordance with applicable legal provisions.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; If User violates
                                    provisions referred to in number 1 and 2 of
                                    the provisions regarding this
                                    confidentiality then all losses, claims
                                    and/or suits experienced by BIDBOX are fully
                                    User&rsquo;s responsibility, and at the
                                    first request of BIDBOX, User is obliged to
                                    settle it in accordance with the applicable
                                    legal and statutory provisions and provide
                                    compensation that may arise from violation
                                    to BIDBOX. User hereby agrees that BIDBOX
                                    will not provide any form compensation
                                    and/or accountability to User or any party
                                    for claim and/or suit that may arise in the
                                    future in connection with violation.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; The obligation to
                                    keep confidential information as referred to
                                    in number 1 and 2 as regarding the
                                    confidentiality becomes invalid if:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (a)&nbsp;&nbsp; Such confidential
                                    information becomes available to the general
                                    public.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (b)&nbsp;&nbsp; Confidential information is
                                    ordered to be opened to fulfil the order of
                                    court or other authorized government body.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (c)&nbsp;&nbsp;&nbsp; Confidential
                                    information is provided in accordance with
                                    applicable legal provisions.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; User agrees to
                                    make every effort and take action needed to
                                    avoid third parties in gaining access to or
                                    resulting in disclosure of confidential
                                    information.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp;&nbsp;&nbsp; In the event that
                                    the collaboration is over, User agrees that
                                    the obligation to keep documents and
                                    material that is confidential information as
                                    stipulated in this provision will remain
                                    valid.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>M.&nbsp;&nbsp; </strong>
                                    <strong>DEFAULT</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; User agrees that
                                    Default is the following thing or event:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Default, which if User is negligent in
                                    carrying out an obligation or violates a
                                    provision in the Terms of Use;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Incorrect Statement. When it turns out that
                                    statement or guarantee given by User in the
                                    Terms of Use &ndash; is incorrect or not in
                                    accordance with the reality.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; In the event of
                                    Default and/or Incorrect Statement by User,
                                    then BIDBOX can choose whether to continue
                                    or close User&rsquo;s Account. If BIDBOX
                                    wishes to close User&rsquo;s Account, then
                                    such request must be notified to User at a
                                    reasonable time according to BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; In the event of
                                    an occurrence of negligence by User as
                                    intended, then BIDBOX has the right to
                                    immediately deactivate User&rsquo;s Account
                                    on BIDBOX without having to make the prior
                                    notice to User, and User hereby agrees that
                                    BIDBOX will not provide compensation and/or
                                    liability in any form to User or any parties
                                    for all demands and/or claims and/or suits
                                    and/or compensation requests from parties
                                    that may arise in connection with the
                                    occurrence of such negligence.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>N.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        INTELLECTUAL PROPERTY RIGHTS
                                    </strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; User declares and
                                    agrees that BIDBOX as the holder of
                                    ownership rights to services, software,
                                    technology tools and content, websites and
                                    other materials including Intellectual
                                    Property Rights related to the BIDBOX
                                    facility.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User is only
                                    allowed to view, print and/or download a
                                    copy of material from BIDBOX Website for
                                    personal and non-commercial use. All
                                    commercial uses need to get permission from
                                    BIDBOX. Every commercial activity without
                                    permission from the BIDBOX is interpreted as
                                    a violation of BIDBOX Intellectual Property
                                    Rights and may results in the termination of
                                    the BIDBOX Account on User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>O.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>TAX</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>&nbsp;</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    That taxes on trading activities of Crypto
                                    Asset are taxes borne by each activity
                                    actor, in this case User and BIDBOX. The
                                    subscription fee for Robot paid by User
                                    includes the Value-Added Tax based on the
                                    applicable regulation(s). User can consult
                                    tax payments to Personal Tax Consultants,
                                    and BIDBOX does not cover User taxes unless
                                    otherwise specified in these Terms and
                                    Conditions.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>P.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>SECURITY</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    BIDBOX has implemented network and security
                                    actions as information security network
                                    against unauthorized access to the use,
                                    changes and/or disclosures of Account, with
                                    security standards that are in accordance
                                    with the provisions of the applicable
                                    legislation. The entry of parties is
                                    responsible for access in the use, change
                                    and/or disclosure Account from third parties
                                    due to the User negligence and/or errors
                                    resulting in the risk borne by the User,
                                    then BIDBOX will not provide compensation
                                    and/or liability in any form to User or any
                                    other parties for any risks,
                                    responsibilities and demands that may arise
                                    due to the negligence/deliberation/error of
                                    the User in providing information.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>Q.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>FORCE MAJEURE</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Force Majeure is
                                    defined as events that occur outside of
                                    BIDBOX capabilities and power, which affect
                                    the execution of Services, including but not
                                    limited to:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Earthquakes, hurricanes, floods, landslides,
                                    volcanic eruptions and other natural
                                    disasters;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    War, demonstrations, riots, terrorism,
                                    sabotage, embargoes and mass strikes;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Economic policies from the Government that
                                    affect directly;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; As long as BIDBOX
                                    has carried out all its obligations in
                                    accordance with applicable laws and
                                    regulations relating to the occurrence of
                                    Force Majeure, BIDBOX will not provide
                                    compensation and/or liability in any form to
                                    the User or any other parties for any risks,
                                    responsibilities and demands anything that
                                    may arise in connection with the delay or
                                    failure to carry out obligations due to
                                    Force Majeure.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>R.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>LIMITATION OF LIABILITY</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; User hereby
                                    understands and agrees that the BIDBOX
                                    Application, including Robot, is not a party
                                    that carries out exchange transactions or
                                    buying and selling of Crypto Assets. User
                                    understands that all funds intended for
                                    buying and selling Crypto Assets (trading)
                                    are in the wallet of each User on the
                                    Exchange/Market Platform, which can only be
                                    withdrawn, deposited, and controlled by User
                                    and subject to terms and conditions of the
                                    related Exchange/Market Platform. Thus,
                                    BIDBOX does not have any access to
                                    User&rsquo;s fund deposited in
                                    Exchange/Market Platform. BIDBOX is not
                                    responsible and will not be held liable for
                                    User&rsquo;s fund deposited to and withdrawn
                                    from the Exchange/Market Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User hereby
                                    understands and agrees that BIDBOX has no
                                    relationship or any affiliation with any
                                    Crypto Asset developer. Crypto Assets that
                                    User can choose to open strategy on the
                                    BIDBOX Robot are Crypto Assets that have
                                    been listed on the Exchange/Trading
                                    Platform, and/or is ranked on the top 100
                                    (one hundred) of the Crypto Assets market
                                    capitalization based on coinmarketcap.com
                                    data. BIDBOX is not responsible and will not
                                    be held liable for any price fluctuation of
                                    Crypto Asset chosen for Robot to run its
                                    strategy by User on the Exchange/Market
                                    Platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX always
                                    strives to maintain secure, convenient, and
                                    well-functioning BIDBOX Services, but we
                                    cannot guarantee continuous operation or
                                    that access to our Services can always be
                                    perfect. There is possibility that
                                    information and data on BIDBOX website and
                                    application are not available in real time.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX does not
                                    guarantee that the result obtained from the
                                    use of services will be accurate or
                                    reliable.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; User agrees that
                                    User utilizes BIDBOX Services at the
                                    User&rsquo;s own risk, and that BIDBOX
                                    Services are provided to User on an
                                    &ldquo;AS IS&rdquo; and &ldquo;AS
                                    AVAILABLE&rdquo; basis.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp;&nbsp;&nbsp; In regards to the
                                    use of Application by the User, BIDBOX has
                                    the right to request and collect the
                                    information regarding the mobile devices
                                    that are used by User&nbsp;to access the
                                    Application provided including but not
                                    limited to the hardware, operating systems,
                                    unique device identifiers, cellular network
                                    information also includes the personal data
                                    such as e-mail addresses, addresses, cell
                                    phone numbers, aliases, passwords, Seller
                                    PIN codes and other information needed to
                                    use this Application service.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    7.&nbsp;&nbsp;&nbsp;&nbsp; The BIDBOX
                                    Application has been tested by BIDBOX
                                    researchers and developers and in its use,
                                    BIDBOX calls for caution in its use and
                                    BIDBOX cannot guarantee the performance and
                                    this Application always runs with the
                                    desired results.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    8.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX and its
                                    developers are not responsible and are not
                                    bound to any form of profit or loss that may
                                    occur as a result of using the
                                    Application.&nbsp;Benefits referred to
                                    increasing number of balances in the form of
                                    Crypto Asset or its value. As for the
                                    intended losses, these include but are not
                                    limited to the: reduced balance in Crypto
                                    Asset and/or its value failure to execute
                                    the API and all orders, network/signal
                                    problems, occurrence&nbsp;error on the
                                    system or error caused by factors in other
                                    forms.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    9.&nbsp;&nbsp;&nbsp;&nbsp; In the use of the
                                    BIDBOX Application, User has agreed that in
                                    any circumstances, User is prohibited from:
                                    doubling, copying, reproducing, translating,
                                    changing the system, modifying, removing the
                                    installation, removing the arrangement or
                                    trying to divert source code of this
                                    software product.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    10.&nbsp; All decisions in the use of the
                                    Application are decisions that are voluntary
                                    or independent made by the User without
                                    coercion from the BIDBOX and its Developers.
                                    For this matter, the User releases BIDBOX
                                    and its Developers of all forms of claims,
                                    compensation and all responsibilities in any
                                    form.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    11.&nbsp; The User states that they
                                    understand the limitations of the security
                                    and privacy but are not limited to: (i)
                                    limitations on security, privacy and
                                    authentication size and features in service;
                                    and (ii) all data and information in
                                    services may lead to eavesdropping,
                                    forgery,&nbsp;spam, sabotage, password
                                    piracy, interference, fraud, electronic
                                    abuse, hacking, and system contamination,
                                    including but not limited,
                                    viruses,&nbsp;worms, and&nbsp;Trojan horses,
                                    which cause the invalidity, damage, or
                                    dangerous access, and/or restore information
                                    or data on User&rsquo;s<em>&nbsp;</em>
                                    computers&nbsp;or other security and privacy
                                    hazards. If the User<em>&nbsp;</em>do not
                                    want to be subject to these risks, User is
                                    <em>&nbsp;</em>recommended not to use the
                                    Application or our Service.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    12.&nbsp; To the fullest extent permitted by
                                    applicable law, BIDBOX (including parent
                                    company, directors, and employees) is not
                                    accountable, and User agrees not to hold
                                    BIDBOX accountable, for any damage or loss
                                    (including but not limited to the loss of
                                    money, reputation, profit, or other
                                    intangible losses) directly or indirectly
                                    caused by the following:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (a)&nbsp;&nbsp; Inability of User in using
                                    BIDBOX services falls under personal risk.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (b)&nbsp;&nbsp; Loss of Use, Loss of Profit,
                                    Loss of Income, Loss of Data, Loss of
                                    Goodwill, or Failure to realize expected
                                    deposits, for any case directly or
                                    indirectly.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (c)&nbsp;&nbsp;&nbsp; Any indirect,
                                    incidental, special, or consequential
                                    losses, arising from or in connection with
                                    the use or inability to use BIDBOX website
                                    or Services including, but not limited to,
                                    any losses caused by it, even if BIDBOX has
                                    been informed about such probability of
                                    losses.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (d)&nbsp;&nbsp; Any losses caused by
                                    omission of User including but not limited
                                    to omission in login through third
                                    party&rsquo;s device and/or failure to keep
                                    the confidentiality of device used for
                                    login.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (e)&nbsp;&nbsp; Condition and quality of
                                    product or Crypto Asset traded in the
                                    Exchanger/Market Platform and chosen by the
                                    User to have its strategy run through
                                    BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (f)&nbsp;&nbsp;&nbsp; Violation of
                                    Intellectual Property Rights.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>(g)&nbsp;&nbsp; Disputes between Users.</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (h)&nbsp;&nbsp; Defamation of other party.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (i)&nbsp;&nbsp;&nbsp;&nbsp; Loss due to
                                    unofficial payment to other party other than
                                    to Official Account of BIDBOX which, by any
                                    means whatsoever, uses the name of BIDBOX or
                                    negligence in the writing of account and/or
                                    other information and/or omission on the
                                    part of the bank.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (j)&nbsp;&nbsp;&nbsp;&nbsp; Fitness for a
                                    particular purpose, durability, title, and
                                    non-infringement.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (k)&nbsp;&nbsp; Virus or other malware (bot,
                                    script, automation tools) obtained by
                                    accessing or connecting to Services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (l)&nbsp;&nbsp;&nbsp;&nbsp; Skimming or
                                    hacking process, which causes loss to User
                                    in BIDBOX Services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (m) Any disruption, bug, error, or
                                    inaccuracy in Services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (n)&nbsp;&nbsp; Damage to your hardware
                                    and/or software from the use of any
                                    Services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (o)&nbsp;&nbsp; Enforcement action taken in
                                    connection with the account of User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    (p)&nbsp;&nbsp; Any hacking committed by a
                                    third party of the account of User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    13.&nbsp; User acknowledges and agrees that
                                    the only right the User has in connection
                                    with any issue or dissatisfaction of
                                    services is to stop using the services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>S.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>NOTIFICATION</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; User agrees to
                                    communicate with the BIDBOX in electronic
                                    format, and agrees that all terms,
                                    conditions, agreements, notices, disclosures
                                    or any other forms of communication provided
                                    by BIDBOX to User are electronically
                                    considered as written.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; User agrees to
                                    receive e-mail from BIDBOX. E-mail sent can
                                    contain information about Accounts,
                                    transactions, systems, promotions and so on.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>T.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        LEGAL DOMICILE AND DISPUTE RESOLUTION
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Any conflicts,
                                    disputes or opinion differences (hereinafter
                                    referred to as &ldquo;
                                    <strong>Disputes</strong>&ldquo;) arising in
                                    connection with Terms of Use, Privacy
                                    Policy, and Services, as far as possible,
                                    will be settled with cooperation.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; Any Dispute that
                                    cannot be resolved by deliberation within 30
                                    (thirty) working days after the notification
                                    of the problem to all parties concerned,
                                    each Party may by written notification to
                                    the other Party refer the matter to a final
                                    settlement through arbitration in Singapore
                                    in accordance with the Singapore
                                    International Arbitration Centre (&ldquo;
                                    <strong>SIAC</strong>&rdquo;). The place of
                                    arbitration is Singapore or as decided
                                    between the parties in writing to state
                                    otherwise regarding the place of
                                    arbitration.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>U.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>MISCELLANEOUS</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; For things that
                                    have not been regulated in this Terms of
                                    Use, all provisions in the related civil
                                    code and the provisions of other relevant
                                    laws and regulations will apply.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; If BIDBOX changes
                                    the contents of this Terms of Use then
                                    BIDBOX will notify changes to the User in
                                    accordance with the applicable laws and
                                    regulations through media notices that are
                                    considered good by BIDBOX then User will be
                                    subject to the changes to the Terms of Use.
                                    Changes to each attachment from the Terms of
                                    Use will be agreed upon and hereinafter
                                    constitute a unity with and are an integral
                                    part of the Terms of Use.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; If User does
                                    actions outside of Terms of Use provisions,
                                    User will be fully responsible and hereby
                                    agrees that BIDBOX will not provide
                                    compensation and/or liability in any form to
                                    User, or any party to all demands and/or
                                    claims and/or suits submitted by other party
                                    in connection with the actions taken by
                                    User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; User shall comply
                                    with all requirements listed in the Terms of
                                    Use. User&rsquo;s Negligence to comply with
                                    or implementing contents of Terms of Use on
                                    one or several occasions will not eliminate
                                    User&rsquo;s obligation to fulfil all the
                                    requirements contained in the Terms of Use.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; Any questions or
                                    other matters regarding this Terms of Use
                                    shall be made through{" "}
                                    <a href="mailto:support@bidbox.community">
                                        support@bidbox.community
                                    </a>
                                    .
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    I hereby acknowledge that I have read,
                                    understood, and accept all of the terms and
                                    conditions as provided &ldquo;
                                    <strong>TERMS OF USE</strong>&rdquo; and I
                                    agree to all of its terms.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
        </div>
    );
};

export default TNC;
