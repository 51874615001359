import axios from "axios";
import apis from "src/utils/apis";
import { configStrict } from "./config";

export const getTicketHistory = async (payload) => {
    let config = await configStrict({
        method: "get",
        url: apis.API_TICKET_HISTORY,
        headers: {
            "Content-Type": "application/json",
        },
        params: payload,
    });
    let res = await axios(config);
    return res;
};

export const getTicketHistoryDetail = async (id) => {
    let config = await configStrict({
        method: "get",
        url: apis.API_TICKET_HISTORY_DETAIL,
        headers: {
            "Content-Type": "application/json",
        },
        params: JSON.stringify({
            id: id,
            page: 1,
            per_page: 1,
        }),
    });
    let res = await axios(config);
    return res;
};

export const replyTicket = async (payload) => {
    let config = await configStrict({
        method: "post",
        url: apis.API_TICKET_REPLY,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: payload,
    });
    let res = await axios(config);
    return res;
};

export const createTicket = async (payload) => {
    let config = await configStrict({
        method: "post",
        url: apis.API_TICKET_CREATE,
        headers: { "content-type": "multipart/form-data" },
        data: payload,
    });
    let res = await axios(config);
    return res;
};
