import React from "react";
import queryString from "query-string";
import { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getTimezones,
    getCountries,
    registerUser,
    requestOtpRegister,
    initRegisterStore,
} from "src/store/actions";
import Select, { components } from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import { LoadingBidbox } from "../../components";

const Signup = (props) => {
    const selectInputRef = useRef();
    const params = queryString.parse(props.location.search);
    const countries = useSelector((state) => state.Countries);
    const timezones = useSelector((state) => state.Timezones);
    const register = useSelector((state) => state.Register);

    const [countrySelected, setCountrySelected] = useState(null);
    const [timezonesSelected, setTimezonesSelected] = useState(null);
    const [security_pin, setSecurityPin] = useState(null);
    const [security_pin_repeat, setSecurityPinRepeat] = useState(null);

    const [agreement, setAgreement] = useState(false);
    const [agreement2, setAgreement2] = useState(false);
    const [sponsor, setSponsor] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();
    const emailRef = useRef();

    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");

    const [userState, setUserState] = useState({});

    const { Option } = components;
    const IconOption = (props) => (
        <Option {...props}>
            <img
                src={require(`./../../assets/icons/flag/${props.data.icon.toLowerCase()}.png`)}
                style={{ width: 24, marginRight: 10 }}
                alt={props.data.label}
            />
            {props.data.label}
        </Option>
    );

    const GET_OTP = () => {
        if (email === "" || email === null || email === undefined) {
            toast("please enter your email");
            emailRef.current.focus();
        } else {
            dispatch(
                requestOtpRegister({
                    address: email,
                    target: 0,
                    otp_context: 1,
                })
            );
        }
    };

    const SIGNUP_SUBMIT = async (e) => {
        try {
            setSecurityPin("000000");
            setSecurityPinRepeat("000000");
            e.preventDefault();
            const country = countrySelected.icon;
            const timeZone = timezonesSelected.value;
            if (agreement !== true) {
                toast("You haven't checked the terms conditions");
            } else if (agreement2 !== true) {
                toast("Please tick I am ready to receive emails from Bidbox");
            } else {
                dispatch(
                    registerUser(
                        {
                            captcha: "iuieys",
                            country: country,
                            timezone: timeZone,
                            full_name: fullName,
                            username: username,
                            password: password,
                            password_repeat: confirmPassword,
                            email: email,
                            otp: otp,
                            referrer_username: sponsor,
                            security_pin: "000000",
                            security_pin_repeat: "000000",
                            agree_mail: true,
                        },
                        history
                    )
                );
            }
        } catch (error) {
            toast(String(error));
        }
    };

    useEffect(() => {
        if (countrySelected !== null)
            dispatch(
                getTimezones({
                    country_code: countrySelected.value,
                })
            );
    }, [countrySelected]);
    useEffect(() => {
        if (countries.successMsg !== null) toast(countries.successMsg);
        if (countries.errorMsg !== null) toast(countries.errorMsg);
        if (timezones.successMsg !== null) toast(timezones.successMsg);
        if (timezones.errorMsg !== null) toast(timezones.errorMsg);
        if (register.regReqOtpSuccessMsg !== null)
            toast(register.regReqOtpSuccessMsg);
        if (register.regReqOtpErrorMsg !== null)
            toast(register.regReqOtpErrorMsg);
        if (register.registerSuccessMsg !== null)
            toast(register.registerSuccessMsg);
        if (register.registerErrorMsg !== null)
            toast(register.registerErrorMsg);

        dispatch(initRegisterStore());
    }, [
        countries.successMsg,
        countries.errorMsg,
        timezones.successMsg,
        timezones.errorMsg,
        register.regReqOtpSuccessMsg,
        register.regReqOtpErrorMsg,
        register.registerErrorMsg,
        register.registerSuccessMsg,
    ]);
    useEffect(() => {
        if (timezones.items.length > 0) selectInputRef.current.clearValue();
    }, [timezones.items]);
    useEffect(() => {
        dispatch(getCountries());
        if (params && params.sponsor !== undefined) {
            window.location.href = 'https://play.google.com/store/apps/details?id=community.bidbox.app&referrer=utm_source%3Dgoogle%26utm_medium%3Dlink%26utm_term%3Dusername%26sponsor_name%3D' + params.sponsor;
        }else{
            window.location.href = 'https://play.google.com/store/apps/details?id=community.bidbox.app'
        }
        // if (params.sponsor !== undefined) {
        //     setSponsor(params.sponsor);
        // }
    }, []);

    return (
        <div className="auth-box">
            {(countries.loading ||
                timezones.loading ||
                register.loadingReqOtp ||
                register.loadingRegister) && <LoadingBidbox />}
            <h2 className="text-dark fz:20 fw:500 mb-3 text-center">
                Welcome You To Join
            </h2>
            <div className="auth-body">
                <form
                    onSubmit={SIGNUP_SUBMIT}
                    id="form-validation"
                    className="auth-form"
                >
                    <div className="group">
                        <div className="field">
                            <label htmlFor="country" className="label-control">
                                Country
                            </label>
                            <Select
                                onChange={setCountrySelected}
                                defaultValue={
                                    countries.items.length > 0
                                        ? countries.items[0]
                                        : ""
                                }
                                options={countries.items}
                                components={{ Option: IconOption }}
                                id="country"
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="timezone" className="label-control">
                                Time Zone
                            </label>
                            <Select
                                ref={selectInputRef}
                                onChange={setTimezonesSelected}
                                defaultValue={
                                    timezones.items.length > 0
                                        ? timezones.items[0]
                                        : ""
                                }
                                options={timezones.items}
                                id="timezone"
                            />
                        </div>
                        <div className="field">
                            <input
                                type="text"
                                className="input-control"
                                id="fullName"
                                placeholder="Full Name"
                                required
                                onChange={(e) => {
                                    setFullName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="field">
                            <input
                                type="text"
                                className="input-control"
                                id="username"
                                placeholder="Username"
                                required
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                        </div>
                        <div className="field">
                            <input
                                type="password"
                                className="input-control"
                                id="password"
                                placeholder="Password"
                                required
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="field">
                            <input
                                type="password"
                                className="input-control"
                                id="confirm-password"
                                placeholder="Confirm password"
                                required
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="label-control">
                                Email (gmail only)
                            </label>
                            <input
                                type="email"
                                className="input-control"
                                id="email"
                                placeholder="example@gmail.com"
                                ref={emailRef}
                                required
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className="field">
                            <div className="flex-field">
                                <input
                                    type="text"
                                    className="input-control"
                                    id="otp-email"
                                    placeholder="OTP Email"
                                    onChange={(e) => {
                                        setOtp(e.target.value);
                                    }}
                                />

                                <button
                                    type="button"
                                    className="btn bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white"
                                    onClick={GET_OTP}
                                >
                                    Get OTP
                                </button>
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="sponsor" className="label-control">
                                Sponsor
                            </label>
                            <input
                                type="text"
                                className="input-control"
                                id="sponsor"
                                value={
                                    sponsor !== undefined && sponsor !== null
                                        ? sponsor
                                        : ""
                                }
                                onChange={(e) => setSponsor(e.target.value)}
                                placeholder="Sponsor username"
                            />
                        </div>
                        <div className="field">
                            <div className="form-check ">
                                <input
                                    className="input-checkbox"
                                    type="checkbox"
                                    onClick={(evt) => {
                                        if (evt.target.checked) {
                                            setAgreement(true);
                                        } else {
                                            setAgreement(false);
                                        }
                                    }}
                                    id="termcondition"
                                />
                                <label
                                    className="check-label fz:14 text-gray cursor:pointer"
                                    htmlFor="termcondition"
                                >
                                    I have read and agreed to "BIDBOX"{" "}
                                    <Link to="/tnc" className="text-app">
                                        Terms of Use
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        to="/privacy-policy"
                                        className="text-app"
                                    >
                                        Privacy Policy
                                    </Link>
                                    .
                                </label>
                            </div>
                        </div>
                        <div className="field">
                            <div className="form-check">
                                <input
                                    className="input-checkbox"
                                    type="checkbox"
                                    onClick={(evt) => {
                                        if (evt.target.checked) {
                                            setAgreement2(true);
                                        } else {
                                            setAgreement2(false);
                                        }
                                    }}
                                    id="receiveemail"
                                />
                                <label
                                    className="check-label fz:14 text-gray cursor:pointer"
                                    htmlFor="receiveemail"
                                >
                                    I agree to receive email notifications and
                                    updates from BIDBOX
                                </label>
                            </div>
                        </div>
                        <button className="btn-md btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white fz:18">
                            Sign Up
                        </button>
                    </div>
                </form>
                <p className="my-2 text-center fz:15 text-gray fw-500">
                    Already have account ?{" "}
                    <Link
                        to="/auth/signin"
                        className="text-app text-uppercase text-decoration-none fw:500 hover:text-app-color-90 active:text-app-color-70"
                    >
                        Sign In Here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Signup;
