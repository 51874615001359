import React from "react";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { Bottombar, Header } from "../navbar/Navbar";
import { Link } from "react-router-dom";
import BidboxLogo from "../../assets/image/logo/logo-bidbox-notext.png";

import { getAccount, getRank, getBotStatus } from "src/store/actions";
const Screen = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #f6fafc;
`;

const Mobile = styled.div`
  position: relative;
  width: 460px;
  min-height: 100vh;
  background: #ffffff;
  transition: width 0.4s, padding 0.3s;
  border: 1px solid #dee3e6;
  padding: 1.2rem 0rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Authentication = (props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 3000);
  }, []);

  return (
    <Screen>
      <Mobile>
        {loading ? (
          <div className="loading-container">
            <div className="loading-box">
              <img src={BidboxLogo} alt="" />
            </div>
          </div>
        ) : null}
        {props.children}
      </Mobile>
    </Screen>
  );
};

const AdminDashboard = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [notification, setNotification] = useState(false);
  useEffect(() => {
    dispatch(getAccount());
    dispatch(getRank());
    dispatch(getBotStatus());
  }, []);
  const SEARCHELEMENT = () => {
    return (
      <div className="search-element">
        <div className="search-header">
          <input
            type="search"
            name="search"
            id="search"
            placeholder="Search..."
            className="search-input"
          />
          <button
            className="close-search-element"
            onClick={() => setSearch(false)}
          >
            Close
          </button>
        </div>
      </div>
    );
  };
  const NOTIFICATIONELEMENT = () => {
    return (
      <div className="search-element">
        <div className="search-header" style={{ position: "relative" }}>
          <span className="fz:14 fw:500 text-dark">Notifications</span>
          <button
            className="close-search-element"
            onClick={() => setNotification(false)}
          >
            Close
          </button>
        </div>
        <div className="d-flex flex-column p-0">
          <li className="news-item">
            <Link to="/user/news-details" className="news-link">
              <div className="news-icon">
                <i className="fa-solid fa-envelope-open-text icon"></i>
              </div>
              <div className="news-details">
                <h2 className="mb-1 fz:16 fw:500 text-dark sttr-text">
                  Order Message
                </h2>
                <p className="mb-0 fz:12 fw:400 text-gray sttr-text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Iure, iste?
                </p>
              </div>
            </Link>
          </li>
          <li className="news-item">
            <Link to="/user/news-details" className="news-link">
              <div className="news-icon">
                <i className="fa-solid fa-bullhorn icon"></i>
              </div>
              <div className="news-details">
                <h2 className="mb-1 fz:16 fw:500 text-dark sttr-text">
                  System Information
                </h2>
                <p className="mb-0 fz:12 fw:400 text-gray sttr-text">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Iure, iste?
                </p>
              </div>
            </Link>
          </li>
        </div>
      </div>
    );
  };
  const goTo = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Screen>
      <Mobile
        style={
          location.pathname === "/user/withdrawal-address-management"
            ? { background: "rgb(247, 249, 252)" }
            : location.pathname == "/user/room-chat"
            ? { padding: "0", overflow: "hidden" }
            : null
        }
      >
        {location.pathname == "/user/dashboard" ||
        location.pathname == "/user/trades" ||
        location.pathname == "/user/bot-setting" ||
        location.pathname == "/user/news" ||
        location.pathname == "/user/chat-country" ? (
          <Header
            btnSearch={() => setSearch(!search)}
            btnNotification={() => goTo("https://help.bidbox.community/news/")}
          />
        ) : null}
        {props.children}
        {search ? SEARCHELEMENT() : notification ? NOTIFICATIONELEMENT() : null}
        {location.pathname === "/user/dashboard" ||
        location.pathname === "/user/trades" ||
        location.pathname === "/user/bot-setting" ||
        location.pathname === "/user/news" ||
        location.pathname === "/user/account" ? (
          <Route render={(props) => <Bottombar {...props} />} />
        ) : null}
      </Mobile>
    </Screen>
  );
};

export { Authentication, AdminDashboard };
