import { takeEvery, put, call } from "redux-saga/effects";

import { TICKET_HISTORY, TICKET_CREATE, TICKET_REPLY } from "./actionTypes";
import {
    ticketHistoryError,
    ticketHistorySuccess,
    ticketCreateSuccess,
    ticketCreateError,
    ticketReplySuccess,
    ticketReplyError,
} from "./actions";

import {
    getTicketHistory as apiGetHistoryTicket,
    createTicket as apiCreateTicket,
    replyTicket as apiReplyTicket,
} from "src/api";
function* getHistoryTicket({ payload }) {
    try {
        const response = yield call(apiGetHistoryTicket, payload);
        const data = response.data;

        yield put(ticketHistorySuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(ticketHistoryError(message));
    }
}
function* createTicket({ payload }) {
    try {
        yield call(apiCreateTicket, payload);

        yield put(ticketCreateSuccess("ticket created successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(ticketCreateError(message));
    }
}

function* replyTicket({ payload }) {
    try {
        yield call(apiReplyTicket, payload);

        yield put(ticketReplySuccess("ticket reply successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(ticketReplyError(message));
    }
}
function* authSaga() {
    yield takeEvery(TICKET_HISTORY, getHistoryTicket);
    yield takeEvery(TICKET_CREATE, createTicket);
    yield takeEvery(TICKET_REPLY, replyTicket);
}

export default authSaga;
