import {
    AFFILIATE_STATISTICS,
    AFFILIATE_ACTIVE_FRONTLINES,
    AFFILIATE_STATISTICS_SUCCESS,
    AFFILIATE_STATISTICS_ERROR,
    AFFILIATE_ACTIVE_FRONTLINES_SUCCESS,
    AFFILIATE_ACTIVE_FRONTLINES_ERROR

  } from "./actionTypes"
  
  export const getAffiliateStatistics = () => {
    return {
      type: AFFILIATE_STATISTICS
    }
  }

  export const getAffiliateActiveFrontlines = () => {
    return {
      type: AFFILIATE_ACTIVE_FRONTLINES
    }
  }
  export const affiliateStatisticsSuccess = message => {
    return {
      type: AFFILIATE_STATISTICS_SUCCESS,
      payload: message,
    }
  }
  export const affiliateActiveFrontlinesSuccess = message => {
    return {
      type: AFFILIATE_ACTIVE_FRONTLINES_SUCCESS,
      payload: message,
    }
  }
  
  
  export const affiliateStatisticsError = message => {
    return {
      type: AFFILIATE_STATISTICS_ERROR,
      payload: message,
    }
  }
  export const affiliateActiveFrontlinesError = message => {
    return {
      type: AFFILIATE_ACTIVE_FRONTLINES_ERROR,
      payload: message,
    }
  }
  