// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../icons/membercentre/halis.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fill-waves {\n  position: absolute;\n  bottom: 0;\n  left: -2px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;\n  background-size: contain;\n  background-position: left;\n  background-repeat: no-repeat;\n  width: 100%;\n  height: 55%;\n}\n@media screen and (max-width: 768px) {\n  .wave-banner {\n    height: 280px;\n  }\n  .fill-waves {\n    height: 90%;\n    bottom: -15%;\n    width: 130%;\n  }\n}\n@media screen and (max-width: 485px) {\n  .fill-waves {\n    height: 100%;\n    bottom: -23%;\n    width: 130%;\n  }\n}\n@media screen and (max-width: 280px) {\n  .fill-waves {\n    height: 100%;\n    bottom: -30%;\n    width: 130%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/membercentre.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,oEAAmE;EACnE,wBAAwB;EACxB,yBAAyB;EACzB,4BAA4B;EAC5B,WAAW;EACX,WAAW;AACb;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;IACX,YAAY;IACZ,WAAW;EACb;AACF;AACA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,WAAW;EACb;AACF;AACA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".fill-waves {\n  position: absolute;\n  bottom: 0;\n  left: -2px;\n  background-image: url(\"../icons/membercentre/halis.png\") !important;\n  background-size: contain;\n  background-position: left;\n  background-repeat: no-repeat;\n  width: 100%;\n  height: 55%;\n}\n@media screen and (max-width: 768px) {\n  .wave-banner {\n    height: 280px;\n  }\n  .fill-waves {\n    height: 90%;\n    bottom: -15%;\n    width: 130%;\n  }\n}\n@media screen and (max-width: 485px) {\n  .fill-waves {\n    height: 100%;\n    bottom: -23%;\n    width: 130%;\n  }\n}\n@media screen and (max-width: 280px) {\n  .fill-waves {\n    height: 100%;\n    bottom: -30%;\n    width: 130%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
