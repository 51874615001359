import { takeEvery,put, call } from "redux-saga/effects";

import { COIN } from "./actionTypes"
import { coinSuccess, coinError } from "./actions"

import { getCoin as apiGetCoin } from "src/api"
function* getCoin(payload) {
    try {
        const coinAllowed = payload.payload
        const response = yield call(
            apiGetCoin
        );

        let coinData = []
        let subscribe_id_coin = []
        const data = response.data
        data.data.cryptoCurrencyList.forEach(el => {
            if (coinAllowed.includes(el.symbol) ){
                coinData.push(
                    {
                      id: el.id,
                      image: `https://s2.coinmarketcap.com/static/img/coins/64x64/${el.id}.png`,
                      base_asset: el.symbol,
                      quote_asset: "USDT",
                      title: el.symbol,
                      subTitle: el.name,
                      coinCap: "0",
                      subCoinCap: "0%",
                      price: "0",
                      subPrice: "0%",
                      type: "green",
                    }
                )
                subscribe_id_coin.push(el.id)
            }
        });
        yield put(coinSuccess({
            data:coinData,
            subscribe_id_coin:subscribe_id_coin
        }));
    } catch (error) {
        yield put(coinError(String(error)));
    }
}

function* authSaga() {
    yield takeEvery(COIN, getCoin);
}

export default authSaga;
