import { takeEvery, put, call } from "redux-saga/effects";

import { ACTIVATE_BOT } from "./actionTypes";
import { activateBotSuccess, activateBotError } from "./actions";

import { activationBot as apiActivateBot } from "src/api";
function* activateBot() {
    try {
        yield call(apiActivateBot);
        yield put(activateBotSuccess("Bot activation successful"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(activateBotError(message));
    }
}

function* authSaga() {
    yield takeEvery(ACTIVATE_BOT, activateBot);
}

export default authSaga;
