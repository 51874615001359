import {
    RANK,
    RANK_SUCCESS,
    RANK_ERROR

  } from "./actionTypes"
  
  export const getRank = () => {
    return {
      type: RANK
    }
  }

  export const rankSuccess = message => {
    return {
      type: RANK_SUCCESS,
      payload: message,
    }
  }
  
  export const rankError = message => {
    return {
      type: RANK_ERROR,
      payload: message,
    }
  }
  