import {
    BOT_SETTING,
    BOT_SETTING_STATUS,
    BOT_PLUGIN,
    BOT_STATUS,
    BOT_UPGRADE,
    BOT_SETTING_SUCCESS,
    BOT_SETTING_STATUS_SUCCESS,
    BOT_PLUGIN_SUCCESS,
    BOT_STATUS_SUCCESS,
    BOT_UPGRADE_SUCCESS,
    BOT_UPGRADE_ERROR,
    BOT_STATUS_ERROR,
    BOT_SETTING_ERROR,
    BOT_SETTING_STATUS_ERROR,
    BOT_PLUGIN_ERROR,
    SET_REQUEST_TRADE,
    SET_REQUEST_TRADE_SUCCESS,
    SET_REQUEST_TRADE_ERROR,
} from "./actionTypes";

export const botUpgrade = (payload) => {
    return {
        type: BOT_UPGRADE,
        payload: payload,
    };
};

export const botSetting = (payload) => {
    return {
        type: BOT_SETTING,
        payload: payload,
    };
};

export const getBotSetting = () => {
    return {
        type: BOT_SETTING_STATUS,
    };
};

export const getBotPlugin = () => {
    return {
        type: BOT_PLUGIN,
    };
};

export const getBotStatus = () => {
    return {
        type: BOT_STATUS,
    };
};

export const botSettingStatusSuccess = (response) => {
    return {
        type: BOT_SETTING_STATUS_SUCCESS,
        payload: response,
    };
};
export const botSettingSuccess = (response) => {
    return {
        type: BOT_SETTING_SUCCESS,
        payload: response,
    };
};
export const botPluginSuccess = (response) => {
    return {
        type: BOT_PLUGIN_SUCCESS,
        payload: response,
    };
};
export const upgradePluginSuccess = (response) => {
    return {
        type: BOT_UPGRADE_SUCCESS,
        payload: response,
    };
};

export const botStatusSuccess = (response) => {
    return {
        type: BOT_STATUS_SUCCESS,
        payload: response,
    };
};
export const botStatusError = (message) => {
    return {
        type: BOT_STATUS_ERROR,
        payload: message,
    };
};

export const botSettingStatusError = (message) => {
    return {
        type: BOT_SETTING_STATUS_ERROR,
        payload: message,
    };
};
export const upgradePluginError = (message) => {
    return {
        type: BOT_UPGRADE_ERROR,
        payload: message,
    };
};

export const botSettingError = (message) => {
    return {
        type: BOT_SETTING_ERROR,
        payload: message,
    };
};

export const botPluginError = (message) => {
    return {
        type: BOT_PLUGIN_ERROR,
        payload: message,
    };
};

export const setRequestTrade = (typeRequest, payload) => {
    return {
        type: SET_REQUEST_TRADE,
        typeRequest: typeRequest,
        payload: payload,
    };
};

export const setRequestTradeSuccess = (message) => {
    return {
        type: SET_REQUEST_TRADE_SUCCESS,
        payload: message,
    };
};
export const setRequestTradeError = (message) => {
    return {
        type: SET_REQUEST_TRADE_ERROR,
        payload: message,
    };
};
