import {
    REWARD,
    REWARD_INSERT_SUCCESS,
    REWARD_UPDATE_SUCCESS,
    REWARD_ERROR,
} from "./actionTypes";

const initialState = {
    items: [],
    cumulativeProfit: '0 USDT',
    todayProfit: '0 USDT',
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REWARD:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case REWARD_INSERT_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case REWARD_UPDATE_SUCCESS:
            state.items
                .filter((key) => key.id === action.payload.id)
                .forEach((v) => {
                    v = action.payload;
                });
            state.loading = false;
            state.successMsg = false;
            state.errorMsg = false;
            break;

        case REWARD_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
