import React, { useRef } from "react";

const DropdownAction = (MenuRef, ToggleRef) => {
    document.addEventListener("mousedown", (e) => {
        if (ToggleRef.current && ToggleRef.current.contains(e.target)) {
            MenuRef.current.classList.toggle("show");
        } else {
            if (MenuRef.current && !MenuRef.current.contains(e.target)) {
                MenuRef.current.classList.remove("show");
            }
        }
    });
};

const Dropdown = ({ title, menu, onClick, menuClassName }) => {
    const toggleRef = useRef();
    const menuRef = useRef();
    // DropdownAction(menuRef, toggleRef);
    return (
        <div className="dropdown">
            <button onClick={onClick} className="dropdown-toggle" ref={toggleRef}>
                {title ? (
                    <h1 className="dropdown-toggle-title">{title}</h1>
                ) : (
                    ""
                )}
            </button>
            <div
                className={`dropdown-menu ${
                    menuClassName ? menuClassName : ""
                }`}
                ref={menuRef}
            >
                {menu}
            </div>
        </div>
    );
};

export default Dropdown;
