import apis from "src/utils/apis";
import axios from "axios";
import { configStrict } from "./config";

// bot plugin
export const loginOpenfire = async () => {
    // No Payload
    let config = await configStrict({
        method: "get",
        url: apis.API_OPENFIRE_LOGIN,
    });
    let res = await axios(config);
    return res;
};