import {
    TICKET_HISTORY,
    TICKET_HISTORY_ERROR,
    TICKET_HISTORY_SUCCESS,
    TICKET_CREATE,
    TICKET_CREATE_ERROR,
    TICKET_CREATE_SUCCESS,
    TICKET_REPLY,
    TICKET_REPLY_ERROR,
    TICKET_REPLY_SUCCESS,
    TICKET_INIT,
} from "./actionTypes";

const initialState = {
    last_id: null,
    items: [],
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKET_INIT:
            state = {
                ...state,
                loading: false,
                items: [],
                successMsg: null,
                errorMsg: null,
            };
            break;

        case TICKET_HISTORY:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case TICKET_HISTORY_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TICKET_HISTORY_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TICKET_REPLY:
        case TICKET_CREATE:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        // state = {
        //     ...state,
        //     ...action.payload,
        //     loading: false,
        //     successMsg: null,
        //     errorMsg: null,
        // };
        // break;
        case TICKET_REPLY_SUCCESS:
        case TICKET_CREATE_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case TICKET_REPLY_ERROR:
        case TICKET_CREATE_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }

    if (state.items !== null) {
        state.items = [
            ...new Map(state.items.map((item) => [item["id"], item])).values(),
        ];
    }
    return state;
};

export default reducer;
