import {
    REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_INIT,
} from "./actionTypes";

export const reqResetPassword = (user,history) => {
    return {
        type: REQUEST_RESET_PASSWORD,
        payload: {user,history},
    };
};

export const reqResetPasswordSuccess = (payload) => {
    return {
        type: REQUEST_RESET_PASSWORD_SUCCESS,
        payload: payload,
    };
};
export const reqResetPasswordError = (message) => {
    return {
        type: REQUEST_RESET_PASSWORD_ERROR,
        payload: message,
    };
};


export const resetPassword = (user,history) => {
    return {
        type: RESET_PASSWORD,
        payload: {user,history},
    };
};

export const resetPasswordSuccess = (payload) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: payload,
    };
};
export const resetPasswordError = (message) => {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: message,
    };
};

export const initResetPassword = () => {
    return {
        type: RESET_PASSWORD_INIT
    };
};