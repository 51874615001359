import {
    AFFILIATE_STATISTICS,
    AFFILIATE_STATISTICS_ERROR,
    AFFILIATE_STATISTICS_SUCCESS,
    AFFILIATE_ACTIVE_FRONTLINES,
    AFFILIATE_ACTIVE_FRONTLINES_ERROR,
    AFFILIATE_ACTIVE_FRONTLINES_SUCCESS
} from "./actionTypes";

const initialState = {
    active_frontlines: [],
    downline_active:0,
    downline_inactive:0,
    frontline_active:0,
    frontline_inactive:0,
    today_activation_profit:0,
    total_activation_profit:0,
    loadingStatiscs: false,
    loadingFrontlines: false,
    statisticsSuccessMsg: null,
    statisticsErrorMsg: null,
    frontlinesSuccessMsg: null,
    frontlinesErrorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AFFILIATE_STATISTICS:
            state = {
                ...state,
                loadingStatiscs: true,
                statisticsSuccessMsg: null,
                statisticsErrorMsg: null
            };
            break;
        case AFFILIATE_STATISTICS_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loadingStatiscs: false,
                statisticsSuccessMsg: null,
                statisticsErrorMsg: null
            };
            break;
        

        case AFFILIATE_STATISTICS_ERROR:
            state = { ...state, loadingStatiscs: false, statisticsSuccessMsg:null,statisticsErrorMsg: action.payload };
            break;

        case AFFILIATE_ACTIVE_FRONTLINES:
            state = {
                ...state,
                loadingStatiscs: true,
                statisticsSuccessMsg: null,
                statisticsErrorMsg: null
            };
            break;
        case AFFILIATE_ACTIVE_FRONTLINES_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loadingFrontlines: false,
                frontlinesSuccessMsg: null,
                frontlinesErrorMsg: null
            };
            break;
        

        case AFFILIATE_ACTIVE_FRONTLINES_ERROR:
            state = { ...state, loadingStatiscs: false, frontlinesSuccessMsg:null,frontlinesErrorMsg: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
