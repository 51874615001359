import {
    PORTOFOLIO,
    PORTOFOLIO_SUCCESS,
    PORTOFOLIO_ERROR,
} from "./actionTypes";

export const getPortofolio = (payload) => {
    return {
        type: PORTOFOLIO,
        payload: payload,
    };
};

export const portofolioSuccess = (message) => {
    return {
        type: PORTOFOLIO_SUCCESS,
        payload: message,
    };
};

export const portofolioError = (message) => {
    return {
        type: PORTOFOLIO_ERROR,
        payload: message,
    };
};
