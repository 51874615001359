import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { ticketCreate, ticketInit } from "src/store/actions";
import toast from "react-hot-toast";

const AccountProblem = () => {
    const dispatch = useDispatch();
    const ID_TICKET_CATEGORY = 2;
    const ticketState = useSelector((state) => state.Ticket);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const [keyFile, setKeyFile] = useState(1);

    const submitTicket = () => {
        if (subject === "" || description === "") {
            toast("please complete the form below");
        } else {
            let formData = new FormData();
            if (file !== null) formData.append("file", file);
            formData.append("id_ticket_category", ID_TICKET_CATEGORY);
            formData.append("subject", subject);
            formData.append("description", description);
            dispatch(ticketCreate(formData));
        }
    };
    useEffect(() => {
        dispatch(ticketInit());
        return () => {
            dispatch(ticketInit());
        };
    }, []);
    useEffect(() => {
        if (ticketState.successMsg !== null) {
            toast(ticketState.successMsg);
            setSubject("");
            setDescription("");
            setFile(null);
            setKeyFile(Math.random().toString(36));
        }
        if (ticketState.errorMsg !== null) toast(ticketState.errorMsg);
    }, [ticketState.successMsg, ticketState.errorMsg]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Give Feedback
                </span>
                <div className="n"></div>
            </header>
            <ul className="list-group">
                <li
                    className="list-btn bg-app-color-10 mb-3"
                    style={{ border: "1px solid #bed7e6 " }}
                >
                    <span className="fz:14 fw:500 text-dark">
                        Feedback Type
                    </span>
                    <span className="fz:14 fw:500 text-dark">
                        Account Problem
                    </span>
                </li>

                <div id="form-validation">
                    <div className="field">
                        <label
                            htmlFor="account"
                            className="label-control text-dark fw:500"
                        >
                            Account
                        </label>
                        <input
                            type="text"
                            id="account"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            className="input-control-feedback"
                            placeholder="Enter The Account Here"
                            required
                        />
                    </div>
                    <div className="field">
                        <label
                            htmlFor="spesifica-problem"
                            className="label-control text-dark fw:500"
                        >
                            Problem Description
                        </label>
                        <textarea
                            name="spesifica-problem"
                            id="spesifica-problem"
                            onChange={(e) => setDescription(e.target.value)}
                            className="textarea-control-feedback"
                            value={description}
                            placeholder="Enter a detailed description of the problem you are ecnountering"
                        ></textarea>
                    </div>

                    <div className="field">
                        <label
                            htmlFor="attach-image"
                            className="label-control text-dark fw:500"
                        >
                            Attachment Image
                        </label>
                        <input
                            type="file"
                            name="file"
                            id="attach-image"
                            accept="image/*"
                            key={keyFile}
                            onChange={(e) => setFile(e.target.files[0])}
                            onClick={(event) => (event.target.value = null)}
                        />
                    </div>
                    <button
                        className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-80 text-white text-uppercase fw:500"
                        onClick={submitTicket}
                    >
                        Submit
                    </button>
                </div>
            </ul>
        </div>
    );
};

export default AccountProblem;
