import { takeEvery, put, call } from "redux-saga/effects";

import { NEWS } from "./actionTypes";
import { newsSuccess, newsError } from "./actions";

import { getNews as apiGetNews } from "src/api";
function* getNews() {
    try {
        const response = yield call(apiGetNews);
        const data = response.data;
        yield put(newsSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(newsError(message));
    }
}

function* authSaga() {
    yield takeEvery(NEWS, getNews);
}

export default authSaga;
