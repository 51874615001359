import axios from "axios";
import apis from "src/utils/apis";
import { configNotStrict, configStrict } from "./config";
// generate address
export const getWallet = async () => {
    // no payload
    let config = await configStrict({
        method: "get",
        url: apis.API_GENERATE_ADDRESS,
        headers: {
            "Content-Type": "application/json",
        },
    });
    let res = await axios(config);
    return res;
};
// show balance
export const getBalance = async () => {
    // no payload
    let config = await configStrict({
        method: "get",
        url: apis.API_SHOW_BALANCE,
        headers: {
            "Content-Type": "application/json",
        },
    });
    let res = await axios(config);
    return res;
};
// transfer balance
export const transferBalance = async (payload) => {
    /* 
    {
        "currency_id": "USDT",
        "otp": "123456",
        "security_pin": "123456",
        "to": "sdfsfsfsdfsfdsfsdfdsfsdfsdfdsfs",
        "value": 100.8
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_WALLET_TRANSFER,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// validate deposit
export const validateDeposit = async (address) => {
    let config = await configStrict({
        method: "post",
        url: apis.API_VALIDATE_DEPOSIT,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify({
            address: address,
        }),
    });
    let res = await axios(config);
    return res;
};
// withdrawal
export const withdrawal = async (payload) => {
    /* 
    {
        "currency_id": "USDT",
        "otp": "123456",
        "security_pin": "123456",
        "to": "sdfsfsfsdfsfdsfsdfdsfsdfsdfdsfs",
        "value": 100.8
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_WITHDRAWAL,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
