import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import ResetPassword from "./auth/resetpassword/reducer";

import Account from "./account/reducer";
import Bot from "./bot/reducer";
import Coin from "./coin/reducer";
import Wallet from "./wallet/reducer";
import Balance from "./balance/reducer";
import Rank from "./rank/reducer";
import Reward from "./reward/reducer";
import Affiliate from "./affiliate/reducer";
import Countries from "./countries/reducer";
import Timezones from "./timezones/reducer";
import ActivateBot from "./activatebot/reducer";
import IpBinding from "./ip_binding/reducer";
import Trades from "./trades/reducer";
import Withdraw from "./withdraw/reducer";
import Deposit from "./deposit/reducer";
import Transfer from "./transfer/reducer";
import News from "./news/reducer";
import Portofolio from "./portofolio/reducer";
import Ticket from "./ticket/reducer";
import GasFee from "./gasfee/reducer";
import Openfire from "./openfire/reducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["Account", "Coin", "Bot", "Wallet", "Balance", "Rank"],
};
const rootReducer = combineReducers({
    // public
    Openfire,
    Login,
    Account,
    ResetPassword,
    Register,
    Bot,
    Coin,
    Wallet,
    Balance,
    Rank,
    Reward,
    Affiliate,
    Countries,
    Timezones,
    ActivateBot,
    IpBinding,
    Trades,
    Withdraw,
    Deposit,
    Transfer,
    News,
    Portofolio,
    Ticket,
    GasFee,
});

export default persistReducer(persistConfig, rootReducer);
