import endpoints from "./endpoints";

const BASE_URL = process.env.REACT_APP_API_GATEWAY;
const BASE_URL_TRADER = process.env.REACT_APP_API_TRADER;
const BASE_URL_TICKET = process.env.REACT_APP_API_TICKET;
const BASE_URL_OPENFIRE = process.env.REACT_APP_API_OPENFIRE;

const Apis = {
    API_AUTH_LOGIN: `${BASE_URL}${endpoints.EP_AUTH_LOGIN}`,
    API_REFRESH_TOKEN: `${BASE_URL}${endpoints.EP_AUTH_REFRESH_TOKEN}`,
    API_AUTH_REQ_OTP: `${BASE_URL}${endpoints.EP_AUTH_REQ_OTP}`,
    API_AUTH_REQ_RESET_PASSWORD: `${BASE_URL}${endpoints.EP_AUTH_REQ_RESET_PASSWORD}`,
    API_AUTH_RESET_PASSWORD: `${BASE_URL}${endpoints.EP_AUTH_RESET_PASSWORD}`,
    API_AUTH_SIGNUP: `${BASE_URL}${endpoints.EP_AUTH_SIGNUP}`,
    API_SITE_BOT_PLUGIN: `${BASE_URL}${endpoints.EP_SITE_BOT_PLUGIN}`,
    API_SITE_COUNTRIES: `${BASE_URL}${endpoints.EP_SITE_COUNTRIES}`,
    API_SITE_TIMEZONES: `${BASE_URL}${endpoints.EP_SITE_TIMEZONES}`,
    API_SITE_PAIRS: `${BASE_URL}${endpoints.EP_SITE_PAIRS}`,
    API_AFFILIATE_USER_RANK: `${BASE_URL}${endpoints.EP_AFFILIATE_USER_RANK}`,
    API_USER_PROFILE: `${BASE_URL}${endpoints.EP_USER_PROFILE}`,
    API_BOT_ALL_SETTINGS: `${BASE_URL}${endpoints.EP_BOT_ALL_SETTINGS}`,
    API_BOT_STATUS: `${BASE_URL}${endpoints.EP_BOT_STATUS}`,
    API_USER_EXCHANGE: `${BASE_URL}${endpoints.EP_USER_EXCHANGE}`,
    API_EXCHANGE_ACTIVATION: `${BASE_URL}${endpoints.EP_EXCHANGE_ACTIVATION}`,
    API_BINDING: `${BASE_URL}${endpoints.EP_API_BINDING}`,
    API_BOT_SETTING: `${BASE_URL}${endpoints.EP_BOT_SETTING}`,
    API_UPGRADE_PLUGIN: `${BASE_URL}${endpoints.EP_UPGRADE_PLUGIN}`,
    API_ACTIVE_TRADES: `${BASE_URL}${endpoints.EP_ACTIVE_TRADES}`,
    API_CANCEL_PENDING_BUY: `${BASE_URL}${endpoints.EP_CANCEL_PENDING_BUY}`,
    API_CANCEL_PENDING_SELL: `${BASE_URL}${endpoints.EP_CANCEL_PENDING_SELL}`,
    API_SALES_ACTIVATION: `${BASE_URL}${endpoints.EP_SALES_ACTIVATION}`,
    API_SERVER_IP: `${BASE_URL}${endpoints.EP_SERVER_IP}`,
    API_GENERATE_ADDRESS: `${BASE_URL}${endpoints.EP_GENERATE_ADDRESS}`,
    API_VALIDATE_DEPOSIT: `${BASE_URL}${endpoints.EP_VALIDATE_DEPOSIT}`,
    API_SHOW_BALANCE: `${BASE_URL}${endpoints.EP_SHOW_BALANCE}`,
    API_CHANGE_PASSWORD: `${BASE_URL}${endpoints.EP_CHANGE_PASSWORD}`,
    API_CHANGE_EMAIL: `${BASE_URL}${endpoints.EP_CHANGE_EMAIL}`,
    API_USER_REQ_OTP: `${BASE_URL}${endpoints.EP_USER_REQ_OTP}`,
    API_CHANGE_SEC_PIN: `${BASE_URL}${endpoints.EP_CHANGE_SEC_PIN}`,
    API_AFFILIATE_STATISTICS: `${BASE_URL}${endpoints.EP_AFFILIATE_STATISTICS}`,
    API_AFFILIATE_FRONTLINES: `${BASE_URL}${endpoints.EP_AFFILIATE_FRONTLINES}`,
    API_DAILY_BONUS: `${BASE_URL}${endpoints.EP_API_DAILY_BONUS}`,
    API_USER_UPDATE_PROFILE: `${BASE_URL}${endpoints.EP_USER_UPDATE_PROFILE}`,
    API_WALLET_WITHDRAWAL: `${BASE_URL}${endpoints.EP_WALLET_WITHDRAWAL}`,
    API_WALLET_TRANSFER: `${BASE_URL}${endpoints.EP_WALLET_TRANSFER}`,
    API_TRADES_PROFIT: `${BASE_URL}${endpoints.EP_TRADES_PROFIT}`,
    API_WITHDRAWAL: `${BASE_URL}${endpoints.EP_WITHDRAWAL}`,
    API_GET_HISTORY_WITHDRAW: `${BASE_URL}${endpoints.EP_HISTORY_WITHDRAW}`,
    API_GET_HISTORY_DEPOSIT: `${BASE_URL}${endpoints.EP_HISTORY_DEPOSIT}`,
    API_GET_HISTORY_TRANSFER: `${BASE_URL}${endpoints.EP_HISTORY_TRANSFER}`,
    API_TRADES_HISTORY: `${BASE_URL}${endpoints.EP_HISTORY_TRADES}`,

    API_COIN_CMC: `https://api.bidbox.community/api/v1/get-coin`,
    API_NEWS_CMC: `${BASE_URL_TRADER}${endpoints.EP_NEWS_CMC}`,
    API_GET_PORTOFOLIO: `${BASE_URL_TRADER}${endpoints.EP_PORTOFOLIO}`,

    API_TICKET_HISTORY: `${BASE_URL_TICKET}${endpoints.EP_TICKET_HISTORY}`,
    API_TICKET_HISTORY_DETAIL: `${BASE_URL_TICKET}${endpoints.EP_TICKET_HISTORY_DETAIL}`,
    API_TICKET_REPLY: `${BASE_URL_TICKET}${endpoints.EP_TICKET_REPLY}`,
    API_TICKET_CREATE: `${BASE_URL_TICKET}${endpoints.EP_TICKET_CREATE}`,
    API_TRADES_SYNC: `${BASE_URL}${endpoints.EP_SYNC_TRADES}`,
    API_TRADES_AVG: `${BASE_URL}${endpoints.EP_AVG_TRADES}`,
    API_GET_TICK_SIZE: `${BASE_URL}${endpoints.EP_GET_TICK_SIZE}`,
    API_GET_CURRENT_PRICE_BINANCE: `${BASE_URL}${endpoints.EP_GET_PRICE_BINANCE}`,
    API_GET_GAS_FEE: `${BASE_URL}${endpoints.EP_GET_GAS_FEE}`,

    API_OPENFIRE_LOGIN: `${BASE_URL_OPENFIRE}${endpoints.EP_API_OPENFIRE_LOGIN}`,


};
export default Apis;
