import axios from 'axios'
import apis from 'src/utils/apis'
import {configStrict} from './config'
// user change email
export const changeEmail = async (payload) => {
    /* payload 
    {
        "new_email": "user@mail.com",
        "otp": "123456",
        "security_pin": "123456"
    }
    */
    let config = await configStrict({
        method:'post',
        url:apis.API_CHANGE_EMAIL,
        headers: {
            "Content-Type": "application/json",
        },
        data:JSON.stringify(payload)
    })
    let res = await axios(config)
    return res
}
// change password
export const changePassword = async (payload) => {
    /* payload 
    {
        "new_password": "123456",
        "new_password_repeat": "123456",
        "old_password": "123456",
        "security_pin": "123456"
    }
    */
    let config = await configStrict({
        method:'post',
        url:apis.API_CHANGE_PASSWORD,
        headers: {
            "Content-Type": "application/json",
        },
        data:JSON.stringify(payload)
    })
    let res = await axios(config)
    return res
}
// change security pin
export const changeSecurityPIN = async (payload) => {
    /* payload 
    {
        "new_security_pin": "123456",
        "new_security_pin_repeat": "123456",
        "otp": "123456",
        "security_pin": "123456"
    }
    */
    let config = await configStrict({
        method:'post',
        url:apis.API_CHANGE_SEC_PIN,
        headers: {
            "Content-Type": "application/json",
        },
        data:JSON.stringify(payload)
    })
    let res = await axios(config)
    return res
}
// profile
export const getAccountProfile = async () => {
    // No Payload
    let config = await configStrict({
        method:'get',
        url:apis.API_USER_PROFILE,
        headers: {
            "Content-Type": "application/json",
        },
    })
    let res = await axios(config)
    return res
}
// request otp
export const userRequestOTP = async (payload) => {
    /* payload 
    {
        "otp_context": 0,
        "target": 0
    }
    OTP context. 0=None, 1=Signup, 2=Api Binding, 3=Withdrawal, 4=Change Pin, 5=Change Password, 6=Change Email
    target. 0 for email, 1 for phone
    */
    
    let config = await configStrict({
        method:'post',
        url:apis.API_USER_REQ_OTP,
        headers: {
            "Content-Type": "application/json",
        },
        data:JSON.stringify(payload)
    })
    let res = await axios(config)
    return res
}
// update profile
export const updateProfile = async (payload) => {
    /*
    {
        "country": "US",
        "full_name": "John",
        "phone": "+123456789",
        "timezone": "America/Los_Angeles"
    }
    */
    let config = await configStrict({
        method:'post',
        url:apis.API_USER_UPDATE_PROFILE,
        headers: {
            "Content-Type": "application/json",
        },
        data:JSON.stringify(payload)
    })
    let res = await axios(config)
    return res
}
