import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tradesHistory } from "src/store/actions";
import toast from "react-hot-toast";
import { toFixedIfNecessary } from "src/utils/helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
const Transaction = () => {
    const dispatch = useDispatch();
    const trades = useSelector((state) => state.Trades);
    const [toggle, setToggle] = useState();
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastDay, setLastDay] = useState(new Date());
    const toggleActive = (id) => {
        if (toggle === id) {
            setToggle(null);
        } else {
            setToggle(id);
        }
    };

    const loadMore = async () => {
        setLastDay(moment(lastDay).subtract("31", "days"));
    };

    useEffect(() => {
        const from_date = moment(lastDay)
            .subtract("30", "days")
            .format("YYYY-MM-DD");
        const to_date = moment(lastDay).format("YYYY-MM-DD");
        dispatch(
            tradesHistory({
                exchange_id: 1,
                from_date: from_date,
                to_date: to_date,
            })
        );
    }, [dispatch,lastDay]);
    useEffect(() => {
        if (trades.history_trades.length > 0) {
            setPosts([...posts, ...trades.history_trades]);
        }
        setHasMore(true);
    }, [trades.history_trades,posts]);
    useEffect(() => {
        if (trades.errorMsg !== null) toast(trades.errorMsg);
    }, [trades.errorMsg]);
    return (
        <div className="details-content" style={{ overflow: "auto" }}>
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Transaction
                </span>
                <div className="n"></div>
            </header>

            <InfiniteScroll
                dataLength={posts.length}
                next={loadMore}
                hasMore={hasMore}
                // loader={<h3> Loading...</h3>}
                // endMessage={<h4>Nothing more to show</h4>}
            >
                {posts !== null &&
                    posts.map((data, idx) => {
                        if (data.status_str === "AVERAGING") return false;

                        return (
                            <div
                                className="card mb-2 card-transaction"
                                key={idx}
                            >
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="icon-coin">
                                            <img
                                                src={data.image}
                                                alt={data.base_asset}
                                            />
                                        </div>
                                        <div
                                            className="d-flex align-items-center justify-content-between"
                                            style={{ columnGap: "5px" }}
                                        >
                                            <h2 className="m-0 fz:16 fw-600 text-dark me-1">
                                                {data.base_asset} /
                                            </h2>
                                            <p className="m-0 fz:12 fw-500 text-dark">
                                                {data.quote_asset}
                                            </p>
                                            <p
                                                className={`m-0 fz:11 ${
                                                    data.status_str ===
                                                    "CUT_SELL"
                                                        ? "text-red"
                                                        : "text-green"
                                                } info-transaction`}
                                            >
                                                {data.status_str}
                                            </p>
                                            {data.avg_for !== "" && (
                                                <p
                                                    className={`m-0 fz:11 info-transaction`}
                                                    style={{
                                                        color: "#008aae",
                                                    }}
                                                >
                                                    AVG
                                                </p>
                                            )}
                                        </div>
                                        <p className="fz-11" style={{position:"absolute",right:40,textAlign:'right',marginTop:15}}>{moment(data.complete_at).format(
                                            "YYYY-MM-DD HH:m:s"
                                        )}</p>
                                        <button
                                            className="btn-transaction-detail"
                                            onClick={() =>
                                                toggleActive(data.order_id)
                                            }
                                        >
                                            <i
                                                className={
                                                    toggle === data.order_id
                                                        ? "fa-solid fa-angle-down"
                                                        : "fa-solid fa-angle-right"
                                                }
                                            ></i>
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="m-0 text-dark fz:15">
                                            {data.buy_price}
                                        </p>
                                        <p
                                            className={`m-0 ${
                                                data.avg_for !== ""
                                                    ? (data.last_buy_qty +
                                                          data.qty) *
                                                          data.sell_price -
                                                          (data.last_buy_price *
                                                              data.last_buy_qty +
                                                              data.buy_price *
                                                                  data.qty) <
                                                      0
                                                        ? "text-red"
                                                        : "text-green"
                                                    : data.profit_amount < 0
                                                    ? "text-red"
                                                    : "text-green"
                                            } fz:15`}
                                        >
                                            {data.avg_for !== ""
                                                ? `${toFixedIfNecessary(
                                                      (data.last_buy_qty +
                                                          data.qty) *
                                                          data.sell_price -
                                                          (data.last_buy_price *
                                                              data.last_buy_qty +
                                                              data.buy_price *
                                                                  data.qty),
                                                      4
                                                  )} (${toFixedIfNecessary(
                                                      (((data.last_buy_qty +
                                                          data.qty) *
                                                          data.sell_price -
                                                          (data.last_buy_price *
                                                              data.last_buy_qty +
                                                              data.buy_price *
                                                                  data.qty)) /
                                                          (data.last_buy_price *
                                                              data.last_buy_qty +
                                                              data.buy_price *
                                                                  data.qty)) *
                                                          100,
                                                      4
                                                  )}%)`
                                                : `${toFixedIfNecessary(
                                                      data.profit_amount,
                                                      4
                                                  )} (${toFixedIfNecessary(
                                                      data.profit_pc,
                                                      4
                                                  )}%)`}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="m-0 text-mute fz:11">
                                            Buy In Price (USDT)
                                        </p>
                                        <p className="m-0 text-mute fz:11">
                                            {data.avg_for !== ""
                                                ? (data.last_buy_qty +
                                                      data.qty) *
                                                      data.sell_price -
                                                      (data.last_buy_price *
                                                          data.last_buy_qty +
                                                          data.buy_price *
                                                              data.qty) <
                                                  0
                                                    ? "Loss"
                                                    : "Profit"
                                                : data.profit_amount < 0
                                                ? "Loss"
                                                : "Profit"}{" "}
                                            (USDT)
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        toggle === data.order_id
                                            ? "card-detail d-block"
                                            : "card-detail d-none"
                                    }
                                >
                                    <table className="mb-0">
                                        <tbody>
                                            <tr>
                                                <td
                                                    className="text-mute fz:12"
                                                    style={{ width: "120px" }}
                                                >
                                                    Order ID
                                                </td>
                                                <td>:</td>
                                                <td
                                                    className="text-dark fz:12"
                                                    style={{
                                                        wordBreak: "break-all",
                                                    }}
                                                >
                                                    {data.order_id.replace(
                                                        "bidbox_",
                                                        ""
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <b className="fz:12 text-green">BUY</b>
                                    <table className="mb-0">
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{ width: "120px" }}
                                                    className="text-mute fz:12"
                                                >
                                                    Order Time
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {moment(data.buy_at).format(
                                                        "YYYY-MM-DD HH:m:s"
                                                    )}
                                                </td>
                                            </tr>
                                            {data.status_str ===
                                                "CANCEL_BUY" && (
                                                <tr>
                                                    <td className="text-mute fz:12">
                                                        Status
                                                    </td>
                                                    <td>:</td>
                                                    <td className="text-dark fz:12">
                                                        {data.status_str}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className="text-mute fz:12">
                                                    Buy Price
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {data.avg_for !== ""
                                                        ? `${data.last_buy_price} / ${data.buy_price}`
                                                        : data.buy_price}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-mute fz:12">
                                                    Field Quantity
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {data.avg_for !== ""
                                                        ? `${data.last_buy_qty} / ${data.qty}`
                                                        : data.qty}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-mute fz:12">
                                                    Buy Amount
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {data.avg_for !== ""
                                                        ? toFixedIfNecessary(
                                                              data.last_buy_price *
                                                                  data.last_buy_qty +
                                                                  data.buy_price *
                                                                      data.qty,
                                                              8
                                                          )
                                                        : data.buy_amount}{" "}
                                                    USDT
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <b className="fz:12 text-red">SELL</b>
                                    <table className="mb-0">
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{ width: "120px" }}
                                                    className="text-mute fz:12"
                                                >
                                                    Order Time
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {moment(
                                                        data.complete_at
                                                    ).format(
                                                        "YYYY-MM-DD HH:m:s"
                                                    )}
                                                </td>
                                            </tr>
                                            {(data.status_str === "CUT_SELL" ||
                                                data.status_str ===
                                                    "COMPLETE") && (
                                                <tr>
                                                    <td className="text-mute fz:12">
                                                        Status
                                                    </td>
                                                    <td>:</td>
                                                    <td className="text-dark fz:12">
                                                        {data.status_str}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td className="text-mute fz:12">
                                                    Sell Price
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {data.sell_price}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-mute fz:12">
                                                    Field Quantity
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {data.avg_for !== ""
                                                        ? data.last_buy_qty +
                                                          data.qty
                                                        : data.qty}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-mute fz:12">
                                                    Deal Amount
                                                </td>
                                                <td>:</td>
                                                <td className="text-dark fz:12">
                                                    {data.avg_for !== ""
                                                        ? (data.last_buy_qty +
                                                              data.qty) *
                                                          data.sell_price
                                                        : data.deal_amount}{" "}
                                                    USDT
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    })}
            </InfiniteScroll>
        </div>
    );
};

export default Transaction;
