import {
    WALLET,
    WALLET_SUCCESS,
    WALLET_ERROR,
    VALIDATE_DEPOSIT,
    VALIDATE_DEPOSIT_SUCCESS,
    VALIDATE_DEPOSIT_ERROR,
    TRANSFER,
    TRANSFER_SUCCESS,
    TRANSFER_ERROR,
    TRANSFER_INIT,
    WITHDRAW,
    WITHDRAW_SUCCESS,
    WITHDRAW_ERROR,
    WITHDRAW_INIT,
} from "./actionTypes";

export const transferInit = () => {
    return {
        type: TRANSFER_INIT,
    };
};

export const transferBalance = (payload) => {
    return {
        type: TRANSFER,
        payload: payload,
    };
};

export const transferSuccess = (payload) => {
    return {
        type: TRANSFER_SUCCESS,
        payload: payload,
    };
};

export const transferError = (payload) => {
    return {
        type: TRANSFER_ERROR,
        payload: payload,
    };
};
export const getWallet = () => {
    return {
        type: WALLET,
    };
};

export const validateDeposit = (address) => {
    return {
        type: VALIDATE_DEPOSIT,
        payload: address,
    };
};
export const walletSuccess = (message) => {
    return {
        type: WALLET_SUCCESS,
        payload: message,
    };
};

export const walletError = (message) => {
    return {
        type: WALLET_ERROR,
        payload: message,
    };
};
export const validateSuccess = (message) => {
    return {
        type: VALIDATE_DEPOSIT_SUCCESS,
        payload: message,
    };
};

export const validateError = (message) => {
    return {
        type: VALIDATE_DEPOSIT_ERROR,
        payload: message,
    };
};

export const withdrawInit = () => {
    return {
        type: WITHDRAW_INIT,
    };
};

export const withdraw = (payload) => {
    return {
        type: WITHDRAW,
        payload: payload,
    };
};

export const withdrawSuccess = (payload) => {
    return {
        type: WITHDRAW_SUCCESS,
        payload: payload,
    };
};

export const withdrawError = (payload) => {
    return {
        type: WITHDRAW_ERROR,
        payload: payload,
    };
};
