import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const SecurityCenter = () => {
    const account = useSelector((state) => state.Account);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Security Center
                </span>
                <div className="n"></div>
            </header>
            <ul className="list-group">
                <Link to="/user/change-password" className="list-btn">
                    <span className="fz:14 fw:500 text-dark">
                        Change Password
                    </span>
                </Link>
                <Link to="/user/modify-email" className="list-btn">
                    <span className="fz:14 fw:500 text-dark">Modify Email</span>
                </Link>
                <Link to="/user/transaction-pin" className="list-btn">
                    <span className="fz:14 fw:500 text-dark">
                        Transaction PIN
                    </span>
                    <span className="fz:11 text-gray">
                        {account.security_pin_active
                            ? "already set"
                            : "Not set yet"}
                    </span>
                </Link>
                <Link to="/user/google-verification-code" className="list-btn">
                    <span className="fz:14 fw:500 text-dark">
                        Google Authenticator Setting
                    </span>
                    <span className="fz:11 text-gray">Not set yet</span>
                </Link>
                <Link
                    to="/user/withdrawal-address-management"
                    className="list-btn"
                >
                    <span className="fz:14 fw:500 text-dark">
                        Withdrawal Address Management
                    </span>
                </Link>
                <Link to="/user/biometrics-pin" className="list-btn">
                    <span className="fz:14 fw:500 text-dark">
                        Biometrics & PIN
                    </span>
                </Link>
            </ul>
        </div>
    );
};

export default SecurityCenter;
