import { parseJwt } from "src/utils/helpers";
import toast from "react-hot-toast";
import { refreshToken } from "./auth";
const getToken = async () => {
    let token = null;
    try {
        token = JSON.parse(localStorage.getItem("bidbox_authUser"));
        const refresh_token = token.jwt_refresh_token;
        token = token.jwt_token;
        const decode = parseJwt(token);
        if (decode.exp) {
            if (new Date(decode.exp * 1000).getTime() < new Date().getTime()) {
                const decode_refresh = parseJwt(refresh_token);
                if (
                    new Date(decode_refresh.exp * 1000).getTime() <
                    new Date().getTime()
                ) {
                    localStorage.removeItem("bidbox_authUser");
                    window.location.href = "/auth/signin";
                } else {
                    try {
                        const res_token_new = await refreshToken(refresh_token);
                        token = res_token_new.response.data;
                        localStorage.setItem(
                            "bidbox_authUser",
                            JSON.stringify(token)
                        );

                        localStorage.setItem(
                            "bidbox_authUser_created",
                            new Date().getTime()
                        );
                        token = token.jwt_token;
                    } catch (error) {
                        const message =
                            error.response.data.message || String(error);
                        toast(message);
                        localStorage.removeItem("bidbox_authUser");
                        setTimeout(() => {
                            window.location.href = "/auth/signin";
                        }, 2000);
                    }
                }
            }
        } else {
            localStorage.removeItem("bidbox_authUser");
            window.location.href = "/auth/signin";
        }
    } catch (error) {
        localStorage.removeItem("bidbox_authUser");
        setTimeout(() => {
            window.location.href = "/auth/signin";
        }, 2000);
    }
    return token;
};
const configStrict = async (payload) => {
    let token = await getToken();
    let headers = {
        ...payload.headers,
        authorization: `Bearer ${token}`,
    };
    return {
        ...payload,
        headers: headers,
    };
};
const configNotStrict = (payload) => {
    return payload;
};
export { configNotStrict, configStrict, getToken };
