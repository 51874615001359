import {
    TRANSFER_HISTORY,
    TRANSFER_HISTORY_SUCCESS,
    TRANSFER_HISTORY_ERROR,
    TRANSFER_HISTORY_INIT,
} from "./actionTypes";

export const transferHistoryInit = () => {
    return {
        type: TRANSFER_HISTORY_INIT,
    };
};

export const transferHistory = (payload) => {
    return {
        type: TRANSFER_HISTORY,
        payload: payload,
    };
};

export const transferHistorySuccess = (payload) => {
    return {
        type: TRANSFER_HISTORY_SUCCESS,
        payload: payload,
    };
};

export const transferHistoryError = (payload) => {
    return {
        type: TRANSFER_HISTORY_ERROR,
        payload: payload,
    };
};
