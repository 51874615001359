import { takeEvery, put, call } from "redux-saga/effects";

import { IP_BINDING, SERVER_IP, UNBOUND } from "./actionTypes";
import {
    serverIPSuccess,
    serverIPError,
    bindingSuccess,
    bindingError,
    unboundSuccess,
    unboundError,
} from "./actions";

import {
    getServerIP as apiGetServerIP,
    apiBinding as apiSetBinding,
    unBind as apiUnbound,
} from "src/api";
function* getServerIp() {
    try {
        const response = yield call(apiGetServerIP);
        const data = response.data;
        yield put(serverIPSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(serverIPError(message));
    }
}
function* setBinding({ payload }) {
    try {
        yield call(apiSetBinding, payload);
        yield put(bindingSuccess("Binding Process successfully"));
        window.history.back();
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(bindingError(message));
    }
}
function* unbound({ payload }) {
    try {
        yield call(apiUnbound, payload);
        yield put(unboundSuccess("Unbound Process successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(unboundError(message));
    }
}
function* authSaga() {
    yield takeEvery(SERVER_IP, getServerIp);
    yield takeEvery(IP_BINDING, setBinding);
    yield takeEvery(UNBOUND, unbound);
}

export default authSaga;
