import apis from "src/utils/apis";
import axios from "axios";
export const getCoin = async () => {
    let config = {
        method: "get",
        url: apis.API_COIN_CMC,
        params: {
            start: 1,
            limit: 10000,
            sortBy: "market_cap",
            sortType: "desc",
            convert: "USDT",
            cryptoType: "coins",
            tagType: "all",
            audited: false,
            aux: "high24h,low24h,total_supply,volume_7d,volume_30d",
        },
        headers: {},
    };
    let res = await axios(config);
    return res;
};

export const getNews = async () => {
    let config = {
        method: "get",
        url: apis.API_NEWS_CMC,
        params: {},
        headers: {},
    };
    let res = await axios(config);
    return res;
};
