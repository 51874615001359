import { Authentication, AdminDashboard } from "../components";
import LandingPage from "../components/layout/LandingPage";
// Auth Routes
import Signup from "../pages/Auth/Signup";
import Signin from "../pages/Auth/Signin";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import NewPassword from "../pages/Auth/NewPassword";

// User Routes
import Dashboard from "../pages/Dashboard/Dashboard";
import Trades from "../pages/Trades/Trades";

// Account Folder Routes
import Account from "../pages/Account/Account";
import Logout from "../pages/Account/Logout";

// Affiliate Folder Routes
import Affiliate from "../pages/Affiliate/Affiliate";

// API Binding Folder Routes
import APIBinding from "../pages/APIBinding/APIBinding";

// Asset Folder Routes
import Asset from "../pages/Asset/Asset";
import Deposit from "../pages/Asset/Deposit";
import Transfer from "../pages/Asset/Transfer";
import Withdrawal from "../pages/Asset/Withdrawal";

// Bot Setting Folder Routes
import BotSetting from "../pages/Botsetting/Botsetting";
import ConvertBNB from "../pages/Botsetting/ConvertBNB";

// News Folder Routes
import News from "../pages/News/News";
import NewsDetail from "../pages/News/Newsdetail";

// Profile Folder Routes
import Profile from "../pages/Profile/Profile";

// Chat Folder Routes
import ChatCountry from "../pages/Chat/ChatCountry";
import RoomChat from "../pages/Chat/RoomChat";

// Reward Detail Folder Routes
import RewardDetail from "../pages/RewardDetail/RewardDetail";

// Member Centre Folder Routes
import Membercentre from "../pages/Membercentre/Membercentre";

// Help & Support Folder Routes
import AccountProblem from "../pages/HelpSupport/AccountProblem";
import APIBindingIsues from "../pages/HelpSupport/APIBindingIsues";
import BUGProblem from "../pages/HelpSupport/BUGProblem";
import HelpSupport from "../pages/HelpSupport/HelpSupport";
import HelpSupportRecord from "../pages/HelpSupport/HelpSupportRecord";
import MoneyFlowProblem from "../pages/HelpSupport/MoneyFlowProblem";
import Other from "../pages/HelpSupport/Other";
import ReportViolations from "../pages/HelpSupport/ReportViolations";
import Suggestions from "../pages/HelpSupport/Suggestions";
import TradingProblem from "../pages/HelpSupport/TradingProblem";

// Security Folder Routes
import BiometricsPin from "../pages/Security/BiometricsPin";
import ChangePassword from "../pages/Security/ChangePassword";
import GVC from "../pages/Security/GVC";
import ModifyEmail from "../pages/Security/ModifyEmail";
import SecurityCenter from "../pages/Security/SecurityCenter";
import TransactionPin from "../pages/Security/TransactionPin";
import WAM from "../pages/Security/WAM";
import TNC from "../pages/PrivacyTnc/TNC";
import PrivacyPolicy from "../pages/PrivacyTnc/PrivacyPolicy";
import PreRegisteredUser from "../pages/PreRegisteredUser/PreRegisteredUser";

// Setting Folder Routes
import Setting from "../pages/Settings/Setting";

// Trading Profit Folder Routes
import TradingProfit from "../pages/TradingProfit/TradingProfit";

// Transaction Folder Routes
import Transaction from "../pages/Transaction/Transaction";

// Landing Page
import LandingPageHome from "../pages/LandingPage/Home";

import Maintenance from "../pages/Maintenance/Maintenance";
import NewMemberRegister from "../pages/PreRegisteredUser/NewMemberRegister";
import About from "../pages/Settings/About";
import HelpSupportRecordDetail from "../pages/HelpSupport/HelpSupportRecordDetail";
import ReplyTicket from "../pages/HelpSupport/ReplyTicket";

const authRoutes = [
    { path: "/auth/signup", component: Signup, layout: Authentication },
/*    { path: "/auth/signin", component: Signin, layout: Authentication },
    {
        path: "/auth/forgot-password",
        component: ForgotPassword,
        layout: Authentication,
    },*/
    {
        path: "/auth/set-new-password/:emailID",
        component: NewPassword,
        layout: Authentication,
    },
];

const userRoutes = [
    /*{
        path: "/user/help-support-record/:ticketID",
        component: HelpSupportRecordDetail,
        layout: AdminDashboard,
    },
    {
        path: "/user/reply-ticket/:ticketID",
        component: ReplyTicket,
        layout: AdminDashboard,
    },
    { path: "/user/logout", component: Logout, layout: AdminDashboard },
    { path: "/user/dashboard", component: Dashboard, layout: AdminDashboard },
    {
        path: "/user/trades",
        component: Trades,
        layout: AdminDashboard,
    },
    {
        path: "/user/bot-setting",
        component: BotSetting,
        layout: AdminDashboard,
    },
    {
        path: "/user/convert-to-bnb",
        component: ConvertBNB,
        layout: AdminDashboard,
    },
    {
        path: "/user/news",
        component: News,
        layout: AdminDashboard,
    },
    {
        path: "/user/order-message",
        component: NewsDetail,
        layout: AdminDashboard,
    },
    {
        path: "/user/system-information",
        component: NewsDetail,
        layout: AdminDashboard,
    },
    {
        path: "/user/account",
        component: Account,
        layout: AdminDashboard,
    },

    {
        path: "/user/affiliate",
        component: Affiliate,
        layout: AdminDashboard,
    },
    {
        path: "/user/api-binding/:id",
        component: APIBinding,
        layout: AdminDashboard,
    },
    {
        path: "/user/asset",
        component: Asset,
        layout: AdminDashboard,
    },
    {
        path: "/user/deposit",
        component: Deposit,
        layout: AdminDashboard,
    },
    {
        path: "/user/transfer",
        component: Transfer,
        layout: AdminDashboard,
    },
    {
        path: "/user/withdrawal",
        component: Withdrawal,
        layout: AdminDashboard,
    },
    {
        path: "/user/profile",
        component: Profile,
        layout: AdminDashboard,
    },
    {
        path: "/user/chat-country",
        component: ChatCountry,
        layout: AdminDashboard,
    },
    {
        path: "/user/room-chat",
        component: RoomChat,
        layout: AdminDashboard,
    },
    {
        path: "/user/reward-detail",
        component: RewardDetail,
        layout: AdminDashboard,
    },
    {
        path: "/user/member-centre",
        component: Membercentre,
        layout: AdminDashboard,
    },
    {
        path: "/user/account-problem",
        component: AccountProblem,
        layout: AdminDashboard,
    },
    {
        path: "/user/api-binding-isues",
        component: APIBindingIsues,
        layout: AdminDashboard,
    },
    {
        path: "/user/bug-problem",
        component: BUGProblem,
        layout: AdminDashboard,
    },
    {
        path: "/user/help-support",
        component: HelpSupport,
        layout: AdminDashboard,
    },
    {
        path: "/user/help-support-record",
        component: HelpSupportRecord,
        layout: AdminDashboard,
    },
    {
        path: "/user/money-flow-problem",
        component: MoneyFlowProblem,
        layout: AdminDashboard,
    },
    {
        path: "/user/other",
        component: Other,
        layout: AdminDashboard,
    },
    {
        path: "/user/report-violations",
        component: ReportViolations,
        layout: AdminDashboard,
    },
    {
        path: "/user/suggestions-oppinions",
        component: Suggestions,
        layout: AdminDashboard,
    },
    {
        path: "/user/trading-problem",
        component: TradingProblem,
        layout: AdminDashboard,
    },
    {
        path: "/user/biometrics-pin",
        component: BiometricsPin,
        layout: AdminDashboard,
    },
    {
        path: "/user/change-password",
        component: ChangePassword,
        layout: AdminDashboard,
    },
    {
        path: "/user/google-verification-code",
        component: GVC,
        layout: AdminDashboard,
    },
    {
        path: "/user/modify-email",
        component: ModifyEmail,
        layout: AdminDashboard,
    },
    {
        path: "/user/security-center",
        component: SecurityCenter,
        layout: AdminDashboard,
    },
    {
        path: "/user/transaction-pin",
        component: TransactionPin,
        layout: AdminDashboard,
    },
    {
        path: "/user/withdrawal-address-management",
        component: WAM,
        layout: AdminDashboard,
    },
    {
        path: "/user/setting",
        component: Setting,
        layout: AdminDashboard,
    },
    {
        path: "/user/trading-profit",
        component: TradingProfit,
        layout: AdminDashboard,
    },
    {
        path: "/user/transaction",
        component: Transaction,
        layout: AdminDashboard,
    },
    {
        path: "/user/about",
        component: About,
        layout: AdminDashboard,
    },*/
];

const nonAuthRoutes = [
    { path: "/tnc", layout: Authentication, component: TNC },
    {
        path: "/pre-registered-user",
        layout: Authentication,
        component: PreRegisteredUser,
    },
    {
        path: "/new-member-register",
        layout: Authentication,
        component: NewMemberRegister,
    },

    {
        path: "/privacy-policy",
        layout: Authentication,
        component: PrivacyPolicy,
    },
    {
        path: "/",
        layout: LandingPage,
        component: LandingPageHome,
    },
];

const maintenanceRoutes = [
    {
        path: "/maintenance",
        layout: Authentication,
        component: Maintenance,
    },
];
export { authRoutes, userRoutes, nonAuthRoutes, maintenanceRoutes };
