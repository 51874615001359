import { takeEvery, put, call } from "redux-saga/effects";

import { WALLET, VALIDATE_DEPOSIT, TRANSFER, WITHDRAW } from "./actionTypes";
import {
    walletSuccess,
    walletError,
    validateSuccess,
    validateError,
    transferSuccess,
    transferError,
    withdrawSuccess,
    withdrawError,
} from "./actions";

import {
    getWallet as apiGetWallet,
    validateDeposit as apiValidateDeposit,
    transferBalance as apiTransfer,
    withdrawal as apiWithdraw,
} from "src/api";
function* getWallet() {
    try {
        const response = yield call(apiGetWallet);
        const data = response.data;
        yield put(walletSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(walletError(message));
    }
}
function* validateDeposit(param) {
    try {
        yield call(apiValidateDeposit, param.payload);
        yield put(validateSuccess("update balance successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(validateError(message));
    }
}

function* transferBalance({ payload }) {
    try {
        yield call(apiTransfer, payload);
        yield put(transferSuccess("Transfer Succesfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(transferError(message));
    }
}

function* withdraw({ payload }) {
    try {
        yield call(apiWithdraw, payload);
        yield put(withdrawSuccess("Withdraw Succesfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(withdrawError(message));
    }
}
function* authSaga() {
    yield takeEvery(WALLET, getWallet);
    yield takeEvery(VALIDATE_DEPOSIT, validateDeposit);
    yield takeEvery(TRANSFER, transferBalance);
    yield takeEvery(WITHDRAW, withdraw);
}

export default authSaga;
