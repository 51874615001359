import {
    BALANCE,
    BALANCE_SUCCESS,
    BALANCE_ERROR

  } from "./actionTypes"
  
  export const getBalance = () => {
    return {
      type: BALANCE
    }
  }
  export const balanceSuccess = message => {
    return {
      type: BALANCE_SUCCESS,
      payload: message,
    }
  }
  
  export const balanceError = message => {
    return {
      type: BALANCE_ERROR,
      payload: message,
    }
  }
  