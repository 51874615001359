import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, initResetPassword } from "src/store/actions";
import toast from "react-hot-toast";

const NewPassword = (props) => {
    const dispatch = useDispatch();
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [password, setPassword] = useState(null);
    const [passwordConfirm, setPasswordConfirm] = useState(null);
    const pathname = window.location.pathname;
    const split_segment_url = pathname.split("/");
    const token_reset = split_segment_url[split_segment_url.length - 1];
    const storeResetPassword = useSelector((state) => state.ResetPassword);

    const doReset = () => {
        if (password === null || passwordConfirm == null) {
            toast("Please enter a new password");
        } else if (password !== passwordConfirm) {
            toast("the new password does not match the confirm password");
        } else {
            dispatch(
                resetPassword(
                    {
                        new_password: password,
                        new_password_repeat: password,
                        password_reset_token: token_reset,
                    },
                    props.history
                )
            );
        }
    };
    useEffect(() => {
        if (storeResetPassword.successMsg !== null) {
            toast(storeResetPassword.successMsg);
        }
        if (storeResetPassword.errorMsg !== null) {
            toast(storeResetPassword.errorMsg);
        }
        dispatch(initResetPassword());
    }, [storeResetPassword.successMsg, storeResetPassword.errorMsg]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Set New Password
                </span>
                <div className="n"></div>
            </header>
            <form id="form-validation">
                <div className="field">
                    <input
                        type={showPassword1 ? "text" : "password"}
                        id="new-password"
                        className="input-control"
                        placeholder="New Password"
                        onChange={(evt) => setPassword(evt.target.value)}
                        required
                    />
                    <button
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPassword1(!showPassword1)}
                    >
                        <i
                            className={
                                showPassword1
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <div className="field">
                    <input
                        type={showPassword2 ? "text" : "password"}
                        id="confirm-new-password"
                        className="input-control"
                        placeholder="Confirm New Password"
                        onChange={(evt) => setPasswordConfirm(evt.target.value)}
                        required
                    />
                    <button
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPassword2(!showPassword2)}
                    >
                        <i
                            className={
                                showPassword2
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <button
                    type="button"
                    onClick={doReset}
                    className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-80 text-white text-uppercase"
                >
                    Set New Password
                </button>
            </form>
        </div>
    );
};

export default NewPassword;
