import React from "react";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBidbox } from "../../components";
import {
    getAccount,
    getRank,
    logoutUser,
    activateBot,
    getBotStatus,
    initActivateBot,
} from "src/store/actions";
import VIP1 from "../../assets/icons/membercentre/VIP_1.png";
import VIP2 from "../../assets/icons/membercentre/VIP_2.png";
import VIP3 from "../../assets/icons/membercentre/VIP_3.png";
import VIP4 from "../../assets/icons/membercentre/VIP_4.png";
import VIP5 from "../../assets/icons/membercentre/VIP_5.png";
import VIP6 from "../../assets/icons/membercentre/VIP_6.png";
import AffilitaeIcon from "../../assets/icons/account/affiliate.png";
import ApiBindIcon from "../../assets/icons/account/apibind.png";
import AssetIcon from "../../assets/icons/account/asset.png";
import AboutIcon from "../../assets/icons/account/question.png";
import HelpIcon from "../../assets/icons/account/help.png";
import MemberCentreIcon from "../../assets/icons/account/membercentre.png";
import ProfitTraderIcon from "../../assets/icons/account/profit-trade.png";
import RewardDetailIcon from "../../assets/icons/account/reward-detail.png";
import SecurityIcon from "../../assets/icons/account/security.png";
import SettingIcon from "../../assets/icons/account/setting.png";
import UserGuideIcon from "../../assets/icons/account/user-guide.png";
import BlankProfile from "../../assets/image/blank_profile.png";
import ModalActivate from "src/components/modal/ModalActivate";

import toast from "react-hot-toast";

const Account = () => {
    const dispatch = useDispatch();
    const [rankSelected, setRankSelected] = useState("M0");
    const [showEmail, setShowEmail] = useState(false);
    const [activateModal, setActivateModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        checked: null,
        message: "",
    });
    const account = useSelector((state) => state.Account);
    const storeActivateBot = useSelector((state) => state.ActivateBot);
    const bot = useSelector((state) => state.Bot);
    const rank = useSelector((state) => state.Rank);
    useEffect(() => {
        dispatch(getAccount());
        dispatch(getRank());
        dispatch(getBotStatus());
    }, []);
    useEffect(() => {
        if (bot.errorMsg !== null) toast(bot.errorMsg);
    }, [bot.errorMsg]);
    const doLogout = () => {
        dispatch(logoutUser());
    };
    const handleActivate = () => {
        let checked = false;
        setActivateModal({
            checked: (status) => {
                checked = status;
            },
            isVisible: true,
            title: bot.bot_expired_at !== null ? "Renew Bot" : "Activate Bot",
            message: "Total: 135 USDT",
            buttons: [
                {
                    label: bot.bot_expired_at !== null ? "Renew" : "Activate",
                    className: "btn-modal",
                    action: () => {
                        if (checked) {
                            setActivateModal({
                                isVisible: false,
                                buttons: [],
                                title: "",
                                checked: null,
                                message: "",
                            });
                            dispatch(activateBot());
                        } else {
                            toast("please check agreement");
                        }
                    },
                },
                {
                    label: "Cancel",
                    className: "btn-modal bg-transparent text-app",
                    action: () => {
                        setActivateModal({
                            isVisible: false,
                            buttons: [],
                            title: "",
                            message: "",
                        });
                    },
                },
            ],
        });
    };
    useEffect(() => {
        if (storeActivateBot.successMsg !== null)
            toast(storeActivateBot.successMsg);
        if (storeActivateBot.errorMsg !== null)
            toast(storeActivateBot.errorMsg);
        dispatch(initActivateBot());
    }, [storeActivateBot.successMsg, storeActivateBot.errorMsg]);
    useEffect(() => {
        if (account.errorMsg !== null) toast(account.errorMsg);
        if (rank.errorMsg !== null) toast(rank.errorMsg);
        switch (rank.name) {
            case "VIP1":
            case "M1":
                setRankSelected(VIP1);
                break;
            case "VIP2":
            case "M2":
                setRankSelected(VIP2);
                break;
            case "VIP3":
            case "M3":
                setRankSelected(VIP3);
                break;
            case "VIP4":
            case "M4":
                setRankSelected(VIP4);
                break;
            case "VIP5":
            case "M5":
                setRankSelected(VIP5);
                break;
            case "VIP6":
            case "M6":
                setRankSelected(VIP6);
                break;
            default:
                setRankSelected("M0");
                break;
        }
    }, [account.errorMsg, rank.errorMsg, rank.name]);
    return (
        <div className="account-content">
            {storeActivateBot.loading && <LoadingBidbox />}
            <div className="header-profile">
                <div className="profile-info">
                    <div className="avatar-md-circle mr-2">
                        <img src={BlankProfile} alt="profile_bidbox" />
                    </div>
                    <div className="d-flex flex-column">
                        <h1 className="sttr-text m-0 fz:16 text-white">
                            {account.full_name}
                        </h1>
                        <h1 className="sttr-text m-0 fz:13 text-white">
                            ID : {showEmail ? account.email : "******"}
                            <i
                                style={{
                                    color: "#cacaca",
                                    cursor: "pointer",
                                    marginLeft: 10,
                                }}
                                onClick={() => setShowEmail(!showEmail)}
                                className={
                                    showEmail
                                        ? "fa-regular fa-eye fz:12"
                                        : "fa-regular fa-eye-slash fz:12"
                                }
                            ></i>
                        </h1>
                    </div>
                    <div className="permission-card">
                        {rankSelected !== "M0" && (
                            <div className="icon-permission">
                                <div
                                    className="img-permission"
                                    style={{
                                        marginTop: -10,
                                    }}
                                >
                                    <img
                                        src={rankSelected}
                                        alt=""
                                        style={{
                                            marginTop: 10,
                                            height: "60%",
                                            width: "90%",
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="d-flex flex-column">
                            <h1 className="m-0 fz:15 text-white fw:500 sttr-text">
                                View Permission
                            </h1>
                            <h1 className="m-0 fz:13 text-white sttr-text">
                                {bot.bot_expired_at === null
                                    ? "you have never activated a bot"
                                    : `Expires on ${bot.bot_expired_at_date}`}
                            </h1>
                        </div>
                        <button
                            type="button"
                            onClick={handleActivate}
                            className="btn bg-app-color text-white fz:14 fw:500 hover:bg-app-color-90 px-3"
                        >
                            {bot.bot_expired_at === null ? "Activate" : "Renew"}
                        </button>
                    </div>
                </div>
            </div>
            <div className="profile-content">
                <ul className="list-group">
                    <li className="list-item">
                        <Link to="/user/profile" className="list-link">
                            <i className="fa-solid fa-user list-icon"></i>
                            <span className="list-name">Profile</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/member-centre" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={MemberCentreIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Member Centre</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/asset" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={AssetIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Asset</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/bot-setting" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={ApiBindIcon}
                                    alt=""
                                    className="img-list-icon"
                                    id="api-binding-icon"
                                />
                            </div>
                            <span className="list-name">API Binding</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/affiliate" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={AffilitaeIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Affiliate</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/reward-detail" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={RewardDetailIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Reward Detail</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/trading-profit" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={ProfitTraderIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Signal Profit</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/help-support" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={HelpIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Help & Support</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/about" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={AboutIcon}
                                    alt=""
                                    className="img-about-icon"
                                />
                            </div>
                            <span className="list-name">About Bidbox</span>
                        </Link>
                    </li>

                    <li className="list-item">
                        <Link to="/user/chat-country" className="list-link">
                            <i className="fas fa-comments list-icon"></i>
                            <span className="list-name">Chat Room</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/setting" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={SettingIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Setting</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link to="/user/security-center" className="list-link">
                            <div className="img-icon">
                                <img
                                    src={SecurityIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">Security</span>
                        </Link>
                    </li>
                    <li className="list-item">
                        <Link
                            to="#"
                            onClick={() =>
                                window.open(
                                    "https://help.bidbox.community/user-guide/",
                                    "_blank"
                                )
                            }
                            className="list-link"
                        >
                            <div className="img-icon">
                                <img
                                    src={UserGuideIcon}
                                    alt=""
                                    className="img-list-icon"
                                />
                            </div>
                            <span className="list-name">User Guide</span>
                        </Link>
                    </li>
                </ul>
                <div className="px-2 mt-2">
                    <button
                        onClick={doLogout}
                        className="btn btn-full text-uppercase bg-app-color hover:bg-app-color-90 text-white fw:500"
                    >
                        Sign Out
                    </button>
                </div>
            </div>
            {activateModal.isVisible && (
                <ModalActivate
                    message={activateModal.message}
                    title={activateModal.title}
                    checked={activateModal.checked}
                    buttons={activateModal.buttons}
                />
            )}
        </div>
    );
};

export default Account;
