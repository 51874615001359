import { takeEvery, put, call } from "redux-saga/effects";

import { TRANSFER_HISTORY } from "./actionTypes";
import { transferHistoryError, transferHistorySuccess } from "./actions";

import { getHistoryTransfer as apiGetHistoryTransfer } from "src/api";
function* getHistoryTransfer({ payload }) {
    try {
        const response = yield call(apiGetHistoryTransfer, payload.from_id);
        const data = response.data;

        yield put(transferHistorySuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(transferHistoryError(message));
    }
}
function* authSaga() {
    yield takeEvery(TRANSFER_HISTORY, getHistoryTransfer);
}

export default authSaga;
