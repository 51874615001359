import {
    EXCHANGE_ACTIVATION,
    EXCHANGE_ACTIVATION_SUCCESS,
    EXCHANGE_ACTIVATION_ERROR,
    EXCHANGE_ACTIVATION_INIT,
    TRADES,
    TRADES_SUCCESS,
    TRADES_ERROR,
    TRADES_INIT,
    TRADES_UPDATE,
    TRADES_REMOVE,
    TRADES_INSERT,
    TRADES_CANCEL,
    TRADES_CANCEL_SUCCESS,
    TRADES_CANCEL_ERROR,
    TRADES_SELL,
    TRADES_SELL_SUCCESS,
    TRADES_SELL_ERROR,
    TRADES_CLEAR_MESSAGE,
    TRADES_PROFIT,
    TRADES_PROFIT_SUCCESS,
    TRADES_PROFIT_ERROR,
    TRADES_HISTORY,
    TRADES_HISTORY_SUCCESS,
    TRADES_HISTORY_ERROR,
    TRADES_SYNC,
    TRADES_SYNC_SUCCESS,
    TRADES_SYNC_ERROR,
    TRADES_AVG,
    TRADES_AVG_SUCCESS,
    TRADES_AVG_ERROR,
    TICK_SIZE,
    TICK_SIZE_SUCCESS,
    TICK_SIZE_ERROR,
    TRADES_CURRENT_PRICE,
    TRADES_CURRENT_PRICE_SUCCESS,
    TRADES_CURRENT_PRICE_ERROR,
} from "./actionTypes";

export const exchangeInit = (payload) => {
    return {
        type: EXCHANGE_ACTIVATION_INIT,
    };
};
export const exchangeActivation = (payload) => {
    return {
        type: EXCHANGE_ACTIVATION,
        payload: payload,
    };
};
export const exchangeActivationSuccess = (message) => {
    return {
        type: EXCHANGE_ACTIVATION_SUCCESS,
        payload: message,
    };
};

export const exchangeActivationError = (message) => {
    return {
        type: EXCHANGE_ACTIVATION_ERROR,
        payload: message,
    };
};

export const tradesInit = (payload) => {
    return {
        type: TRADES_INIT,
    };
};
export const getTrades = (payload) => {
    return {
        type: TRADES,
        payload: payload,
    };
};

export const updateTrades = (where, payload) => {
    return {
        type: TRADES_UPDATE,
        payload: { where, payload },
    };
};

export const insertTrades = (payload) => {
    return {
        type: TRADES_INSERT,
        payload: payload,
    };
};
export const removeTrades = (payload) => {
    return {
        type: TRADES_REMOVE,
        payload: payload,
    };
};
export const tradesSuccess = (message) => {
    return {
        type: TRADES_SUCCESS,
        payload: message,
    };
};

export const tradesError = (message) => {
    return {
        type: TRADES_ERROR,
        payload: message,
    };
};

export const tradesCancel = (payload) => {
    return {
        type: TRADES_CANCEL,
        payload: payload,
    };
};

export const tradesCancelSuccess = (message) => {
    return {
        type: TRADES_CANCEL_SUCCESS,
        payload: message,
    };
};

export const tradesCancelError = (message) => {
    return {
        type: TRADES_CANCEL_ERROR,
        payload: message,
    };
};

export const tradesSell = (payload) => {
    return {
        type: TRADES_SELL,
        payload: payload,
    };
};

export const tradesSellSuccess = (message) => {
    return {
        type: TRADES_SELL_SUCCESS,
        payload: message,
    };
};

export const tradesSellError = (message) => {
    return {
        type: TRADES_SELL_ERROR,
        payload: message,
    };
};

export const clearTradesMessage = () => {
    return {
        type: TRADES_CLEAR_MESSAGE,
    };
};
export const tradesProfit = (payload) => {
    return {
        type: TRADES_PROFIT,
        payload: payload,
    };
};
export const tradesProfitSuccess = (message) => {
    return {
        type: TRADES_PROFIT_SUCCESS,
        payload: message,
    };
};

export const tradesProfitError = (message) => {
    return {
        type: TRADES_PROFIT_ERROR,
        payload: message,
    };
};

export const tradesHistory = (payload) => {
    return {
        type: TRADES_HISTORY,
        payload: payload,
    };
};

export const tradesHistorySuccess = (message) => {
    return {
        type: TRADES_HISTORY_SUCCESS,
        payload: message,
    };
};

export const tradesHistoryError = (message) => {
    return {
        type: TRADES_HISTORY_ERROR,
        payload: message,
    };
};

export const tradesSyncSuccess = (message) => {
    return {
        type: TRADES_SYNC_SUCCESS,
        payload: message,
    };
};

export const tradesSyncError = (message) => {
    return {
        type: TRADES_SYNC_ERROR,
        payload: message,
    };
};

export const tradesSync = (payload) => {
    return {
        type: TRADES_SYNC,
        payload: payload,
    };
};
export const tradesAvgSuccess = (message) => {
    return {
        type: TRADES_AVG_SUCCESS,
        payload: message,
    };
};

export const tradesAvgError = (message) => {
    return {
        type: TRADES_AVG_ERROR,
        payload: message,
    };
};

export const tradesAvg = (payload) => {
    return {
        type: TRADES_AVG,
        payload: payload,
    };
};

export const getTickSize = (payload) => {
    return {
        type: TICK_SIZE,
        payload: payload,
    };
};

export const tickSizeSuccess = (payload) => {
    return {
        type: TICK_SIZE_SUCCESS,
        payload: payload,
    };
};
export const tickSizeError = (message) => {
    return {
        type: TICK_SIZE_ERROR,
        payload: message,
    };
};

export const tradesGetCurrentPrice = (payload) => {
    return {
        type: TRADES_CURRENT_PRICE,
        payload: payload,
    };
};

export const tradesGetCurrentPriceSuccess = (payload) => {
    return {
        type: TRADES_CURRENT_PRICE_SUCCESS,
        payload: payload,
    };
};

export const tradesGetCurrentPriceError = (message) => {
    return {
        type: TRADES_CURRENT_PRICE_ERROR,
        payload: message,
    };
};
