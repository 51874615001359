import { NEWS, NEWS_SUCCESS, NEWS_ERROR } from "./actionTypes";

const initialState = {
    items: [],
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case NEWS:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case NEWS_SUCCESS:
            state = {
                ...state,
                items: action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case NEWS_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
