import { takeEvery, put, call } from "redux-saga/effects";

import { BALANCE } from "./actionTypes";
import { balanceSuccess, balanceError } from "./actions";

import { getBalance as apiGetBalance } from "src/api";
function* getBalance() {
    try {
        const response = yield call(apiGetBalance);
        const data = response.data;
        yield put(balanceSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(balanceError(message));
    }
}

function* authSaga() {
    yield takeEvery(BALANCE, getBalance);
}

export default authSaga;
