import {
    SERVER_IP,
    SERVER_IP_SUCCESS,
    SERVER_IP_ERROR,
    IP_BINDING,
    IP_BINDING_SUCCESS,
    IP_BINDING_ERROR,
    UNBOUND,
    UNBOUND_SUCCESS,
    UNBOUND_ERROR,
    IP_BINDING_INIT,
} from "./actionTypes";

const initialState = {
    ips: [],
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case IP_BINDING_INIT:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case UNBOUND:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case IP_BINDING:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case SERVER_IP:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case SERVER_IP_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case SERVER_IP_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        case IP_BINDING_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case IP_BINDING_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case UNBOUND_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case UNBOUND_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
