import React from "react";

const BiometricsPin = () => {
  return (
    <div className="details-content">
      <header className="details-header">
        <button className="back-button" onClick={() => window.history.back()}>
          <i className="fa-solid fa-angle-left back-icon text-dark"></i>
        </button>
        <span className="text-uppercase fw:500 text-dark">
          Biometrics & PIN
        </span>
        <div className="n"></div>
      </header>
    </div>
  );
};

export default BiometricsPin;
