import React, { useState, useEffect } from "react";
import {
    userRequestOtp,
    transferBalance,
    transferInit,
} from "src/store/actions";
import { LoadingBidbox } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../../components/modal/Modal";
const Transfer = () => {
    const dispatch = useDispatch();
    const wallet = useSelector((state) => state.Wallet);
    const balance = useSelector((state) => state.Balance);
    const account = useSelector((state) => state.Account);

    const [receivingAccount, setReceivingAccount] = useState("");
    const [pin, setPIN] = useState("");
    const [transferQty, setTransferQty] = useState("");
    const [otp, setOtp] = useState("");

    const [generalModal, setGeneralModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });
    const handleGetOtp = () => {
        dispatch(
            userRequestOtp({
                otp_context: 7,
                target: 0,
            })
        );
    };

    useEffect(() => {
        if (account.reqOtpSuccessMsg !== null) toast(account.reqOtpSuccessMsg);
        if (account.reqOtpErrorMsg !== null) toast(account.reqOtpErrorMsg);
    }, [account.reqOtpSuccessMsg, account.reqOtpErrorMsg]);

    const confirmTransfer = () => {
        if (receivingAccount === "")
            return toast("Please enter Receiving Account");
        if (transferQty === "") return toast("Please enter Transfer Quantity");
        if (otp === "") return toast("Please enter OTP Code");
        if (account.security_pin_active === false) {
            setPIN("000000");
        }
        setGeneralModal({
            title: "",
            isVisible: true,
            message: "are you sure about the balance transfer?",
            buttons: [
                {
                    label: "Confirm",
                    className: "btn-modal",
                    action: () => {
                        dispatch(
                            transferBalance({
                                currency_id: "USDT",
                                otp: otp,
                                security_pin: pin,
                                to: receivingAccount,
                                value: parseFloat(transferQty),
                            })
                        );
                        setGeneralModal({
                            isVisible: false,
                        });
                    },
                },
                {
                    label: "Cancel",
                    className: "btn-modal bg-transparent text-app",
                    action: () => {
                        setGeneralModal({
                            isVisible: false,
                        });
                    },
                },
            ],
        });
    };
    useEffect(() => {
        if (wallet.transferSuccess !== null) toast(wallet.transferSuccess);
        if (wallet.transferError !== null) toast(wallet.transferError);
        dispatch(transferInit());
    }, [wallet.transferSuccess, wallet.transferError]);
    return (
        <div className="py-5">
            {wallet.loading && <LoadingBidbox />}
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Transfer
                </span>
                <div className="n"></div>
            </header>
            <div>
                <form id="form-validation" action="#" className="mb-2">
                    <div className="px-2">
                        <div className="chain-field">
                            <span className="placeholder">Chain:</span>
                            <span className="badge-chain">TRC20</span>
                        </div>
                    </div>
                    <div
                        className="card mb-2 border-0"
                        style={{ borderRadius: "0", background: "#edf7fd" }}
                    >
                        <div className="card-body px-2">
                            <div className="field">
                                <label
                                    htmlFor="receiving-account"
                                    className="label-control"
                                >
                                    Receiving Account
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setReceivingAccount(e.target.value)
                                    }
                                    id="receiving-account"
                                    className="input-control fz:15"
                                    placeholder="Receiving Account"
                                    style={{ height: "35px" }}
                                    required
                                />
                            </div>
                            <div className="field">
                                <label
                                    htmlFor="transfer-quantity"
                                    className="label-control"
                                >
                                    Transfer Quantity
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setTransferQty(e.target.value)
                                    }
                                    id="transfer-quantity"
                                    className="input-control fz:15"
                                    placeholder="The minimum withdrawal 10 USDT"
                                    style={{ height: "35px" }}
                                    required
                                />
                            </div>
                            <p className="my-1 fz:14 fw:500 text-gray sttr-text">
                                Available: {balance.active_balance}
                            </p>
                            <div className="d-flex aign-items-center justify-content-between flex-wrap">
                                <p className="m-0 fz:14 fw-500 text-dark">
                                    Arrival Quantity
                                </p>
                                <p className="m-0 fz:14 fw-500 text-dark">
                                    {transferQty !== undefined &&
                                    transferQty !== ""
                                        ? transferQty
                                        : 0}{" "}
                                    USDT
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="field">
                            <label
                                htmlFor="otp-email"
                                className="label-control"
                            >
                                OTP Email
                            </label>
                            <input
                                type="text"
                                id="otp-email"
                                onChange={(e) => setOtp(e.target.value)}
                                className="input-control fz:15"
                                placeholder="OTP Email"
                                style={{ height: "35px" }}
                                required
                            />
                            <button
                                type="button"
                                onClick={handleGetOtp}
                                className="input-btn-absolute hover:bg-app-color-90"
                            >
                                Get Otp
                            </button>
                        </div>
                        <div className="field">
                            <label
                                htmlFor="transaction-pin"
                                className="label-control"
                            >
                                Transaction PIN
                            </label>
                            <input
                                type="text"
                                onChange={(e) => setPIN(e.target.value)}
                                id="transaction-pin"
                                className="input-control fz:15"
                                placeholder="Transaction PIN"
                                style={{ height: "35px" }}
                            />
                        </div>
                        <div className="d-flex justify-content-end mb-2">
                            <Link
                                to="/user/transaction-pin"
                                className="text-app text-decoration-none hover:text-app-color-90 fz:12 m-0 fw:500"
                            >
                                Set / Change Transaction PIN
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={confirmTransfer}
                            className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color text-uppercase text-white"
                        >
                            Confirm Transfer
                        </button>
                    </div>
                </form>
            </div>
            {generalModal.isVisible && (
                <Modal
                    message={generalModal.message}
                    title={generalModal.title}
                    buttons={generalModal.buttons}
                />
            )}
        </div>
    );
};

export default Transfer;
