import {
    ACTIVATE_BOT,
    ACTIVATE_BOT_ERROR,
    ACTIVATE_BOT_SUCCESS,
    ACTIVATE_BOT_INIT,
} from "./actionTypes";

const initialState = {
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVATE_BOT_INIT:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case ACTIVATE_BOT:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case ACTIVATE_BOT_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case ACTIVATE_BOT_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
