export const ACCOUNT_PROFILE = "SET_ACCOUNT_PROFILE";
export const ACCOUNT_PROFILE_SUCCESS = "ACCOUNT_PROFILE_SUCCESS";
export const ACCOUNT_PROFILE_ERROR = "ACCOUNT_PROFILE_ERROR";
export const ACCOUNT_REQ_OTP = "ACCOUNT_REQ_OTP";
export const ACCOUNT_REQ_OTP_SUCCESS = "ACCOUNT_REQ_OTP_SUCCESS";
export const ACCOUNT_REQ_OTP_ERROR = "ACCOUNT_REQ_OTP_ERROR";
export const CHANGE_PIN = "CHANGE_PIN";
export const CHANGE_PIN_SUCCESS = "CHANGE_PIN_SUCCESS";
export const CHANGE_PIN_ERROR = "CHANGE_PIN_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_ERROR = "CHANGE_EMAIL_ERROR";
export const ACCOUNT_INIT = "ACCOUNT_INIT";
