import React from "react";
import htmlParse from "html-react-parser";
const Tab = ({ tabs }) => {
    return (
        <div className="tab">
            {tabs.map((key, index) => {
                return (
                    <button key={index}
                        className="tablinks"
                        onClick={(evt) => {
                            let tablinks =
                                document.getElementsByClassName("tablinks");
                            for (let i = 0; i < tablinks.length; i++) {
                                tablinks[i].className = tablinks[
                                    i
                                ].className.replace(" active", "");
                            }
                            evt.currentTarget.className += " active";
                            key.onClick();
                        }}
                    >
                        {key.label}
                    </button>
                );
            })}
        </div>
    );
};

export default Tab;
