import axios from "axios";
import apis from "src/utils/apis";
import { configNotStrict, configStrict } from "./config";
export const getHistoryWithdraw = async (from_id) => {
    let config = await configStrict({
        method: "post",
        url: apis.API_GET_HISTORY_WITHDRAW,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify({
            from_id: from_id,
        }),
    });
    let res = await axios(config);
    return res;
};
