import {
    LOGIN,
    LOGIN_SUCCESS,
    AUTH_ERROR,
  } from "./actionTypes"
  
  const initialState = {
    loading:false,
    errorMessage: null,
    successMessage: null,
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN:
        state = {
          ...state,
          loading:true,
          successMessage: null,
          errorMessage: null,
        }
        break
      case LOGIN_SUCCESS:
        state = {
          ...state,
          loading:false,
          successMessage: null,
        }
        break
      case AUTH_ERROR:
        state = { ...state, 
            loading:false,
            errorMessage: action.payload }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  