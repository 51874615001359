import React, {useState, useRef, useEffect,} from "react";
import { useSelector, useDispatch } from "react-redux";
import  { Strophe, $pres,$msg, $iq, onPresence } from 'strophe.js'
import 'strophejs-plugin-muc';
import {openfireLogin} from "src/store/actions";

const RoomChat = () => {
  const dispatch = useDispatch()
  const BOSH_SERVICE = process.env.REACT_APP_BOSH_SERVICE;
  let connection = useRef(null)

  const [activeInput, setActiveInput] = useState(false);
  const GetFileRef = useRef(null);
  const openfireState = useSelector(state => state.Openfire)
  const getValue = (e) => {
    if (e.target.value.length >= 1) {
      setActiveInput(true);
    } else {
      setActiveInput(false);
    }
  };
  const getFile = (e) => {
    GetFileRef.current.click();
  };
  useEffect(()=>{
    if(!(openfireState.username && openfireState.password)){
      dispatch(openfireLogin())
    }
  },[])

  const onMessage = (msg) => {
    console.log('OnMessage, Received XMPP message:', msg);
    // handle incoming message
    return true;
  };

  const messageHandler = (message) => {
    console.log('Received XMPP message:', message);
    // handle incoming message
  };

  const presHandler = (presence) => {
    console.log('Received XMPP presence:', presence);
    // handle presence update
  };
  const onHistory = (messageArchive) => {
    const messages = messageArchive.getElementsByTagName('forwarded');
    console.log(messages)
    // const history = [];
    // for (let i = 0; i < messages.length; i++) {
    //   const message = messages[i].querySelector('message');
    //   const sender = message.getAttribute('from');
    //   const body = message.querySelector('body').textContent;
    //   const timestamp = message.querySelector('delay').getAttribute('stamp');
    //   const date = new Date(timestamp);
    //   history.push({
    //     sender,
    //     body,
    //     date
    //   });
    // }
    // setHistory(history);
  };

  const onConnect = (status) => {
    if (status === Strophe.Status.CONNECTED) {
      console.log('XMPP connection established.');
      if (connection) { // Periksa apakah connection sudah didefinisikan
        const options = {
          with: 'room-name@conference.your-xmpp-server',
          start: '2023-01-01T00:00:00Z',
          end: '2023-05-01T00:00:00Z'
        };
        console.log(connection)
        // connection.mam.query(options, onHistory);
        connection.addHandler(onMessage, null, 'message', null, null, null);
        connection.send($pres().tree());

        connection.muc.init(connection);
        connection.muc.join('id@conference.openfire-server.bidbox.community','irhammusthofa',messageHandler,presHandler)
      }
    }
  };


  const sendMessage = (to, body) => {
    const msg = $msg({ to, type: 'chat' }).c('body').t(body);
    connection.send(msg);
  };
  return (
    <div className="room-chat">
      <header className="details-header" style={{ background: "#e6e6e6" }}>
        <button
          className="back-button"
          style={{ background: "transparent" }}
          onClick={() => window.history.back()}
        >
          <i className="fa-solid fa-angle-left back-icon text-dark"></i>
        </button>
        <span className="text-uppercase fw:500 text-dark">
          Bidbox Community
        </span>
        <div className="n"></div>
      </header>
      <div className="room-chat-dialog">
        <div className="room-chat-content">
          <div className="chat-box-bubble chat-outer">
            <div className="chat-box-bubble-avatar">
              <i className="fas fa-user"></i>
            </div>
            <div className="chat-box-bubble-details">
              <div className="chat-box-bubble-profile">
                <h1 className="name-profile-user">Username</h1>
                <p className="chat-real-time">10:00</p>
              </div>
              <p className="chat-box-bubble-description">
                Hallo guys, Gimana kabarnya?
              </p>
            </div>
          </div>
          <div className="chat-box-bubble chat-inner">
            <div className="chat-box-bubble-avatar">
              <i className="fas fa-user"></i>
            </div>
            <div className="chat-box-bubble-details">
              <div className="chat-box-bubble-profile">
                <h1 className="name-profile-user">Username</h1>
                <p className="chat-real-time">10:00</p>
              </div>
              <p className="chat-box-bubble-description">Sehat brodihh</p>
            </div>
          </div>
        </div>
        <div className="chat-box-send room-chat-box-send">
          <div
            className={`chat-box-input-message ${
              activeInput ? "input-message-active" : "input-message-disactive"
            }`}
          >
            <input
              type="text"
              className="text-input-chat"
              placeholder="Ketik pesan anda..."
              onChange={getValue}
            />
            <button
              className={`btn-send-message ${
                activeInput ? "enabled" : "disabled"
              }`}
            >
              <i className="fas fa-paper-plane"></i>
            </button>
          </div>
          <div
            className={`chat-box-input-other ${
              activeInput ? "hide-on-input-active" : "show-on-input-disactive"
            }`}
          >
            <button className="btn-input-other" onClick={getFile}>
              <input type="file" hidden ref={GetFileRef} />
              <i className="fas fa-image"></i>
            </button>
            <button className="btn-input-other">
              <i className="fas fa-smile-beam"></i>
            </button>
            <button className="btn-input-other">
              <i className="fas fa-heart"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomChat;
