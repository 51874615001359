import { takeEvery, put, call } from "redux-saga/effects";

import {
    BOT_SETTING,
    BOT_STATUS,
    BOT_PLUGIN,
    BOT_UPGRADE,
    BOT_SETTING_STATUS,
    SET_REQUEST_TRADE,
} from "./actionTypes";
import {
    botSettingStatusSuccess,
    botSettingStatusError,
    upgradePluginSuccess,
    botStatusSuccess,
    botPluginSuccess,
    botSettingSuccess,
    botSettingError,
    upgradePluginError,
    botPluginError,
    botStatusError,
    setRequestTradeSuccess,
    setRequestTradeError,
} from "./actions";

import {
    getBotPlugin as apiGetBotPlugin,
    botSetting as apiBotSetting,
    upgradePlugin as apiUpgradePlugin,
    botStatus as apiBotStatus,
    getBotAllSetting as apiGetBotSetting,
} from "src/api";

function* botStatus() {
    try {
        const response = yield call(apiBotStatus);
        const data = response.data;

        let tmp_date = new Date(0);
        tmp_date.setUTCSeconds(data.expired_at);
        const data_new = {
            ...data,
            bot_expired_at: data.expired_at,
            bot_expired_at_date: tmp_date.toLocaleDateString("en-US"),
        };
        yield put(botStatusSuccess(data_new));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        if (error.response.status === 404) {
            yield put(botStatusError(null));
        } else {
            yield put(botStatusError(message));
        }
    }
}
function* botSetting({ payload }) {
    try {
        yield call(apiBotSetting, payload);
        yield put(botSettingSuccess("successfully save bot settings"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(botSettingError(message));
    }
}
function* getBotSetting() {
    try {
        const response = yield call(apiGetBotSetting);
        let data = {};
        response.data.bot_settings.forEach((el) => {
            if (el.exchange_id === 1) {
                data.binance = el;
            } else {
                data.tokocrypto = el;
            }
        });
        yield put(botSettingStatusSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(botSettingStatusError(message));
    }
}
function* botPlugin() {
    try {
        const response = yield call(apiGetBotPlugin);
        const data = response.data;
        yield put(
            botPluginSuccess({
                itemPlugins: data.items,
            })
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(botPluginError(message));
    }
}

function* upgradePlugin({ payload }) {
    try {
        yield call(apiUpgradePlugin, payload);
        yield put(upgradePluginSuccess("Upgrade Plugin Successfully"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(upgradePluginError(message));
    }
}

function* setRequestTrade({ typeRequest, payload }) {
    try {
        let message = "";
        let newPayload = {};
        if (typeRequest === "auto-averaging") {
            message = "Auto Averaging Successfully";
            newPayload = {
                autoAveraging: payload.allow_auto_averaging,
            };
        }
        yield call(apiBotSetting, payload);
        yield put(
            setRequestTradeSuccess({
                message: message,
                payload: newPayload,
            })
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(setRequestTradeError(message));
    }
}
function* authSaga() {
    yield takeEvery(BOT_UPGRADE, upgradePlugin);
    yield takeEvery(BOT_SETTING, botSetting);
    yield takeEvery(BOT_PLUGIN, botPlugin);
    yield takeEvery(BOT_STATUS, botStatus);
    yield takeEvery(BOT_SETTING_STATUS, getBotSetting);
    yield takeEvery(SET_REQUEST_TRADE, setRequestTrade);
}

export default authSaga;
