import { GAS_FEE, GAS_FEE_SUCCESS, GAS_FEE_ERROR } from "./actionTypes";

export const gasFeeHistory = (payload) => {
    return {
        type: GAS_FEE,
        payload: payload,
    };
};

export const gasFeeSuccess = (payload) => {
    return {
        type: GAS_FEE_SUCCESS,
        payload: payload,
    };
};
export const gasFeeError = (message) => {
    return {
        type: GAS_FEE_ERROR,
        payload: message,
    };
};
