import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    REGISTER_REQ_OTP,
    REGISTER_REQ_OTP_SUCCESS,
    REGISTER_REQ_OTP_ERROR,
    INIT_REGISTER_STORE,
} from "./actionTypes";

const initialState = {
    loadingRegister: false,
    loadingReqOtp: false,
    registerSuccessMsg: null,
    registerErrorMsg: null,
    regReqOtpSuccessMsg: null,
    regReqOtpErrorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER:
            state = {
                ...state,
                loadingRegister: true,
                registerSuccessMsg: null,
                registerErrorMsg: null,
            };
            break;
        case REGISTER_SUCCESS:
            state = {
                ...state,
                loadingRegister: false,
                registerSuccessMsg: action.payload,
                registerErrorMsg: null,
            };
            break;
        case REGISTER_ERROR:
            state = {
                ...state,
                loadingRegister: false,
                registerSuccessMsg: null,
                registerErrorMsg: action.payload,
            };
            break;

        case REGISTER_REQ_OTP:
            state = {
                ...state,
                loadingReqOtp: true,
                regReqOtpSuccessMsg: null,
                regReqOtpErrorMsg: null,
            };
            break;
        case REGISTER_REQ_OTP_SUCCESS:
            state = {
                ...state,
                loadingReqOtp: false,
                regReqOtpSuccessMsg: action.payload,
                regReqOtpErrorMsg: null,
            };
            break;
        case REGISTER_REQ_OTP_ERROR:
            state = {
                ...state,
                loadingReqOtp: false,
                regReqOtpSuccessMsg: null,
                regReqOtpErrorMsg: action.payload,
            };
            break;

        case INIT_REGISTER_STORE:
            state = {
                ...state,
                loadingReqOtp: false,
                loadingRegister: false,
                regReqOtpSuccessMsg: null,
                regReqOtpErrorMsg: null,
                registerSuccessMsg: null,
                registerErrorMsg: null,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
