import dataCoin from "src/assets/coin";

export const get_decimal_length = (val) => {
    try {
        let digit_kali = 10;
        let digit = 1;
        while (val * digit_kali !== 1) {
            digit_kali *= 10;
            digit++;
        }
        return digit;
    } catch (error) {
        console.log(error.message);
        return error;
    }
};
export const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
        return num; // if value < 1000, nothing to do
    }
};
export const numberWithCommas = (x) => {
    return x;
    //   return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
export const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};
export const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
};
export const searchIcon = async (base_asset) => {
    const get_icon_local = localStorage.getItem(`bidbox_icon_${base_asset}`);
    if (get_icon_local !== undefined && get_icon_local !== null)
        return get_icon_local;

    let image = "";
    await dataCoin
        .filter((key) => key.symbol === base_asset)
        .forEach((el) => {
            let id = el.id;
            if (el.symbol === "SOL") id = 5426;
            image = `https://s2.coinmarketcap.com/static/img/coins/64x64/${id}.png`;
        });
    localStorage.setItem(`bidbox_icon_${base_asset}`, image);
    return image;
};
export const rankConvert = (rank) => {
    switch (rank) {
        case "M0":
            return "VIP0";
        case "M1":
            return "VIP1";
        case "M2":
            return "VIP2";
        case "M3":
            return "VIP3";
        case "M4":
            return "VIP4";
        case "M5":
            return "VIP5";
        case "M6":
            return "VIP6";
        default:
            return rank;
    }
};
