import apis from 'src/utils/apis'
import axios from 'axios'
import {configStrict} from './config'
// activation bot
export const activationBot = async () => {
    // No Payload
    let config = await configStrict({
        method:'get',
        url:apis.API_SALES_ACTIVATION
    })
    let res = await axios(config)
    return res
}

// bot status
export const botStatus = async () => {
    // No Payload
    let config = await configStrict({
        method:'get',
        url:apis.API_BOT_STATUS,
    })
    let res = await axios(config)
    return res
}

// upgrade plugin
export const upgradePlugin = async (payload) => {
    /* Payload
    {
        "plugin_id": 1
    }
    */
    let config = await configStrict({
        method:'post',
        url:apis.API_UPGRADE_PLUGIN,
        headers: {
            "Content-Type": "application/json",
        },
        data:JSON.stringify(payload)
    })
    let res = await axios(config)
    return res
}