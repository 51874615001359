import { takeEvery, put, call } from "redux-saga/effects";
import { searchIcon } from "src/utils/helpers";
import { PORTOFOLIO } from "./actionTypes";
import { portofolioSuccess, portofolioError } from "./actions";

import { getPortofolio as apiGetPortofolio } from "src/api";
function* getPortofolio({ payload }) {
    try {
        const response = yield call(apiGetPortofolio, payload);
        const data = response.data;
        if (data.items === null) data.items = [];
        if (data.items !== null) {
            data.items.forEach(async (el) => {
                el.image = await searchIcon(el.base_asset);
            });
        }
        yield put(portofolioSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(portofolioError(message));
    }
}

function* authSaga() {
    yield takeEvery(PORTOFOLIO, getPortofolio);
}

export default authSaga;
