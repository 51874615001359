import { takeEvery, put, call } from "redux-saga/effects";

import {
    AFFILIATE_STATISTICS,
    AFFILIATE_ACTIVE_FRONTLINES,
} from "./actionTypes";
import {
    affiliateStatisticsSuccess,
    affiliateStatisticsError,
    affiliateActiveFrontlinesSuccess,
    affiliateActiveFrontlinesError,
} from "./actions";

import {
    getAffiliateStatistics as apiGetAffiliateStatistics,
    getAffiliateActiveFrontlines as apiGetAffiliateActiveFrontlines,
} from "src/api";
function* getAffiliateStatistics() {
    try {
        const response = yield call(apiGetAffiliateStatistics);
        const data = response.data;
        yield put(affiliateStatisticsSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(affiliateStatisticsError(message));
    }
}
function* getAffiliateActiveFrontlines() {
    try {
        const response = yield call(apiGetAffiliateActiveFrontlines);
        const data = response.data;
        yield put(affiliateActiveFrontlinesSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(affiliateActiveFrontlinesError(message));
    }
}

function* authSaga() {
    yield takeEvery(AFFILIATE_STATISTICS, getAffiliateStatistics);
    yield takeEvery(AFFILIATE_ACTIVE_FRONTLINES, getAffiliateActiveFrontlines);
}

export default authSaga;
