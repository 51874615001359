import LogoHorizontal from "src/assets/image/logo/Bidbox-logo-horizontal.png";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
const Home = (props) => {
    useEffect(() => {
        props.history.push("/");
    }, []);
    return <div></div>;
};
export default Home;
