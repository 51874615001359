import React from "react";
import { useLocation } from "react-router-dom";

const Newsdetail = () => {
    const location = useLocation();
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    {location.pathname === "/user/order-message"
                        ? "Order Message"
                        : "System Information"}
                </span>
                <div className="n"></div>
            </header>
            <div className="dbhs-content">
                {/* <div
                    className="card mb-1"
                    style={{
                        background: "rgb(241, 241, 241)",
                        border: "1px solid rgba(0, 139, 174, 0.376)",
                    }}
                >
                    <div className="card-body py-1">
                        <p className="m-0 fz:13 text-dark">
                            There are new buy in BINANCE AKRO/USDT Transaction
                            <br />
                            price 0.012345 Quantity 12345 Pending sell position
                            <br />
                            price 0.01234
                        </p>
                    </div>
                </div>
                <div
                    className="card mb-1"
                    style={{
                        background: "rgb(241, 241, 241)",
                        border: "1px solid rgba(0, 139, 174, 0.376)",
                    }}
                >
                    <div className="card-body py-1">
                        <p className="m-0 fz:13 text-dark">
                            There are new buy in BINANCE AKRO/USDT Transaction
                            <br />
                            price 0.012345 Quantity 12345 Pending sell position
                            <br />
                            price 0.01234
                        </p>
                    </div>
                </div>
                <div
                    className="card mb-1"
                    style={{
                        background: "rgb(241, 241, 241)",
                        border: "1px solid rgba(0, 139, 174, 0.376)",
                    }}
                >
                    <div className="card-body py-1">
                        <p className="m-0 fz:13 text-dark">
                            There are new sell in BINANCE AKRO/USDT Average
                            <br />
                            transaction price 0.012345 quantity 12345 average
                            <br />
                            position price 0.01234
                        </p>
                    </div>
                </div>
                <div className="date-time-message">
                    <span>2022-07-17 07:45</span>
                </div>
                <div
                    className="card mb-1"
                    style={{
                        background: "rgb(241, 241, 241)",
                        border: "1px solid rgba(0, 139, 174, 0.376)",
                    }}
                >
                    <div className="card-body py-1">
                        <p className="m-0 fz:13 text-dark">
                            There are new buy in BINANCE AKRO/USDT Transaction
                            <br />
                            price 0.012345 Quantity 12345 Pending sell position
                            <br />
                            price 0.01234
                        </p>
                    </div>
                </div>
                <div
                    className="card mb-1"
                    style={{
                        background: "rgb(241, 241, 241)",
                        border: "1px solid rgba(0, 139, 174, 0.376)",
                    }}
                >
                    <div className="card-body py-1">
                        <p className="m-0 fz:13 text-dark">
                            There are new sell in BINANCE AKRO/USDT Average
                            <br />
                            transaction price 0.012345 quantity 12345 average
                            <br />
                            position price 0.01234
                        </p>
                    </div>
                </div>
                <div className="date-time-message">
                    <span>2022-07-17 07:43</span>
                </div> */}
            </div>
        </div>
    );
};

export default Newsdetail;
