import { searchIcon } from "src/utils/helpers";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import toast from "react-hot-toast";
import Modal from "../../components/modal/Modal";
import ModalAVG from "../../components/modal/ModalAVG";
import ModalTable from "../../components/modal/ModalTable";
import { capitalizeFirstLetter } from "src/utils/helpers";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingBidbox } from "../../components";
import {
    insertTrades,
    removeTrades,
    getTrades,
    getBotSetting,
    updateTrades,
    tradesCancel,
    tradesSell,
    clearTradesMessage,
    tradesSync,
    tradesAvg,
    getTickSize,
    tradesGetCurrentPrice,
} from "src/store/actions";
import { Centrifuge } from "centrifuge";
import { getToken } from "src/api/config";
import { toFixedIfNecessary } from "src/utils/helpers";
import copy from "copy-to-clipboard";
const Trades = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.Account);
    const bot = useSelector((state) => state.Bot);
    const trades = useSelector((state) => state.Trades);
    const [tradesAvailable, setTradesAvailable] = useState(false);
    const [decimalLength, setDecimalLength] = useState(0);
    const [avgBuyPrice, setAvgBuyPrice] = useState(0);
    const [pairSelected, setPairSelected] = useState({
        base_asset: null,
        quote_asset: null,
    });
    const [targetProfit, setTargetProfit] = useState(1.2);
    const [tickSize, setTickSize] = useState({});
    const [priceChangeAVG, setPriceChangeAVG] = useState({
        price: 0,
        price_chg: 0,
    });
    const [modalAVG, setModalAVG] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });
    const [generalModal, setGeneralModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });

    const [tableModal, setTableModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
        data: {},
    });
    let centrifuge = useRef(null);
    let symbolAVG = useRef(null);
    useEffect(() => {
        dispatch(getBotSetting());
        dispatch(getTrades());
    }, []);
    useEffect(() => {
        if (trades.trades !== null) {
            if (tradesAvailable === false) {
                setTradesAvailable(true);
            }
        }
    }, [trades.trades]);
    useEffect(() => {
        if (trades.successMsg !== null) toast(trades.successMsg);
        if (trades.errorMsg !== null) toast(trades.errorMsg);
        dispatch(clearTradesMessage());
    }, [trades.successMsg, trades.errorMsg]);
    useEffect(() => {
        if (account.id === null) return;
        async function startSocket() {
            const token = await getToken();
            centrifuge.current = new Centrifuge(process.env.REACT_APP_WS_URL, {
                token: token,
            });

            // Allocate Subscription to a channel.
            centrifuge.sub = centrifuge.current.newSubscription(
                "cfPriceChangeBinance"
            );
            centrifuge.new = centrifuge.current.newSubscription(
                `cfTrade_new_${account.id}`
            );
            centrifuge.buy = centrifuge.current.newSubscription(
                `cfTrade_buy_filled_${account.id}`
            );
            centrifuge.cancel_buy = centrifuge.current.newSubscription(
                `cfTrade_cancel_buy_${account.id}`
            );
            centrifuge.cut = centrifuge.current.newSubscription(
                `cfTrade_cut_${account.id}`
            );
            centrifuge.complete = centrifuge.current.newSubscription(
                `cfTrade_complete_${account.id}`
            );

            // Trigger subscribe process.
            centrifuge.sub.subscribe();
            centrifuge.new.subscribe();
            centrifuge.buy.subscribe();
            centrifuge.cancel_buy.subscribe();
            centrifuge.cut.subscribe();
            centrifuge.complete.subscribe();

            // Trigger actual connection establishement.
            centrifuge.current.connect();
            centrifuge.sub.on("subscribed", (ctx) => {
                centrifuge.status = "subscribed";
            });
            centrifuge.sub.on("subscribing", (ctx) => {
                centrifuge.status = "subscribing";
            });
            centrifuge.sub.on("unsubscribed", (ctx) => {
                centrifuge.status = "unsubscribed";
            });
            centrifuge.new.on("publication", async (ctx) => {
                const data = ctx.data;
                const symbol = `${data.base_asset}${data.quote_asset}`;
                data.image = await searchIcon(data.base_asset);
                data.symbol = symbol;
                dispatch(insertTrades(data));
                if (symbol !== "") {
                    dispatch(tradesGetCurrentPrice([symbol]));
                }
            });
            centrifuge.buy.on("publication", (ctx) => {
                const data = ctx.data;
                dispatch(
                    updateTrades(
                        {
                            whereBy: "action",
                            payload: data,
                        },
                        {
                            status: 2,
                            status_name: "PENDING_SELL",
                        }
                    )
                );
            });
            centrifuge.complete.on("publication", (ctx) => {
                const data = ctx.data;
                dispatch(removeTrades(data));
            });
            centrifuge.cancel_buy.on("publication", (ctx) => {
                const data = ctx.data;
                dispatch(removeTrades(data));
            });
            centrifuge.cut.on("publication", (ctx) => {
                const data = ctx.data;
                dispatch(removeTrades(data));
            });
            centrifuge.sub.on("publication", function (ctx) {
                const data = ctx.data;
                data.forEach((el) => {
                    dispatch(
                        updateTrades(
                            {
                                whereBy: "symbol",
                                payload: el.symbol,
                            },
                            el
                        )
                    );
                    if (symbolAVG.current !== null) {
                        if (symbolAVG.current === el.symbol) {
                            setPriceChangeAVG(el);
                        }
                    }
                });
            });
        }
        startSocket();
    }, [tradesAvailable, account.id]);
    const confirmTrades = (params) => {
        let message = "";
        let title = "";
        if (params.status_name === "PENDING_BUY") {
            message =
                "by pressing the cancel button, you will cancel any buy order open positions.";
            // title = "Cancel";
        }
        if (params.status_name === "PENDING_SELL") {
            message =
                "by pressing the sell button, you will authorize a sell manually out of the system's order and it may incur losses when executed while the price is floating.";
            // title = "Sell";
        }
        if (params.status_name === "AVERAGING") {
            message =
                "by pressing the sell button, you will authorize a sell manually out of the system's order and it may incur losses when executed while the price is floating.";
            // title = "Sell";
        }
        setGeneralModal({
            isVisible: true,
            message: message,
            title: title,
            buttons: [
                {
                    label: "Confirm",
                    className: "btn-modal",
                    action: () => {
                        if (params.status_name === "PENDING_BUY") {
                            dispatch(
                                tradesCancel({
                                    trade_id: params.id,
                                    exchange_id: params.exchange_id,
                                })
                            );
                        } else if (params.status_name === "PENDING_SELL") {
                            dispatch(
                                tradesSell({
                                    trade_id: params.id,
                                    exchange_id: params.exchange_id,
                                })
                            );
                        } else if (params.status_name === "AVERAGING") {
                            dispatch(
                                tradesSell({
                                    trade_id: params.avg_id,
                                    exchange_id: params.exchange_id,
                                })
                            );
                        }
                        setGeneralModal({ isVisible: false });
                    },
                },
                {
                    label: "Cancel",
                    className: "btn-modal bg-transparent text-app",
                    action: () => {
                        setGeneralModal({ isVisible: false });
                    },
                },
            ],
        });
    };
    const confirmSync = (params) => {
        let id = params.id;
        if (params.status_name === "AVERAGING") id = params.avg_id;
        setGeneralModal({
            isVisible: true,
            message:
                "This command is used to synchronize your open transaction orders with Binance, if there is a difference",
            title: "Synchronize",
            buttons: [
                {
                    label: "Confirm",
                    className: "btn-modal",
                    action: () => {
                        dispatch(
                            tradesSync({
                                trade_id: id,
                                exchange_id: params.exchange_id,
                            })
                        );
                        setGeneralModal({ isVisible: false });
                    },
                },
                {
                    label: "Cancel",
                    className: "btn-modal bg-transparent text-app",
                    action: () => {
                        setGeneralModal({ isVisible: false });
                    },
                },
            ],
        });
    };
    const copyOrderId = (params) => {
        let id = params.id;
        if (params.status_name === "AVERAGING") id = params.avg_id;

        copy(id);
        toast("Order ID has been copied");
    };
    useEffect(() => {
        if (pairSelected.base_asset !== null) {
            const base_asset = pairSelected.base_asset;
            const quote_asset = pairSelected.quote_asset;

            let get_tick_size = localStorage.getItem(
                `bidbox_user_ticksize_${base_asset}${quote_asset}`
            );
            if (get_tick_size !== null) {
                get_tick_size = JSON.parse(get_tick_size);
                let obj = {};
                obj[`${base_asset}${quote_asset}`] = get_tick_size;
                setTickSize({
                    ...tickSize,
                    ...obj,
                });
                setModalAVG({
                    ...modalAVG,
                    isVisible: true,
                });
                // setPriceBuy(
                //     parseFloat(priceBuy).toFixed(get_tick_size.decimalLength)
                // );
                // setPriceSell(
                //     parseFloat(priceSell).toFixed(get_tick_size.decimalLength)
                // );
            } else {
                alert("Tick Size Not Found");
            }
        }
    }, [tickSize,trades.tick_size,modalAVG,pairSelected.base_asset,pairSelected.quote_asset]);
    useEffect(() => {
        if (trades.successMsg === "Averaging successfully") {
            dispatch(getTrades());
        }
    }, [dispatch,trades.successMsg]);
    const showModalAvg = (params) => {
        const symbol = `${params.base_asset}${params.quote_asset}`;
        setPairSelected({
            base_asset: params.base_asset,
            quote_asset: params.quote_asset,
        });

        let get_tick_size = localStorage.getItem(
            `bidbox_user_ticksize_${params.base_asset}${params.quote_asset}`
        );
        if (get_tick_size === null) {
            dispatch(
                getTickSize({
                    base_asset: params.base_asset,
                    quote_asset: params.quote_asset,
                })
            );
        }

        let id = params.id;
        symbolAVG.current = `${params.base_asset}${params.quote_asset}`;
        setAvgBuyPrice(0);
        if (typeof trades.current_price[symbol] == "undefined") {
            if (symbol !== "") {
                dispatch(tradesGetCurrentPrice([symbol]));
            }

            setPriceChangeAVG({
                symbol: "",
                price: "",
            });
        } else {
            setPriceChangeAVG({
                symbol: symbol,
                price: trades.current_price[symbol],
            });
        }
        let _decimalLength = JSON.parse(get_tick_size).decimalLength;
        setDecimalLength(_decimalLength);
        let buy_in_amount = 0;
        let avg_sell_price = 0;
        setModalAVG({
            title: "Averaging",
            isVisible: get_tick_size !== null,
            data: {
                ...modalAVG.data,
                pair: `${params.base_asset}/${params.quote_asset}`,
                symbol: `${params.base_asset}${params.quote_asset}`,
                buy_price: params.buy_price,
                sell_price: params.sell_price,
                position_amount: params.fba,
                position_profit_and_lose: 0,
                avg_buy_price: params.buy_price,
                avg_sell_price: 0,
                avg_tp: 1.5,
                avg_qty: 0,
                avg_profit_and_lose: 0,
                avg_total_qty: 0,
                modal_qty: 0,
                focus: "",
            },
            buyOnChange: (e) => {
                buy_in_amount = e.target.value;
                setAvgBuyPrice(buy_in_amount);
            },
            tpOnChange: (e) => {
                setTargetProfit(e.target.value);
            },
            sellOnChange: (v) => {
                avg_sell_price = v;
            },
            buttons: [
                {
                    label: "Confirm",
                    className: "btn-modal",
                    action: (sell_price, buy_price) => {
                        dispatch(
                            tradesAvg({
                                buy_in_amount: parseFloat(
                                    parseFloat(buy_in_amount).toFixed(
                                        _decimalLength
                                    )
                                ),
                                exchange_id: params.exchange_id,
                                sell_price: parseFloat(
                                    parseFloat(avg_sell_price).toFixed(
                                        _decimalLength
                                    )
                                ),
                                trade_id: id,
                            })
                        );
                        setModalAVG({ isVisible: false });
                    },
                },
                {
                    label: "Cancel",
                    className: "btn-modal bg-transparent text-app",
                    action: (sell_price, buy_price) => {
                        setModalAVG({ isVisible: false });
                    },
                },
            ],
        });
    };
    return (
        <div className="trades-content content-on">
            {trades.loading && <LoadingBidbox />}
            <Banner />
            <div className="card bg-app-color text-white mb-2 overflow-x:auto">
                <div className="card-body">
                    <div className="d-grid grid-column:3">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        Buy-in Amount
                                    </td>
                                    <td className="fz:11 text-white">:</td>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        {
                                            bot.binance.bot_setting
                                                .open_position_amount
                                        }{" "}
                                        USDT
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        Signal Quantity
                                    </td>
                                    <td className="fz:11 text-white">:</td>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        {
                                            bot.binance.bot_setting
                                                .max_pending_order
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        Signal Mode
                                    </td>
                                    <td className="fz:11 text-white">:</td>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        {capitalizeFirstLetter(
                                            bot.binance.bot_setting
                                                .signal_mode_name
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        Signal Option
                                    </td>
                                    <td className="fz:11 text-white">:</td>
                                    <td className="fz:11 text-white whitespace-nowrap">
                                        {bot.plugin_name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-end">
                            <Link
                                to="/user/transaction"
                                className="d-flex flex-column align-items-center justify-content-center text-white text-decoration-none"
                            >
                                <i className="bx bx-book-reader fz:22"></i>
                                <span className="fz:11 fw:600">
                                    Transaction
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card border-0" style={{ background: "#f0f2ff" }}>
                <div className="card-body p-2">
                    <div
                        className="breadcrumb p-0 m-0"
                        style={{ listStyle: "none", float: "left" }}
                    >
                        <li className="fz:14 fw:600 text-red mr-1 text-uppercase">
                            live
                        </li>
                        <li
                            className="breadcrumb-item fz:12 bg-app-color fw:400 text-white px-2"
                            style={{
                                borderRadius: "8px",
                                paddingTop: "1px",
                                paddingBottom: "1px",
                            }}
                        >
                            Binance
                        </li>
                        <li className="breadcrumb-item fz:12 fw:600 text-dark">
                            Tokocrypto
                        </li>
                    </div>
                    <span
                        className="breadcrumb-item fz:12 fw:600 text-dark"
                        style={{
                            float: "right",
                            marginTop: "16px",
                            marginRight: 5,
                        }}
                    >
                        Open Order (
                        {trades.trades &&
                            trades.trades.filter(
                                (key) =>
                                    !(
                                        key.avg_for !== "" ||
                                        (key.status_name === "AVERAGING" &&
                                            (key.avg_id === null ||
                                                key.avg_id === undefined))
                                    )
                            ).length}
                        )
                    </span>
                    <br />

                    <div className="mt-3 mb-2">
                        {trades.trades !== null &&
                            trades.trades.map((key, index) => {
                                if (
                                    key.avg_for !== "" ||
                                    (key.status_name === "AVERAGING" &&
                                        (key.avg_id === null ||
                                            key.avg_id === undefined))
                                ) {
                                    return false;
                                }
                                return (
                                    <div
                                        key={index}
                                        className="card card-trade-list mb-2"
                                        onClick={(e) => {
                                            if (e.target.nodeName === "DIV") {
                                                setTableModal({
                                                    isVisible: true,
                                                    title: "Order Detail",
                                                    message: "",
                                                    data: key,
                                                    buttons: [
                                                        {
                                                            label: "Close",
                                                            className:
                                                                "btn-modal",
                                                            action: () => {
                                                                setTableModal({
                                                                    isVisible: false,
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            }
                                        }}
                                    >
                                        <div
                                            style={
                                                trades.loading_sync &&
                                                trades.loading_sync === key.id
                                                    ? {
                                                          borderRadius: 10,
                                                          width: "100%",
                                                          height: "100%",
                                                          position: "absolute",
                                                          textAlign: "center",
                                                          verticalAlign:
                                                              "baseline",
                                                          color: "white",
                                                          padding: "50px",
                                                          backgroundColor:
                                                              "rgba(0, 0, 0, 0.4)",
                                                          backdropFilter:
                                                              "blur(2)",
                                                      }
                                                    : {
                                                          borderRadius: 10,
                                                          width: "100%",
                                                          padding: "50px",
                                                          height: "100%",
                                                          position: "absolute",
                                                          textAlign: "center",
                                                          verticalAlign:
                                                              "baseline",
                                                          color: "white",
                                                          backgroundColor:
                                                              "rgba(0, 0, 0, 0.4)",
                                                          backdropFilter:
                                                              "blur(2)",
                                                          display: "none",
                                                      }
                                            }
                                        >
                                            <span>Synchronizing...</span>
                                        </div>
                                        <div className="card-body">
                                            <div
                                                className="d-flex flex-wrap align-items-center justify-content-between mb-1"
                                                style={{
                                                    borderBottom:
                                                        "1px solid rgba(0,0,0,0.06)",
                                                    paddingBottom: "3px",
                                                }}
                                            >
                                                <div className="trade-icon-flex">
                                                    <div className="icon">
                                                        {key.image && (
                                                            <img
                                                                src={key.image}
                                                                alt="coin-img"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="d-block">
                                                        <div className="d-flex align-items-center">
                                                            <h1
                                                                className="m-0 fz:16 fw:600 text-dark text-uppercase"
                                                                style={{
                                                                    paddingRight: 5,
                                                                }}
                                                            >
                                                                {key.base_asset}{" "}
                                                                /{" "}
                                                            </h1>
                                                            <h1 className="m-0 fz:12 fw:500 text-gray text-uppercase">
                                                                {
                                                                    key.quote_asset
                                                                }
                                                            </h1>

                                                            <h1
                                                                className={
                                                                    key.avg_status !==
                                                                    "active"
                                                                        ? "m-0 fz:12 fw:500 text-uppercase text-gray"
                                                                        : "m-0 fz:12 fw:500 text-uppercase"
                                                                }
                                                                style={
                                                                    key.avg_status ===
                                                                    "active"
                                                                        ? {
                                                                              color: "#008aae",
                                                                          }
                                                                        : null
                                                                }
                                                            >
                                                                &nbsp;
                                                                {key.avg_status &&
                                                                key.avg_status !==
                                                                    null
                                                                    ? "AVG"
                                                                    : ""}
                                                            </h1>
                                                        </div>
                                                        <h1 className="m-0 fz:11 fw:500 text-dark">
                                                            Price:{" "}
                                                            <span
                                                                className={
                                                                    key.price_chg >
                                                                    0
                                                                        ? `fz:12 fw-500 text-green`
                                                                        : key.price_chg <
                                                                          0
                                                                        ? `fz:12 fw-500 text-red`
                                                                        : `fz:12 fw-500 text-mute`
                                                                }
                                                            >
                                                                {!isNaN(
                                                                    key.price_current
                                                                ) &&
                                                                    toFixedIfNecessary(
                                                                        key.price_current,
                                                                        8
                                                                    )}
                                                            </span>
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex flex-column align-items-end"
                                                    style={{
                                                        rowGap: "2px",
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            key.profit_percent >
                                                            0
                                                                ? "badge-trades bg-green py-0"
                                                                : key.profit_percent <
                                                                  0
                                                                ? "badge-trades bg-red py-0"
                                                                : "badge-trades py-0"
                                                        }
                                                        style={{
                                                            width: "132.6px",
                                                            height: "23.19px",
                                                        }}
                                                    >
                                                        {key.profit_percent !==
                                                        undefined
                                                            ? `${toFixedIfNecessary(
                                                                  key.profit_percent,
                                                                  8
                                                              )}%`
                                                            : "0.0%"}
                                                    </div>
                                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                        {key.status_name !==
                                                            "PENDING_BUY" &&
                                                            key.avg_status ===
                                                                null && (
                                                                <button
                                                                    className="btn-card-trades-primary py-0"
                                                                    style={{
                                                                        width: "48.9px",
                                                                        height: "23.19px",
                                                                        marginRight:
                                                                            "1px",
                                                                    }}
                                                                    onClick={() =>
                                                                        showModalAvg(
                                                                            key
                                                                        )
                                                                    }
                                                                >
                                                                    AVG
                                                                </button>
                                                            )}

                                                        <button
                                                            className="btn-card-trades-success py-0"
                                                            style={{
                                                                width: "10.30px",
                                                                height: "23.19px",
                                                                marginRight:
                                                                    "1px",
                                                            }}
                                                            onClick={() =>
                                                                confirmSync(key)
                                                            }
                                                        >
                                                            <i className="bx bx-refresh fz:20"></i>
                                                        </button>

                                                        <button
                                                            style={{
                                                                width: "48.9px",
                                                                height: "23.19px",
                                                                marginRight:
                                                                    "1px",
                                                            }}
                                                            onClick={() =>
                                                                confirmTrades(
                                                                    key
                                                                )
                                                            }
                                                            className={
                                                                key.status_name !==
                                                                "PENDING_BUY"
                                                                    ? "btn-card-trades-primary py-0"
                                                                    : "btn-card-trades py-0"
                                                            }
                                                        >
                                                            {key.status_name ===
                                                            "PENDING_BUY"
                                                                ? "Cancel"
                                                                : "Sell"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex flex-wrap justify-content-between"
                                                style={{ marginBottom: "5px" }}
                                            >
                                                <div className="d-flex flex-column">
                                                    <h1 className="mb-1 fz:11 fw:500 text-dark">
                                                        Quantity:{" "}
                                                        {key.avg_status ===
                                                        "active"
                                                            ? key.buy_qty +
                                                              key.avg_qty
                                                            : key.buy_qty}
                                                    </h1>
                                                    <h1 className="m-0 fz:11 fw:500 text-dark">
                                                        Buy in Price:{" "}
                                                        {key.avg_status ===
                                                        "active"
                                                            ? toFixedIfNecessary(
                                                                  (key.avg_buy_price +
                                                                      key.buy_price) /
                                                                      2,
                                                                  8
                                                              )
                                                            : toFixedIfNecessary(
                                                                  key.buy_price,
                                                                  8
                                                              )}
                                                    </h1>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h1 className="m-0 fz:11 fw:500 text-dark">
                                                        PNL:{" "}
                                                        <span
                                                            className={
                                                                key.profit < 0
                                                                    ? `text-red`
                                                                    : key.profit >
                                                                      0
                                                                    ? `text-green`
                                                                    : `text-mute`
                                                            }
                                                        >
                                                            {`${toFixedIfNecessary(
                                                                key.profit,
                                                                8
                                                            )} ${
                                                                key.quote_asset
                                                            }`}
                                                        </span>
                                                    </h1>
                                                    <h1 className="m-0 fz:11 fw:500 text-dark">
                                                        Chg:{" "}
                                                        <span
                                                            className={
                                                                key.price_chg <
                                                                0
                                                                    ? `text-red`
                                                                    : key.price_chg >
                                                                      0
                                                                    ? `text-green`
                                                                    : `text-mute`
                                                            }
                                                        >
                                                            {key.price_chg !==
                                                            undefined
                                                                ? `${toFixedIfNecessary(
                                                                      key.price_chg,
                                                                      8
                                                                  )}%`
                                                                : "0.00%"}
                                                        </span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <h1
                                                    className="m-0 fz:8 text-gray"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        copyOrderId(key)
                                                    }
                                                >
                                                    Order ID :{" "}
                                                    {key.avg_status === "active"
                                                        ? key.avg_id.replace(
                                                              "bidbox_",
                                                              ""
                                                          )
                                                        : key.id.replace(
                                                              "bidbox_",
                                                              ""
                                                          )}
                                                </h1>

                                                <h1 className="m-0 fz:8 text-gray">
                                                    Signal ID : {key.signal_id}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            {generalModal.isVisible && (
                <Modal
                    message={generalModal.message}
                    title={generalModal.title}
                    buttons={generalModal.buttons}
                />
            )}

            {tableModal.isVisible && (
                <ModalTable
                    message={tableModal.message}
                    title={tableModal.title}
                    buttons={tableModal.buttons}
                    data={tableModal.data}
                />
            )}
            {modalAVG.isVisible && (
                <ModalAVG
                    message={modalAVG.message}
                    title={modalAVG.title}
                    data={modalAVG.data}
                    buyOnChange={modalAVG.buyOnChange}
                    tpOnChange={modalAVG.tpOnChange}
                    sellOnChange={modalAVG.sellOnChange}
                    priceChange={priceChangeAVG}
                    buyPrice={avgBuyPrice}
                    targetProfit={targetProfit}
                    buttons={modalAVG.buttons}
                    decimalLength={decimalLength}
                />
            )}
        </div>
    );
};

export default Trades;
