export const WALLET = "WALLET";
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_ERROR = "WALLET_ERROR";
export const VALIDATE_DEPOSIT = "VALIDATE_DEPOSIT";
export const VALIDATE_DEPOSIT_SUCCESS = "VALIDATE_DEPOSIT_SUCCESS";
export const VALIDATE_DEPOSIT_ERROR = "VALIDATE_DEPOSIT_ERROR";
export const TRANSFER = "TRANSFER";
export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS";
export const TRANSFER_ERROR = "TRANSFER_ERROR";
export const TRANSFER_INIT = "TRANSFER_INIT";
export const WITHDRAW = "WITHDRAW";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";
export const WITHDRAW_INIT = "WITHDRAW_INIT";
