import axios from "axios";
import apis from "src/utils/apis";
import { configStrict } from "./config";
// daily bonus
export const getReward = async (from_date, to_date) => {
    let date = new Date();
    let config = await configStrict({
        method: "post",
        url: apis.API_DAILY_BONUS,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify({
            from_date: from_date,
            to_date: to_date,
        }),
    });
    let res = await axios(config);
    res.data.cumulativeProfit =
        res.data.direct_activation +
        res.data.direct_sp +
        res.data.pass_up_activation +
        res.data.pass_up_sp +
        res.data.peer_activation +
        res.data.peer_sp;
    res.data.todayProfit = 0;
    if (res.data.items === null) res.data.items = [];
    res.data.items.forEach((el) => {
        if (date.toLocaleDateString("en-CA") === el.bonus_date) {
            res.data.todayProfit +=
                el.direct_activation +
                el.direct_profit_sharing +
                el.global_deviden_activation +
                el.global_deviden_profit_sharing +
                el.pass_up_activation +
                el.pass_up_profit_sharing +
                el.peer_activation +
                el.peer_profit_sharing;
        }
        // res.data.cumulativeProfit += (
        //     el.direct_activation +
        //     el.direct_profit_sharing +
        //     el.global_deviden_activation +
        //     el.global_deviden_profit_sharing +
        //     el.pass_up_activation +
        //     el.pass_up_profit_sharing +
        //     el.peer_activation +
        //     el.peer_profit_sharing
        // )
    });
    return res;
};

// active frontlines
export const getAffiliateActiveFrontlines = async () => {
    let config = await configStrict({
        method: "get",
        url: apis.API_AFFILIATE_FRONTLINES,
        headers: {
            "Content-Type": "application/json",
        },
    });
    let res = await axios(config);
    return res;
};

// statistics
export const getAffiliateStatistics = async () => {
    let config = await configStrict({
        method: "get",
        url: apis.API_AFFILIATE_STATISTICS,
        headers: {
            "Content-Type": "application/json",
        },
    });
    let res = await axios(config);
    return res;
};
// user rank
export const getRank = async () => {
    let config = await configStrict({
        method: "get",
        url: apis.API_AFFILIATE_USER_RANK,
        headers: {
            "Content-Type": "application/json",
        },
    });
    let res = await axios(config);
    return res;
};
