import React, { useState, useEffect } from "react";

import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
    initRegisterStore,
    initAccount,
    changeEmail,
    userRequestOtp,
    requestOtpRegister,
} from "src/store/actions";
import { LoadingBidbox } from "../../components";
const ModifyEmail = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.Account);
    const register = useSelector((state) => state.Register);
    const [newEmail, setNewEmail] = useState("");
    const [otpNewEmail, setOtpNewEmail] = useState("");
    const [otpOldEmail, setOtpOldEmail] = useState("");
    const sendOtpNewEmail = () => {
        dispatch(
            requestOtpRegister({
                address: newEmail,
                target: 0,
                otp_context: 6,
            })
        );
    };
    const sendOtpOldEmail = () => {
        dispatch(
            userRequestOtp({
                otp_context: 6,
                target: 0,
            })
        );
    };
    const confirmModifyEmail = () => {
        if (newEmail === "") return toast("Please enter your new email");
        if (otpNewEmail === "")
            return toast("Please enter your otp code new email");
        if (otpOldEmail === "")
            return toast("Please enter your otp code old email");
        dispatch(
            changeEmail({
                new_email: newEmail,
                otp: otpOldEmail,
                otp_new: otpNewEmail,
                security_pin: "000000",
            })
        );
    };
    useEffect(() => {
        return () => {
            dispatch(initAccount());
            dispatch(initRegisterStore());
        };
    }, []);
    useEffect(() => {
        if (register.regReqOtpSuccessMsg !== null)
            toast(register.regReqOtpSuccessMsg);
        if (register.regReqOtpErrorMsg !== null)
            toast(register.regReqOtpErrorMsg);
    }, [register.regReqOtpSuccessMsg, register.regReqOtpErrorMsg]);
    useEffect(() => {
        if (account.successMsg !== null) toast(account.successMsg);
        if (account.errorMsg !== null) toast(account.errorMsg);
    }, [account.successMsg, account.errorMsg]);
    useEffect(() => {
        if (account.reqOtpSuccessMsg !== null) toast(account.reqOtpSuccessMsg);
        if (account.reqOtpErrorMsg !== null) toast(account.reqOtpErrorMsg);
    }, [account.reqOtpSuccessMsg, account.reqOtpErrorMsg]);
    return (
        <div className="details-content">
            {account.loading && <LoadingBidbox />}
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Modify Email
                </span>
                <div className="n"></div>
            </header>
            <h2 className="fz:17 fw-600 text-dark mb-2">
                New email address verification
            </h2>
            <div id="form-validation">
                <div className="field">
                    {/* <label htmlFor="new-email" className="label-control">
                        New email address
                    </label> */}
                    <input
                        type="email"
                        onChange={(e) => setNewEmail(e.target.value)}
                        id="new-email"
                        className="input-control"
                        placeholder="Input New Email"
                        required
                    />
                </div>
                <div className="field">
                    {/* <label htmlFor="new-email" className="label-control">
                        New email verification code
                    </label> */}
                    <input
                        type="email"
                        onChange={(e) => setOtpNewEmail(e.target.value)}
                        id="new-email"
                        className="input-control"
                        placeholder="Input OTP From New Email"
                        required
                    />
                    <button
                        type="button"
                        onClick={sendOtpNewEmail}
                        className="input-btn-absolute bg-slate-color-30 text-dark fw:600 px-3"
                    >
                        Send
                    </button>
                </div>
                <h2 className="fz:17 fw-600 text-dark mb-1 mt-4">
                    Safety verification
                </h2>
                <div className="field">
                    {/* <label
                        htmlFor="email-verification-code"
                        className="label-control"
                    >
                        Email verification code
                    </label> */}
                    <input
                        type="text"
                        onChange={(e) => setOtpOldEmail(e.target.value)}
                        id="email-verification-code"
                        className="input-control"
                        placeholder="Input OTP From Old Email"
                        required
                    />
                    <button
                        type="button"
                        onClick={sendOtpOldEmail}
                        className="input-btn-absolute bg-slate-color-30 text-dark fw:600 px-3"
                    >
                        Send
                    </button>
                </div>
                <button
                    onClick={confirmModifyEmail}
                    className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white text-uppercase"
                >
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default ModifyEmail;
