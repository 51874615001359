import { NEWS, NEWS_SUCCESS, NEWS_ERROR } from "./actionTypes";

export const getNews = () => {
    return {
        type: NEWS,
    };
};

export const newsSuccess = (message) => {
    return {
        type: NEWS_SUCCESS,
        payload: message,
    };
};

export const newsError = (message) => {
    return {
        type: NEWS_ERROR,
        payload: message,
    };
};
