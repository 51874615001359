import {
    REQUEST_RESET_PASSWORD,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_INIT,
} from "./actionTypes";

const initialState = {
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD:
        case REQUEST_RESET_PASSWORD:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case RESET_PASSWORD_INIT:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case RESET_PASSWORD_SUCCESS:
        case REQUEST_RESET_PASSWORD_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case RESET_PASSWORD_ERROR:
        case REQUEST_RESET_PASSWORD_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
