import {
    SERVER_IP,
    SERVER_IP_SUCCESS,
    SERVER_IP_ERROR,
    IP_BINDING,
    IP_BINDING_SUCCESS,
    IP_BINDING_ERROR,
    UNBOUND,
    UNBOUND_SUCCESS,
    UNBOUND_ERROR,
    IP_BINDING_INIT,
} from "./actionTypes";

export const initIPBinding = () => {
    return {
        type: IP_BINDING_INIT,
    };
};

export const unbound = (payload) => {
    return {
        type: UNBOUND,
        payload: payload,
    };
};
export const setBinding = (payload) => {
    return {
        type: IP_BINDING,
        payload: payload,
    };
};
export const getServerIP = () => {
    return {
        type: SERVER_IP,
    };
};
export const serverIPSuccess = (message) => {
    return {
        type: SERVER_IP_SUCCESS,
        payload: message,
    };
};

export const serverIPError = (message) => {
    return {
        type: SERVER_IP_ERROR,
        payload: message,
    };
};

export const bindingSuccess = (message) => {
    return {
        type: IP_BINDING_SUCCESS,
        payload: message,
    };
};

export const bindingError = (message) => {
    return {
        type: IP_BINDING_ERROR,
        payload: message,
    };
};

export const unboundSuccess = (message) => {
    return {
        type: UNBOUND_SUCCESS,
        payload: message,
    };
};

export const unboundError = (message) => {
    return {
        type: UNBOUND_ERROR,
        payload: message,
    };
};
