import MaintenanceImage from "src/assets/image/maintenance.jpeg";
const Maintenance = () => {
    return (
        <div
            style={{
                verticalAlign: "middle",
                display: "inline-block",
                height: "100%",
            }}
        >
            <img src={MaintenanceImage} alt="_Bidbox-logo.png" />
        </div>
    );
};
export default Maintenance;
