import { takeEvery, put, call } from "redux-saga/effects";

import { TIMEZONES } from "./actionTypes";
import { timezonesSuccess, timezonesError } from "./actions";

import { getTimezones as apiGetTimezones } from "src/api";
function* getTimezones({ payload }) {
    try {
        const response = yield call(apiGetTimezones, payload);
        const data = response.data;
        let cnt = [];
        data.timezones.forEach((el) => {
            cnt.push({
                value: el.timezone,
                label: el.timezone,
            });
        });
        yield put(
            timezonesSuccess({
                items: cnt,
            })
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(timezonesError(message));
    }
}

function* authSaga() {
    yield takeEvery(TIMEZONES, getTimezones);
}

export default authSaga;
