import React from "react";
import { Link } from "react-router-dom";
import { rankConvert } from "src/utils/helpers";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccount } from "src/store/actions";
import BlankProfile from "../../assets/image/blank_profile.png";

import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
const Profile = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.Account);

    const copyText = () => {
        copy(
            `${window.location.origin}/auth/signup?sponsor=${account.username}`
        );
        toast("link has been copied");
    };
    useEffect(() => {
        dispatch(getAccount());
    }, []);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">Profile</span>
                <div className="n"></div>
            </header>
            <div className="card overflow-x:auto">
                <div className="card-body px-2">
                    <div
                        className="d-flex flex-wrap align-items-center"
                        style={{ gap: "15px" }}
                    >
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="avatar-profile mb-2">
                                <img src={BlankProfile} alt="profileimage" />
                            </div>
                            <button
                                className="btn fz:13 bg-app-color hover:bg-app-color-90 text-white fw:500 px-2"
                                style={{ height: "30px", lineHeight: "19px" }}
                            >
                                Change Avatar
                            </button>
                        </div>
                        <div className="profile-detail">
                            <div className="field">
                                <label
                                    htmlFor="sponsor"
                                    className="fz:13 fw:600 text-dark"
                                >
                                    Your Sponsor
                                </label>
                                <input
                                    type="text"
                                    value={account.referrer_username}
                                    className="input-control sm bg-slate-color-20"
                                    required
                                />
                            </div>
                            <div className="card overflow-x:auto">
                                <div className="card-body px-1">
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td className="fz:11 text-dark fw-500">
                                                    Country
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    :
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    {account.country_name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fz:11 text-dark fw-500">
                                                    Name
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    :
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    {account.full_name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fz:11 text-dark fw-500">
                                                    Username
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    :
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    {account.username}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fz:11 text-dark fw-500">
                                                    Email
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    :
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    {account.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fz:11 text-dark fw-500">
                                                    Rank
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    :
                                                </td>
                                                <td className="fz:11 text-dark fw-500 text-uppercase">
                                                    {rankConvert(
                                                        account.rank_name
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fz:11 text-dark fw-500">
                                                    Referral Link
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    :
                                                </td>
                                                <td className="fz:11 text-dark fw-500">
                                                    <Link
                                                        to={`/auth/signup?sponsor=${account.username}`}
                                                    >
                                                        {`${window.location.origin}/auth/signup?sponsor=${account.username}`}
                                                    </Link>
                                                    <i
                                                        style={{
                                                            margin: 5,
                                                            cursor: "pointer",
                                                        }}
                                                        className="bx bx-copy"
                                                        onClick={copyText}
                                                    ></i>
                                                    &nbsp;Copy
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
