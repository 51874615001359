import { takeLatest, takeEvery, put, call } from "redux-saga/effects";

import { REGISTER, REGISTER_REQ_OTP } from "./actionTypes";
import {
    registerSuccess,
    registerError,
    requestOtpRegisterSuccess,
    requestOtpRegisterError,
} from "./actions";

import {
    signup as apiRegisterUser,
    reqOtp as apiRegisterReqOtp,
} from "src/api";
function* registerUser({ payload: { user, history } }) {
    try {
        yield call(apiRegisterUser, user);
        yield put(registerSuccess("account register successful"));
        setTimeout(() => {
            history.push("/auth/signin");
        }, 2000);
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(registerError(message));
    }
}

function* registerReqOtp({ payload }) {
    try {
        yield call(apiRegisterReqOtp, payload);
        yield put(
            requestOtpRegisterSuccess("We have sent the otp code to your email")
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(requestOtpRegisterError(message));
    }
}
function* authSaga() {
    yield takeLatest(REGISTER, registerUser);
    yield takeEvery(REGISTER_REQ_OTP, registerReqOtp);
}

export default authSaga;
