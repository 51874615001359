import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal/Modal";
import {
    getServerIP,
    userRequestOtp,
    setBinding,
    initIPBinding,
    unbound,
    getBotSetting,
} from "src/store/actions";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
const APIBinding = () => {
    const dispatch = useDispatch();
    const bot = useSelector((state) => state.Bot);
    const params = useParams();
    const location = useLocation();
    const ip_binding = useSelector((state) => state.IpBinding);
    const account = useSelector((state) => state.Account);
    const [agreement, setAgreement] = useState(null);
    const [bindingId, setBindingId] = useState(1);
    const [accessKey, setAccessKey] = useState("");
    const [privateKey, setPrivateKey] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [generalModal, setGeneralModal] = useState({
        isVisible: false,
        buttons: [],
        title: "",
        message: "",
    });
    useEffect(() => {
        dispatch(getServerIP());
        if (location.pathname === "/user/api-binding/binding-binance") {
            setBindingId(1);
        } else {
            setBindingId(2);
        }
    }, []);
    const handleBack = () => {
        window.history.back();
    };
    const getOtp = () => {
        dispatch(
            userRequestOtp({
                otp_context: 2,
                target: 0,
            })
        );
    };
    const handleBind = () => {
        if (agreement === true) {
            if (bot.binance.api_binding.api_key === "") {
                dispatch(
                    setBinding({
                        api_key: accessKey,
                        exchange_id: bindingId,
                        secret_key: privateKey,
                        otp: verificationCode,
                    })
                );
            } else {
                setGeneralModal({
                    isVisible: true,
                    buttons: [
                        {
                            label: "Confirm",
                            className: "btn-modal",
                            action: () => {
                                dispatch(
                                    unbound({
                                        exchange_id: 1,
                                    })
                                );
                                setGeneralModal({
                                    isVisible: false,
                                });
                                dispatch(getBotSetting());
                                handleBack();
                            },
                        },
                        {
                            label: "Cancel",
                            className: "btn-modal bg-transparent text-app",
                            action: () => {
                                setGeneralModal({
                                    isVisible: false,
                                });
                            },
                        },
                    ],
                    title: "",
                    message:
                        "Are you sure to unbind the Bidbox and this exchanger?",
                });
            }
        } else {
            toast("Please tick The Risk NOTICE Carefully");
        }
    };
    useEffect(() => {
        if (account.reqOtpSuccessMsg !== null) toast(account.reqOtpSuccessMsg);
        if (account.reqOtpErrorMsg !== null) toast(account.reqOtpErrorMsg);
    }, [account.reqOtpSuccessMsg, account.reqOtpErrorMsg]);
    useEffect(() => {
        if (ip_binding.errorMsg !== null) toast(ip_binding.errorMsg);
        if (ip_binding.successMsg !== null) toast(ip_binding.successMsg);
        dispatch(initIPBinding());
    }, [ip_binding.successMsg, ip_binding.errorMsg]);
    useEffect(() => {
        setAccessKey(bot.binance.api_binding.api_key);
    }, [bot.binance.api_binding.api_key]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button className="back-button" onClick={handleBack}>
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    {params.id === "binding-binance"
                        ? "Binding Binance"
                        : "Binding Tokocrypto"}
                </span>
                <div className="n"></div>
            </header>
            <div
                className="card mb-2 card-affiliate"
                style={{ borderRadius: "8px" }}
            >
                <div className="card-body pt-2 pb-2">
                    <p className="mb-1 fz:14 fw-700 text-dark">Precautions</p>
                    <p className="m-0 fz:11 fw-500 text-dark">
                        1. Please confirm that the API permission has checked
                        "Enable Spot & Margin Trading" 2. Please enter the
                        correct API, please do not enter special characters
                    </p>
                </div>
            </div>
            <div
                className="card mb-2 card-affiliate"
                style={{ borderRadius: "8px" }}
            >
                <div className="card-body pt-2 pb-2">
                    <p className="mb-1 fz:12 fw-600 text-dark">
                        IP group binding
                    </p>
                    <p className="m-0 fz:10 fw-500 text-dark">
                        For security, Binance Exchange recommends binding the
                        server IP address when creating the API. For users who
                        need to bind the IP address, click Edit permissions in
                        the upper right corner after the API is created, and
                        click in the IP address permission column ti restrict
                        access to only trusted IPs. (Recommended) option, click
                        the copy button to copy the IP group to the input box
                        and click OK, after adding, click save in the upper
                        right corner.
                    </p>
                    <div className="input-copy mt-2">
                        <h2
                            className="m-0 fz:13 text-dark fw-500 sttr-text"
                            id="ip-address"
                        >
                            {ip_binding.ips.join(" ")}
                        </h2>
                        <button
                            className="cpy-btn fz:17"
                            onClick={() => {
                                toast("copy success");
                                copy(ip_binding.ips.join(" "));
                            }}
                        >
                            <i className="bx bx-copy"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div
                className="card mb-2 card-affiliate"
                style={{ borderRadius: "8px" }}
            >
                <div className="card-body pt-2 pb-2">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td width="120">
                                    <label
                                        htmlFor="access-key"
                                        className="label-control fz:12 text-dark fw:500 whitespace-nowrap"
                                    >
                                        API Key
                                    </label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setAccessKey(e.target.value)
                                        }
                                        value={accessKey}
                                        id="access-key"
                                        className="fz:12 input-api-binding"
                                        placeholder="Please Enter API Key Here"
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label
                                        htmlFor="private-key"
                                        className="label-control text-dark fz:12 fw:500 whitespace-nowrap"
                                    >
                                        Secret Key
                                    </label>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id="private-key"
                                        onChange={(e) =>
                                            setPrivateKey(e.target.value)
                                        }
                                        className="fz:12 input-api-binding"
                                        placeholder="Please Enter Secret Key Here"
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label
                                        htmlFor="verification-code"
                                        className="fz:12 label-control text-dark fw:500 fz:12 whitespace-nowrap"
                                    >
                                        Verification Code
                                    </label>
                                </td>
                                <td>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            type="text"
                                            id="verification-code"
                                            onChange={(e) =>
                                                setVerificationCode(
                                                    e.target.value
                                                )
                                            }
                                            className="fz:12 input-api-binding"
                                            placeholder="Please Enter Email Verification Code Here"
                                            required
                                        />
                                        <button
                                            onClick={getOtp}
                                            className="btn-send-code"
                                        >
                                            Get OTP
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="field">
                <div className="form-check">
                    <input
                        className="input-checkbox"
                        type="checkbox"
                        onClick={(evt) => {
                            if (evt.target.checked) {
                                setAgreement(true);
                            } else {
                                setAgreement(false);
                            }
                        }}
                        id="termcondition"
                    />
                    <label
                        className="check-label fz:13 fw:500 text-mute cursor:pointer"
                        htmlFor="termcondition"
                    >
                        I Have Read{" "}
                        <span className="text-app">
                            The Risk NOTICE Carefuly
                        </span>
                    </label>
                </div>
            </div>

            <div style={{ textAlign: "center" }}>
                <button
                    onClick={handleBind}
                    style={
                        bot.binance.api_binding.api_key === ""
                            ? { color: "white" }
                            : { color: "red" }
                    }
                    className="btn bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-uppercase px-4"
                >
                    {bot.binance.api_binding.api_key === ""
                        ? "Bind"
                        : "Unbound"}
                </button>
            </div>
            {generalModal.isVisible && (
                <Modal
                    message={generalModal.message}
                    title={generalModal.title}
                    buttons={generalModal.buttons}
                />
            )}
        </div>
    );
};

export default APIBinding;
