import {
    ACCOUNT_PROFILE,
    ACCOUNT_PROFILE_SUCCESS,
    ACCOUNT_PROFILE_ERROR,
    ACCOUNT_REQ_OTP,
    ACCOUNT_REQ_OTP_SUCCESS,
    ACCOUNT_REQ_OTP_ERROR,
    CHANGE_PIN,
    CHANGE_PIN_SUCCESS,
    CHANGE_PIN_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_EMAIL,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_EMAIL_ERROR,
    ACCOUNT_INIT,
} from "./actionTypes";

const initialState = {
    country: null,
    country_name: null,
    created_at: null,
    email: null,
    full_name: null,
    id: null,
    jwt_expired_at: null,
    phone: null,
    status: null,
    timezone: null,
    timezone_offset: null,
    username: null,
    security_pin_active: null,
    referrer_username: null,
    loading: false,
    successMsg: null,
    errorMsg: null,
    loadingRequestOtp: false,
    reqOtpSuccessMsg: null,
    reqOtpErrorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INIT:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
                reqOtpErrorMsg: null,
                reqOtpSuccessMsg: null,
                loadingRequestOtp: null,
            };
            break;

        case CHANGE_EMAIL:
        case CHANGE_PASSWORD:
        case CHANGE_PIN:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case CHANGE_EMAIL_SUCCESS:
        case CHANGE_PASSWORD_SUCCESS:
        case CHANGE_PIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;

        case CHANGE_EMAIL_ERROR:
        case CHANGE_PASSWORD_ERROR:
        case CHANGE_PIN_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        case ACCOUNT_REQ_OTP:
            state = {
                ...state,
                loadingReqOtp: true,
                reqOtpSuccessMsg: null,
                reqOtpErrorMsg: null,
            };
            break;

        case ACCOUNT_REQ_OTP_SUCCESS:
            state = {
                ...state,
                loadingReqOtp: false,
                reqOtpSuccessMsg: action.payload,
                reqOtpErrorMsg: null,
            };
            break;

        case ACCOUNT_REQ_OTP_ERROR:
            state = {
                ...state,
                loadingReqOtp: false,
                reqOtpSuccessMsg: null,
                reqOtpErrorMsg: action.payload,
            };
            break;
        case ACCOUNT_PROFILE:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case ACCOUNT_PROFILE_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case ACCOUNT_PROFILE_ERROR:
            state = { ...state, loading: false, errorMsg: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
