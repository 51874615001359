import { TIMEZONES, TIMEZONES_SUCCESS, TIMEZONES_ERROR } from "./actionTypes";

export const getTimezones = (payload) => {
    return {
        type: TIMEZONES,
        payload: payload,
    };
};

export const timezonesSuccess = (message) => {
    return {
        type: TIMEZONES_SUCCESS,
        payload: message,
    };
};

export const timezonesError = (message) => {
    return {
        type: TIMEZONES_ERROR,
        payload: message,
    };
};
