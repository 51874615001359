import {
  OPENFIRE_LOGIN,
  OPENFIRE_LOGIN_SUCCESS,
  OPENFIRE_LOGIN_ERROR
} from "./actionTypes"
  
  export const openfireLogin = () => {
    return {
      type: OPENFIRE_LOGIN
    }
  }

  export const openfireLoginSuccess = message => {
    return {
      type: OPENFIRE_LOGIN_SUCCESS,
      payload: message,
    }
  }
  
  export const openfireLoginError = message => {
    return {
      type: OPENFIRE_LOGIN_ERROR,
      payload: message,
    }
  }
  