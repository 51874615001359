import React from "react";
import { Link } from "react-router-dom";
const Setting = (props) => {
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => props.history.push("/user/account")}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">Setting</span>
                <div className="n"></div>
            </header>
            <ul className="list-group">
                <Link className="list-btn">
                    <span className="fz:14 fw:500 text-dark">Language</span>
                    <span className="fz:12 text-gray">English</span>
                </Link>
            </ul>
        </div>
    );
};

export default Setting;
