import { takeEvery, put, call } from "redux-saga/effects";

import { DEPOSIT_HISTORY } from "./actionTypes";
import { depositHistoryError, depositHistorySuccess } from "./actions";

import { getHistoryDeposit as apiGetHistoryDeposit } from "src/api";
function* getHistoryDeposit({ payload }) {
    try {
        const response = yield call(apiGetHistoryDeposit, payload.from_id);
        const data = response.data;
        yield put(depositHistorySuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(depositHistoryError(message));
    }
}
function* authSaga() {
    yield takeEvery(DEPOSIT_HISTORY, getHistoryDeposit);
}

export default authSaga;
