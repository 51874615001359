export const BOT_SETTING = "BOT_SETTING";
export const BOT_SETTING_SUCCESS = "BOT_SETTING_SUCCESS";
export const BOT_SETTING_ERROR = "BOT_SETTING_ERROR";

export const BOT_SETTING_STATUS = "BOT_SETTING_STATUS";
export const BOT_SETTING_STATUS_SUCCESS = "BOT_SETTING_STATUS_SUCCESS";
export const BOT_SETTING_STATUS_ERROR = "BOT_SETTING_STATUS_ERROR";

export const BOT_PLUGIN = "BOT_PLUGIN";
export const BOT_PLUGIN_SUCCESS = "BOT_PLUGIN_SUCCESS";
export const BOT_PLUGIN_ERROR = "BOT_PLUGIN_ERROR";

export const BOT_STATUS = "BOT_STATUS";
export const BOT_STATUS_SUCCESS = "BOT_STATUS_SUCCESS";
export const BOT_STATUS_ERROR = "BOT_STATUS_ERROR";

export const BOT_UPGRADE = "BOT_UPGRADE";
export const BOT_UPGRADE_SUCCESS = "BOT_UPGRADE_SUCCESS";
export const BOT_UPGRADE_ERROR = "BOT_UPGRADE_ERROR";

export const SET_REQUEST_TRADE = "SET_REQUEST_TRADE";
export const SET_REQUEST_TRADE_SUCCESS = "SET_REQUEST_TRADE_SUCCESS";
export const SET_REQUEST_TRADE_ERROR = "SET_REQUEST_TRADE_ERROR";
