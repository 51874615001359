import { COUNTRIES, COUNTRIES_SUCCESS, COUNTRIES_ERROR } from "./actionTypes";

export const getCountries = () => {
    return {
        type: COUNTRIES,
    };
};

export const countriesSuccess = (message) => {
    return {
        type: COUNTRIES_SUCCESS,
        payload: message,
    };
};

export const countriesError = (message) => {
    return {
        type: COUNTRIES_ERROR,
        payload: message,
    };
};
