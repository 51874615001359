import React, {useState, useRef, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import Banner from "../../components/banner/Banner";
import Dropdown from "../../components/dropdown/Dropdown";
import  { Strophe, $pres,$msg, $iq, onPresence } from 'strophe.js'
import 'strophejs-plugin-muc';
import ScrollableFeed from 'react-scrollable-feed'
import {openfireLogin} from "src/store/actions";


const listCountry = [
    { id: 'id', title: "Indonesia", flag: "id" }
    // { id: 'us', title: "United States", flag: "us" },
];


const ChatCountry = () => {
    const dispatch = useDispatch()
    const BOSH_SERVICE = process.env.REACT_APP_BOSH_SERVICE;
    let connection = useRef(null)
    let currentGroup = useRef(null)
    const [showChatBox, setShowChatBox] = useState(true);
    const [activeInput, setActiveInput] = useState(false);
    const GetFileRef = useRef(null);
    const [chat,setChat] = useState([])
    const [message,setMessage] = useState('')
    const [newConnection,setConnection] = useState(null)
    const [menuClassName,setMenuClassName] = useState('')
    const [selectedCountry,setSelectedCountry] = useState({
        id:'id',
        title:'Indonesia'
    })
    const openfireState = useSelector(state => state.Openfire)
    const onSelectedCountry = (v) => {
        setMenuClassName((menuClassName) =>{
            if (menuClassName === ''){
                return 'show'
            }else{
                return ''
            }
        })
        setSelectedCountry({
            id:v.id,
            title:v.title
        })
    }
    const getValue = (e) => {
        setMessage(e.target.value)
        if (e.target.value.length >= 1) {
            setActiveInput(true);
        } else {
            setActiveInput(false);
        }
    };

    const onKeyUpMessage = (e)=> {
        if (e.key === 'Enter' || e.keyCode === 13){
            sendMessage()
        }
    }
    const sendMessage = () => {

        const to = 'id@conference.openfire-server.bidbox.community'
        const body = message

        const delay = new Date();
        const msg = $msg({ to, type: 'groupchat' }).c('body').t(body).up().c('delay', {
            xmlns: 'urn:xmpp:delay',
            stamp: delay.toISOString()
        }).t(delay.toISOString());
        newConnection.send(msg);
        setMessage('')
    };
    const onConnect = (status) => {
        if (status === Strophe.Status.CONNECTED) {
            console.log('XMPP connection established.');
            if (connection) { // Periksa apakah connection sudah didefinisikan
                setConnection(connection)
                // connection.mam.query(options, onHistory);
                connection.addHandler(onMessage, null, 'message', null, null, null);
                connection.send($pres().tree());
                // console.log($pres().tree())
                connection.muc.join(selectedCountry.id + '@conference.openfire-server.bidbox.community',openfireState.username,messageHandler,presHandler)

            }
        }
    };

    useEffect(()=>{
        if(!(openfireState.username && openfireState.password)){
            dispatch(openfireLogin())
        }
    },[])
    useEffect(()=>{
        if(openfireState.username && openfireState.password){
            connection = new Strophe.Connection(BOSH_SERVICE);
            connection.connect(openfireState.username + '@openfire-server.bidbox.community', openfireState.password, onConnect);
        }
    },[openfireState.username,openfireState.password])
    const onMessage = (msg) => {
        let to = msg.getAttribute('to');
        const type = msg.getAttribute('type');
        const elems = msg.getElementsByTagName('body');
        const addresses = msg.getElementsByTagName('addresses');
        const delay = msg.getElementsByTagName('delay')
        if (type === 'groupchat' && elems.length > 0 && addresses.length){
            let is_parent = false
            let timestamp = new Date().toISOString()
            if(delay.length > 0){
                timestamp = delay[0].getAttribute('stamp')
            }
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let date_time = new Date(timestamp)
            const date = date_time.toLocaleDateString('en-GB',options)
            let from = addresses[0].children[0].getAttribute('jid');
            from = from.split('@')[0]
            to = to.split('@')[0]
            let nickname = from
            if (to === from) {
                from = 'me'
            }
            setChat((chat) => {
                const newArray = [...chat];
                const newState = {
                    date:date_time,
                    time_string: date + " " +  date_time.getHours() + ":" + date_time.getMinutes(),
                    date_string:date,
                    to:to,
                    from:from,
                    type:type,
                    timestamp:timestamp,
                    nickname:nickname,
                    body:elems[0].innerHTML
                }
                newArray.push(newState);

                return newArray
            })
            // console.log('OnMessage, Received XMPP message:', msg);
        }

        // handle incoming message
        return true;
    };
    const messageHandler = (message) => {
        // console.log('Received XMPP message:', message);
        // handle incoming message
    };

    const presHandler = (presence) => {
        // console.log('Received XMPP presence:', presence);
        // handle presence update
    };

    return (
        <div className="home-content content-on">
            <Banner />
            {showChatBox ? (
                <div className="chat-box">
                    <div className="chat-box-header">
                        <div className="chat-box-header-flex-1">
                            <h1 className="chat-bx-title">Bidbox:</h1>
                            <Dropdown
                                title={selectedCountry.title}
                                menuClassName={menuClassName}
                                onClick={()=>{
                                    if(menuClassName === ''){
                                        setMenuClassName('show')
                                    }else{
                                        setMenuClassName('')
                                    }
                                }}
                                menu={
                                    <>
                                        {listCountry.map((list, idx) => (
                                            <li
                                                className="dropdown-list"
                                                key={idx}
                                            >
                                                <button className="dropdown-list-btn" onClick={()=> onSelectedCountry(list)}>
                                                    <div className="dropdown-list-flag">
                                                        <img
                                                            src={require(`../../assets/icons/flag/${list.flag}.png`)}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <span className="dropdown-list-btn-title">
                                                        {list.title}
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </>
                                }
                            />
                        </div>
                        <div className="chat-box-header-flex-1">
                            <button
                                className="chat-box-header-close"
                                onClick={() => setShowChatBox(!showChatBox)}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div className="chat-box-dialog">
                        <ScrollableFeed className={'chat-box-content'}>
                            {chat && chat.map((v,k) =>
                            {
                                let is_group = true
                                if (currentGroup !== v.date_string){
                                    currentGroup = v.date_string
                                }else{
                                    is_group = false
                                }
                                const time = v.date.get
                                return (<div key={k} className="chat-box-bubble chat-outer">
                                    <div className="chat-box-bubble-details">
                                        <p className="chat-box-bubble-description fz:10">{v.from === 'me' ? <span className="text-red">{v.nickname}</span> : <span className="text-gray">{v.nickname}</span>}</p>
                                        <p className="chat-box-bubble-description fz:12">
                                            {v.body}
                                        </p>
                                        <p className="chat-box-bubble-description fz:9" style={{margin:2}}>{v.time_string} </p>
                                    </div>
                                </div>)
                            }) }
                        </ScrollableFeed>
                    </div>
                    <div className="chat-box-send chat-on-country">
                        <div
                            className={`chat-box-input-message input-message-active`}
                        >
                            <input
                                type="text"
                                className="text-input-chat"
                                placeholder="Ketik pesan anda..."
                                value={message}
                                onKeyUp={onKeyUpMessage}
                                onChange={getValue}
                            />{" "}
                            <button
                                className={`btn-send-message ${
                                    activeInput ? "enabled" : "disabled"
                                }`}
                                onClick={sendMessage}
                            >
                                <i className="fas fa-paper-plane"></i>
                            </button>
                        </div>
                        {/* <div
                            className={`chat-box-input-other ${
                                activeInput
                                    ? "hide-on-input-active"
                                    : "show-on-input-disactive"
                            }`}
                        >
                            <button className="btn-input-other">
                                <i className="fas fa-smile-beam"></i>
                            </button>
                        </div> */}
                    </div>
                </div>
            ) : null}

            {showChatBox ? null : (
                <button
                    className="chat-bubble-button"
                    onClick={() => setShowChatBox(!showChatBox)}
                >
                    <i className="fa-solid fa-comment-dots"></i>
                </button>
            )}
        </div>
    );
};

export default ChatCountry;
