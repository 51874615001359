import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tradesProfit } from "src/store/actions";
import { toFixedIfNecessary } from "src/utils/helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
const TradingProfit = () => {
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState();
    const [showProfit, setShowProfit] = useState(true);
    const [showCumulative, setShowCumulative] = useState(true);
    const trades = useSelector((state) => state.Trades);
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastDay, setLastDay] = useState(moment());
    const toggleActive = (id) => {
        if (toggle === id) {
            setToggle(null);
        } else {
            setToggle(id);
        }
    };
    useEffect(() => {
        const from_date = moment(lastDay)
            .subtract("90", "days")
            .format("YYYY-MM-DD");
        const to_date = moment(lastDay).format("YYYY-MM-DD");
        dispatch(
            tradesProfit({
                exchange_id: 1,
                from_date: from_date,
                to_date: to_date,
            })
        );
    }, [dispatch,lastDay]);
    const loadMore = async () => {
        setLastDay(moment(lastDay).subtract("31", "days"));
    };
    useEffect(() => {
        if (trades.tradesProfit.items.length > 0) {
            let tmp = [...posts, ...trades.tradesProfit.items];
            tmp = tmp
                .filter((a, i) => tmp.findIndex((s) => a.date === s.date) === i)
                .sort(function (a, b) {
                    if (a.date_epoch < b.date_epoch) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            setPosts(tmp);
        } else {
            let last_from_date = new Date(
                trades.tradesProfit.from_date
            ).getTime();
            let first_from_date = new Date("2022-09-01 00:00:00").getTime();
            if (last_from_date > first_from_date) {
                loadMore();
            }
        }
        setHasMore(true);
    }, [trades.tradesProfit.items,posts,trades.tradesProfit.from_date]);
    return (
        <div className="details-content" style={{ overflow: "auto" }}>
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Signal Profit
                </span>
                <div className="n"></div>
            </header>
            <div className="card mb-2 bg-app-color">
                <div className="card-body">
                    <div className="d-grid grid-column:2 mb-3">
                        <div className="d-flex flex-column">
                            <p className="m-0 fz:15 fw:600 text-dark">
                                Today Profit
                            </p>
                            <h2 className="m-0 fz:15 fw:600 text-white">
                                {showProfit
                                    ? toFixedIfNecessary(
                                          trades.tradesProfit.profitToday,
                                          8
                                      )
                                    : "***"}{" "}
                                <span className="fz:12">USDT</span>
                                <i
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        color: "#cacaca",
                                    }}
                                    onClick={() => setShowProfit(!showProfit)}
                                    className={
                                        showProfit
                                            ? "fa-regular fa-eye fz:12"
                                            : "fa-regular fa-eye-slash fz:12"
                                    }
                                ></i>
                            </h2>
                            {/* <p className="m-0 fz:11 text-white">= 0 IDR</p> */}
                        </div>
                        <div className="d-flex flex-column">
                            <p className="m-0 fz:15 fw-600 text-dark text-center">
                                Cumulative Profit
                            </p>
                            <h2 className="m-0 fz:15 fw-600 text-white text-center">
                                {showCumulative
                                    ? toFixedIfNecessary(
                                          trades.tradesProfit.profit_cumulative
                                              ? trades.tradesProfit
                                                    .profit_cumulative
                                              : 0,
                                          8
                                      )
                                    : "***"}{" "}
                                <span className="fz:12">USDT</span>
                                <i
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        color: "#cacaca",
                                    }}
                                    onClick={() =>
                                        setShowCumulative(!showCumulative)
                                    }
                                    className={
                                        showCumulative
                                            ? "fa-regular fa-eye fz:12"
                                            : "fa-regular fa-eye-slash fz:12"
                                    }
                                ></i>
                            </h2>
                            {/* <p className="m-0 fz:11 text-white text-center">
                                = 0 IDR
                            </p> */}
                        </div>
                    </div>

                    <div className="d-grid grid-column:2 mb-3">
                        {/* <div className="d-flex flex-column">
                            <p className="m-0 fz:15 fw:600 text-dark">
                                Today Loss
                            </p>
                            <h2 className="m-0 fz:15 fw:600 text-white">
                                {showProfitLoss
                                    ? toFixedIfNecessary(
                                          trades.tradesProfit.lossToday,
                                          8
                                      )
                                    : "***"}{" "}
                                <span className="fz:12">USDT</span>
                                <i
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        color: "#cacaca",
                                    }}
                                    onClick={() =>
                                        setShowProfitLoss(!showProfitLoss)
                                    }
                                    className={
                                        showProfitLoss
                                            ? "fa-regular fa-eye fz:12"
                                            : "fa-regular fa-eye-slash fz:12"
                                    }
                                ></i>
                            </h2>
                            {/* <p className="m-0 fz:11 text-white">= 0 IDR</p> 
                        </div> */}
                        {/* <div className="d-flex flex-column">
                            <p className="m-0 fz:15 fw-600 text-dark text-center">
                                Cumulative Loss
                            </p>
                            <h2 className="m-0 fz:15 fw-600 text-white text-center">
                                {showCumulativeLoss
                                    ? toFixedIfNecessary(
                                          trades.tradesProfit.loss_cumulative
                                              ? trades.tradesProfit
                                                    .loss_cumulative
                                              : 0,
                                          8
                                      )
                                    : "***"}{" "}
                                <span className="fz:12">USDT</span>
                                <i
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        color: "#cacaca",
                                    }}
                                    onClick={() =>
                                        setShowCumulativeLoss(
                                            !showCumulativeLoss
                                        )
                                    }
                                    className={
                                        showCumulativeLoss
                                            ? "fa-regular fa-eye fz:12"
                                            : "fa-regular fa-eye-slash fz:12"
                                    }
                                ></i>
                            </h2>
                            {/* <p className="m-0 fz:11 text-white text-center">
                                = 0 IDR
                            </p> 
                        </div> */}
                    </div>
                    {/* <p className="fz:12 text-right text-white mb-0 mt-2">
                        Data within 24 hours might not be real time.
                    </p>
                    <p className="fz:12 text-right text-white m-0">
                        Data is shown under your chosen timezone.
                    </p> */}
                </div>
            </div>

            <InfiniteScroll
                dataLength={posts.length}
                next={loadMore}
                hasMore={hasMore}
                // loader={<h3> Loading...</h3>}
                // endMessage={<h4>Nothing more to show</h4>}
            >
                {posts.map((data, idx) => {
                    let arrays = 0
                    if(data.items !== null){
                        data.items.map((k, i) => {
                            if (k.status_str === "AVERAGING" || k.profit_amount < 0) {
                                return false;
                            }
                            arrays ++
                        })
                    }
                    if (arrays === 0) return


                    return (
                        <div
                            className={
                                toggle === idx
                                    ? "accordion active"
                                    : "accordion"
                            }
                            key={idx}
                        >
                            <button
                                type="button"
                                className="accordion-button"
                                onClick={() => toggleActive(idx)}
                            >
                                <span className="fz:13 fw:500 text-dark">
                                    {data.date}
                                </span>
                                <div className="d-flex flex-column">
                                    <span
                                        className={
                                            data.profit_amount >= 0
                                                ? "fz:15 fw:500 text-green-dark"
                                                : "fz:15 fw:500 text-red"
                                        }
                                    >
                                        {toFixedIfNecessary(
                                            data.profit_amount,
                                            8
                                        )}
                                    </span>
                                    <span
                                        className={
                                            data.profit_amount >= 0
                                                ? "fz:13 fw:500 text-green-dark"
                                                : "fz:13 fw:500 text-red"
                                        }
                                    >
                                        Profit
                                    </span>
                                </div>
                                <i className="fa-solid fa-angle-down"></i>
                            </button>
                            <div
                                className={
                                    toggle === idx
                                        ? "accordion-content show-content"
                                        : "accordion-content"
                                }
                            >
                                {data.items !== null &&
                                    data.items.map((k, i) => {
                                        if (k.status_str === "AVERAGING" || k.profit_amount < 0) {
                                            return false;
                                        }
                                        return (
                                            <div
                                                key={i}
                                                className="card mb-2 card-transaction"
                                            >
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <div className="icon-coin">
                                                            <img
                                                                src={k.image}
                                                                alt="icon-coin"
                                                            />
                                                        </div>
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                columnGap:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <h2 className="m-0 fz:16 fw-600 text-dark me-1">
                                                                {k.base_asset} /
                                                            </h2>
                                                            <p className="m-0 fz:12 fw-500 text-dark">
                                                                {k.quote_asset}
                                                            </p>
                                                            {/* <p className="m-0 fz:11 text-green info-transaction">
                                                        Buy
                                                    </p> */}

                                                            {k.avg_for !==
                                                                "" && (
                                                                <p
                                                                    className={`m-0 fz:11 info-transaction`}
                                                                    style={{
                                                                        color: "#008aae",
                                                                    }}
                                                                >
                                                                    AVG
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="m-0 text-dark fz:15">
                                                            {toFixedIfNecessary(
                                                                k.sell_price,
                                                                8
                                                            )}
                                                        </p>
                                                        <p
                                                            className={
                                                                k.profit_pc >= 0
                                                                    ? "m-0 text-green-dark fz:15"
                                                                    : "m-0 text-red fz:15"
                                                            }
                                                        >
                                                            {toFixedIfNecessary(
                                                                k.profit_amount,
                                                                8
                                                            ) +
                                                                " (" +
                                                                toFixedIfNecessary(
                                                                    k.profit_pc *
                                                                        100,
                                                                    3
                                                                ) +
                                                                "%)"}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <p className="m-0 text-mute fz:11">
                                                            Sell In Price (USDT)
                                                        </p>
                                                        <p className="m-0 text-mute fz:11">
                                                            Profit (USDT)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default TradingProfit;
