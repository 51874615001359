import {
    DEPOSIT_HISTORY,
    DEPOSIT_HISTORY_SUCCESS,
    DEPOSIT_HISTORY_ERROR,
    DEPOSIT_HISTORY_INIT,
} from "./actionTypes";

export const depositHistoryInit = () => {
    return {
        type: DEPOSIT_HISTORY_INIT,
    };
};

export const depositHistory = (payload) => {
    return {
        type: DEPOSIT_HISTORY,
        payload: payload,
    };
};

export const depositHistorySuccess = (payload) => {
    return {
        type: DEPOSIT_HISTORY_SUCCESS,
        payload: payload,
    };
};

export const depositHistoryError = (payload) => {
    return {
        type: DEPOSIT_HISTORY_ERROR,
        payload: payload,
    };
};
