import React from "react";
import styled from "styled-components";
import htmlParse from "html-react-parser";
const FModal = styled.div`
    position: fixed;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: fade 0.4s forwards;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }
`;
const ModalDialog = styled.div`
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
`;
const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    border-radius: 0.3rem;
    outline: 0;
    min-width: 400px;

    @media screen and (max-width: 400px) {
        min-width: auto;
    }
`;
const ModalHeader = styled.div`
    position: relative;
`;
const ModalBody = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
`;
const ModalFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem 0.75rem 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    column-gap: 10px;
`;

const ModalButton = styled.button`
    position: relative;
    display: inline-block;
    padding: 0.3rem 1rem;
    background: ${(props) => (props.primary ? "#ffffff" : "#008aae")};
    color: ${(props) => (props.primary ? "#008aae" : "#ffffff")};
    border: 1px solid transparent;
    font-weight: 500;
    font-size: 15px;
    border-radius: 0.25rem;
    transition: ${(props) =>
        props.primary ? "background 0.3s, color 0.2s" : "none"};
    &:hover {
        background: ${(props) => (props.primary ? "#008aae" : "#007e9e")};
        color: ${(props) => (props.primary ? "#ffffff" : "#ffffff")};
    }
    &:active {
        background: ${(props) => (props.primary ? "#0897bb" : "#068cad")};
    }
`;
const Modal = ({ buttons, title, message }) => {
    return (
        <FModal>
            <ModalDialog>
                <ModalContent>
                    <ModalBody>
                        {title ? (
                            <p className="fz:15 fw:600 text-center m-0">
                                {title}
                            </p>
                        ) : null}
                        {message ? (
                            <p className="fz:15 fw:400 text-center m-0">
                                {htmlParse(message)}
                            </p>
                        ) : null}
                    </ModalBody>
                    {buttons && (
                        <ModalFooter>
                            {buttons.map((key, index) => {
                                return (
                                    <ModalButton
                                        key={index}
                                        type="button"
                                        className={key.className}
                                        onClick={key.action}
                                    >
                                        {key.label}
                                    </ModalButton>
                                );
                            })}
                        </ModalFooter>
                    )}
                </ModalContent>
            </ModalDialog>
        </FModal>
    );
};

export default Modal;
