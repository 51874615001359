import {
    ACTIVATE_BOT,
    ACTIVATE_BOT_SUCCESS,
    ACTIVATE_BOT_ERROR,
    ACTIVATE_BOT_INIT,
} from "./actionTypes";

export const initActivateBot = () => {
    return {
        type: ACTIVATE_BOT_INIT,
    };
};
export const activateBot = () => {
    return {
        type: ACTIVATE_BOT,
    };
};
export const activateBotSuccess = (message) => {
    return {
        type: ACTIVATE_BOT_SUCCESS,
        payload: message,
    };
};

export const activateBotError = (message) => {
    return {
        type: ACTIVATE_BOT_ERROR,
        payload: message,
    };
};
