import {
    WITHDRAW_HISTORY,
    WITHDRAW_HISTORY_SUCCESS,
    WITHDRAW_HISTORY_ERROR,
    WITHDRAW_HISTORY_INIT,
} from "./actionTypes";

export const withdrawHistoryInit = () => {
    return {
        type: WITHDRAW_HISTORY_INIT,
    };
};

export const withdrawHistory = (payload) => {
    return {
        type: WITHDRAW_HISTORY,
        payload: payload,
    };
};

export const withdrawHistorySuccess = (payload) => {
    return {
        type: WITHDRAW_HISTORY_SUCCESS,
        payload: payload,
    };
};

export const withdrawHistoryError = (payload) => {
    return {
        type: WITHDRAW_HISTORY_ERROR,
        payload: payload,
    };
};
