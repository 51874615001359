import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VIP1 from "../../assets/icons/membercentre/VIP1.png";
import VIP2 from "../../assets/icons/membercentre/VIP2.png";
import VIP3 from "../../assets/icons/membercentre/VIP3.png";
import VIP4 from "../../assets/icons/membercentre/VIP4.png";
import VIP5 from "../../assets/icons/membercentre/VIP5.png";
import VIP6 from "../../assets/icons/membercentre/VIP6.png";
import icon1 from "../../assets/image/11.png";
import icon2 from "../../assets/image/22.png";
import icon3 from "../../assets/image/33.png";
import icon4 from "../../assets/image/44.png";
import icon5 from "../../assets/image/55.png";
import icon6 from "../../assets/image/66.png";
import peer_award from "../../assets/image/peer-award.png";
import team_reward from "../../assets/image/team-reward.png";
import direct_referral from "../../assets/image/direct-refferal.png";
import global_deviden from "../../assets/image/global-deviden.png";
const Membercentre = () => {
  const account = useSelector((state) => state.Account);
  const VIPDATA = [
    {
      id: 1,
      img: VIP1,
      icon: icon1,
      username: "username member",
      VIP: "VIP1",
      nextVIP: "VIP2",
      DirectRefferal: "Direct Referral 3 VIP1s and 20 members",
      DirrectRefferalAward: "30 USDT",
      ProfitSharing: "2%",
    },
    {
      id: 2,
      img: VIP2,
      icon: icon2,
      username: "Username Member",
      VIP: "VIP2",
      nextVIP: "VIP3",
      DirectRefferal:
        "5 direct Referral, at least 1 VIP2 in 3 different lines and more than 80 members",
      DirrectRefferalAward: "38 USDT",
      ProfitSharing: "3%",
      TeamProfitSharing: "1%",
    },
    {
      id: 3,
      img: VIP3,
      icon: icon3,
      username: "Username Member",
      VIP: "VIP3",
      nextVIP: "VIP4",
      DirectRefferal:
        "8 direct Referral, at least 1 VIP3 in 3 different lines and more than 250 members",
      DirrectRefferalAward: "46 USDT",
      ProfitSharing: "4%",
      TeamProfitSharing: "1%",
      PeerProfitSharing: "0,1%",
    },
    {
      id: 4,
      img: VIP4,
      icon: icon4,
      username: "Username Member",
      VIP: "VIP4",
      nextVIP: "VIP5",
      DirectRefferal:
        "12 direct Referral, at least 1 VIP4 in 3 different lines and more than 800 members",
      DirrectRefferalAward: "54 USDT",
      ProfitSharing: "5%",
      TeamProfitSharing: "1%",
      PeerProfitSharing: "0,1%",
    },
    {
      id: 5,
      img: VIP5,
      icon: icon5,
      username: "Username Member",
      VIP: "VIP5",
      nextVIP: "VIP6",
      DirectRefferal:
        "20 direct Referral, at least 1 VIP4 in 3 different lines and more than 2.500 members",
      DirrectRefferalAward: "62 USDT",
      ProfitSharing: "6%",
      TeamProfitSharing: "1%",
      PeerProfitSharing: "0,1%",
    },
    {
      id: 6,
      img: VIP6,
      icon: icon6,
      username: "Username Member",
      VIP: "VIP6",
      DirrectRefferalAward: "70 USDT",
      globalDividendReward: "8 USDT",
      ProfitSharing: "7%",
      PeerProfitSharing: "1%",
      TeamProfitSharing: "0,1%",
      globalDividend: "2%",
    },
  ];
  const [slideState, setSlideState] = useState(0);
  useEffect(() => {
    const sliderContainer = document.querySelector(".slider-container");
    const sliderItem = Array.from(document.querySelectorAll("#slider-item"));

    let isPressed = false;
    let startPos = 0;
    let currentTranslate = 0;
    let prevTranslate = 0;
    let animationID = 0;
    let currentIndex = 0;

    sliderItem.forEach((slide, index) => {
      const slideIndex = slide.querySelector("#slide-index");
      slideIndex.addEventListener("dragstart", (e) => {
        e.preventDefault();
      });

      // On Touch Events
      slide.addEventListener("touchstart", touchStart(index));
      slide.addEventListener("touchend", touchEnd);
      slide.addEventListener("touchmove", touchMove);

      // Mouse Events
      slide.addEventListener("mousedown", touchStart(index));
      slide.addEventListener("mouseup", touchEnd);
      slide.addEventListener("mouseleave", touchEnd);
      slide.addEventListener("mousemove", touchMove);
    });

    // window.oncontextmenu = function (e) {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   return false;
    // };

    function touchStart(index) {
      return function (e) {
        currentIndex = index;
        startPos = getPositionX(e);
        isPressed = true;
        animationID = requestAnimationFrame(animation);
        sliderContainer.style.cursor = "grabbing";
      };
    }
    function touchEnd() {
      isPressed = false;
      cancelAnimationFrame(animationID);

      const movedBy = currentTranslate - prevTranslate;

      if (movedBy < -100 && currentIndex < sliderItem.length - 1) {
        currentIndex += 1;
        if (currentIndex === slideState) {
          setSlideState(currentIndex);
        } else {
          setSlideState(currentIndex);
        }
      }

      if (movedBy > 100 && currentIndex > 0) {
        currentIndex -= 1;
        if (currentIndex === slideState) {
          setSlideState(currentIndex);
        } else {
          setSlideState(currentIndex);
        }
      }

      setPositionByIndex();

      sliderContainer.style.cursor = "grab";
    }
    function touchMove(e) {
      if (isPressed) {
        const currentPosition = getPositionX(e);
        currentTranslate = prevTranslate + currentPosition - startPos;
      }
    }
    function getPositionX(e) {
      return e.type.includes("mouse") ? e.pageX : e.touches[0].clientX;
    }
    function animation() {
      setSliderPosition();
      if (isPressed) requestAnimationFrame(animation);
    }
    function setSliderPosition() {
      sliderContainer.style.transform = `translateX(${currentTranslate}px)`;
    }
    function setPositionByIndex(e) {
      currentTranslate = currentIndex * -window.innerWidth;
      prevTranslate = currentTranslate;
      setSliderPosition();
    }
  }, []);
  return (
    <div className="details-content px-0 overflow-x:hidden">
      <header className="details-header">
        <button className="back-button" onClick={() => window.history.back()}>
          <i className="fa-solid fa-angle-left back-icon text-dark"></i>
        </button>
        <span className="text-uppercase fw:500-dark">MEMBER CENTRE</span>
        <div className="n"></div>
      </header>
      <div className="slider-container" style={{ marginTop: -10 }}>
        {VIPDATA.map((data, index) => (
          <div
            className={
              index === slideState
                ? "wave-banner v1 mb-3 active"
                : "wave-banner v1 mb-3"
            }
            id="slider-item"
            data-item={data.id}
            key={index}
          >
            <div
              className="fill-waves"
              style={{
                backgroundImage:
                  "url(../../assets/icons/membercentre/halis.png)",
              }}
            ></div>
            <div
              className="membership-flex lg"
              style={{ flexWrap: "wrap" }}
              id="slide-index"
            >
              <div
                className="icon"
                style={{
                  background: "transparent",
                  border: 0,
                }}
              >
                <img
                  src={data.icon}
                  alt="icon"
                  style={{
                    position: "absolute",
                    top: 25,
                  }}
                  width={50}
                />
              </div>
              <div className="d-block">
                <h2 className="fz:12 m-0 text-dark">{account.username}</h2>
                <p className="fz:12 fw:600 m-0 text-dark">{data.VIP}</p>
                <p className="fz:10 fw:500 mb-1 text-first">
                  #The current level is higher than this level
                </p>

                {data.nextVIP ? (
                  <div className="vip-range">
                    <h1 className="text-dark mb-0 fz:14 fw:600">{data.VIP}</h1>
                    <span className="range">{data.DirectRefferal}</span>
                    <h1 className="text-dark mb-0 fz:14 fw:600">
                      {data.nextVIP}
                    </h1>
                  </div>
                ) : null}
              </div>
              <div className="icon-vip">
                <img src={data.img} alt={data.img} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <h2 className="text-center mb-2 text-dark fz:15">
        VIP membership rights
      </h2>
      <div className="px-2">
        <div className="card mb-2" style={{ background: "#f7fafd" }}>
          <div className="card-body">
            {VIPDATA.map((data, index) => (
              <>
                {index === slideState ? (
                  <>
                    <div className="membership-flex mb-2" key={index}>
                      <div className="icon">
                        <img
                          // src={direct_referral}
                          // alt="global-deviden"
                          style={{ width: 24 }}
                        />
                      </div>
                      <div className="d-block">
                        <h2 className="mb-0 fz:14 fw:600 text-dark">
                          Direct referral reward
                        </h2>
                        <p className="m-0 fz:12 fw:400 text-dark">
                          Bot activation direct reward is{" "}
                          {data.DirrectRefferalAward}
                        </p>
                      </div>
                    </div>

                    <div className="membership-flex mb-2">
                      <div className="icon">
                        <img
                          // src={team_reward}
                          // alt="global-deviden"
                          style={{ width: 24 }}
                        />
                      </div>
                      <div className="d-block">
                        <h2 className="mb-0 fz:14 fw:600 text-dark">
                          Direct Profit Sharing
                        </h2>
                        <p className="m-0 fz:12 fw:400 text-dark">
                          Direct Profit Sharing ratio is {data.ProfitSharing}
                        </p>
                      </div>
                    </div>

                    {data.TeamProfitSharing ? (
                      <div className="membership-flex mb-2">
                        <div className="icon">
                          <img
                            // src={peer_award}
                            // alt="global-deviden"
                            style={{ width: 24 }}
                          />
                        </div>
                        <div className="d-block">
                          <h2 className="mb-0 fz:14 fw:600 text-dark">
                            Team Profit Sharing
                          </h2>
                          <p className="m-0 fz:12 fw:400 text-dark">
                            Team Profit Sharing ratio is{" "}
                            {data.TeamProfitSharing}
                          </p>
                        </div>
                      </div>
                    ) : null}

                    {data.PeerProfitSharing ? (
                      <div className="membership-flex mb-2">
                        <div className="icon">
                          <img
                            // src={peer_award}
                            // alt="global-deviden"
                            style={{ width: 24 }}
                          />
                        </div>
                        <div className="d-block">
                          <h2 className="mb-0 fz:14 fw:600 text-dark">
                            Peer Profit Sharing
                          </h2>
                          <p className="m-0 fz:12 fw:400 text-dark">
                            Peer Profit Sharing ratio is{" "}
                            {data.PeerProfitSharing}
                          </p>
                        </div>
                      </div>
                    ) : null}

                    {data.globalDividend ? (
                      <div className="membership-flex">
                        <div className="icon">
                          <img
                            // src={global_deviden}
                            // alt="global-deviden"
                            style={{ width: 24 }}
                          />
                        </div>
                        <div className="d-block">
                          <h2 className="mb-0 fz:14 fw:600 text-dark">
                            Global dividend
                          </h2>
                          <p className="m-0 fz:12 fw:400 text-dark">
                            Global {data.globalDividend} weighted dividend (need
                            to deduct equal rewards within the organization)
                          </p>
                        </div>
                      </div>
                    ) : null}

                    {data.globalDividendReward ? (
                      <div className="membership-flex">
                        <div className="icon">
                          <img
                            // src={global_deviden}
                            // alt="global-deviden"
                            style={{ width: 24 }}
                          />
                        </div>
                        <div className="d-block">
                          <h2 className="mb-0 fz:14 fw:600 text-dark">
                            Global Dividend Refferal Reward
                          </h2>
                          <p className="m-0 fz:12 fw:400 text-dark">
                            Globall dividend reward for bot activation reward is{" "}
                            {data.globalDividendReward}
                          </p>
                        </div>
                      </div>
                    ) : null}

                    {/* */}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
        <h2 className="text-center mb-2 text-dark fz:15">Member use rules</h2>

        <div className="card mb-2" style={{ background: "#f7fafd" }}>
          <div className="card-body">
            <p className="m-0 fz:11 text-dark">
              1. Directly push profit to deduct the positive part of fuel
              consumption, and directly push member to purchase activation code.
            </p>
            <p className="m-0 fz:11 text-dark">
              2. The final interpretation right belongs to the company
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membercentre;
