import React from "react";

const SelectContainer = ({ className, id, children }) => {
  return (
    <div
      className={
        className ? `select-container ${className}` : "select-container"
      }
      id={id}
    >
      {children}
    </div>
  );
};

const SelectPlaceholder = ({ className, id, children }) => {
  return (
    <div
      className={
        className ? `select-placeholder ${className}` : "select-placeholder"
      }
      id={id}
      onClick={console.log("Click")}
    >
      {children}
    </div>
  );
};

const SelectMenu = ({ className, id, children }) => {
  return (
    <div
      className={className ? `select-menu ${className}` : "select-menu"}
      id={id}
    >
      {children}
    </div>
  );
};

const SelectOption = ({ className, id, children, onClick }) => {
  return (
    <div
      className={className ? `select-option ${className}` : "select-option"}
      id={id}
    >
      {children}
    </div>
  );
};
export { SelectContainer, SelectPlaceholder, SelectMenu, SelectOption };
