import { GAS_FEE, GAS_FEE_SUCCESS, GAS_FEE_ERROR } from "./actionTypes";

const initialState = {
    items: [],
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GAS_FEE:
            state = {
                ...state,
                items: [],
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case GAS_FEE_SUCCESS:
            let items = action.payload.items;
            action.payload.items.forEach((el) => {
                el.date_epoch = new Date(el.date).getTime();
                let gas_fee_total = 0
                el.items.forEach((el2) => {
                    items.forEach((el3) => {
                        el3.items.forEach((el4) => {
                            if (el2.last_id === null) {
                                el2.last_id = "";
                                el2.last_buy_qty = "";
                                el2.last_buy_price = "";
                            }
                            if (el2.avg_for === el4.order_id) {
                                el2.last_id = el4.order_id;
                                el2.last_buy_qty = el4.qty;
                                el2.last_buy_price = el4.buy_price;
                            }
                        });
                    });

                    if(el2.avg_for !== ""){
                        if(el2.last_id !== ""){
                            const gas_fee = (el2.sharing_profit_pc/ 100) * (
                                (el2.last_buy_qty + el2.qty) * el2.sell_price - (el2.last_buy_price * el2.last_buy_qty + el2.buy_price * el2.qty)
                            )
                            gas_fee_total += gas_fee
                        }
                    }else if(el2.status_str !== 'AVERAGING'){
                        const gas_fee = (el2.sharing_profit_pc/ 100) * el2.profit_amount
                        gas_fee_total += gas_fee
                    }
                });
                el.gas_fee = gas_fee_total
            });
            state = {
                ...state,
                items: action.payload.items,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case GAS_FEE_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
