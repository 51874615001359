import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { ticketHistory, ticketReply, ticketInit } from "src/store/actions";
import toast from "react-hot-toast";

const ReplyTicket = (props) => {
    const pathname = window.location.pathname;
    const split_segment_url = pathname.split("/");
    const id_ticket = split_segment_url[split_segment_url.length - 1];
    const dispatch = useDispatch();
    const ticketState = useSelector((state) => state.Ticket);
    const [description, setDescription] = useState("");
    const [ticketCategory, setTicketCategory] = useState("");
    const [file, setFile] = useState(null);
    const [keyFile, setKeyFile] = useState(1);
    useEffect(() => {
        dispatch(
            ticketHistory({
                id: id_ticket,
                page: 1,
                per_page: 100,
            })
        );
        dispatch(ticketInit());
        return () => {
            dispatch(ticketInit());
        };
    }, []);

    const submitTicket = () => {
        if (description === "") {
            toast("please complete the form below");
        } else {
            let formData = new FormData();
            if (file !== null) formData.append("file", file);
            formData.append("id_ticket", id_ticket);
            formData.append("description", description);
            dispatch(ticketReply(formData));
        }
    };
    useEffect(() => {
        dispatch(ticketInit());
        return () => {
            dispatch(ticketInit());
        };
    }, []);
    useEffect(() => {
        if (ticketState.successMsg !== null) {
            toast(ticketState.successMsg);
            setDescription("");
            setFile(null);
            setKeyFile(Math.random().toString(36));
        }
        if (ticketState.errorMsg !== null) toast(ticketState.errorMsg);
    }, [ticketState.successMsg, ticketState.errorMsg]);
    const back = () => {
        props.history.push(`/user/help-support-record/${id_ticket}`);
    };
    useEffect(() => {
        if (ticketState.items.length > 0) {
            setTicketCategory(ticketState.items[0].master_ticket_category.name);
        }
    }, [ticketState.items]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">Reply</span>
                <div className="n"></div>
            </header>
            <ul className="list-group">
                <li
                    className="list-btn bg-app-color-10 mb-3"
                    style={{ border: "1px solid #bed7e6 " }}
                >
                    <span className="fz:14 fw:500 text-dark">
                        Feedback Type
                    </span>
                    <span className="fz:14 fw:500 text-dark">
                        {ticketCategory}
                    </span>
                </li>

                <div id="form-validation">
                    <div className="field">
                        <label
                            htmlFor="spesifica-problem"
                            className="label-control text-dark fw:500"
                        >
                            Reply Description
                        </label>
                        <textarea
                            name="spesifica-problem"
                            id="spesifica-problem"
                            value={description}
                            onChange={(v) => setDescription(v.target.value)}
                            className="textarea-control-feedback"
                            placeholder="Enter a detailed description of the problem you are ecnountering"
                        ></textarea>
                    </div>

                    <div className="field">
                        <label
                            htmlFor="attach-image"
                            className="label-control text-dark fw:500"
                        >
                            Attachment Image
                        </label>
                        <input
                            type="file"
                            name="file"
                            id="attach-image"
                            accept="image/*"
                            key={keyFile}
                            onChange={(e) => setFile(e.target.files[0])}
                            onClick={(event) => (event.target.value = null)}
                        />
                    </div>
                    <button
                        onClick={submitTicket}
                        className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-80 text-white text-uppercase fw:500"
                    >
                        Submit
                    </button>
                </div>
            </ul>
        </div>
    );
};

export default ReplyTicket;
