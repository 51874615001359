const Endpoints = {
    EP_AUTH_LOGIN: "auth/login",
    EP_AUTH_REFRESH_TOKEN: "auth/refresh-token/{token}",
    EP_AUTH_REQ_OTP: "auth/request-otp",
    EP_AUTH_REQ_RESET_PASSWORD: "auth/request-password-reset",
    EP_AUTH_RESET_PASSWORD: "auth/reset-password",
    EP_AUTH_SIGNUP: "auth/signup",
    EP_SITE_BOT_PLUGIN: "site/bot-plugins",
    EP_SITE_COUNTRIES: "site/countries",
    EP_SITE_TIMEZONES: "site/timezones",
    EP_SITE_PAIRS: "site/market-pairs",
    EP_AFFILIATE_USER_RANK: "strict/affiliate/user-rank",
    EP_USER_PROFILE: "strict/user/profile",
    EP_BOT_ALL_SETTINGS: "strict/service-trading-bot/bot-all-settings",
    EP_BOT_SETTING: "strict/service-trading-bot/bot-setting",
    EP_BOT_STATUS: "strict/sales/bot-status",
    EP_EXCHANGE_ACTIVATION: "strict/service-trading-bot/exchange-activation",
    EP_USER_EXCHANGE: "strict/service-trading-bot/user-exchanges",
    EP_API_BINDING: "strict/service-trading-bot/api-binding",
    EP_UPGRADE_PLUGIN: "strict/sales/upgrade-plugin",
    EP_ACTIVE_TRADES: "strict/service-trading-bot/active-trades",
    EP_CANCEL_PENDING_BUY: "strict/service-trading-bot/cancel-pending-buy",
    EP_CANCEL_PENDING_SELL: "strict/service-trading-bot/cut-trade",
    EP_SALES_ACTIVATION: "strict/sales/activation",
    EP_SERVER_IP: "strict/service-trading-bot/server-ip",
    EP_GENERATE_ADDRESS: "strict/wallet/generate-address",
    EP_VALIDATE_DEPOSIT: "strict/wallet/validate-deposit",
    EP_SHOW_BALANCE: "strict/wallet/show-balance",
    EP_CHANGE_PASSWORD: "strict/user/change-password",
    EP_USER_REQ_OTP: "strict/user/request-otp",
    EP_CHANGE_SEC_PIN: "strict/user/change-security-pin",
    EP_CHANGE_EMAIL: "strict/user/change-email",
    EP_AFFILIATE_STATISTICS: "strict/affiliate/statistics",
    EP_AFFILIATE_FRONTLINES: "strict/affiliate/active-frontlines",
    EP_API_DAILY_BONUS: "strict/affiliate/daily-bonus",
    EP_USER_UPDATE_PROFILE: "strict/user/update-profile",
    EP_WALLET_WITHDRAWAL: "strict/wallet/withdrawal",
    EP_WALLET_TRANSFER: "strict/wallet/transfer-balance",
    EP_TRADES_PROFIT: "strict/service-trading-bot/trade-profit",
    EP_WITHDRAWAL: "strict/wallet/withdrawal",
    EP_HISTORY_WITHDRAW: "strict/wallet/withdrawal-history",
    EP_HISTORY_DEPOSIT: "strict/wallet/deposit-history",
    EP_HISTORY_TRANSFER: "strict/wallet/transfer-history",
    EP_NEWS_CMC: "api/v1/cmc/get-news-home",
    EP_PORTOFOLIO: "signal-completed-profit",
    EP_HISTORY_TRADES: "strict/service-trading-bot/trade-history",

    EP_TICKET_HISTORY: "strict/ticket/history",
    EP_TICKET_HISTORY_DETAIL: "strict/ticket/history",
    EP_TICKET_REPLY: "strict/ticket/reply",
    EP_TICKET_CREATE: "strict/ticket",
    EP_SYNC_TRADES: "strict/service-trading-bot/sync-trade",
    EP_AVG_TRADES: "strict/service-trading-bot/averaging",
    EP_GET_TICK_SIZE: "site/exchange-info-binance",
    EP_GET_PRICE_BINANCE: "strict/market-price/binance",
    EP_GET_GAS_FEE: "strict/service-trading-bot/history-gas-fee",

    EP_API_OPENFIRE_LOGIN:"/strict/user/create"
};
export default Endpoints;
