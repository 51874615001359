import { takeEvery, put, call } from "redux-saga/effects";

import { REQUEST_RESET_PASSWORD, RESET_PASSWORD } from "./actionTypes";
import {
    reqResetPasswordSuccess,
    reqResetPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
} from "./actions";

import {
    reqResetPassword as apiReqResetPassword,
    resetPassword as apiResetPassword,
} from "src/api";
function* reqResetPassword({ payload: { user, history } }) {
    try {
        yield call(apiReqResetPassword, user);
        yield put(
            reqResetPasswordSuccess(
                "We have sent the password reset link to your email"
            )
        );
        history.push("/auth/signin");
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(reqResetPasswordError(message));
    }
}

function* resetPassword({ payload: { user, history } }) {
    try {
        yield call(apiResetPassword, user);
        yield put(resetPasswordSuccess("password has been successfully reset"));
        history.push("/auth/signin");
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(resetPasswordError(message));
    }
}
function* authSaga() {
    yield takeEvery(REQUEST_RESET_PASSWORD, reqResetPassword);
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default authSaga;
