import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { changePassword } from "src/store/actions";
import { LoadingBidbox } from "../../components";
const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.Account);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [password, setPassword] = useState(null);
    const [passwordConfirm, setPasswordConfirm] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [pin, setPin] = useState(null);

    const handleChangePassword = () => {
        if (password === null) return toast("Please enter your new password");
        if (passwordConfirm === null)
            return toast("Please re-type your new password");
        if (oldPassword === null)
            return toast("Please enter your old password");
        // if (pin === null) return toast("Please enter your transaction pin");
        if (password !== passwordConfirm)
            return toast("Confirm password does not match");

        if (account.security_pin_active === false) {
            setPin("000000");
        }
        dispatch(
            changePassword({
                new_password: password,
                new_password_repeat: passwordConfirm,
                old_password: oldPassword,
                security_pin: pin,
            })
        );
    };
    useEffect(() => {
        if (account.successMsg !== null) toast(account.successMsg);
        if (account.errorMsg !== null) toast(account.errorMsg);
    }, [account.successMsg, account.errorMsg]);

    return (
        <div className="details-content">
            {account.loading && <LoadingBidbox />}
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Change Password
                </span>
                <div className="n"></div>
            </header>
            <form id="form-validation">
                <div className="field">
                    <input
                        type={showPassword1 ? "text" : "password"}
                        id="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="input-control"
                        placeholder="Input New Password"
                        required
                    />

                    <button
                        style={{ marginTop: -5 }}
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPassword1(!showPassword1)}
                    >
                        <i
                            className={
                                showPassword1
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <div className="field">
                    <input
                        type={showPassword2 ? "text" : "password"}
                        id="re-type-new-password"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        className="input-control"
                        placeholder="Re-type New Password"
                        required
                    />
                    <button
                        style={{ marginTop: -5 }}
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPassword2(!showPassword2)}
                    >
                        <i
                            className={
                                showPassword2
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <div className="field">
                    <input
                        type={showPassword3 ? "text" : "password"}
                        id="old-password"
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="input-control"
                        placeholder="Input Old Password"
                        required
                    />

                    <button
                        style={{ marginTop: -5 }}
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPassword3(!showPassword3)}
                    >
                        <i
                            className={
                                showPassword3
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>
                <div className="field">
                    <input
                        type={showPin ? "text" : "password"}
                        id="transaction-pin"
                        onChange={(e) => setPin(e.target.value)}
                        className="input-control"
                        placeholder="Input Transaction PIN"
                    />
                    <button
                        style={{ marginTop: -5 }}
                        type="button"
                        className="icon-absolute-right"
                        onClick={() => setShowPin(!showPin)}
                    >
                        <i
                            className={
                                showPin
                                    ? "fa-regular fa-eye"
                                    : "fa-regular fa-eye-slash"
                            }
                        ></i>
                    </button>
                </div>

                <button
                    type="button"
                    onClick={handleChangePassword}
                    className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-white text-uppercase"
                >
                    Confirm
                </button>
            </form>
        </div>
    );
};

export default ChangePassword;
