import {
    OPENFIRE_LOGIN,
    OPENFIRE_LOGIN_SUCCESS,
    OPENFIRE_LOGIN_ERROR,
} from "./actionTypes";

const initialState = {
    username:null,
    password:null,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPENFIRE_LOGIN:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null
            };
            break;
        case OPENFIRE_LOGIN_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null
            };
            break;

        case OPENFIRE_LOGIN_ERROR:
            state = { ...state, loading: false, successMsg:null,errorMsg: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
