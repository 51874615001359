import {
    TRANSFER_HISTORY,
    TRANSFER_HISTORY_ERROR,
    TRANSFER_HISTORY_SUCCESS,
    TRANSFER_HISTORY_INIT,
} from "./actionTypes";

const initialState = {
    last_id: null,
    user_id: null,
    items: [],
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSFER_HISTORY_INIT:
            state = {
                ...state,
                loading: false,
                items: [],
                successMsg: null,
                errorMsg: null,
            };
            break;

        case TRANSFER_HISTORY:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case TRANSFER_HISTORY_SUCCESS:
            state = {
                ...state,
                last_id: action.payload.last_id,
                user_id: action.payload.user_id,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            if (action.payload.items === null) action.payload.items = [];
            state.items = state.items.concat(action.payload.items);
            break;
        case TRANSFER_HISTORY_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }

    if (state.items !== null) {
        state.items = [
            ...new Map(state.items.map((item) => [item["id"], item])).values(),
        ];
    }
    return state;
};

export default reducer;
