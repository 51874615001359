import {
    REWARD,
    REWARD_INSERT_SUCCESS,
    REWARD_UPDATE_SUCCESS,
    REWARD_ERROR

  } from "./actionTypes"
  
  export const getReward = (from_date,to_date) => {
    return {
      type: REWARD,
      from_date:from_date,
      to_date:to_date,

    }
  }

  export const rewardSuccess = payload => {
    return {
      type: REWARD_INSERT_SUCCESS,
      payload: payload,
    }
  }
  export const rewardUpdateSuccess = payload => {
    return {
      type: REWARD_UPDATE_SUCCESS,
      payload: payload,
    }
  }
  export const rewardError = message => {
    return {
      type: REWARD_ERROR,
      payload: message,
    }
  }
  