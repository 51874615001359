import React from "react";
import Slider from "react-slick";
import BannerImage from "../../assets/image/banner.jpg";
import BannerMaintenance from "../../assets/image/banner-maintenance.jpeg";
import BidboxBigAchievements from "../../assets/image/bidbox_achievements.jpeg";
import BidboxPartnership from "../../assets/image/bidbox_partnership.jpeg";
import V3 from "../../assets/image/v3.jpeg";
import SOFTLAUNCH from "../../assets/image/softlaunch.png";
const Banner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };
    const clickBanner = () => {
        window.open("https://t.me/bidboxcommunity", "_blank").focus();
    };
    return (
        <Slider {...settings}>
            <div className="banner-image">
                <img src={V3} alt={V3} />
            </div>
            <div className="banner-image">
                <img src={BidboxPartnership} alt={BidboxPartnership} />
            </div>
            <div className="banner-image">
                <img src={BidboxBigAchievements} alt={BidboxBigAchievements} />
            </div>
            <div className="banner-image">
                <img
                    style={{ cursor: "pointer" }}
                    src={SOFTLAUNCH}
                    alt={SOFTLAUNCH}
                />
            </div>
            <div className="banner-image" onClick={clickBanner}>
                <img
                    style={{ cursor: "pointer" }}
                    src={BannerImage}
                    alt={BannerImage}
                />
            </div>
        </Slider>
    );
};

export default Banner;
