import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BidboxHorizontal from "../../assets/image/logo/Bidbox-logo-horizontal.png";

const Header = ({ className, id, children, btnSearch, btnNotification }) => {
    return (
        <header className="header-top">
            <div className="header-menu">
                <div className="header-logo">
                    <img src={BidboxHorizontal} alt="_bidbox-logo.png" />
                </div>
                <div
                    className="d-flex align-items-center"
                    style={{ columnGap: "5px" }}
                >
                    <button
                        className="btn-icon-md text-dark"
                        onClick={btnSearch}
                    >
                        <i className="bx bx-search text-dark"></i>
                    </button>
                    <button className="btn-icon-md text-dark">
                        <i className="bx bx-scan text-dark"></i>
                    </button>
                    <button
                        className="btn-icon-md text-dark"
                        onClick={btnNotification}
                    >
                        <i className="bx bx-news text-dark"></i>
                    </button>
                </div>
            </div>
        </header>
    );
};

const Sidebar = ({ children }) => {
    return <div className="sidebar">{children}</div>;
};

const Bottombar = () => {
    const location = useLocation();

    useEffect(() => {
        const thisLocation = document.querySelectorAll("#item");
        thisLocation.forEach((link) => {
            const href = link.getAttribute("href");
            if (location.pathname == href) {
                link.classList.add("active");
            }
        });
    }, []);

    return (
        <nav className="navbar-bottom _user">
            <div className="navbar-menu _grid">
                <Link to="/user/dashboard" className="navbar-link" id="item">
                    <i className="bx bx-home-alt navbar-icon"></i>
                    <span>Home</span>
                </Link>
                <Link to="/user/trades" className="navbar-link" id="item">
                    <i className="bx bx-line-chart navbar-icon"></i>
                    <span>Signal</span>
                </Link>
                <Link to="/user/bot-setting" className="navbar-link" id="item">
                    <i className="bx bx-time navbar-icon"></i>
                    <span>Signal Setting</span>
                </Link>
                <Link to="/user/news" className="navbar-link" id="item">
                    <i className="bx bx-bell navbar-icon"></i>
                    <span>Notification</span>
                </Link>
                <Link to="/user/account" className="navbar-link" id="item">
                    <i className="bx bx-user navbar-icon"></i>
                    <span>Account</span>
                </Link>
            </div>
        </nav>
    );
};
export { Bottombar, Sidebar, Header };
