import React from "react";
import { useHistory } from "react-router-dom";
const PrivacyPolicy = (props) => {
    const history = useHistory();
    const propsState = props.location.state;

    const backToSignup = () => {
        try {
            history.push(propsState.prevPath);
        } catch (error) {
            history.push("/auth/signup");
        }
    };
    return (
        <div className="sc-dbhs">
            <header className="or-header">
                <button className="back-button me-auto" onClick={backToSignup}>
                    <i className="fa-solid fa-angle-left back-icon"></i>
                </button>
                <span className="text-uppercase fw-500 me-auto">
                    PRIVACY POLICY
                </span>
            </header>
            <div className="dbhs-content" style={{ padding: 20 }}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>PRIVACY POLICY</strong>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>BIDBOX</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    BIDBOX provides a subscription-based
                                    software to help its User to execute buy and
                                    sell Crypto Asset in the spot market.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    This BIDBOX Privacy Policy (hereinafter
                                    referred to as the &ldquo;
                                    <strong>Privacy Policy</strong>&rdquo;)
                                    governs how BIDBOX collects, stores, uses,
                                    processes, controls, protects, maintains,
                                    transfers and discloses information
                                    collected from You as a User (hereinafter
                                    referred to as &ldquo;<strong>You</strong>
                                    &rdquo; or &ldquo;<strong>User</strong>
                                    &rdquo;) from BIDBOX official site,
                                    application both Android and iOS based, all
                                    products or services provided by BIDBOX (the
                                    &ldquo;<strong>Services</strong>&rdquo;).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>A.&nbsp;&nbsp; </strong>
                                    <strong>DEFINITIONS</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    As long as the context of the sentence does
                                    not specify otherwise, the terms or
                                    definitions in this Privacy Policy have the
                                    following meanings:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Crypto Assets
                                    mean intangible commodities in digital form,
                                    using cryptography that use principles of
                                    peer-to-peer (interface) network-based
                                    decentralized technology or known as
                                    Blockchain Network, that is traded in a
                                    platform. Reference to Crypto Assets in this
                                    document has the same meaning as
                                    cryptocurrency.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; Crypto Asset
                                    Trading Activity (hereinafter referred to as
                                    &ldquo;<strong>Crypto Trading</strong>
                                    &rdquo;) means the activity of buying and
                                    selling Crypto Assets on the basis of
                                    achieving the points of agreement between
                                    demand price and offer price that happens in
                                    a market or exchanger platform.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    <strong>Registration&nbsp;</strong>means the
                                    registration process to become a Member of
                                    BIDBOX, which consists of registering
                                    private information, creating a BIDBOX
                                    account, and approval to the Terms of Use
                                    and Privacy Policy of BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; Cookies are
                                    identifier transferred to computer or mobile
                                    device of User which enables BIDBOX to
                                    identify the computer or device of User and
                                    informs BIDBOX about how and when Services
                                    or official site is used or visited, by how
                                    many persons, and to trace activities on the
                                    BIDBOX official site.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; Privacy Policy
                                    means a provision that regulates how BIDBOX
                                    collects, stores, uses, processes, controls,
                                    protects, maintains, transfers and discloses
                                    information collected from User through the
                                    BIDBOX official site and all products or
                                    Services provided by BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    6.&nbsp;&nbsp;&nbsp;&nbsp; Services mean
                                    services provided by BIDBOX including but
                                    not limited to the BIDBOX official site,
                                    application both Android and iOS based, and
                                    all products or services provided in the
                                    sale of the BIDBOX software.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    7.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX means a
                                    software developed and owned by BIDBOX with
                                    the aim of selling a Crypto Asset trading
                                    tool.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    8.&nbsp;&nbsp;&nbsp;&nbsp; Account means a
                                    BIDBOX account used to access the Service.
                                    This definition includes Account that has
                                    activated BIDBOX membership and Account that
                                    has not activated BIDBOX membership yet.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    9.&nbsp;&nbsp;&nbsp;&nbsp; User&rsquo;s
                                    BIDBOX Address means a combination of
                                    numbers and letters operating on the Tron
                                    network (TRC20) with a parent (core)
                                    controlled by BIDBOX, functioning to store,
                                    send and receive Crypto Assets in USDT.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    10.&nbsp; BIDBOX means a tool for trading
                                    Crypto Assets in the form of an automator to
                                    read crypto market movements, place buy or
                                    sell positions on the Crypto Asset
                                    Exchange/Trading Platform, as well as
                                    executing the sale or purchase of Crypto
                                    Assets automatically based on the algorithm
                                    provided by BIDBOX&rsquo;s artificial
                                    intelligence and monitored by professionals.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    11.&nbsp; User or You means the person
                                    (individual) owner of the BIDBOX Account.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    12.&nbsp; Active User means a User who has
                                    activated the BIDBOX membership.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    13.&nbsp; Username means the unique name
                                    entered by User during Registration.
                                    Username also acts as a referral code when a
                                    User invites a prospective user.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    14.&nbsp; The Exchange/Trading Platform
                                    means a platform that provides Crypto Asset
                                    exchange (buying and selling) services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    15.&nbsp; Personal Information is all forms
                                    of Information that is recorded in material
                                    form or not, from that information the
                                    identity of a person can be directly seen or
                                    recognized and known fairly and can be
                                    ascertained directly by an organization or
                                    body that collects the information, or when
                                    it is collected with other information,
                                    which can identify a person directly,
                                    indirectly, who can or may be accessed by
                                    the competent authorities in the event of a
                                    problem.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    16.&nbsp; Law related Personal Data means
                                    the laws and regulations related to personal
                                    data obtainment, storage, use, deletion,
                                    etc. that is applied internationally.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>B.&nbsp;&nbsp; </strong>
                                    <strong>
                                        PERSONAL INFORMATION OBTAINED AND
                                        COLLECTED BY BIDBOX
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may
                                    collect personally identifiable information
                                    from Users in various ways, collecting them
                                    into one, but not limiting, when Users visit
                                    our site, register on the site, and connect
                                    with other similar activities, services,
                                    features or resources provide on our Site.
                                    The user may be asked to provide a name, and
                                    an appropriate email address. Users may
                                    visit our site anonymously. We will collect
                                    personally identifiable information from
                                    Users when they voluntarily send us the
                                    information. Users may always refuse to
                                    provide personally identifiable information,
                                    except that it may prevent it from engaging
                                    in activities related to a particular Site.
                                    The user has read and agreed to the legal
                                    provisions (CP, CPS, Subscriber Agreement,
                                    &amp; Privacy Policy) of Peruri CA&nbsp;
                                    <a href="https://ca.peruri.co.id/ca/legal">
                                        https://ca.peruri.co.id/ca/legal
                                    </a>
                                    , as well as the issuance of an Electronic
                                    Certificate after successful video recording
                                    (eKYC).
                                </p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; During the usage
                                    of Services, BIDBOX may collects Personal
                                    Information of User, which includes, but not
                                    limited to:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Identity data, such as name, gender,
                                    proficle picture;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Contacts data, such as email address and
                                    phone number;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    User&rsquo;s BIDBOX Address, addresses on
                                    the whitelist, receiver address of
                                    withdrawals from User&rsquo;s BIDBOX
                                    Address.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Transaction data, such as details of orders
                                    and purchases of Crypto Assets or
                                    User&rsquo;s Transaction History executed in
                                    the Exchanger/Market Platform as long as the
                                    said transaction is connected tto the
                                    Services;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    v.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Technical data, such as Internet Protocol
                                    (IP) address, login data, type and version
                                    of browser, timezone and location settings,
                                    type and version of browser plug-in,
                                    operation system and platform, device
                                    identity and matters related to the device
                                    when using the Services;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vi.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Profile
                                    data, such as Username and password,
                                    preferences, response and feedback to
                                    survey;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vii.&nbsp;&nbsp;&nbsp;&nbsp; Usage data,
                                    such as information on how User use the
                                    Services;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    viii.&nbsp;&nbsp; Location data, a method
                                    used by User when taking and sharing their
                                    location with BIDBOX in the form of photos
                                    or videos and uploading them to the BIDBOX
                                    official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ix.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Biometric
                                    data, is a sound file when the User uses
                                    other facial and body features and voice or
                                    things that are features of the device by
                                    uploading it to the BIDBOX official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    x.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Marketing data and correspondence, other
                                    matters related to User&rsquo;s preference
                                    ini receiving marketing from BIDBOX and
                                    third party, also User&rsquo;s communication
                                    preferences;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    xi.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; User
                                    agrees not to submit any inaccurate or
                                    misleading information, and User agrees to
                                    notify any inaccuracies or changes to such
                                    information to BIDBOX. BIDBOX reserves the
                                    right at its discretion to require further
                                    documentation to verify the information
                                    submitted by User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>C.&nbsp;&nbsp; </strong>
                                    <strong>DATA OBTAINMENT</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may
                                    receive, obtain, and collects User&rsquo;s
                                    data during the following situation but not
                                    limited to:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When User
                                    makes account in BIDBOX BIDBOX official site
                                    or updates BIDBOX account, including therein
                                    username, e-mail address, phone number,
                                    password, address, etc;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp; When User uses a
                                    feature or function available on BIDBOX
                                    BIDBOX official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp;&nbsp;&nbsp; When User registers
                                    for a service of BIDBOX through BIDBOX
                                    official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp;&nbsp;&nbsp; When User records and
                                    uploads a user-generated content to BIDBOX
                                    BIDBOX official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    v.&nbsp;&nbsp;&nbsp;&nbsp; When User uses
                                    the chatting function on BIDBOX BIDBOX
                                    official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vi.&nbsp;&nbsp;&nbsp; When User participates
                                    in a competition, promotion, or survey;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vii.&nbsp;&nbsp; When User participates in
                                    an activity or campaign on BIDBOX official
                                    site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    viii. When User logs into their account or
                                    interacts with BIDBOX through external
                                    service or BIDBOX official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ix.&nbsp;&nbsp;&nbsp; When User interacts
                                    with us off-line, including when User
                                    interacts with outsourced employees of
                                    BIDBOX off-line.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>D.&nbsp;&nbsp; </strong>
                                    <strong>USE OF DATA</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may use
                                    all or some data obtained and collected from
                                    User as referred to in the previous section
                                    for matters as follows:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To
                                    identify and register User as user and to
                                    administer, verify, deactivate, or manage
                                    account of User;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp; To facilitate or
                                    enable any verification which we consider
                                    necessary before BIDBOX provides services to
                                    User or before we register User as user,
                                    including the Know Your Customer process;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp;&nbsp;&nbsp; To enable BIDBOX to
                                    provide, process, and facilitate, orders and
                                    payment transactions;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp;&nbsp;&nbsp; To facilitate User
                                    Service in implementing instruction of User,
                                    dealing with or responding to any question
                                    raised by (or supposedly raised by) User
                                    and/or on behalf of User;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    v.&nbsp;&nbsp;&nbsp;&nbsp; To communicate
                                    with User and send information in connection
                                    with the use of BIDBOX Services, including
                                    but not limited to information about any
                                    update as well as support which may be
                                    required from time to time to ensure
                                    continuity of BIDBOX Services;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vi.&nbsp;&nbsp;&nbsp; To monitor and analyze
                                    activity, behavior, and demographic data,
                                    including habits and responsiveness of
                                    BIDBOX to various services available on the
                                    BIDBOX official site;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vii.&nbsp;&nbsp; To send User direct or
                                    targeted marketing communication,
                                    advertising, promotion, survey, and special
                                    offer or promotion;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    viii.&nbsp; To use information obtained from
                                    User for the purpose of research, analysis,
                                    development, and testing of product in order
                                    to improve safety and security of services
                                    on the Website, as well as for developing
                                    new feature and product;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ix.&nbsp;&nbsp;&nbsp; To perform monitoring
                                    or investigation of suspicious transactions
                                    or transactions indicated of containing the
                                    element of fraud or violation against the
                                    Terms and Conditions or the applicable legal
                                    provisions, as well as to take the necessary
                                    actions as follow-up of the result of
                                    monitoring or investigation of such
                                    transactions;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    x.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In certain
                                    situations, BIDBOX may need to use or
                                    disclose User&rsquo;s data for the purpose
                                    of law enforcement or for the fulfillment of
                                    requirements of the applicable laws and
                                    regulations, including in the event of any
                                    dispute or legal proceeding between User and
                                    BIDBOX;
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    xi.&nbsp;&nbsp;&nbsp; Any other purpose
                                    which BIDBOX will inform User when obtaining
                                    approval from User is collectively referred
                                    to as (
                                    <strong>&ldquo;Purpose&rdquo;</strong>
                                    &nbsp;);
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX will / may
                                    collect, use, disclose, or process personal
                                    data of User depending on the situation
                                    encountered, and it is possible that such
                                    Purpose may not stated above. However,
                                    BIDBOX will inform User about such other
                                    purpose of that kind when obtaining approval
                                    from User, except for data processing which
                                    applies without approval from User permitted
                                    by the Law related to Privacy Data.
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>E.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        PROTCTION OF USER&rsquo;S PERSONAL DATA
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX applies
                                    various safety measures and strives to
                                    ensure Personal Data of User in BIDBOX
                                    system. Personal data of User are kept
                                    behind a secure network and only accessible
                                    by a small number of employees having
                                    special access right to the system.
                                    Nevertheless, the absence of absolute
                                    guarantee or security is unavoidable.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX &nbsp;will
                                    keep personal data in accordance with Law
                                    related to Privacy Data and/or other
                                    applicable laws. Namely, BIDBOX will destroy
                                    or anonymize personal data of User when
                                    BIDBOX reasonable deems that (i) the purpose
                                    of personal data which have been collected
                                    is not functioned anymore by the storage of
                                    such personal data; (ii) the storage is not
                                    necessary anymore for any legal or
                                    commercial purposes whatsoever; and (iii)
                                    there is no instruction letter legitimating
                                    further retrieval of personal data. In the
                                    event that a User stops using BIDBOX
                                    Services, or the license of a User to use
                                    Services is terminated or revoked, BIDBOX
                                    may continue to keep, use, and/or disclose
                                    personal data of User in accordance with the
                                    Privacy Policy and obligations of BIDBOX
                                    under the Law related to Privacy Data.
                                    Subject to the applicable law, BIDBOX may
                                    securely dispose personal data of User
                                    without any prior notification to User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>F.&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        DISCLOSURE OF USER&rsquo;S PERSONAL DATA
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX guarantees
                                    that there is no sale, leakage, transfer,
                                    distribution, or lending of personal data of
                                    User to other party, without permission from
                                    User, except in the event of occurrence of
                                    the following matters or events:
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When there
                                    is requirement for disclosure of data of
                                    User to other party assisting BIDBOX in
                                    providing services on BIDBOX Services
                                    including BIDBOX official site and
                                    processing any form of activities of User on
                                    BIDBOX Services including BIDBOX official
                                    site, including but not limited to
                                    transaction process, payment verification,
                                    etc.;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    ii.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may
                                    provide and make available relevant data or
                                    information to BIDBOX business partner in
                                    accordance with rules of approval from User
                                    to use business partner&rsquo;s services,
                                    which are included in the BIDBOX official
                                    site, or other platform which integrates API
                                    or its services, or business partner with
                                    whom BIDBOX has cooperated to deliver forms
                                    of promotional services, contest, or other
                                    dedicated matters;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iii.&nbsp;&nbsp;&nbsp; Other matters
                                    required by BIDBOX such as banking, payment,
                                    and other services with User, in this case
                                    including settlement of obstacles and other
                                    matters;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    iv.&nbsp;&nbsp;&nbsp; BIDBOX may provide
                                    relevant information to vendor, consultant,
                                    marketing partner, research firm, or similar
                                    service providers;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    v.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may share
                                    information of User to its subsidiaries and
                                    affiliates to help in maximizing or
                                    providing services as well as data
                                    processing for and on behalf of BIDBOX;
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    vi.&nbsp;&nbsp;&nbsp; BIDBOX discloses data
                                    of User in an effort of complying with and
                                    fulfilling legal obligations and/or based on
                                    valid request from Similar Law Enforcement
                                    Agencies and Apparatus.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>G.&nbsp;&nbsp; </strong>
                                    <strong>
                                        STORAGE AND DELETION OF INFORMATION
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Personal
                                    Information of User will only be stored as
                                    long as necessary for fulfilling the purpose
                                    of its collection, or as long as such
                                    storage is required or allowed by the
                                    applicable laws and regulations. BIDBOX will
                                    stop storing Personal Information, or delete
                                    the purpose of linking such Personal
                                    Information to You as an individual, as soon
                                    as it is considered that the purpose of
                                    collection of such Personal Information does
                                    not require storing Personal Information
                                    anymore and the storage is not necessary
                                    anymore for business or legal purposes.
                                </p>
                                <p>&nbsp;</p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; Personal
                                    Information of User collected by BIDBOX may
                                    be stored, transferred, or processed by a
                                    third party service provider. BIDBOX will
                                    take reasonable effort to ensure that all of
                                    such third party service providers provide a
                                    level of protection equivalent to the
                                    commitment of BIDBOX based on this Privacy
                                    Policy.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; Personal
                                    Information of User may also be stored or
                                    processed outside the country of the User by
                                    a party working for BIDBOX in other country,
                                    or by third party service provider, vendor,
                                    supplier, partner, contractor, or affiliate
                                    of BIDBOX. In this case, BIDBOX will ensure
                                    that such Personal Information continues to
                                    be a subject of level of protection
                                    equivalent to those required in the law of
                                    the country (and, in any case whatsoever, in
                                    line with our commitment in this Privacy
                                    Policy).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX will store
                                    and process Information as long as the
                                    account of User is active.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>H.&nbsp;&nbsp; </strong>
                                    <strong>COOKIES</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Our site may use
                                    Cookies to improve user experience. Users'
                                    web browsers place cookies on their hard
                                    drives for recording purposes and sometimes
                                    to track information about them. User can
                                    choose to sets their web browser to refuse
                                    cookies, or to notify when a cookie is sent.
                                    If they do, note that some parts of the Site
                                    may not function properly.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may link
                                    information cookies to personal data.
                                    Cookies are also linked to information on
                                    transactions of User and Cookies are also
                                    used to deliver specific content in
                                    accordance with the interests of User and to
                                    monitor the use of BIDBOX Services.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; User will
                                    automatically receive Cookies when the
                                    Computer or Device of User uses or browses
                                    BIDBOX official site. However, User may
                                    choose to modify or choose to refuse the Use
                                    of Cookies through web or browser setting
                                    preferences in the device or computer of
                                    User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may use
                                    and provide information on data of User
                                    BIDBOX obtained from the Use of Cookies by
                                    User to a third party, such as location
                                    data, advertising identifier, or e-mail
                                    address used for advertisement, including
                                    but not limited to for promotional and
                                    advertisement purposes in which such data
                                    cannot personally identified.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    5.&nbsp;&nbsp;&nbsp;&nbsp; The use of
                                    Cookies can be controlled by User through
                                    setting preferences of web browser used by
                                    User, namely by making modification or
                                    choosing to refuse the Use of Cookies.
                                    However, by making such setting, performance
                                    of service during access to BIDBOX Official
                                    site may be affected, such as certain
                                    functions and pages on BIDBOX Website unable
                                    to perform optimally for the provision of
                                    services to User.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>I.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>
                                        DISCLAIMER OF SECURITY AND THIRD PARTY
                                        WEBSITE
                                    </strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX DOES NOT
                                    GUARANTEE SECURITY OF PERSONAL DATA AND/OR
                                    OTHER INFORMATION THE MEMBER / VERIFIED
                                    MEMBER SUBMITS ON A THIRD PARTY WEBSITE.
                                    BIDBOX has indeed implemented a variety of
                                    security measures to maintain the safety of
                                    personal data of User with or under the
                                    control of BIDBOX. Personal data of User are
                                    kept behind a secure network and only
                                    accessible by a limited number of persons
                                    having special access right to the system,
                                    who are obligated to keep the
                                    confidentiality of such personal data. In
                                    the event that the User places an order or
                                    accesses personal data, BIDBOX offers the
                                    use of secure server. All personal data or
                                    sensitive information submitted by User are
                                    encrypted into our database and can only be
                                    accessed as stated above.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; In an effort of
                                    providing User with increasing value, BIDBOX
                                    may choose various third party websites to
                                    be linked to, and to frame them in the
                                    BIDBOX official site. BIDBOX may also
                                    participate in co-branding and other
                                    relationship to offer e-commerce as well as
                                    other services and features to BIDBOX
                                    visitors. All of these linked websites have
                                    separate and independent privacy policy and
                                    security settings. Even if a third party is
                                    affiliated to BIDBOX, BIDBOX has no control
                                    over these linked websites, since each of
                                    them has privacy and data collection
                                    practices separated from BIDBOX. It is
                                    possible that data collected by BIDBOX
                                    co-branding partner or third party website
                                    (even if it is offered on or through BIDBOX
                                    official site) are not received by BIDBOX.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; Therefore, BIDBOX
                                    is not responsible or accountable for
                                    content, security settings (or the absence
                                    of security settings), and activities of
                                    these linked websites. These linked websites
                                    are only for the convenience of User, and
                                    therefore User accesses them at the
                                    User&rsquo;s own risk. Nevertheless, BIDBOX
                                    strives to protect the integrity of BIDBOX
                                    official site and links placed on each
                                    website, and therefore, BIDBOX welcomes any
                                    feedback on these linked websites
                                    (including, but not limited to, in the event
                                    that certain link is not functioning).
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    <strong>J.&nbsp;&nbsp;&nbsp;&nbsp; </strong>
                                    <strong>MISCELLANEOUS</strong>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    1.&nbsp;&nbsp;&nbsp;&nbsp; Services are not
                                    intended for children aged less than 17
                                    years. BIDBOX does not intentionally collect
                                    or store personal data or any non-personal
                                    information from any person aged less than
                                    17 years and any part of BIDBOX Services is
                                    not intended for children aged less than 17
                                    years. As parent or legal guardian, please
                                    do not give permission to children under
                                    your care to submit personal data to BIDBOX.
                                    In the event that personal data of children
                                    aged less than 17 years under your care are
                                    opened to BIDBOX, you hereby consent to the
                                    processing of personal data of the child and
                                    accept and agree to be bound to this Privacy
                                    Policy on behalf of such child. BIDBOX will
                                    close any account used exclusively by such
                                    children and will eliminate and/or delete
                                    any personal data BIDBOX believes to be sent
                                    without consent of parent or legal guardian
                                    by a child aged less than 17 years.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    2.&nbsp;&nbsp;&nbsp;&nbsp; BIDBOX may update
                                    this Privacy Policy at any time. BIDBOX
                                    hereby advises User to read carefully and
                                    always recheck every page of this privacy
                                    policy from time to time to find out about
                                    any changes which have been made to this
                                    Privacy Policy. In the event that BIDBOX
                                    makes any amendment to BIDBOX Privacy
                                    Policy, BIDBOX will post such amendment or
                                    the Privacy Policy which has been amended on
                                    BIDBOX official site. BIDBOX has full rights
                                    to amend this Privacy Policy at any time
                                    without requiring approval from User but by
                                    referring to the applicable laws and
                                    regulations.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    3.&nbsp;&nbsp;&nbsp;&nbsp; IN THE EVENT THAT
                                    USER DOES NOT PERMIT THE PROCESSING OF
                                    PERSONAL DATA OF USER AS EXPLAINED IN THIS
                                    PRIVACY POLICY, WE DO NOT ADVISE TO USE
                                    BIDBOX SERVICES OR TO ACCESS BIDBOX OFFICIAL
                                    SITE.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="100%">
                                <p>
                                    4.&nbsp;&nbsp;&nbsp;&nbsp; If User has any
                                    questions, issues, or complaints regarding
                                    BIDBOX privacy policy practices, please
                                    contact us by e-mail at&nbsp;
                                    <a href="mailto:support@bidbox.community">
                                        support@bidbox.community
                                    </a>
                                    .
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
