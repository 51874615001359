export const TICKET_HISTORY = "TICKET_HISTORY";
export const TICKET_HISTORY_SUCCESS = "TICKET_HISTORY_SUCCESS";
export const TICKET_HISTORY_ERROR = "TICKET_HISTORY_ERROR";
export const TICKET_CREATE = "TICKET_CREATE";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
export const TICKET_CREATE_ERROR = "TICKET_CREATE_ERROR";
export const TICKET_REPLY = "TICKET_REPLY";
export const TICKET_REPLY_SUCCESS = "TICKET_REPLY_SUCCESS";
export const TICKET_REPLY_ERROR = "TICKET_REPLY_ERROR";
export const TICKET_INIT = "TICKET_INIT";
