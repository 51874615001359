import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    AUTH_ERROR
} from "./actionTypes";

export const loginUser = (user, history) => {
    return {
        type: LOGIN,
        payload: { user, history },
    };
};

export const logoutUser = (history) => {
    return {
        type: LOGOUT,
        payload: { history },
    };
};

export const loginSuccess = (message) => {
    return {
        type: LOGIN_SUCCESS,
        payload: message,
    };
};
export const authError = (message) => {
    return {
        type: AUTH_ERROR,
        payload: message,
    };
};
