import {
    WALLET,
    WALLET_SUCCESS,
    WALLET_ERROR,
    VALIDATE_DEPOSIT,
    VALIDATE_DEPOSIT_ERROR,
    VALIDATE_DEPOSIT_SUCCESS,
    TRANSFER,
    TRANSFER_ERROR,
    TRANSFER_SUCCESS,
    TRANSFER_INIT,
    WITHDRAW,
    WITHDRAW_ERROR,
    WITHDRAW_SUCCESS,
    WITHDRAW_INIT,
} from "./actionTypes";

const initialState = {
    address: null,
    currency_id: null,
    loading: false,
    loadingValidate: false,
    successMsg: null,
    errorMsg: null,
    validateSuccess: null,
    validateError: null,
    transferSuccess: null,
    transferError: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case WITHDRAW_INIT:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case WITHDRAW:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case WITHDRAW_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case WITHDRAW_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        case TRANSFER_INIT:
            state = {
                ...state,
                loading: false,
                transferSuccess: null,
                transferError: null,
            };
            break;

        case TRANSFER:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                transferSuccess: null,
                transferError: null,
            };
            break;

        case TRANSFER_SUCCESS:
            state = {
                ...state,
                loading: false,
                transferSuccess: action.payload,
                transferError: null,
            };
            break;
        case TRANSFER_ERROR:
            state = {
                ...state,
                loading: false,
                transferSuccess: null,
                transferError: action.payload,
            };
            break;
        case WALLET:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
                validateSuccess: null,
                validateError: null,
            };
            break;
        case VALIDATE_DEPOSIT:
            state = {
                ...state,
                loadingValidate: true,
                validateSuccess: null,
                validateError: null,
            };
            break;
        case WALLET_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
            };
            break;
        case VALIDATE_DEPOSIT_SUCCESS:
            state = {
                ...state,
                loadingValidate: false,
                validateSuccess: action.payload,
            };
            break;

        case VALIDATE_DEPOSIT_ERROR:
            state = {
                ...state,
                loadingValidate: false,
                validateError: action.payload,
            };
            break;

        case WALLET_ERROR:
            state = { ...state, loading: false, errorMsg: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
