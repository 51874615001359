import React from "react";
import BidboxLogo from "../../assets/image/logo/logo-bidbox-notext.png";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
    getWallet,
    getBalance,
    validateDeposit,
    withdrawHistory,
    withdrawInit,
    depositHistory,
    withdrawHistoryInit,
    transferHistoryInit,
    transferHistory,
    gasFeeHistory,
} from "src/store/actions";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { toFixedIfNecessary } from "src/utils/helpers";
import InfiniteScroll from "react-infinite-scroll-component";

const Asset = () => {
    const dispatch = useDispatch();
    const [showBalance, setShowBalance] = useState(true);
    const [showLockedBalance, setShowLockedBalance] = useState(true);
    const [toggle, setToggle] = useState(1);
    const balance = useSelector((state) => state.Balance);
    const wallet = useSelector((state) => state.Wallet);
    const withdraw = useSelector((state) => state.Withdraw);
    const deposit = useSelector((state) => state.Deposit);
    const transfer = useSelector((state) => state.Transfer);
    const [toggleGasFee, setToggleGasFee] = useState(null);
    const [toggleItemsGF, setToggleItemsGF] = useState(null);
    const gasFeeState = useSelector((state) => state.GasFee);
    const [posts, setPosts] = useState([]);
    const [dataTransfer, setDataTransfer] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [hasMoreTransfer, setHasMoreTransfer] = useState(true);
    const [lastDay, setLastDay] = useState(new Date());
    const [lastIdTransfer, setLastIdTransfer] = useState(0);
    const toggleTabs = (index) => {
        setToggle(index);
    };
    const doValidateDeposit = (isAlert) => {
        dispatch(validateDeposit(wallet.address));
    };
    useEffect(() => {
        dispatch(getBalance());
        dispatch(getWallet());
        dispatch(
            withdrawHistory({
                from_id: 0,
            })
        );
        dispatch(
            depositHistory({
                from_id: 0,
            })
        );
        dispatch(
            transferHistory({
                from_id: 0,
            })
        );

        return () => {
            dispatch(withdrawInit());
            dispatch(withdrawHistoryInit());
            dispatch(transferHistoryInit());
        };
    }, []);
    useEffect(() => {
        if (balance.errorMsg !== null && balance.errorMsg !== "")
            toast(balance.errorMsg);
    }, [balance.errorMsg]);
    useEffect(() => {
        if (withdraw.errorMsg !== null && withdraw.errorMsg !== "")
            toast(withdraw.errorMsg);
    }, [withdraw.errorMsg]);
    useEffect(() => {
        if (transfer.errorMsg !== null && transfer.errorMsg !== "")
            toast(transfer.errorMsg);
    }, [transfer.errorMsg]);
    useEffect(() => {
        if (wallet.validateSuccess !== null && wallet.validateSuccess !== "")
            toast(wallet.validateSuccess);
        if (wallet.validateError !== null && wallet.validateError !== "")
            toast(wallet.validateError);
    }, [wallet.validateSuccess, wallet.validateError]);
    useEffect(() => {
        if (wallet.errorMsg !== null && wallet.errorMsg !== "")
            toast(wallet.errorMsg);
        // if (wallet.loadingValidate && validateAlert) toast("Refreshing...");
    }, [wallet.errorMsg]);
    const status_transaksi = (status) => {
        switch (status) {
            case 0:
                return <span style={{ color: "orange" }}>PENDING</span>;
            case 1:
                return <span style={{ color: "red" }}>FAILED</span>;
            case 2:
                return <span style={{ color: "green" }}>SUCCESS</span>;

            default:
                return <span style={{ color: "orange" }}>PENDING</span>;
        }
    };

    const loadMore = async () => {
        setLastDay(moment(lastDay).subtract("31", "days"));
    };

    const loadMoreTransfer = async () => {
        setLastIdTransfer(transfer.last_id);
    };
    useEffect(() => {
        const from_date = moment(lastDay)
            .subtract("90", "days")
            .format("YYYY-MM-DD");
        const to_date = moment(lastDay).format("YYYY-MM-DD");
        dispatch(
            gasFeeHistory({
                exchange_id: 1,
                from_date: from_date,
                to_date: to_date,
            })
        );
    }, [lastDay]);
    useEffect(() => {
        if (gasFeeState.items.length > 0) {
            let tmp = [...posts, ...gasFeeState.items];
            tmp = tmp
                .filter((a, i) => tmp.findIndex((s) => a.date === s.date) === i)
                .sort(function (a, b) {
                    if (a.date_epoch < b.date_epoch) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            setPosts(tmp);
        }
        setHasMore(true);
    }, [gasFeeState.items]);

    useEffect(() => {
        if (transfer.items.length > 0) {
            let tmp = [...dataTransfer, ...transfer.items];
            tmp = tmp
                .filter((a, i) => tmp.findIndex((s) => a.id === s.id) === i)
                .sort(function (a, b) {
                    if (a.created_at < b.created_at) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            setDataTransfer(tmp);
        }
        setHasMoreTransfer(true);
    }, [transfer.items]);

    useEffect(() => {
        if (lastIdTransfer !== null && lastIdTransfer !== 0) {
            dispatch(
                transferHistory({
                    from_id: lastIdTransfer,
                })
            );
        }
    }, [lastIdTransfer]);
    useEffect(() => {
        if (gasFeeState.errorMsg !== null) toast(gasFeeState.errorMsg);
    }, [gasFeeState.errorMsg]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">Asset</span>
                <div className="n"></div>
            </header>
            <div className="card mb-2 bg-app-color overflow-x:auto">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-block">
                            <div className="d-flex flex-column">
                                <h1 className="m-0 fz:12 text-dark">
                                    Total Asset Balance
                                </h1>
                                <h1 className="m-0 fz:17 fw:600 text-white">
                                    {showBalance
                                        ? balance.active_balance
                                        : "***"}

                                    <i
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: 10,
                                            color: "#cacaca",
                                        }}
                                        onClick={() =>
                                            setShowBalance(!showBalance)
                                        }
                                        className={
                                            showBalance
                                                ? "fa-regular fa-eye fz:12"
                                                : "fa-regular fa-eye-slash fz:12"
                                        }
                                    ></i>
                                </h1>
                            </div>
                            <div className="d-flex flex-column">
                                <h1 className="m-0 fz:12 text-dark">
                                    Locked Gas Fee
                                </h1>
                                <h1 className="m-0 fz:12 fw:600 text-white">
                                    {showLockedBalance
                                        ? balance.locked_balance
                                        : "***"}
                                    <i
                                        style={{
                                            color: "#cacaca",
                                            cursor: "pointer",
                                            marginLeft: 10,
                                        }}
                                        onClick={() =>
                                            setShowLockedBalance(
                                                !showLockedBalance
                                            )
                                        }
                                        className={
                                            showLockedBalance
                                                ? "fa-regular fa-eye fz:12"
                                                : "fa-regular fa-eye-slash fz:12"
                                        }
                                    ></i>
                                </h1>
                            </div>
                        </div>
                        <div className="logo-absolute">
                            <img src={BidboxLogo} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-2">
                <div className="card-body">
                    <div className="d-grid grid-column:3">
                        <Link
                            to="/user/deposit"
                            className="d-flex flex-column align-items-center text-decoration-none hover:text-app-color-70"
                        >
                            <i className="fa-solid fa-sack-dollar text-app fz:22 mb-1"></i>
                            <h2 className="m-0 fz:12 fw:600 text-dark">
                                Deposit
                            </h2>
                        </Link>
                        <Link
                            to="/user/withdrawal"
                            className="d-flex flex-column align-items-center text-decoration-none hover:text-app-color-70"
                        >
                            <i className="fa-solid fa-wallet fz:22 text-app mb-1"></i>
                            <h2 className="m-0 fz:12 fw:600 text-dark">
                                Withdrawal
                            </h2>
                        </Link>
                        <Link
                            to="/user/transfer"
                            className="d-flex flex-column align-items-center text-decoration-none hover:text-app-color-70"
                        >
                            <i className="fa-solid fa-money-bill-transfer text-app fz:22 mb-1"></i>
                            <h2 className="m-0 fz:12 fw:600 text-dark">
                                Transfer
                            </h2>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mb-2">
                <h1 className="m-0 fz:12 fw:600 text-dark text-uppercase">
                    History Record
                </h1>
                <button
                    onClick={() => doValidateDeposit(true)}
                    className="btn fz:12 fw:600 p-0 d-flex align-items-center"
                    style={{ height: "auto" }}
                >
                    <i className="bx bx-refresh fz:20"></i>
                    <span style={{ color: "red" }}>Update Balance</span>
                </button>
            </div>

            <div
                className="card mb-2 overflow-x:auto overflow-y:hidden border-0"
                style={{ minWidth: "100%" }}
            >
                <div
                    className="d-grid py-2 grid-column:4-fix"
                    style={{ gap: "10px" }}
                >
                    <button
                        className={
                            toggle === 1 ? "btn-tabs active" : "btn-tabs"
                        }
                        onClick={() => toggleTabs(1)}
                    >
                        Deposit
                    </button>
                    <button
                        className={
                            toggle === 2 ? "btn-tabs active" : "btn-tabs"
                        }
                        onClick={() => toggleTabs(2)}
                    >
                        Withdrawal
                    </button>
                    <button
                        className={
                            toggle === 3 ? "btn-tabs active" : "btn-tabs"
                        }
                        onClick={() => toggleTabs(3)}
                    >
                        Transfer
                    </button>
                    <button
                        className={
                            toggle === 4 ? "btn-tabs active" : "btn-tabs"
                        }
                        onClick={() => toggleTabs(4)}
                    >
                        Gas Fee
                    </button>
                </div>
            </div>
            {toggle === 1 ? (
                <>
                    {deposit.items.map((k, v) => (
                        <div key={v} className="card mb-2 order-1 card-active">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Deposit
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        {new Date(
                                            k.created_at * 1000
                                        ).toLocaleString("en-GB")}
                                    </p>
                                </div>
                                <h2 className="mb-1 fz:17 text-dark">
                                    {k.amount} USDT
                                </h2>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Chain
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        TRC20
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2
                                        className="m-0 fz:11 fw:600 text-gray"
                                        style={{ width: "200px" }}
                                    >
                                        Deposit Address
                                    </h2>
                                    <p
                                        className="m-0 fz:11 fw:600 text-gray"
                                        style={{ wordBreak: "break-all" }}
                                    >
                                        {k.sender_address}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Status
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        {status_transaksi(k.status)}
                                        {/* PENDING/APPROVED/SUCCES */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}

            {toggle === 2 ? (
                <>
                    {withdraw.items.map((k, v) => (
                        <div key={v} className="card mb-2 order-1">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Withdrawal
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        {new Date(
                                            k.created_at * 1000
                                        ).toLocaleString("en-GB")}
                                    </p>
                                </div>
                                <h2 className="mb-1 fz:17 text-dark">
                                    {k.amount} USDT
                                </h2>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Chain
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        TRC20
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2
                                        className="m-0 fz:11 fw:600 text-gray"
                                        style={{ width: "200px" }}
                                    >
                                        Withdrawal Address
                                    </h2>
                                    <p
                                        className="m-0 fz:11 fw:600 text-gray"
                                        style={{ wordBreak: "break-all" }}
                                    >
                                        {k.dst_address}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2
                                        className="m-0 fz:11 fw:600 text-gray"
                                        style={{ width: "200px" }}
                                    >
                                        Hash
                                    </h2>
                                    <p
                                        className="m-0 fz:11 fw:600 text-gray"
                                        style={{ wordBreak: "break-all" }}
                                    >
                                        {k.tx_id}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Fee Withdrawal
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        {k.fee_amount} USDT
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-1">
                                    <h2 className="m-0 fz:11 fw:600 text-gray">
                                        Status
                                    </h2>
                                    <p className="m-0 fz:11 fw:600 text-gray">
                                        {status_transaksi(k.status)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}
            {toggle === 3 && (
                <InfiniteScroll
                    dataLength={dataTransfer.length}
                    next={loadMoreTransfer}
                    hasMore={hasMoreTransfer}
                    // loader={<h3> Loading...</h3>}
                    // endMessage={<h4>Nothing more to show</h4>}
                >
                    <>
                        {transfer.items.map((k, v) => (
                            <div key={v} className="card mb-2 order-1">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <h2
                                            className={
                                                transfer.user_id ===
                                                k.dst_user_id
                                                    ? "m-0 fz:11 fw:600 text-green"
                                                    : "m-0 fz:11 fw:600 text-red"
                                            }
                                        >
                                            {transfer.user_id === k.dst_user_id
                                                ? "Incoming Transfer"
                                                : "Outgoing Transfer"}
                                        </h2>
                                        <p className="m-0 fz:11 fw:600 text-gray">
                                            {new Date(
                                                k.created_at * 1000
                                            ).toLocaleString("en-GB")}
                                        </p>
                                    </div>
                                    <h2
                                        className={
                                            transfer.user_id === k.dst_user_id
                                                ? "mb-1 fz:17 text-green"
                                                : "mb-1 fz:17 text-red"
                                        }
                                    >
                                        {k.amount} USDT
                                    </h2>
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <h2 className="m-0 fz:11 fw:600 text-gray">
                                            Chain
                                        </h2>
                                        <p className="m-0 fz:11 fw:600 text-gray">
                                            TRC20
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <h2 className="m-0 fz:11 fw:600 text-gray">
                                            Fee Amount
                                        </h2>
                                        <p className="m-0 fz:11 fw:600 text-gray">
                                            {k.fee_amount} USDT
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <h2 className="m-0 fz:11 fw:600 text-gray">
                                            {transfer.user_id === k.dst_user_id
                                                ? "Receive from"
                                                : "Send to"}
                                        </h2>
                                        <p className="m-0 fz:11 fw:600 text-gray">
                                            {transfer.user_id === k.dst_user_id
                                                ? k.src_username
                                                : k.dst_username}
                                        </p>
                                    </div>
                                    {/* <div className="d-flex align-items-center justify-content-between mb-1">
                        <h2 className="m-0 fz:11 fw:600 text-gray">
                            Status
                        </h2>
                        <p className="m-0 fz:11 fw:600 text-gray">
                            PENDING/APPROVED/SUCCES
                        </p>
                    </div> */}
                                </div>
                            </div>
                        ))}
                    </>
                </InfiniteScroll>
            )}

            {toggle === 4 && (
                <InfiniteScroll
                    dataLength={posts.length}
                    next={loadMore}
                    hasMore={hasMore}
                    // loader={<h3> Loading...</h3>}
                    // endMessage={<h4>Nothing more to show</h4>}
                >
                    {posts.map((data, idx) => {

                        let arrays = 0
                        if(data.items !== null){
                            data.items.map((k, i) => {
                                if (k.status_str === "AVERAGING")
                                    return false;
                                arrays ++
                            })
                        }
                        if (arrays === 0) return

                            return (
                                <div
                                    className={
                                        toggleGasFee === idx
                                            ? "accordion active"
                                            : "accordion"
                                    }
                                    key={idx}
                                >
                                    <button
                                        type="button"
                                        className="accordion-button"
                                        onClick={() =>
                                            setToggleGasFee(
                                                toggleGasFee === idx ? null : idx
                                            )
                                        }
                                    >
                                <span className="fz:13 fw:500 text-dark">
                                    {data.date}
                                </span>
                                        <div className="d-flex flex-column">
                                    <span
                                        className={
                                            data.gas_fee >= 0
                                                ? "fz:15 fw:500 text-green-dark"
                                                : "fz:15 fw:500 text-red"
                                        }
                                    >
                                        {toFixedIfNecessary(data.gas_fee, 8)}
                                    </span>
                                            <span
                                                className={
                                                    data.gas_fee >= 0
                                                        ? "fz:13 fw:500 text-green-dark"
                                                        : "fz:13 fw:500 text-red"
                                                }
                                            >
                                        Gas Fee
                                    </span>
                                        </div>
                                        <i className="fa-solid fa-angle-down"></i>
                                    </button>
                                    <div
                                        className={
                                            toggleGasFee === idx
                                                ? "accordion-content show-content"
                                                : "accordion-content"
                                        }
                                    >
                                        {data.items !== null &&
                                            data.items.map((k, i) => {
                                                if (k.status_str === "AVERAGING")
                                                    return false;
                                                return (
                                                    <div
                                                        className="card mb-2 card-transaction"
                                                        key={i}
                                                    >
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="d-flex align-items-center mb-2">
                                                                    <div className="icon-coin">
                                                                        <img
                                                                            src={
                                                                                k.image
                                                                            }
                                                                            alt={
                                                                                k.base_asset
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        style={{
                                                                            columnGap:
                                                                                "5px",
                                                                        }}
                                                                    >
                                                                        <h2 className="m-0 fz:16 fw-600 text-dark me-1">
                                                                            {
                                                                                k.base_asset
                                                                            }{" "}
                                                                            /
                                                                        </h2>
                                                                        <p className="m-0 fz:12 fw-500 text-dark">
                                                                            {
                                                                                k.quote_asset
                                                                            }
                                                                        </p>
                                                                        <p
                                                                            className={`m-0 fz:11 ${
                                                                                k.status_str ===
                                                                                "CUT_SELL"
                                                                                    ? "text-red"
                                                                                    : "text-green"
                                                                            } info-transaction`}
                                                                        >
                                                                            {
                                                                                k.status_str
                                                                            }
                                                                        </p>
                                                                        {k.avg_for !==
                                                                            "" && (
                                                                                <p
                                                                                    className={`m-0 fz:11 info-transaction`}
                                                                                    style={{
                                                                                        color: "#008aae",
                                                                                    }}
                                                                                >
                                                                                    AVG
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                <p className="m-0 text-mute fz:11">
                                                                    {moment(
                                                                        k.complete_at
                                                                    ).format(
                                                                        "YYYY-MM-DD HH:m:s"
                                                                    )}
                                                                </p>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="m-0 text-dark fz:15">
                                                                    {k.avg_for !== ""
                                                                        ? toFixedIfNecessary(
                                                                            (k.sharing_profit_pc /
                                                                                100) *
                                                                            ((k.last_buy_qty +
                                                                                    k.qty) *
                                                                                k.sell_price -
                                                                                (k.last_buy_price *
                                                                                    k.last_buy_qty +
                                                                                    k.buy_price *
                                                                                    k.qty)),
                                                                            8
                                                                        )
                                                                        : toFixedIfNecessary(
                                                                            (k.sharing_profit_pc /
                                                                                100) *
                                                                            k.profit_amount,
                                                                            8
                                                                        )}{" "}
                                                                </p>
                                                                <p
                                                                    className={`m-0 ${
                                                                        k.avg_for !== ""
                                                                            ? (k.last_buy_qty +
                                                                                k.qty) *
                                                                            k.sell_price -
                                                                            (k.last_buy_price *
                                                                                k.last_buy_qty +
                                                                                k.buy_price *
                                                                                k.qty) <
                                                                            0
                                                                                ? "text-red"
                                                                                : "text-green"
                                                                            : k.profit_amount <
                                                                            0
                                                                                ? "text-red"
                                                                                : "text-green"
                                                                    } fz:15`}
                                                                >
                                                                    {k.avg_for !== ""
                                                                        ? `${toFixedIfNecessary(
                                                                            (k.last_buy_qty +
                                                                                k.qty) *
                                                                            k.sell_price -
                                                                            (k.last_buy_price *
                                                                                k.last_buy_qty +
                                                                                k.buy_price *
                                                                                k.qty),
                                                                            4
                                                                        )}`
                                                                        : `${toFixedIfNecessary(
                                                                            k.profit_amount,
                                                                            4
                                                                        )}`}
                                                                </p>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="m-0 text-mute fz:11">
                                                                    Gas Fee (USDT)
                                                                </p>
                                                                <p className="m-0 text-mute fz:11">
                                                                    {k.avg_for !== ""
                                                                        ? (k.last_buy_qty +
                                                                            k.qty) *
                                                                        k.sell_price -
                                                                        (k.last_buy_price *
                                                                            k.last_buy_qty +
                                                                            k.buy_price *
                                                                            k.qty) <
                                                                        0
                                                                            ? "Loss"
                                                                            : "Profit"
                                                                        : k.profit_amount <
                                                                        0
                                                                            ? "Loss"
                                                                            : "Profit"}{" "}
                                                                    (USDT)
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            )

                    })}
                </InfiniteScroll>
            )}
        </div>
    );
};

export default Asset;
