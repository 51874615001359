import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getReward } from "src/store/actions";
import toast from "react-hot-toast";
import Tab from "../../components/Tab/Tab";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
const RewardDetail = () => {
    const dispatch = useDispatch();
    const reward = useSelector((state) => state.Reward);
    const [showProfit, setShowProfit] = useState(true);
    const [showCumulative, setShowCumulative] = useState(true);
    const [filter, setFilter] = useState("all");
    const [toggle, setToggle] = useState();
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [lastDay, setLastDay] = useState(new Date());
    const toggleActive = (id) => {
        if (toggle === id) {
            setToggle(null);
        } else {
            setToggle(id);
        }
    };
    useEffect(() => {
        const from_date = moment(lastDay)
            .subtract("30", "days")
            .format("YYYY-MM-DD");
        const to_date = moment(lastDay).format("YYYY-MM-DD");
        dispatch(getReward(from_date, to_date));
    }, [lastDay]);
    const loadMore = async () => {
        setLastDay(moment(lastDay).subtract("31", "days"));
    };
    useEffect(() => {
        if (reward.items.length > 0) {
            let tmp = [...posts, ...reward.items];
            tmp = tmp
                .filter(
                    (a, i) =>
                        tmp.findIndex((s) => a.bonus_date === s.bonus_date) ===
                        i
                )
                .sort(function (a, b) {
                    if (a.date_epoch < b.date_epoch) {
                        return 0;
                    } else {
                        return -1;
                    }
                });
            console.log(tmp);
            setPosts(tmp);
        } else {
            let last_from_date = new Date(reward.from_date).getTime();
            let first_from_date = new Date("2022-09-01 00:00:00").getTime();
            if (last_from_date > first_from_date) {
                loadMore();
            }
        }
        setHasMore(true);
    }, [reward.items]);
    const get_total = (data) => {
        if (filter === "all") {
            return (
                data.direct_activation +
                data.direct_profit_sharing +
                data.global_deviden_activation +
                data.global_deviden_profit_sharing +
                data.pass_up_activation +
                data.pass_up_profit_sharing +
                data.peer_activation +
                data.peer_profit_sharing +
                " USDT"
            );
        } else {
            return data[filter] + " USDT";
        }
    };
    useEffect(() => {
        if (reward.errorMsg !== null) toast(reward.errorMsg);
    }, [reward.errorMsg]);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Reward Detail
                </span>
                <div className="n"></div>
            </header>
            {/* <div className="tab overflow-x:auto whitespace-nowrap">
                <Tab
                    tabs={[
                        {
                            label: "All",
                            onClick: () => {
                                setFilter("all");
                            },
                        },
                        {
                            label: "Direct Activation",
                            onClick: () => {
                                setFilter("direct_activation");
                            },
                        },
                        {
                            label: "Team Activation",
                            onClick: () => {
                                setFilter("pass_up_activation");
                            },
                        },
                        {
                            label: "Peer Activation",
                            onClick: () => {
                                setFilter("peer_activation");
                            },
                        },
                        {
                            label: "Direct Profit Sharing",
                            onClick: () => {
                                setFilter("direct_profit_sharing");
                            },
                        },
                        {
                            label: "Team Profit Sharing",
                            onClick: () => {
                                setFilter("pass_up_profit_sharing");
                            },
                        },
                        {
                            label: "Peer Profit Sharing",
                            onClick: () => {
                                setFilter("peer_profit_sharing");
                            },
                        },
                        {
                            label: "Global Dividend Activation",
                            onClick: () => {
                                setFilter("global_deviden_activation");
                            },
                        },
                        {
                            label: "Global Dividend Profit Sharing",
                            onClick: () => {
                                setFilter("global_deviden_profit_sharing");
                            },
                        },
                    ]}
                />
            </div> */}
            <div
                className="card mb-2 bg-app-color"
                style={{ overflow: "auto" }}
            >
                <div className="card-body">
                    <div className="d-grid grid-column:2 mb-3">
                        <div className="d-flex flex-column">
                            <p className="m-0 fz:15 fw:600 text-dark">
                                Today Reward
                            </p>
                            <h2 className="m-0 fz:15 fw:600 text-white">
                                {showProfit ? reward.todayProfit : "***"}{" "}
                                <span className="fz:12">USDT</span>
                                <i
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        color: "#cacaca",
                                    }}
                                    onClick={() => setShowProfit(!showProfit)}
                                    className={
                                        showProfit
                                            ? "fa-regular fa-eye fz:12"
                                            : "fa-regular fa-eye-slash fz:12"
                                    }
                                ></i>
                            </h2>
                            {/* <p className="m-0 fz:11 text-white">= 0 IDR</p> */}
                        </div>
                        <div className="d-flex flex-column">
                            <p className="m-0 fz:15 fw-600 text-dark text-center">
                                Cumulative Reward
                            </p>
                            <h2 className="m-0 fz:15 fw-600 text-white text-center">
                                {showCumulative
                                    ? reward.cumulativeProfit
                                    : "***"}{" "}
                                <span className="fz:12">USDT</span>
                                <i
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                        color: "#cacaca",
                                    }}
                                    onClick={() =>
                                        setShowCumulative(!showCumulative)
                                    }
                                    className={
                                        showCumulative
                                            ? "fa-regular fa-eye fz:12"
                                            : "fa-regular fa-eye-slash fz:12"
                                    }
                                ></i>
                            </h2>
                            {/* <p className="m-0 fz:11 text-white text-center">
                                = 0 IDR
                            </p> */}
                        </div>
                    </div>
                    <p className="fz:12 text-right text-white mb-0 mt-2">
                        Data within 24 hours might not be real time.
                    </p>
                    <p className="fz:12 text-right text-white m-0">
                        Data is shown under your chosen timezone.
                    </p>
                </div>
            </div>

            <InfiniteScroll
                dataLength={posts.length}
                next={loadMore}
                hasMore={hasMore}
                // loader={<h3> Loading...</h3>}
                // endMessage={<h4>Nothing more to show</h4>}
            >
                {posts.map((data, idx) => (
                    <div
                        className={
                            toggle === data.bonus_date
                                ? "accordion active"
                                : "accordion"
                        }
                        key={idx}
                    >
                        <button
                            type="button"
                            className="accordion-button"
                            onClick={() => toggleActive(data.bonus_date)}
                        >
                            <span className="fz:11 text-dark">
                                {data.bonus_date}
                            </span>
                            <span className="fz:13 fw-600 text-green-dark">
                                {get_total(data)}
                            </span>
                            <i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div
                            className={
                                toggle === data.bonus_date
                                    ? "accordion-content show-content"
                                    : "accordion-content"
                            }
                        >
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Direct Activation
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.direct_activation}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Team Activation
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.pass_up_activation}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Peer Activation
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.peer_activation}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Direct Profit Sharing
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.direct_profit_sharing}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Team Profit Sharing
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.pass_up_profit_sharing}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Peer Profit Sharing
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.peer_profit_sharing}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Global Dividend Activation
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.global_deviden_activation}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fz:11 fw:500 text-dark">
                                            Global Dividend Profit Sharing
                                        </td>
                                        <td className="fz:11 fw:500 text-dark">
                                            :
                                        </td>
                                        <td className="fz:11 fw:500 text-green-dark">
                                            {data.global_deviden_profit_sharing}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default RewardDetail;
