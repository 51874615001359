const coin_allowed = [
    "1INCH",
    "AAVE",
    "ACA",
    "ACH",
    "ACLX",
    "ACM",
    "ADA",
    "ADX",
    "AERGO",
    "AGIX",
    "AGLD",
    "AION",
    "AKRO",
    "ALGO",
    "ALICE",
    "ALPACA",
    "ALPHA",
    "ALPINE",
    "AMB",
    "AMP",
    "ANC",
    "ANKR",
    "ANT",
    "APE",
    "API3",
    "AR",
    "ARDR",
    "ARK",
    "ARPA",
    "ASR",
    "AST",
    "ASTR",
    "ATA",
    "ATM",
    "ATOM",
    "AUCTION",
    "AUDIO",
    "AUTO",
    "AVA",
    "AVAX",
    "AXS",
    "BADGER",
    "BAKE",
    "BAL",
    "BAND",
    "BAR",
    "BAT",
    "BCH",
    "BEAM",
    "BEL",
    "BETA",
    "BETH",
    "BICO",
    "BIFI",
    "BLZ",
    "BNB",
    "BNT",
    "BNX",
    "BOND",
    "BSW",
    "BTC",
    "BTCST",
    "BTG",
    "BTS",
    "BURGER",
    "C98",
    "CAKE",
    "CELO",
    "CELR",
    "CFX",
    "CHESS",
    "CHR",
    "CHZ",
    "CITY",
    "CKB",
    "CLV",
    "COCOS",
    "COMP",
    "COS",
    "COTI",
    "CREAM",
    "CRV",
    "CTSI",
    "CTX",
    "CTXC",
    "CVC",
    "CVP",
    "CVX",
    "DAR",
    "DASH",
    "DATA",
    "DCR",
    "DEGO",
    "DENT",
    "DEXE",
    "DF",
    "DGB",
    "DIA",
    "DNT",
    "DOCK",
    "DODO",
    "DOGE",
    "DOT",
    "DREP",
    "DUSK",
    "DYDX",
    "EGLD",
    "ELF",
    "ENJ",
    "ENS",
    "EOS",
    "EPX",
    "ERN",
    "ETC",
    "ETH",
    "FARM",
    "FET",
    "FIDA",
    "FIL",
    "FIO",
    "FIRO",
    "FIS",
    "FLM",
    "FLOW",
    "FLUX",
    "FOR",
    "FORTH",
    "FRONT",
    "FTM",
    "FTT",
    "FUN",
    "FXS",
    "GAL",
    "GALA",
    "GAS",
    "GHST",
    "GLMR",
    "GMT",
    "GRT",
    "GTC",
    "GTO",
    "HARD",
    "HBAR",
    "HIGH",
    "HIVE",
    "HNT",
    "HOT",
    "ICP",
    "ICX",
    "IDEX",
    "ILV",
    "IMX",
    "INJ",
    "IOST",
    "IOTA",
    "IOTX",
    "IQ",
    "IRIS",
    "JASMY",
    "JOE",
    "JST",
    "JUV",
    "KAVA",
    "KDA",
    "KEY",
    "KLAY",
    "KMD",
    "KNC",
    "KP3R",
    "KSM",
    "LAZIO",
    "LDO",
    "LEVER",
    "LINA",
    "LINK",
    "LIT",
    "LOKA",
    "LOOM",
    "LPT",
    "LRC",
    "LSK",
    "LTC",
    "LTO",
    "LUNC",
    "MANA",
    "MASK",
    "MATIC",
    "MBL",
    "MBOX",
    "MC",
    "MDT",
    "MDX",
    "MFT",
    "MINA",
    "MIR",
    "MITH",
    "MKR",
    "MLN",
    "MOB",
    "MOVR",
    "MTL",
    "MULTI",
    "NBS",
    "NEAR",
    "NEBL",
    "NEO",
    "NEXO",
    "NKN",
    "NMR",
    "NULS",
    "OAX",
    "OCEAN",
    "OG",
    "OGN",
    "OM",
    "OMG",
    "ONE",
    "ONG",
    "ONT",
    "OOKI",
    "OP",
    "ORN",
    "OXT",
    "PAXG",
    "PEOPLE",
    "PERL",
    "PERP",
    "PHA",
    "PHB",
    "PIVX",
    "PLA",
    "PNT",
    "POLS",
    "POLY",
    "POND",
    "PORTO",
    "POWR",
    "PROM",
    "PROS",
    "PSG",
    "PUNDIX",
    "PYR",
    "QI",
    "QLC",
    "QKC",
    "QNT",
    "QTUM",
    "QUICK",
    "RAD",
    "REP",
    "REQ",
    "RIF",
    "RLC",
    "RNDR",
    "ROSE",
    "RSR",
    "RUNE",
    "SAND",
    "SANTOS",
];

export default coin_allowed;
