import { takeLatest, takeEvery, put, call } from "redux-saga/effects";

import { LOGIN, LOGOUT } from "./actionTypes";
import { loginSuccess, authError } from "./actions";

import { loginUser as apiLoginUser } from "src/api";
function* loginUser({ payload: { user, history } }) {
    try {
        const latest_version = parseInt(process.env.REACT_APP_AUTH_VERSION);
        const response = yield call(apiLoginUser, user.email, user.password);
        const data = response.data;
        localStorage.setItem("bidbox_authUser", JSON.stringify(data));
        localStorage.setItem("bidbox_authUser_created", new Date().getTime());
        localStorage.setItem("bidbox_version_auth", latest_version);

        yield put(loginSuccess(data));
        history.push("/user/dashboard");
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(authError(message));
    }
}
function* logoutUser() {
    try {
        localStorage.removeItem("bidbox_authUser");
        window.location.href = "/auth/signin";
    } catch (error) {
        yield put(authError(error));
    }
}
function* authSaga() {
    yield takeLatest(LOGIN, loginUser);
    yield takeEvery(LOGOUT, logoutUser);
}

export default authSaga;
