import React, { useState, useEffect } from "react";

const BNBData = [
    { id: 1, name: "ASTR", avbalance: "0.123456", bnbValue: "0.00012345" },
    { id: 2, name: "ENJ", avbalance: "0.123456", bnbValue: "0.00012345" },
    { id: 3, name: "GRT", avbalance: "0.123456", bnbValue: "0.00012345" },
];

const ConvertBNB = () => {
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);

    useEffect(() => {
        setList(BNBData);
    }, [list]);

    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map((li) => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };

    const catalog = list.map(({ id, name, avbalance, bnbValue }) => {
        return (
            <tr key={id}>
                <td>
                    <div className="convert-bnb-elm">
                        <div className="form-check d-flex align-items-center pl-0">
                            <input
                                className="form-check-input check-input-circle mr-2"
                                type="checkbox"
                                onChange={handleClick}
                                checked={isCheck.includes(id)}
                            />
                            <label
                                className="form-check-label fz:13"
                                style={{ userSelect: "none" }}
                            >
                                {name}
                            </label>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="convert-bnb-elm text-right">
                        <span className="text-right fz:13">{avbalance}</span>
                    </div>
                </td>
                <td>
                    <div className="convert-bnb-elm text-right">
                        <span className="text-right fz:13">={bnbValue}</span>
                    </div>
                </td>
            </tr>
        );
    });

    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Convert To BNB
                </span>
                <div className="n"></div>
            </header>
            <table className="table">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="fz:13 fw:400 text-gray"
                            style={{ color: "#ddddd !important" }}
                        >
                            Name
                        </th>
                        <th
                            scope="col"
                            className="fz:13 fw:400 text-gray text-right"
                            style={{ color: "#ddddd !important" }}
                        >
                            Available Balance
                        </th>
                        <th
                            scope="col"
                            className="fz:13 fw:400 text-gray text-right"
                            style={{ color: "#ddddd !important" }}
                        >
                            Approx.BNB Value
                        </th>
                    </tr>
                </thead>
                <tbody>{catalog}</tbody>
            </table>
            <div className="form-check d-flex align-items-center pl-0">
                <input
                    className="form-check-input check-input-circle mr-2"
                    type="checkbox"
                    value=""
                    id="id"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                />
                <label
                    className="form-check-label fz:13"
                    htmlFor="id"
                    style={{ userSelect: "none" }}
                >
                    All
                </label>
            </div>
            <div className="bnb-convert-box">
                <div className="bnb-convert-details">
                    <p className="m-0 p-0 fz:11 text-gray">You will get:</p>
                    <h1 className="m-0 p-0 fz:16 text-dark">
                        0.00 <span className="fz:12">BNB</span>
                    </h1>
                </div>
                <button className="btn-convert-fx disabled" disabled>
                    Convert
                </button>
            </div>
        </div>
    );
};

export default ConvertBNB;
