import React from "react";
import Banner from "../../components/banner/Banner";
import { rankConvert } from "src/utils/helpers";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    getAffiliateStatistics,
    getAffiliateActiveFrontlines,
} from "src/store/actions";
const Affiliate = () => {
    const dispatch = useDispatch();
    const affiliate = useSelector((state) => state.Affiliate);

    useEffect(() => {
        dispatch(getAffiliateStatistics());
        dispatch(getAffiliateActiveFrontlines());
    }, []);
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Affiliate
                </span>
                <div className="n"></div>
            </header>
            <div className="my-2">
                <Banner />

                <div className="card mb-4 card-affiliate">
                    <h1 className="text-card-absolute">Activation Bonus</h1>
                    <div className="card-body">
                        <div className="d-grid grid-column:2">
                            <div className="card bg-white-color">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        {affiliate.today_activation_profit}{" "}
                                        <span className="fz:11">USDT</span>
                                    </h1>
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        Today's Activation Bonus
                                    </h1>
                                </div>
                            </div>
                            <div className="card bg-white-color">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        {affiliate.total_activation_profit}{" "}
                                        <span className="fz:11">USDT</span>
                                    </h1>
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        Total Activation Bonus
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-4 card-affiliate">
                    <h1 className="text-card-absolute">Direct Refferal</h1>
                    <div className="card-body">
                        <div className="d-grid grid-column:2">
                            <div className="card bg-white-color">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        {affiliate.frontline_inactive}
                                    </h1>
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        Not Activated Yet
                                    </h1>
                                </div>
                            </div>
                            <div className="card bg-white-color">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        {affiliate.frontline_active}
                                    </h1>
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        Activated
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-4 card-affiliate">
                    <h1 className="text-card-absolute">Total Team</h1>
                    <div className="card-body">
                        <div className="d-grid grid-column:2">
                            <div className="card bg-white-color">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        {affiliate.downline_inactive}
                                    </h1>
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        Not Activated Yet
                                    </h1>
                                </div>
                            </div>
                            <div className="card bg-white-color">
                                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        {affiliate.downline_active}
                                    </h1>
                                    <h1 className="m-0 fz:11 fw:500 text-center text-dark">
                                        Activated
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-4 card-affiliate">
                    <h1 className="text-card-absolute">DIRECT TEAM</h1>
                    <div className="card-body">
                        <div className="card overflow-x:auto">
                            <div className="card-body p-2">
                                <table
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                >
                                    <tr>
                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                            Grade
                                        </td>
                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                            Username
                                        </td>
                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                            Total Team
                                        </td>
                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                            Registration Date
                                        </td>
                                    </tr>
                                    <tbody>
                                        {affiliate.active_frontlines !== null &&
                                            affiliate.active_frontlines.map(
                                                (key, index) => (
                                                    <tr>
                                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                                            {rankConvert(
                                                                key.rank
                                                            )}
                                                        </td>
                                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                                            <Link
                                                                to="#"
                                                                style={{
                                                                    textDecoration:
                                                                        "none",
                                                                }}
                                                            >
                                                                {key.username}
                                                            </Link>
                                                        </td>
                                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                                            {key.total_team}
                                                        </td>
                                                        <td className="m-0 fz:11 fw-600 text-uppercase">
                                                            {new Date(
                                                                key.registration_time *
                                                                    1000
                                                            ).toLocaleDateString(
                                                                "en-GB"
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Affiliate;
