import { takeEvery, put, call } from "redux-saga/effects";

import {
    ACCOUNT_PROFILE,
    ACCOUNT_REQ_OTP,
    CHANGE_PIN,
    CHANGE_PASSWORD,
    CHANGE_EMAIL,
} from "./actionTypes";
import {
    apiSuccess,
    apiError,
    userRequestOtpSuccess,
    userRequestOtpError,
    changePinSuccess,
    changePinError,
    changePasswordSuccess,
    changePasswordError,
    changeEmailSuccess,
    changeEmailError,
} from "./actions";

import {
    getAccountProfile as apiGetAccount,
    userRequestOTP as apiUserRequestOtp,
    changeSecurityPIN as apiUserChangePin,
    changePassword as apiUserChangePassword,
    changeEmail as apiUserChangeEmail,
} from "src/api";
function* getAccountProfile() {
    try {
        const response = yield call(apiGetAccount);
        const data = response.data;
        yield put(apiSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(apiError(message));
    }
}
function* userRequestOtp({ payload }) {
    try {
        yield call(apiUserRequestOtp, payload);

        yield put(
            userRequestOtpSuccess("We have sent the otp code to your email")
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(userRequestOtpError(message));
    }
}

function* userChangePin({ payload }) {
    try {
        yield call(apiUserChangePin, payload);
        yield put(changePinSuccess("Change Transaction PIN is successful"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(changePinError(message));
    }
}

function* userChangePassword({ payload }) {
    try {
        yield call(apiUserChangePassword, payload);
        yield put(changePasswordSuccess("Password changed successfully"));
        window.history.back();
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(changePasswordError(message));
    }
}

function* userChangeEmail({ payload }) {
    try {
        yield call(apiUserChangeEmail, payload);
        yield put(changeEmailSuccess("Email changed successfully"));
        window.history.back();
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(changeEmailError(message));
    }
}
function* authSaga() {
    yield takeEvery(ACCOUNT_PROFILE, getAccountProfile);
    yield takeEvery(ACCOUNT_REQ_OTP, userRequestOtp);
    yield takeEvery(CHANGE_PIN, userChangePin);
    yield takeEvery(CHANGE_PASSWORD, userChangePassword);
    yield takeEvery(CHANGE_EMAIL, userChangeEmail);
}

export default authSaga;
