import apis from "src/utils/apis";
import axios from "axios";
import { configNotStrict } from "./config";

// bot plugin
export const getBotPlugin = async () => {
    // No Payload
    let config = await configNotStrict({
        method: "get",
        url: apis.API_SITE_BOT_PLUGIN,
    });
    let res = await axios(config);
    return res;
};
// countries
export const getCountries = async () => {
    // No Payload
    let config = await configNotStrict({
        method: "get",
        url: apis.API_SITE_COUNTRIES,
    });
    let res = await axios(config);
    return res;
};
// timezones
export const getTimezones = async (payload) => {
    /* payload 
    {
        country_code : "US"
    }
    */
    let config = await configNotStrict({
        method: "get",
        url: apis.API_SITE_TIMEZONES,
        params: payload,
    });
    let res = await axios(config);
    return res;
};
// market pairs
export const getPairs = async () => {
    let config = await configNotStrict({
        method: "get",
        url: apis.API_SITE_PAIRS,
    });
    let res = await axios(config);
    return res;
};
