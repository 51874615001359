import React from "react";

const GVC = () => {
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Google Authenticator Setting
                </span>
                <div className="n"></div>
            </header>
            <div className="card mb-2">
                <div className="card-body">
                    <div
                        className="d-flex align-items-center"
                        style={{ columnGap: "5px" }}
                    >
                        <h1
                            className="m-0 fz:14 bg-app-color text-white d-flex align-items-center justify-content-center"
                            style={{
                                padding: "0.25rem 0.6rem",
                                borderRadius: "50%",
                            }}
                        >
                            1
                        </h1>
                        <div className="d-block">
                            <p className="m-0 fz:12 text-dark">
                                Search and download the Google Authenticator App
                                in the Play Store
                            </p>
                            <button
                                className="bg-app-color fz:14 text-white py-0 px-2"
                                style={{ border: "none", borderRadius: "5px" }}
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <div
                        class="d-flex align-items-center"
                        style={{ columnGap: "5px" }}
                    >
                        <h1
                            class="m-0 fz:14 bg-app-color text-white d-flex align-items-center justify-content-center"
                            style={{
                                padding: "0.25rem 0.5rem",
                                borderRadius: "50%",
                            }}
                        >
                            2
                        </h1>
                        <div class="d-block">
                            <p class="m-0 fz:12 text-dark">
                                Please record the 16-bit key on paper and keep
                                it in a safe place. If your phone is lost, you
                                can use this key to restore your Google
                                Authenticator
                            </p>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td width="80%">
                                        <button
                                            class="d-flex align-items-center bg-app-color fz:14 text-white py-0 px-2"
                                            style={{
                                                border: "none",
                                                borderRadius: "5px",
                                                width: "100%",
                                            }}
                                        >
                                            Set-up Key:
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            style={{ right: "0px !important" }}
                                            className="input-btn-absolute bg-slate-color-30 fz:12 text-dark fw:600 px-3"
                                        >
                                            Copy
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <div
                        class="d-flex align-items-center"
                        style={{ columnGap: "5px" }}
                    >
                        <h1
                            class="m-0 fz:14 bg-app-color text-white d-flex align-items-center justify-content-center"
                            style={{
                                padding: "0.25rem 0.5rem",
                                borderRadius: "50%",
                            }}
                        >
                            3
                        </h1>
                        <div class="d-block">
                            <p class="m-0 fz:12 text-dark">
                                Add a bidbox account to the Google Authenticator
                                app, select the time-based key type, add manualy
                                enter the 16 digit key
                            </p>
                            {/* <button
                class="d-flex align-items-center bg-app-color fz:14 text-white py-0 px-2"
                style={{ border: "none", borderRadius: "5px", width: "100%" }}
              >
                Secret Key:
              </button> */}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-body">
                    <div className="field">
                        {/* <label
                            htmlFor="email-verification-code"
                            className="label-control"
                        >
                            Email verification code
                        </label> */}
                        <input
                            type="text"
                            id="email-verification-code"
                            className="input-control"
                            placeholder="Input Email OTP"
                            required
                        />
                        <button
                            className="input-btn-absolute bg-slate-color-30 fz:12 text-dark fw:600 px-3"
                            style={{ height: 27, marginBottom: 3 }}
                        >
                            Paste
                        </button>
                    </div>
                    <div className="field">
                        {/* <label
                            htmlFor="email-verification-code"
                            className="label-control"
                        >
                            Google Authenticator code
                        </label> */}
                        <input
                            type="text"
                            id="email-verification-code"
                            className="input-control"
                            placeholder="Input the Google Authenticator Code"
                            required
                        />
                        <button
                            className="input-btn-absolute bg-slate-color-30 fz:12 text-dark fw:600 px-3"
                            style={{ height: 27, marginBottom: 3 }}
                        >
                            Paste
                        </button>
                    </div>
                </div>
            </div>

            {/* <div class="card mb-2">
                <div class="card-body">
                    <div className="field">
                        <label
                            htmlFor="email-verification-code"
                            className="label-control"
                        >
                            Google Authenticator code
                        </label>
                        <input
                            type="text"
                            id="email-verification-code"
                            className="input-control"
                            placeholder="Enter the Google Authenticator Code"
                            required
                        />
                        <button
                            className="input-btn-absolute bg-slate-color-30 fz:12 text-dark fw:600 px-3"
                            style={{ height: 27, marginBottom: 3 }}
                        >
                            Paste
                        </button>
                    </div>
                </div>
            </div> */}

            <div className="px-2 mt-2">
                <button className="btn btn-full text-uppercase bg-app-color hover:bg-app-color-90 text-white fw:500">
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default GVC;
