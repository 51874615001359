import React from "react";
import BTC from "../../assets/icons/coin/BTC-non.png";
import Banner from "../../components/banner/Banner";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toFixedIfNecessary } from "src/utils/helpers";
import {
    getBotSetting,
    getCoin,
    getAccount,
    getBotStatus,
    getRank,
    getBotPlugin,
    getNews,
    getPortofolio,
} from "src/store/actions";
import toast from "react-hot-toast";
import { numFormatter, numberWithCommas } from "src/utils/helpers";
import ImgProfit from "src/assets/image/hijau.png";
import ImgLossProfit from "src/assets/image/merah.png";

import InfiniteScroll from "react-infinite-scroll-component";

import coin_allowed from "src/assets/coin_allowed.js";

const Dashboard = () => {
    const dispatch = useDispatch();
    const coin = useSelector((state) => state.Coin);
    const news = useSelector((state) => state.News);
    const portofolio = useSelector((state) => state.Portofolio);
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [current_page, setCurrentPage] = useState(1);
    useEffect(() => {
        dispatch(getAccount());
        // dispatch(getNews());
        dispatch(getRank());
        dispatch(getBotStatus());
        dispatch(getBotSetting());
        dispatch(getBotPlugin());
        // if (coin.data.length === 0) dispatch(getCoin(coin_allowed));
    }, []);

    useEffect(() => {
        if (coin.errorMsg !== null) toast(coin.errorMsg);
    }, [coin.errorMsg]);
    useEffect(() => {
        if (current_page === 0) return;
        dispatch(
            getPortofolio({
                page: current_page,
                per_page: 10,
            })
        );
    }, [current_page]);
    const loadMore = async () => {
        setCurrentPage(current_page + 1);
    };
    useEffect(() => {
        if (portofolio.items.length > 0) {
            setPosts([...posts, ...portofolio.items]);
            setHasMore(true);
        } else {
            setHasMore(false);
        }
    }, [portofolio.items]);
    return (
        <div className="home-content content-on">
            <Banner />
            <div
                style={{
                    display: "flex",
                    overflowX: "auto",
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
            >
                {news.items.map((k, v) => (
                    <a
                        href={k.key}
                        key={v}
                        style={{
                            marginRight: 15,
                            width: 200,
                            cursor: "pointer",
                            textDecoration: "none",
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            style={{ borderRadius: 10, marginBottom: 10 }}
                            width="150"
                            src={
                                k.image === null
                                    ? "https://s2.coinmarketcap.com/static/cloud/img/news/placeholder1-new.jpg"
                                    : k.image
                            }
                            alt="ImageNews"
                        />
                        <p
                            className="fz:12 text-gray"
                            style={{ marginBottom: 0 }}
                        >
                            {k.sub_title}
                        </p>
                        <p className="fz:13">{k.title}</p>
                    </a>
                ))}
            </div>
            <h2 className="fz:16" style={{ marginLeft: 10, marginRight: 10 }}>
                Signal Performance
            </h2>
            <div
                className="card overflow-x:auto border-0"
                style={{ overflow: "auto" }}
            >
                <InfiniteScroll
                    dataLength={posts.length}
                    next={loadMore}
                    hasMore={hasMore}
                    // loader={<h3> Loading...</h3>}
                    // endMessage={<h4>Nothing more to show</h4>}
                >
                    {posts.map((k, v) => (
                        <div
                            key={v}
                            className="card card-trade-list-shadow mb-2"
                            style={{ marginRight: 10, marginLeft: 10 }}
                        >
                            <div key={v} className="card-body">
                                <div
                                    className="d-flex flex-wrap align-items-center justify-content-between mb-1"
                                    style={{
                                        borderBottom:
                                            "0px solid rgba(0,0,0,0.06)",
                                        paddingBottom: "3px",
                                    }}
                                >
                                    <div className="trade-icon-flex">
                                        <div className="icon">
                                            <img src={k.image} alt="coin-img" />
                                        </div>
                                        <div className="d-block">
                                            <div className="d-flex align-items-center">
                                                <h1
                                                    className="m-0 fz:16 fw:600 text-dark text-uppercase"
                                                    style={{
                                                        paddingRight: 5,
                                                    }}
                                                >
                                                    {k.base_asset} /
                                                </h1>
                                                <h1 className="m-0 fz:12 fw:500 text-gray text-uppercase">
                                                    {k.quote_asset}
                                                </h1>

                                                <h1
                                                    className={
                                                        k.is_avg !== true
                                                            ? "m-0 fz:12 fw:500 text-uppercase text-gray"
                                                            : "m-0 fz:12 fw:500 text-uppercase"
                                                    }
                                                    style={
                                                        k.is_avg === true
                                                            ? {
                                                                  color: "#008aae",
                                                              }
                                                            : null
                                                    }
                                                >
                                                    &nbsp;
                                                    {k.is_avg &&
                                                    k.is_avg === true
                                                        ? "AVG"
                                                        : ""}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-column align-items-end"
                                        style={{
                                            rowGap: "2px",
                                        }}
                                    >
                                        <h1
                                            className={`mb-1 fz:18 fw:500 ${
                                                k.profit >= 0
                                                    ? "text-green"
                                                    : "text-red"
                                            }`}
                                        >
                                            {k.profit >= 0 ? "Profit" : "Loss"}
                                        </h1>
                                        <h1
                                            className={`mb-1 fz:16 fw:500 ${
                                                k.profit >= 0
                                                    ? "text-green"
                                                    : "text-red"
                                            }`}
                                        >
                                            {k.profit < 0
                                                ? toFixedIfNecessary(
                                                      k.profit,
                                                      8
                                                  )
                                                : `+${toFixedIfNecessary(
                                                      k.profit,
                                                      8
                                                  )}`}
                                            %
                                        </h1>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                    <div className="d-flex flex-column">
                                        <h1
                                            className={`mb-1 fz:20 fw:500 ${
                                                k.profit >= 0
                                                    ? "text-green"
                                                    : "text-red"
                                            }`}
                                        >
                                            {toFixedIfNecessary(
                                                k.sell_price,
                                                8
                                            )}
                                        </h1>
                                        <h1 className="m-0 fz:11 fw:500 text-dark">
                                            Sell in Price (USDT)
                                        </h1>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <img
                                            src={
                                                k.profit >= 0
                                                    ? ImgProfit
                                                    : ImgLossProfit
                                            }
                                            alt="Img Profit"
                                            width="100"
                                        />
                                        <h1 className="m-0 fz:9 fw:500 text-dark">
                                            {new Date(
                                                parseInt(k.finish_at)
                                            ).toLocaleString("en-GB")}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Dashboard;
