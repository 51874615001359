import React from "react";
import { Link } from "react-router-dom";
import Banner from "../../components/banner/Banner";
const News = () => {
    return (
        <div className="news-content content-on">
            <div className="px-2">
                <Banner />
            </div>
            <div className="d-flex flex-column p-0">
                <li className="news-item">
                    <Link to="/user/order-message" className="news-link">
                        <div className="news-icon">
                            <i className="fa-solid fa-envelope-open-text icon"></i>
                        </div>
                        <div className="news-details">
                            <h2 className="mb-1 fz:16 fw:500 text-dark sttr-text">
                                Order Message
                            </h2>
                            <p className="mb-0 fz:12 fw:400 text-gray sttr-text"></p>
                        </div>
                    </Link>
                </li>
                <li className="news-item">
                    <Link to="/user/system-information" className="news-link">
                        <div className="news-icon">
                            <i className="fa-solid fa-bullhorn icon"></i>
                        </div>
                        <div className="news-details">
                            <h2 className="mb-1 fz:16 fw:500 text-dark sttr-text">
                                System Information
                            </h2>
                            <p className="mb-0 fz:12 fw:400 text-gray sttr-text"></p>
                        </div>
                    </Link>
                </li>
            </div>
        </div>
    );
};

export default News;
