import { searchIcon } from "src/utils/helpers";
import { takeEvery, put, call } from "redux-saga/effects";

import { GAS_FEE } from "./actionTypes";
import { gasFeeError, gasFeeSuccess } from "./actions";

import { getGasFee as apiGetGasFee } from "src/api";

function* getGasFee({ payload }) {
    try {
        const response = yield call(apiGetGasFee, payload);
        let data = response.data;
        if (data.items === null) data.items = [];
        if (data.items !== null) {
            data.items.forEach(async (el) => {
                el.date_epoch = new Date(el.date).getTime();
                if (el.items !== null) {
                    el.items.forEach(async (el_items) => {
                        el_items.image = await searchIcon(el_items.base_asset);
                    });
                }
            });
        }
        yield put(gasFeeSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(gasFeeError(message));
    }
}
function* authSaga() {
    yield takeEvery(GAS_FEE, getGasFee);
}

export default authSaga;
