import {
    EXCHANGE_ACTIVATION,
    EXCHANGE_ACTIVATION_ERROR,
    EXCHANGE_ACTIVATION_SUCCESS,
    EXCHANGE_ACTIVATION_INIT,
    TRADES,
    TRADES_ERROR,
    TRADES_SUCCESS,
    TRADES_INIT,
    TRADES_UPDATE,
    TRADES_INSERT,
    TRADES_REMOVE,
    TRADES_CANCEL,
    TRADES_CANCEL_SUCCESS,
    TRADES_CANCEL_ERROR,
    TRADES_SELL,
    TRADES_SELL_SUCCESS,
    TRADES_SELL_ERROR,
    TRADES_CLEAR_MESSAGE,
    TRADES_PROFIT,
    TRADES_PROFIT_SUCCESS,
    TRADES_PROFIT_ERROR,
    TRADES_HISTORY,
    TRADES_HISTORY_SUCCESS,
    TRADES_HISTORY_ERROR,
    TRADES_SYNC,
    TRADES_SYNC_SUCCESS,
    TRADES_SYNC_ERROR,
    TRADES_AVG,
    TRADES_AVG_SUCCESS,
    TRADES_AVG_ERROR,
    TICK_SIZE,
    TICK_SIZE_SUCCESS,
    TICK_SIZE_ERROR,
    TRADES_CURRENT_PRICE,
    TRADES_CURRENT_PRICE_SUCCESS,
    TRADES_CURRENT_PRICE_ERROR,
} from "./actionTypes";

import { toFixedIfNecessary } from "src/utils/helpers";
const initialState = {
    trades: [],
    tradesProfit: {
        items: [],
    },
    history_trades: [],
    loading: false,
    successMsg: null,
    errorMsg: null,
    current_price: {},
};
const reducer = (state = initialState, action) => {
    let tmp_trades = null;
    let tmp = [];
    switch (action.type) {
        case TRADES_INSERT:
            if (state.trades === null) state.trades = [];
            const find = state.trades.filter(
                (key) =>
                    key.id === action.payload.id &&
                    key.exchange_id === action.payload.exchange_id
            ).length;
            if (find === 0) state.trades.unshift(action.payload);

            tmp_trades = state.trades;
            tmp = [];
            if (tmp_trades === null) tmp_trades = [];
            if (state.trades.trades !== null) {
                state.trades.forEach((el1, index1) => {
                    tmp_trades.forEach((el2, index2) => {
                        if (el2.id === el1.avg_for) {
                            if (el1.status_name === "PENDING_BUY") {
                                el2.avg_status = "inactive";
                            } else {
                                el2.avg_status = "active";
                                el2.status_name = "AVERAGING";
                            }

                            el2.avg_id = el1.id;
                            el2.avg_qty = el1.buy_qty;
                            el2.avg_buy_price = el1.buy_price;
                            el2.avg_sell_price = el1.sell_price;
                        }
                        if (el2.status_name === "PENDING_BUY") {
                            el2.sort_priority = 1;
                        } else {
                            el2.sort_priority = 2;
                        }
                        tmp_trades[index2] = el2;
                        // if (tmp[index2] === undefined) {
                        //     tmp.push({
                        //         ...tmp_trades[index2],
                        //         ...tmp_payload,
                        //     });
                        // } else {
                        //     tmp[index2] = {
                        //         ...tmp_trades[index2],
                        //         ...tmp_payload,
                        //     };
                        // }
                    });
                });
            }
            state.trades = tmp_trades;
            break;
        case TRADES_REMOVE:
            if (Array.isArray(state.trades)) {
                state.trades = state.trades.filter(
                    (key) =>
                        !(
                            (key.id === action.payload.id ||
                                key.avg_id === action.payload.id) &&
                            key.exchange_id === action.payload.exchange_id
                        )
                );
            }
            break;
        case TRADES_UPDATE:
            if (state.trades === null) state.trades = [];
            if (action.payload.where.whereBy === "symbol") {
                state.trades
                    .filter(
                        (key) =>
                            `${key.base_asset}${key.quote_asset}` ===
                            action.payload.where.payload
                    )
                    .forEach((v) => {
                        const buy_qty =
                            v.avg_status === "active"
                                ? v.buy_qty + v.avg_qty
                                : v.buy_qty;
                        const buy_price =
                            v.avg_status === "active"
                                ? v.avg_buy_price
                                : v.buy_price;
                        const modal = buy_price * buy_qty;
                        const jual =
                            v.avg_status === "active"
                                ? action.payload.payload.price
                                : action.payload.payload.price * buy_qty;
                        let laba = jual - modal;
                        let profit_persen = toFixedIfNecessary(
                            (laba / modal) * 100,
                            8
                        );
                        if (v.avg_status === "active") {
                            // const a_fba = v.fba;
                            const a_buy_price = v.buy_price;
                            const a_avg_buy_price = v.avg_buy_price;
                            // const a_margin_call = v.avg_multiply;
                            // const a_modal_avg = a_margin_call * a_fba;
                            // const a_modal_total = a_modal_avg + a_fba;
                            // const a_qty = a_fba / a_buy_price;
                            // const a_avg_qty =
                            // (a_margin_call * a_fba) / a_avg_buy_price;
                            // const a_qty_total = a_qty + a_avg_qty;
                            // profit_persen =
                            //     ((a_qty_total * jual - a_modal_total) /
                            //         a_modal_total) *
                            //     100;
                            const total_jual = jual * (v.buy_qty + v.avg_qty);
                            const total_modal =
                                a_buy_price * v.buy_qty +
                                a_avg_buy_price * v.avg_qty;

                            laba = total_jual - total_modal;
                            profit_persen = (laba / total_modal) * 100;
                            // laba = a_qty_total * jual - a_modal_total;
                        }
                        if (v.status_name !== "PENDING_BUY") {
                            v.profit_percent = profit_persen;
                            v.profit = laba;
                            v.profit = toFixedIfNecessary(v.profit, 8);
                        } else {
                            v.profit_percent = 0;
                            v.profit = 0;
                        }
                        v.price_current = action.payload.payload.price;
                        v.price_chg = toFixedIfNecessary(
                            action.payload.payload.price_change,
                            2
                        );
                    });
            } else if (action.payload.where.whereBy === "action") {
                state.trades
                    .filter(
                        (key) =>
                            key.id === action.payload.where.payload.id &&
                            key.exchange_id ===
                                action.payload.where.payload.exchange_id
                    )
                    .forEach((v) => {
                        for (const [k, val] of Object.entries(
                            action.payload.payload
                        )) {
                            v[k] = val;
                        }
                    });
                tmp_trades = state.trades;
                if (tmp_trades === null) tmp_trades = [];
                if (state.trades.trades !== null) {
                    state.trades.forEach((el1, index1) => {
                        tmp_trades.forEach((el2, index2) => {
                            let tmp_payload = {};

                            if (el2.id === el1.avg_for) {
                                if (el1.status_name === "PENDING_BUY") {
                                    tmp_payload.avg_status = "inactive";
                                } else {
                                    tmp_payload.avg_status = "active";
                                    tmp_payload.status_name = "AVERAGING";
                                }
                                tmp_payload.avg_id = el1.id;
                                tmp_payload.avg_qty = el1.buy_qty;
                                tmp_payload.avg_buy_price = el1.buy_price;
                                tmp_payload.avg_sell_price = el1.sell_price;
                            }

                            tmp_trades[index2] = {
                                ...tmp_trades[index2],
                                ...tmp_payload,
                            };

                            if (
                                tmp_trades[index2].status_name === "PENDING_BUY"
                            ) {
                                tmp_trades[index2].sort_priority = 1;
                            } else {
                                tmp_trades[index2].sort_priority = 2;
                            }
                        });
                    });
                }
                state.trades = tmp_trades;
            }

            break;

        case TRADES_PROFIT:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_PROFIT_SUCCESS:
            const tradesProfit = {
                tradesProfit: action.payload,
            };
            state = {
                ...state,
                ...tradesProfit,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_PROFIT_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        case TRADES_CLEAR_MESSAGE:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_INIT:
            state = {
                ...state,
                trades: [],
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_SUCCESS:
            tmp_trades = action.payload.trades;
            tmp = [];
            if (tmp_trades === null) tmp_trades = [];
            if (action.payload.trades !== null) {
                action.payload.trades.forEach((el1, index1) => {
                    tmp_trades.forEach((el2, index2) => {
                        if (el2.avg_status === undefined) {
                            el2.avg_status = null;
                            el2.avg_buy_price = null;
                            el2.avg_sell_price = null;
                            el2.avg_qty = null;
                            el2.avg_id = null;
                        }
                        if (el2.id === el1.avg_for) {
                            if (el1.status_name === "PENDING_BUY") {
                                el2.avg_status = "inactive";
                            } else {
                                el2.avg_status = "active";
                                el2.status_name = "AVERAGING";
                            }

                            el2.avg_id = el1.id;
                            el2.avg_qty = el1.buy_qty;
                            el2.avg_buy_price = el1.buy_price;
                            el2.avg_sell_price = el1.sell_price;
                        }

                        if (el2.status_name === "PENDING_BUY") {
                            el2.sort_priority = 1;
                        } else {
                            el2.sort_priority = 2;
                        }
                        if (tmp[index2] === undefined) {
                            tmp.push(el2);
                        } else {
                            tmp[index2] = el2;
                        }
                    });
                });
            }
            state = {
                ...state,
                trades: tmp,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        case EXCHANGE_ACTIVATION_INIT:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case EXCHANGE_ACTIVATION:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case EXCHANGE_ACTIVATION_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case EXCHANGE_ACTIVATION_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TRADES_CANCEL:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_CANCEL_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case TRADES_CANCEL_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TRADES_SELL:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_SELL_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case TRADES_SELL_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TRADES_HISTORY:
            state = {
                ...state,
                history_trades: [],
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_HISTORY_SUCCESS:
            let items_history = [];
            action.payload.items.forEach((el) => {
                if (el.items !== null) {
                    el.items.forEach((el_items) => {
                        el_items.date = el.date;
                        el_items.all_loss_amount = el.loss_amount;
                        el_items.all_profit_amount = el.profit_amount;
                        items_history.push(el_items);
                    });
                }
            });
            let new_items_history = items_history;
            new_items_history.forEach((new_item) => {
                items_history.forEach((items) => {
                    if (items.last_id === null) {
                        items.last_id = "";
                        items.last_buy_qty = "";
                        items.last_buy_price = "";
                    }
                    if (items.avg_for === new_item.order_id) {
                        items.last_id = new_item.order_id;
                        items.last_buy_qty = new_item.qty;
                        items.last_buy_price = new_item.buy_price;
                    }
                });
            });
            state = {
                ...state,
                history_trades: items_history,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_HISTORY_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TRADES_SYNC:
            state = {
                ...state,
                ...action.payload,
                loading_sync: action.payload.trade_id,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_SYNC_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_sync: null,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case TRADES_SYNC_ERROR:
            state = {
                ...state,
                loading: false,
                loading_sync: null,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TRADES_AVG:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_AVG_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case TRADES_AVG_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case TICK_SIZE:
            state = {
                ...state,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case TICK_SIZE_SUCCESS:
            state = {
                ...state,
                tick_size: action.payload,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case TICK_SIZE_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case TRADES_CURRENT_PRICE:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_CURRENT_PRICE_SUCCESS:
            let new_current_price = {};
            action.payload.forEach((el) => {
                new_current_price[el.symbol] = el.price;
                state.trades.map((p, idx) => {
                    if (
                        p.symbol === el.symbol &&
                        p.price_current === undefined
                    ) {
                        const buy_qty =
                            p.avg_status === "active"
                                ? p.buy_qty + p.avg_qty
                                : p.buy_qty;
                        const buy_price =
                            p.avg_status === "active"
                                ? p.avg_buy_price
                                : p.buy_price;
                        const modal = buy_price * buy_qty;
                        const jual =
                            p.avg_status === "active"
                                ? el.price
                                : el.price * buy_qty;
                        let laba = jual - modal;
                        let profit_persen = toFixedIfNecessary(
                            (laba / modal) * 100,
                            8
                        );
                        if (p.avg_status === "active") {
                            const a_buy_price = p.buy_price;
                            const a_avg_buy_price = p.avg_buy_price;
                            const total_jual = jual * (p.buy_qty + p.avg_qty);
                            const total_modal =
                                a_buy_price * p.buy_qty +
                                a_avg_buy_price * p.avg_qty;

                            laba = total_jual - total_modal;
                            profit_persen = (laba / total_modal) * 100;
                            // laba = a_qty_total * jual - a_modal_total;
                        }
                        if (p.status_name !== "PENDING_BUY") {
                            p.profit_percent = profit_persen;
                            p.profit = laba;
                            p.profit = toFixedIfNecessary(p.profit, 8);
                        } else {
                            p.profit_percent = 0;
                            p.profit = 0;
                        }
                        state.trades[idx] = {
                            ...p,
                            price_current: el.price,
                            price_chg: 0,
                        };
                    }
                });
            });
            state = {
                ...state,
                current_price: {
                    ...state.current_price,
                    ...new_current_price,
                },
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case TRADES_CURRENT_PRICE_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    if (state.trades !== null) {
        state.trades.sort(function (a, b) {
            return a.sort_priority > b.sort_priority || b.profit - a.profit;
        });
    }

    return state;
};

export default reducer;
