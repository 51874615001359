import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    userRequestOtp,
    initAccount,
    withdraw,
    withdrawInit,
    getBalance,
} from "src/store/actions";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

const Withdrawal = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.Account);
    const balance = useSelector((state) => state.Balance);
    const wallet = useSelector((state) => state.Wallet);

    const [address, setAddress] = useState("");
    const [qty, setQty] = useState("");
    const [otpEmail, setOtpEmail] = useState("");
    const [pin, setPin] = useState("");
    const feeWD = 2;
    const confirmWD = () => {
        if (account.security_pin_active === false) {
            setPin("000000");
        }
        dispatch(
            withdraw({
                currency_id: "USDT",
                otp: otpEmail,
                security_pin: pin,
                to: address,
                value: parseFloat(qty - feeWD),
            })
        );
    };
    const getOtp = () => {
        dispatch(
            userRequestOtp({
                otp_context: 3,
                target: 0,
            })
        );
    };
    useEffect(() => {
        if (balance.errorMsg !== null && balance.errorMsg !== "")
            toast(balance.errorMsg);
    }, [balance.errorMsg]);
    useEffect(() => {
        dispatch(getBalance());
        return () => {
            dispatch(initAccount());
            dispatch(withdrawInit());
        };
    }, []);
    useEffect(() => {
        if (account.reqOtpSuccessMsg !== null) toast(account.reqOtpSuccessMsg);
        if (account.reqOtpErrorMsg !== null) toast(account.reqOtpErrorMsg);

        dispatch(initAccount());
    }, [account.reqOtpSuccessMsg, account.reqOtpErrorMsg]);

    useEffect(() => {
        if (wallet.successMsg !== null) {
            toast(wallet.successMsg);
            window.history.back();
        }
        if (wallet.errorMsg !== null) {
            toast(wallet.errorMsg);
        }

        dispatch(withdrawInit());
    }, [wallet.successMsg, wallet.errorMsg]);
    return (
        <div className="py-5">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Withdrawal
                </span>
                <div className="n"></div>
            </header>
            <div>
                <div id="form-validation" className="mb-2">
                    <div className="px-2">
                        <div className="chain-field">
                            <span className="placeholder">Chain:</span>
                            <span className="badge-chain">TRC20</span>
                        </div>
                    </div>
                    <div
                        className="card mb-2 border-0"
                        style={{ borderRadius: "0", background: "#edf7fd" }}
                    >
                        <div className="card-body px-2">
                            <div className="field">
                                <label
                                    htmlFor="address"
                                    className="label-control"
                                >
                                    Address
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="input-control fz:15"
                                    placeholder="Paste address here"
                                    style={{ height: "35px" }}
                                    required
                                />
                            </div>
                            <div className="field">
                                <label
                                    htmlFor="quantity"
                                    className="label-control"
                                >
                                    Quantity
                                </label>
                                <input
                                    type="text"
                                    id="quantity"
                                    onChange={(e) => setQty(e.target.value)}
                                    className="input-control fz:15"
                                    placeholder="The minimum withdrawal 10 USDT"
                                    style={{ height: "35px" }}
                                    required
                                />
                            </div>
                            <p className="my-1 fz:14 fw:500 text-gray sttr-text">
                                Available: {balance.active_balance}
                            </p>
                            <div className="d-flex aign-items-center justify-content-between flex-wrap">
                                <p className="m-0 fz:14 fw:500 text-dark">
                                    Transaction Fees
                                </p>
                                <p className="m-0 fz:14 fw:500 text-dark">
                                    {feeWD} USDT
                                </p>
                            </div>
                            <div className="d-flex aign-items-center justify-content-between flex-wrap">
                                <p className="m-0 fz:14 fw-500 text-dark">
                                    Arrival Quantity
                                </p>
                                <p className="m-0 fz:14 fw-500 text-dark">
                                    {qty !== undefined && qty !== ""
                                        ? qty - feeWD
                                        : 0}{" "}
                                    USDT
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="field">
                            <label
                                htmlFor="otp-email"
                                className="label-control"
                            >
                                OTP Email
                            </label>
                            <input
                                type="text"
                                id="otp-email"
                                className="input-control fz:15"
                                placeholder="OTP Email"
                                style={{ height: "35px" }}
                                onChange={(e) => setOtpEmail(e.target.value)}
                                required
                            />
                            <button
                                onClick={getOtp}
                                className="input-btn-absolute hover:bg-app-color-90"
                            >
                                Get Otp
                            </button>
                        </div>
                        <div className="field">
                            <label
                                htmlFor="transaction-pin"
                                className="label-control"
                            >
                                Transaction PIN
                            </label>
                            <input
                                type="text"
                                id="transaction-pin"
                                className="input-control fz:15"
                                placeholder="Transaction PIN"
                                style={{ height: "35px" }}
                                onChange={(e) => setPin(e.target.value)}
                            />
                        </div>
                        <div className="d-flex justify-content-end mb-2">
                            <Link
                                to="/user/transaction-pin"
                                className="text-app text-decoration-none hover:text-app-color-90 fz:12 m-0 fw:500"
                            >
                                Set / Change Transaction PIN
                            </Link>
                        </div>
                        <button
                            onClick={confirmWD}
                            className="btn btn-full bg-app-color hover:bg-app-color-90 active:bg-app-color text-uppercase text-white"
                        >
                            Confirm Withdrawal
                        </button>
                    </div>
                </div>
                <div className="px-2">
                    <p className="mb-2 fz:14 fw-500">Precautions</p>
                    <p className="mb-2 fz:13 fw-500">
                        The minimum amount of single withdrawal is: 10 USDT
                        <br />
                        Please do not transfer USDT assets to addresses other
                        than USDT, otherwise it cannot be retrieved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Withdrawal;
