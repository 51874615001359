import apis from "src/utils/apis";
import axios from "axios";
import { configStrict, configNotStrict } from "./config";

// history trades
export const getGasFee = async (payload) => {
    /* payload 
    {
        exchange_id : 1,
        from_date : 2022-08-23,
        to_date : 2022-08-23,
    }
    */
    let config = await configStrict({
        method: "get",
        url: apis.API_GET_GAS_FEE,
        params: payload,
    });
    let res = await axios(config);
    return res;
};
