import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import {
    authRoutes,
    userRoutes,
    nonAuthRoutes,
    maintenanceRoutes,
} from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";

function App() {
    const [reactSiteMode, siteReactSiteMode] = useState("live");
    useEffect(() => {
        siteReactSiteMode(process.env.REACT_APP_SITE_MODE);
    }, []);

    const redirect_landing_page = () => {
        if (window.location.pathname === "/")
            window.location.href = "https://help.bidbox.community";
	    //window.location.origin + "/home";
    };
    return (
        <Router>
            <Switch>
                {reactSiteMode !== "maintenance" &&
                    authRoutes.map((route, idx) => (
                        <Authmiddleware
                            key={idx}
                            path={route.path}
                            component={route.component}
                            isAuthProtected={false}
                            isAuthRoutes={true}
                            layout={route.layout}
                        />
                    ))}
                {reactSiteMode !== "maintenance" &&
                    userRoutes.map((route, idx) => (
                        <Authmiddleware
                            key={idx}
                            path={route.path}
                            component={route.component}
                            isAuthProtected={false}
                            isAuthRoutes={false}
                            layout={route.layout}
                        />
                    ))}

                {reactSiteMode !== "maintenance" &&
                    nonAuthRoutes.map((route, index) => (
                        <Authmiddleware
                            key={index}
                            path={route.path}
                            component={route.component}
                            isAuthProtected={false}
                            isAuthRoutes={false}
                            layout={route.layout}
                        />
                    ))}
                {reactSiteMode === "maintenance" &&
                    maintenanceRoutes.map((route, index) => (
                        <Authmiddleware
                            key={index}
                            path={route.path}
                            component={route.component}
                            isAuthProtected={false}
                            isAuthRoutes={false}
                            layout={route.layout}
                        />
                    ))}
                {reactSiteMode === "maintenance" && (
                    <Redirect strict from="/" to="/maintenance" />
                )}

                {reactSiteMode !== "maintenance" && redirect_landing_page()}
            </Switch>
        </Router>
    );
}

export default App;
