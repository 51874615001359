import { takeEvery, put, call } from "redux-saga/effects";

import { REWARD } from "./actionTypes";
import { rewardSuccess, rewardError } from "./actions";

import { getReward as apiGetReward } from "src/api";
function* getReward({ from_date, to_date }) {
    try {
        const response = yield call(apiGetReward, from_date, to_date);
        let data = response.data;
        data.items.forEach((el) => {
            el.date_epoch = new Date(el.bonus_date).getTime();
        });
        yield put(rewardSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(rewardError(message));
    }
}

function* authSaga() {
    yield takeEvery(REWARD, getReward);
}

export default authSaga;
