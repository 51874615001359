import {
    ACCOUNT_INIT,
    ACCOUNT_PROFILE,
    ACCOUNT_PROFILE_SUCCESS,
    ACCOUNT_PROFILE_ERROR,
    ACCOUNT_REQ_OTP,
    ACCOUNT_REQ_OTP_SUCCESS,
    ACCOUNT_REQ_OTP_ERROR,
    CHANGE_PIN,
    CHANGE_PIN_SUCCESS,
    CHANGE_PIN_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_EMAIL,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_EMAIL_ERROR,
} from "./actionTypes";

export const initAccount = () => {
    return {
        type: ACCOUNT_INIT,
    };
};

export const getAccount = () => {
    return {
        type: ACCOUNT_PROFILE,
    };
};

export const apiSuccess = (message) => {
    return {
        type: ACCOUNT_PROFILE_SUCCESS,
        payload: message,
    };
};

export const apiError = (message) => {
    return {
        type: ACCOUNT_PROFILE_ERROR,
        payload: message,
    };
};

export const userRequestOtp = (payload) => {
    return {
        type: ACCOUNT_REQ_OTP,
        payload: payload,
    };
};

export const userRequestOtpSuccess = (payload) => {
    return {
        type: ACCOUNT_REQ_OTP_SUCCESS,
        payload: payload,
    };
};
export const userRequestOtpError = (payload) => {
    return {
        type: ACCOUNT_REQ_OTP_ERROR,
        payload: payload,
    };
};

export const changePin = (payload) => {
    return {
        type: CHANGE_PIN,
        payload: payload,
    };
};

export const changePinSuccess = (payload) => {
    return {
        type: CHANGE_PIN_SUCCESS,
        payload: payload,
    };
};
export const changePinError = (payload) => {
    return {
        type: CHANGE_PIN_ERROR,
        payload: payload,
    };
};

export const changePassword = (payload) => {
    return {
        type: CHANGE_PASSWORD,
        payload: payload,
    };
};

export const changePasswordSuccess = (payload) => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: payload,
    };
};
export const changePasswordError = (payload) => {
    return {
        type: CHANGE_PASSWORD_ERROR,
        payload: payload,
    };
};

export const changeEmail = (payload) => {
    return {
        type: CHANGE_EMAIL,
        payload: payload,
    };
};

export const changeEmailSuccess = (payload) => {
    return {
        type: CHANGE_EMAIL_SUCCESS,
        payload: payload,
    };
};
export const changeEmailError = (payload) => {
    return {
        type: CHANGE_EMAIL_ERROR,
        payload: payload,
    };
};
