import React from "react";
import { useHistory, Link } from "react-router-dom";
const PreRegisteredUser = (props) => {
    const history = useHistory();
    const propsState = props.location.state;

    const backToSignup = () => {
        try {
            history.push(propsState.prevPath);
        } catch (error) {
            history.push("/auth/signup");
        }
    };
    return (
        <div className="sc-dbhs">
            <header className="or-header">
                <button className="back-button me-auto" onClick={backToSignup}>
                    <i className="fa-solid fa-angle-left back-icon"></i>
                </button>
                <span className="text-uppercase fw-500 me-auto">
                    pre-registered User
                </span>
            </header>
            <div className="dbhs-content" style={{ padding: 20 }}>
                Hello BIDBOX User!
                <br />
                <br />
                Welcome to BIDBOX to all pre-registered user from 2nd Phase of
                Pre-Registration.
                <br />
                <br />
                To all 2nd phase pre-registered users, per 23 September 2022
                24.00 AM (GMT +00.00), you can login to BIDBOX (
                <a href="https://bidbox.community/auth/signin">
                    https://bidbox.community/auth/signin
                </a>
                ) using your registered email with default password "123456".
                <br />
                <br />
                Next, all you have to do is deposit 25 USDT to your BIDBOX USDT
                TRC20 Address and activate our AI Service. We suggest you to
                change your password after activating our AI Service. The 2nd
                phase pre-registered member will have until 25 September 2022 at
                14.00 (GMT +00.00) to activate our AI Service.
                <br />
                <br />
                We are also announcing to all 1st phase pre-registered users,
                that you can also activate our AI Service during this period, if
                you haven't yet before.
                <br />
                <br />
                BIDBOX will announce the date for new user registration soon
                after we have finished updating our database with the 1st and
                2nd phase pre-registered users.
                <br />
                <br />
                If you have any questions regarding BIDBOX, please contact our
                support at{" "}
                <a href="mailto:support@bidbox.community">
                    support@bidbox.community
                </a>
                .
                <br />
                <br />
                <br />
                To receive the latest news related to BIDBOX, make sure to
                follow our telegram channel.
                <br />
                <br />
                <br />
                Thank you.
                <br />
                <br />
                Regards,
                <br />
                BIDBOX
            </div>
        </div>
    );
};

export default PreRegisteredUser;
