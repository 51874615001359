export const IP_BINDING = "IP_BINDING";
export const IP_BINDING_SUCCESS = "IP_BINDING_SUCCESS";
export const IP_BINDING_ERROR = "IP_BINDING_ERROR";
export const SERVER_IP = "SERVER_IP";
export const SERVER_IP_SUCCESS = "SERVER_IP_SUCCESS";
export const SERVER_IP_ERROR = "SERVER_IP_ERROR";

export const UNBOUND = "UNBOUND";
export const UNBOUND_SUCCESS = "UNBOUND_SUCCESS";
export const UNBOUND_ERROR = "UNBOUND_ERROR";
export const IP_BINDING_INIT = "IP_BINDING_INIT";
