import { COIN, COIN_SUCCESS, COIN_UPDATE, COIN_ERROR } from "./actionTypes";

const initialState = {
    data: [],
    subscribe_id_coin: [],
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COIN:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case COIN_UPDATE:
            state.data
                .filter((key) => key.id === action.payload.id)
                .forEach((v) => {
                    v.coinCap = action.payload.coinCap;
                    v.subCoinCap = action.payload.subCoinCap;
                    v.price = action.payload.price;
                    v.subPrice = action.payload.subPrice;
                    v.type = action.payload.type;
                });
            break;
        case COIN_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: action.payload,
                errorMsg: null,
            };
            break;
        case COIN_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
