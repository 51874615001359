import { takeEvery, put, call } from "redux-saga/effects";

import { COUNTRIES } from "./actionTypes";
import { countriesSuccess, countriesError } from "./actions";

import { getCountries as apiGetCountries } from "src/api";
function* getCountries() {
    try {
        const response = yield call(apiGetCountries);
        const data = response.data;
        let cnt = [];
        data.countries.forEach((element) => {
            cnt.push({
                value: element.id,
                label: element.name,
                icon: element.id,
            });
        });
        yield put(
            countriesSuccess({
                items: cnt,
            })
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(countriesError(message));
    }
}

function* authSaga() {
    yield takeEvery(COUNTRIES, getCountries);
}

export default authSaga;
