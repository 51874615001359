import apis from "src/utils/apis";
import axios from "axios";
import { configStrict, configNotStrict } from "./config";

// active trades
export const getActiveTrades = async () => {
    // No Payload
    let config = await configStrict({
        method: "get",
        url: apis.API_ACTIVE_TRADES,
    });
    let res = await axios(config);
    return res;
};
// api binding
export const apiBinding = async (payload) => {
    /* payload
    {
        "api_key": "sdflkusd98sojhsdfi7yisjkdhfsd87usdkjfhs",
        "exchange_id": 1,
        "secret_key": "sdflkusd98sojhsdfi7yisjkdhfsd87usdkjfhs"
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_BINDING,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// unbind
export const unBind = async (payload) => {
    /* payload
    {
        "exchange_id": 1,
    }
    */
    let config = await configStrict({
        method: "delete",
        url: apis.API_BINDING,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// get bot all setting
export const getBotAllSetting = async () => {
    /* no payload
     */
    let config = await configStrict({
        method: "get",
        url: apis.API_BOT_ALL_SETTINGS,
    });
    let res = await axios(config);
    return res;
};
// bot setting
export const botSetting = async (payload) => {
    /* payload
    {
        "allow_auto_averaging": true,
        "exchange_id": 1,
        "max_pending_order": 20,
        "open_position_amount": 5,
        "signal_mode": 0
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_BOT_SETTING,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// cancel pending buy
export const cancelPendingBuy = async (payload) => {
    /* payload
    {
        "exchange_id": 1,
        "trade_id": "c2d29867-3d0b-d497-9191-18a9d8ee7830"
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_CANCEL_PENDING_BUY,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// cut trade
export const cancelPendingSell = async (payload) => {
    /* payload
    {
        "exchange_id": 1,
        "trade_id": "c2d29867-3d0b-d497-9191-18a9d8ee7830"
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_CANCEL_PENDING_SELL,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// exchange activation
export const exchangeActivation = async (payload) => {
    /* payload
    {
        "command": 1,
        "exchange_id": 1
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_EXCHANGE_ACTIVATION,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};
// server ip
export const getServerIP = async () => {
    /* no payload
     */
    let config = await configStrict({
        method: "get",
        url: apis.API_SERVER_IP,
    });
    let res = await axios(config);
    return res;
};
// user exchange
export const getUserExchange = async () => {
    /* no payload
     */
    let config = await configStrict({
        method: "get",
        url: apis.API_USER_EXCHANGE,
    });
    let res = await axios(config);
    return res;
};

// active trades
export const getTradesProfit = async (payload) => {
    /* payload 
    {
        exchange_id : 1,
        from_date : 2022-08-23,
        to_date : 2022-08-23,
    }
    */
    let config = await configStrict({
        method: "get",
        url: apis.API_TRADES_PROFIT,
        params: payload,
    });
    let res = await axios(config);
    return res;
};

// history trades
export const getTradesHistory = async (payload) => {
    /* payload 
    {
        exchange_id : 1,
        from_date : 2022-08-23,
        to_date : 2022-08-23,
    }
    */
    let config = await configStrict({
        method: "get",
        url: apis.API_TRADES_HISTORY,
        params: payload,
    });
    let res = await axios(config);
    return res;
};

// sync trades
export const syncTrades = async (payload) => {
    /* payload 
    {
        "exchange_id": 1,
        "trade_id": "c2d29867-3d0b-d497-9191-18a9d8ee7830"
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_TRADES_SYNC,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};

// averaging
export const avgTrades = async (payload) => {
    /* payload 
    {
        "buy_in_amount": 80,
        "exchange_id": 1,
        "sell_price": 0.06449,
        "trade_id": "c2d29867-3d0b-d497-9191-18a9d8ee7830"
    }
    */
    let config = await configStrict({
        method: "post",
        url: apis.API_TRADES_AVG,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);
    return res;
};

export const getTickSize = async (payload) => {
    let config = await configNotStrict({
        method: "get",
        url: apis.API_GET_TICK_SIZE,
        params: {
            base_asset: payload.base_asset,
            quote_asset: payload.quote_asset,
        },
    });
    let res = await axios(config);
    return res;
};

export const getCurrentPriceBinance = async (payload) => {
    let config = await configStrict({
        method: "get",
        url: apis.API_GET_CURRENT_PRICE_BINANCE,
        params: {
            symbols: JSON.stringify(payload),
        },
    });
    let res = await axios(config);
    return res;
};
