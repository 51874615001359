import { all, fork } from "redux-saga/effects";

//public
import RegisterSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import BalanceSaga from "./balance/saga";
import WalletSaga from "./wallet/saga";
import ResetPasswordSaga from "./auth/resetpassword/saga";
import AccountSaga from "./account/saga";
import RankSaga from "./rank/saga";
import BotSaga from "./bot/saga";
import CoinSaga from "./coin/saga";
import RewardSaga from "./reward/saga";
import AffiliateSaga from "./affiliate/saga";
import CountriesSaga from "./countries/saga";
import TimezonesSaga from "./timezones/saga";
import ActivateBotSaga from "./activatebot/saga";
import IpBindingSaga from "./ip_binding/saga";
import TradesSaga from "./trades/saga";
import WithdrawSaga from "./withdraw/saga";
import DepositSaga from "./deposit/saga";
import TransferSaga from "./transfer/saga";
import NewsSaga from "./news/saga";
import PortofolioSaga from "./portofolio/saga";
import TicketSaga from "./ticket/saga";
import GasFeeSaga from "./gasfee/saga";
import OpenfireSaga from "./openfire/saga";
// import WalletSaga from "./wallet/saga"

export default function* rootSaga() {
    yield all([
        //public
        fork(IpBindingSaga),
        fork(AccountSaga),
        fork(AuthSaga),
        fork(WalletSaga),
        fork(BalanceSaga),
        fork(RankSaga),
        ResetPasswordSaga(),
        BotSaga(),
        fork(CoinSaga),
        fork(RewardSaga),
        fork(AffiliateSaga),
        fork(CountriesSaga),
        fork(TimezonesSaga),
        fork(RegisterSaga),
        fork(ActivateBotSaga),
        fork(TradesSaga),
        fork(WithdrawSaga),
        fork(DepositSaga),
        fork(TransferSaga),
        fork(NewsSaga),
        fork(PortofolioSaga),
        fork(TicketSaga),
        fork(GasFeeSaga),
        fork(OpenfireSaga),
        // fork(WalletSaga)
    ]);
}
