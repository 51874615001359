import React from "react";
import LoadingSpinner from "../../assets/gif/spinner.gif";
import styled from "styled-components";
import BidboxLogo from "src/assets/image/logo/logo-bidbox-notext.png";

const Spinner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: grid;
    place-content: center;
    background: rgba(0, 0, 0, 0.05);
    width: 460px;
    height: 100vh;
    z-index: 10000;
`;
const Loading = () => {
    return (
        <Spinner>
            <img
                src={LoadingSpinner}
                alt=""
                style={{ backgrond: "transparent" }}
            />
        </Spinner>
    );
};
const LoadingBidbox = () => {
    return (
        <div className="loading-container">
            <div className="loading-box">
                <img src={BidboxLogo} alt="" />
            </div>
        </div>
    );
};
export { Loading, LoadingBidbox };
