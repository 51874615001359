import React from "react";
import TradingProblem from "../../assets/image/Trading-problem.png";
import AccountProblem from "../../assets/image/Accountproblem.png";
import MoneyFlowProblem from "../../assets/image/Moneyflow.png";
import BUGProblem from "../../assets/image/bug.png";
import SuggestionsOppinions from "../../assets/image/suggest.png";
import APIBinding from "../../assets/image/apibind.png";
import ReportViolations from "../../assets/image/report.png";
import Other from "../../assets/image/other.png";

import { Link } from "react-router-dom";
const HelpSupport = () => {
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Help & Support
                </span>
                <div className="n"></div>
            </header>
            <div className="help-center-grid">
                <Link
                    to="/user/trading-problem"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img
                                    src={TradingProblem}
                                    alt={TradingProblem}
                                />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Trading Problem
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/account-problem"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img
                                    src={AccountProblem}
                                    alt={AccountProblem}
                                />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Account Problem
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/money-flow-problem"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img
                                    src={MoneyFlowProblem}
                                    alt={MoneyFlowProblem}
                                />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Money Flow Problem
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/bug-problem"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img src={BUGProblem} alt={BUGProblem} />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Bug Problem
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/suggestions-oppinions"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img
                                    src={SuggestionsOppinions}
                                    alt={SuggestionsOppinions}
                                />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Suggestions / Opinions
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/api-binding-isues"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img src={APIBinding} alt={APIBinding} />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                API Binding Problem
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/report-violations"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img
                                    src={ReportViolations}
                                    alt={ReportViolations}
                                />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Report Violations
                            </span>
                        </div>
                    </div>
                </Link>
                <Link
                    to="/user/other"
                    className="card text-decoration-none"
                    style={{
                        background: "#f1f1f1",
                        border: "1px solid #008bae60",
                    }}
                >
                    <div className="card-body">
                        <div className="help-center">
                            <div className="icon">
                                <img src={Other} alt={Other} />
                            </div>
                            <span className="fz:12 fw:500 text-center text-dark">
                                Other
                            </span>
                        </div>
                    </div>
                </Link>
            </div>

            <Link
                to="/user/help-support-record"
                className="mt-3 btn btn-full text-uppercase text-white bg-app-color hover:bg-app-color-90 active:bg-app-color-80"
            >
                Help & Support Record
            </Link>
        </div>
    );
};

export default HelpSupport;
