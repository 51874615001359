import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reqResetPassword, initResetPassword } from "src/store/actions";
import toast from "react-hot-toast";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const storeResetPassword = useSelector((state) => state.ResetPassword);
    const [email, setEmail] = useState("");

    const handleResetPassword = () => {
        if (email === "" || email === null || email === undefined) {
            toast("please enter your email");
        } else {
            dispatch(
                reqResetPassword(
                    {
                        email: email,
                    },
                    props.history
                )
            );
        }
    };
    useEffect(() => {
        if (storeResetPassword.successMsg !== null) {
            toast(storeResetPassword.successMsg);
        }
        if (storeResetPassword.errorMsg !== null) {
            toast(storeResetPassword.errorMsg);
        }
        dispatch(initResetPassword());
    }, [storeResetPassword.successMsg, storeResetPassword.errorMsg]);

    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
            </header>
            <h1 className="text-left text-dark fz:20 fw:500 sttr-text mb-2">
                Reset Password
            </h1>
            <form id="form-validation">
                <div className="field">
                    <input
                        type="text"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="input-control"
                        placeholder="example@gmail.com"
                        required
                    />
                </div>
                <button
                    type="button"
                    onClick={handleResetPassword}
                    className="btn btn-full text-uppercase text-white bg-app-color hover:bg-app-color-90 active:bg-app-color-80"
                >
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default ForgotPassword;
