import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { ticketHistory, ticketInit } from "src/store/actions";
import toast from "react-hot-toast";

const HelpSupportRecordDetail = (props) => {
    const pathname = window.location.pathname;
    const split_segment_url = pathname.split("/");
    const id_ticket = split_segment_url[split_segment_url.length - 1];
    const dispatch = useDispatch();
    const ticketState = useSelector((state) => state.Ticket);
    useEffect(() => {
        dispatch(
            ticketHistory({
                id: id_ticket,
                page: 1,
                per_page: 100,
            })
        );
        dispatch(ticketInit());
        return () => {
            dispatch(ticketInit());
        };
    }, []);
    const showReplyTicket = () => {
        props.history.push(`/user/reply-ticket/${id_ticket}`);
    };
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Help & Support Record
                </span>
                <div className="n"></div>
            </header>
            {/* <div className="card mb-2 bg-app-color border-0">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-block">
                            <p className="m-0 text-white">
                                Type: API Binding Issues
                            </p>
                            <p className="m-0 text-white">
                                Date: 2022-17-08 | 21:40
                            </p>
                        </div>
                        <p className="m-0 fz:18 text-white">
                            Status:{" "}
                            <span className="text-uppercase">Solved</span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="card"
                style={{
                    background: "rgb(241, 241, 241)",
                    border: "1px solid rgba(0, 139, 174, 0.376)",
                }}
            >
                <div className="card-body py-1">
                    <p className="m-0 fz:13">
                        Q: Why is the API that has been bound, prompt API error?
                    </p>
                    <p className="mb-0 fz:13">
                        A: Do you have checked trading privileges when
                        reclassifying the binding API
                    </p>
                    <br />
                    <p className="m-0 fz:13 ps-2">
                        Check if your Secret Key is fully entered (usually when
                        you create an API, the Secret Key of the Exchange is
                        only displayed once, and it will be displayed as ***, so
                        check if your Secret Key is entered correctly <br />
                        <br />
                        Make sure you fill in the API key (Access key) and
                        Secret Key, the content is not filed in (for example,
                        filling the API key to Secret Key) <br />
                        <br />
                        When binding the Binance exchange API, confirm that the
                        exchange you are using is a currency main station
                        (Binance.com), because that is two different exchanges
                        We currently only supports coin-operated mainstays
                        (binance.com)
                    </p>
                </div>
            </div> */}
            <div className="d-flex align-items-center justify-content-between mb-1">
                <button
                    style={{ color: "white", margin: 10 }}
                    className="btn bg-app-color hover:bg-app-color-90 active:bg-app-color-70 text-uppercase px-4 fz:12"
                    onClick={showReplyTicket}
                >
                    Reply
                </button>
            </div>
            {ticketState.items.length > 0 &&
                ticketState.items[0].ticket_details &&
                ticketState.items[0].ticket_details.map((k, v) => (
                    <div key={v} className="card mb-2 order-1">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <p
                                    className={`m-0 fz:11 fw:600 ${
                                        k.is_client === true
                                            ? "text-gray"
                                            : "text-red"
                                    }`}
                                >
                                    {k.is_client === true
                                        ? "Me"
                                        : "Admin Bidbox"}
                                </p>
                                <p className="m-0 fz:11 fw:600 text-gray">
                                    {new Date(k.created_at).toLocaleString(
                                        "en-GB"
                                    )}
                                </p>
                            </div>
                            <h2
                                className="mb-1 fz:11 text-dark"
                                style={{
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {k.description}
                            </h2>
                            <div style={{ textAlign: "center" }}>
                                {k.attachment_image !== null && (
                                    <img
                                        src={k.attachment_image}
                                        width="50%"
                                        alt="img-attachment"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default HelpSupportRecordDetail;
