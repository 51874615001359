import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWallet } from "src/store/actions"
import { QRCodeSVG } from "qrcode.react";
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
const Deposit = () => {
  const dispatch = useDispatch()
  const wallet = useSelector(state=>state.Wallet)

  const copyText = () => {
    copy(wallet.address);
    toast("Wallet address has been copied");

  };
  useEffect(() => {
    dispatch(getWallet())
  },[])
  useEffect(() => {
    if (wallet.errorMsg !== null) toast(wallet.errorMsg)
  },[wallet.errorMsg])
  return (
    <div className="details-content">
      <header className="details-header">
        <button
          className="back-button mr-auto"
          onClick={() => window.history.back()}
        >
          <i className="fa-solid fa-angle-left back-icon text-dark"></i>
        </button>
        <span className="text-uppercase fw:500 mr-auto text-dark">Deposit</span>
      </header>
      <h1 className="mb-2 fz:15 fw:600 text-dark text-uppercase text-center">
        USDT-TRC20 ONLY
      </h1>
      <div className="d-flex align-items-center justify-content-center mb-2">
        <div className="qr-square">
          <QRCodeSVG
            className="qr-image"
            style={{ padding: 5 }}
            value={wallet.address}
          />
        </div>
      </div>

      <div className="wallet-address mb-3">
        <p className="mb-1 fz:11 text-mute sttr-text">Wallet Address</p>
        <h2
          className="m-0 fz:14 fw-600 sttr-text text-dark"
          id="wallet-address"
        >
          {wallet.address}
        </h2>
        <button style={{zIndex:20000}} className="cpy-btn wallet-btn" onClick={copyText}>
          <i className="bx bx-copy"></i>
        </button>
      </div>
      <p className="mb-2 fz:13 fw-500">Precautions</p>
      <p className="fz:12 fw-500">
        1. Please do not deposit any non-USDT assets to the address above.{" "}
        <br />
        2. Ensure you use TRC20 chain to deposit, or assets might be lost.{" "}
        <br />
        3. The Deposit may take a short while to arrive. <br />
        4. Funds may not be withdrawn from inactive accounts.
      </p>
    </div>
  );
};

export default Deposit;
