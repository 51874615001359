import axios from "axios";
import apis from "src/utils/apis";
import { configNotStrict } from "./config";
// login
export const loginUser = async (email, password) => {
    let data = JSON.stringify({
        email: email,
        password: password,
        captcha: "1234",
    });
    let config = configNotStrict({
        method: "post",
        url: apis.API_AUTH_LOGIN,
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    });
    let res = await axios(config);
    return res;
};
// refresh token
export const refreshToken = async (token) => {
    const res = configNotStrict({
        method: "get",
        url: `${apis.API_REFRESH_TOKEN}/${token}`,
    });
    return res;
};
// req otp
export const reqOtp = async (payload) => {
    /*
    {
        "address": "user@mail.com",
        "otp_context": 0,
        "target": 0
    }
    OTP context. 0=None, 1=Signup, 2=Api Binding, 3=Withdrawal, 4=Change Pin, 5=Change Password, 6=Change Email
    target. 0 for email, 1 for phone
    */
    let config = configNotStrict({
        method: "post",
        url: apis.API_AUTH_REQ_OTP,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    return await axios(config);
};
// req reset password
export const reqResetPassword = async (payload) => {
    /* payload
    {
        "email": "user@mail.com"
    }
    */
    let config = await configNotStrict({
        method: "post",
        url: apis.API_AUTH_REQ_RESET_PASSWORD,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    return await axios(config);
};
// reset password
export const resetPassword = async (payload) => {
    /* payload
    {
        "new_password": "123456",
        "new_password_repeat": "123456",
        "password_reset_token": "123456"
    }
    */

    let config = configNotStrict({
        method: "post",
        url: apis.API_AUTH_RESET_PASSWORD,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    return await axios(config);
};
// signup
export const signup = async (payload) => {
    /* payload
    {
        "captcha": "iuieys",
        "country": "US",
        "email": "user@mail.com",
        "full_name": "John",
        "otp": "123456",
        "password": "123456",
        "password_repeat": "123456",
        "referrer_username": "hgT6Hd7Hs0GeT6jdGf",
        "security_pin": "123456",
        "security_pin_repeat": "123456",
        "timezone": "America/Los_Angeles",
        "username": "johndoe"
    }
    */
    let config = configNotStrict({
        method: "post",
        url: apis.API_AUTH_SIGNUP,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    return await axios(config);
};
