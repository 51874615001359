import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
// Importing CSS Link
import "./assets/scss/main.css";
import "./assets/css/membercentre.css";
import "./assets/icons/boxicons-2/css/boxicons.min.css";
import "./assets/icons/fontawesome-6/css/all.min.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
if (!localStorage.getItem("bidbox_version_auth")) {
    localStorage.removeItem("bidbox_authUser");
} else {
    const latest_version = parseInt(process.env.REACT_APP_AUTH_VERSION);
    const version_auth = parseInt(localStorage.getItem("bidbox_version_auth"));
    if (version_auth < latest_version) {
        localStorage.removeItem("bidbox_authUser");
    }
}
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Toaster />
                <App />
            </PersistGate>
        </Provider>
    </BrowserRouter>
);
