import React from "react";
import { Route,Redirect} from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAuthRoutes,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("bidbox_authUser")) {
        return (
          <Redirect
            to={{ pathname: "/auth/signin", state: { from: props.location } }}
          />
        );
      }else if(isAuthRoutes && localStorage.getItem("bidbox_authUser")){
        return (
          <Redirect
            to={{ pathname: "/user/dashboard", state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default Authmiddleware;
