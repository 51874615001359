import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { ticketHistory, ticketInit } from "src/store/actions";
import toast from "react-hot-toast";

const HelpSupportRecord = (props) => {
    const dispatch = useDispatch();
    const ticketState = useSelector((state) => state.Ticket);
    useEffect(() => {
        dispatch(
            ticketHistory({
                page: 1,
                per_page: 100,
            })
        );
        dispatch(ticketInit());
        return () => {
            dispatch(ticketInit());
        };
    }, []);
    const get_status_ticket = (v) => {
        switch (v) {
            case true:
                return "waiting for admin's answer";
            default:
                return "admin answered";
        }
    };
    const openDetail = (id_ticket) => {
        props.history.push(`/user/help-support-record/${id_ticket}`);
    };
    return (
        <div className="details-content">
            <header className="details-header">
                <button
                    className="back-button"
                    onClick={() => window.history.back()}
                >
                    <i className="fa-solid fa-angle-left back-icon text-dark"></i>
                </button>
                <span className="text-uppercase fw:500 text-dark">
                    Help & Support Record
                </span>
                <div className="n"></div>
            </header>
            {/* <div className="card mb-2 bg-app-color border-0">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-block">
                            <p className="m-0 text-white">
                                Type: API Binding Issues
                            </p>
                            <p className="m-0 text-white">
                                Date: 2022-17-08 | 21:40
                            </p>
                        </div>
                        <p className="m-0 fz:18 text-white">
                            Status:{" "}
                            <span className="text-uppercase">Solved</span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                className="card"
                style={{
                    background: "rgb(241, 241, 241)",
                    border: "1px solid rgba(0, 139, 174, 0.376)",
                }}
            >
                <div className="card-body py-1">
                    <p className="m-0 fz:13">
                        Q: Why is the API that has been bound, prompt API error?
                    </p>
                    <p className="mb-0 fz:13">
                        A: Do you have checked trading privileges when
                        reclassifying the binding API
                    </p>
                    <br />
                    <p className="m-0 fz:13 ps-2">
                        Check if your Secret Key is fully entered (usually when
                        you create an API, the Secret Key of the Exchange is
                        only displayed once, and it will be displayed as ***, so
                        check if your Secret Key is entered correctly <br />
                        <br />
                        Make sure you fill in the API key (Access key) and
                        Secret Key, the content is not filed in (for example,
                        filling the API key to Secret Key) <br />
                        <br />
                        When binding the Binance exchange API, confirm that the
                        exchange you are using is a currency main station
                        (Binance.com), because that is two different exchanges
                        We currently only supports coin-operated mainstays
                        (binance.com)
                    </p>
                </div>
            </div> */}
            {ticketState.items.map((k, v) => (
                <div
                    key={v}
                    className="card mb-2 order-1 card-active"
                    style={{ cursor: "pointer" }}
                    onClick={() => openDetail(k.id)}
                >
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                            <h2 className="m-0 fz:11 fw:600 text-gray">
                                {k.master_ticket_category.name}
                            </h2>
                            <p className="m-0 fz:11 fw:600 text-gray">
                                {new Date(k.created_at).toLocaleString("en-GB")}
                            </p>
                        </div>
                        {k.subject !== "" && k.subject !== null && (
                            <h2 className="mb-1 fz:11 text-dark">
                                {" "}
                                {k.subject !== "" && k.subject !== null
                                    ? k.subject
                                    : ""}
                            </h2>
                        )}
                        <div className="d-flex align-items-center justify-content-between mb-1">
                            <h2
                                className={`m-0 fz:11 fw:600 ${
                                    k.is_answer_client === true
                                        ? "text-green"
                                        : "text-red"
                                }`}
                            >
                                {get_status_ticket(k.is_answer_client)}
                            </h2>
                            {/* <p className="m-0 fz:11 fw:600 text-gray">TRC20</p> */}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HelpSupportRecord;
