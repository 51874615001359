import {
    BOT_SETTING,
    BOT_SETTING_STATUS,
    BOT_PLUGIN,
    BOT_STATUS,
    BOT_UPGRADE,
    BOT_UPGRADE_SUCCESS,
    BOT_SETTING_STATUS_SUCCESS,
    BOT_SETTING_SUCCESS,
    BOT_PLUGIN_SUCCESS,
    BOT_STATUS_SUCCESS,
    BOT_STATUS_ERROR,
    BOT_PLUGIN_ERROR,
    BOT_SETTING_STATUS_ERROR,
    BOT_SETTING_ERROR,
    BOT_UPGRADE_ERROR,
    SET_REQUEST_TRADE,
    SET_REQUEST_TRADE_ERROR,
    SET_REQUEST_TRADE_SUCCESS,
} from "./actionTypes";

const initialState = {
    stopLoss: false,
    autoStopLoss: false,
    autoAveraging: false,
    bot_expired_at: null,
    bot_expired_month: null,
    bot_expired_at_date: null,
    bot_price: null,
    address: null,
    currency_id: null,
    plugin_id: null,
    plugin_name: null,
    plugin_signal_qty: null,
    itemPlugins: [],
    binance: {
        exchange_id: 1,
        exchange_name: "Binance",
        exchange_active: false,
        bot_setting: {
            exchange_id: 1,
            exchange_name: "Binance",
            open_position_amount: 0,
            signal_mode: 0,
            signal_mode_name: "STANDARD",
            max_pending_order: 0,
        },
        api_binding: {
            exchange_id: 1,
            exchange_name: "Binance",
            api_key: "",
            secret_key: "",
            active: false,
        },
    },
    tokocrypto: {
        exchange_id: 2,
        exchange_name: "Tokocrypto",
        exchange_active: false,
        bot_setting: {
            exchange_id: 2,
            exchange_name: "Tokocrypto",
            open_position_amount: 0,
            signal_mode: 0,
            signal_mode_name: "STANDARD",
            max_pending_order: 0,
        },
        api_binding: {
            exchange_id: 2,
            exchange_name: "Tokocrypto",
            api_key: "",
            secret_key: "",
            active: false,
        },
    },
    loading: false,
    successMsg: null,
    errorMsg: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BOT_UPGRADE:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case BOT_SETTING_STATUS:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case BOT_SETTING_STATUS_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case BOT_SETTING_STATUS_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        case BOT_SETTING:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case BOT_PLUGIN:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;
        case BOT_STATUS:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case BOT_STATUS_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                errorMsg: null,
            };
            break;

        case BOT_UPGRADE_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload,
                loading: false,
                errorMsg: null,
            };
            break;

        case BOT_SETTING_SUCCESS:
            state = {
                ...state,
                successMsg: action.payload,
                loading: false,
                errorMsg: null,
            };
            break;

        case BOT_PLUGIN_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                errorMsg: null,
            };
            break;

        case BOT_PLUGIN_ERROR:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMsg: null,
            };
            break;

        case BOT_UPGRADE_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case BOT_STATUS_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;

        case BOT_SETTING_ERROR:
            state = {
                ...state,
                loading: false,
                errorMsg: action.payload,
            };
            break;

        case SET_REQUEST_TRADE:
            state = {
                ...state,
                loading: true,
                successMsg: null,
                errorMsg: null,
            };
            break;

        case SET_REQUEST_TRADE_SUCCESS:
            state = {
                ...state,
                ...action.payload.payload,
                loading: false,
                successMsg: action.payload.message,
                errorMsg: null,
            };
            break;

        case SET_REQUEST_TRADE_ERROR:
            state = {
                ...state,
                loading: false,
                successMsg: null,
                errorMsg: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
