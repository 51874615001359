import apis from "src/utils/apis";
import axios from "axios";

export const getPortofolio = async (params) => {
    let config = {
        method: "get",
        url: apis.API_GET_PORTOFOLIO,
        params: params,
        headers: {},
    };
    let res = await axios(config);
    return res;
};
